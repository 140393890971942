import React from 'react'
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'

const MessageDate = styled.div`
        font-size: 10px;
        text-align: ${(props) => (props.isOwnMessage ? 'right' : 'left')};
        align-self: flex-end;
`;

const StyledSender = styled.div`
    align-self: flex-start;
    text-align: ${(props) => (props.isOwnMessage ? 'right' : 'left')};
    p {
        text-align: ${(props) => (props.isOwnMessage ? 'right' : 'left')};     
        font-size: 12px;
        font-weight: bold;
    }

`;

const SenderName = ({
    isOwnMessage,
    message,
    isDark
}) => {
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;
    return (
        <StyledSender
            isDark={isDark}
            className='flex flex-column'
            isOwnMessage={isOwnMessage}>
            <div className='flex items-center'>
                <img src={message?.sender?.profilePhoto} alt='Profile' style={{ width: '3em', height: '3em', borderRadius: '20%' }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        padding: '0.5em',
                        margin: '0.5em',
                    }}
                >
                    <p style={{
                        color: theme === 'dark' ? colors.white : colors.black,
                        textAlign: isOwnMessage ? 'right' : 'left',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}>
                        {message.senderFullName}
                    </p>
                    {/* Message Date */}
                    <MessageDate isOwnMessage={isOwnMessage} isDark={isDark}>
                        <span style={{
                            color: theme === 'dark' ? colors.white : colors.black,
                            textAlign: isOwnMessage ? 'right' : 'left',
                            fontSize: '10px',
                        }}>
                            {new Date(message.createdAt).toLocaleString()}
                        </span>
                    </MessageDate>
                </div>
            </div>
        </StyledSender>
    )
}

export default SenderName
