import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import ConditionalRender from '../Core/ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../Core/HoverPopup';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext';
import colors from '../globalStyles.scss';
import MultiFields from '../Core/MultiFields';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .field-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
    border-bottom-color: ${props => props.showLightColors && colors.white};
    padding-bottom: 10px;
    background: ${props => props.showLightColors && colors.white};
    padding: 1em;

    input, textarea {
      margin-right: 10px;
    }

    .remove {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        background: ${colors.red};
        margin-left: 5px;
        padding: 0.5em;
        color: ${colors.white};
    }
  }
`;

const FieldsWrapper = ({
    name,
    showLightColors,
    validate,
    initialValue,
    setIsEditing,
    disabled,
    ...props
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    return (
        <FieldArray name={name} {...props} initialValue={initialValue}
        >
            {({ fields }) => {

                return (
                    <FieldContainer
                        theme={theme}
                        showLightColors={showLightColors}
                    >
                        <p className='mb-md'>
                            {text?.proposals?.create?.fields?.description}
                        </p>
                        {fields.map((field, index) => (
                            <div key={field} className="field-item">
                                <span className='mr-sm' style={{
                                    height: '100%',
                                    background: colors.blue,
                                    color: colors.white,
                                    padding: '0.5em',
                                    fontFamily: colors.openSans,
                                }}>{index + 1}</span>
                                <div className='w-100'>
                                    <div className={classnames({
                                        'w-100': true,
                                        'flex': isDesktop || isTablet,
                                    })} >
                                        <MultiFields
                                            name={`${field}.description`}
                                            component="input"
                                            type="text"
                                            showLightColors={showLightColors}
                                            label={text?.proposals?.create?.descriptionTwo}
                                            className={classnames({
                                                'mr-md': isDesktop || isTablet,
                                            })}
                                            validate={validate}
                                            setIsEditing={setIsEditing}
                                            disabled={disabled}

                                        />
                                        <MultiFields
                                            className={classnames({
                                                'mr-md': isDesktop || isTablet,
                                                'mt-md': isPhone
                                            })}
                                            name={`${field}.amount`}
                                            component="amount"
                                            type="number"
                                            showLightColors={showLightColors}
                                            label={text?.proposals?.create?.fields?.amount}
                                            setIsEditing={setIsEditing}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <MultiFields
                                        name={`${field}.notes`}
                                        component="textarea"
                                        type="text"
                                        showLightColors={showLightColors}
                                        label={text?.proposals?.create?.fields?.notes}
                                        className='mr-md mt-md w-100'
                                        setIsEditing={setIsEditing}
                                        disabled={disabled}
                                    />
                                </div>
                                <ConditionalRender renderIf={fields.length > 1}>
                                    <ConditionalRender renderIf={!disabled}>
                                        <span
                                            className="remove"
                                            onClick={() => {
                                                fields.remove(index);
                                                setIsEditing && setIsEditing(true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faClose} style={{
                                                color: colors.white
                                            }} />
                                        </span>
                                    </ConditionalRender>
                                </ConditionalRender>
                            </div>
                        ))}
                        <ConditionalRender renderIf={!disabled}>

                            <div
                                className="flex items-center justify-center"
                            >
                                <HoverPopup
                                    onClick={() => {
                                        fields.push({});
                                        setIsEditing && setIsEditing(true);
                                    }}
                                    style={{
                                        background: colors.blue,
                                        cursor: 'pointer'
                                    }}
                                    placement={'bottom'}
                                    id={'addProposalField'}
                                    text={isDesktop && text?.proposals?.create?.fields?.add}
                                >
                                    <FontAwesomeIcon icon={faAdd} />
                                </HoverPopup>
                            </div>
                        </ConditionalRender>
                    </FieldContainer>
                )
            }}
        </FieldArray>
    );
};

export default FieldsWrapper;
