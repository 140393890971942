import React, { useState, useEffect, useMemo, useCallback } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import { Field } from 'react-final-form';
import CallToAction from './CallToAction';
import MultiFields from './MultiFields';
import Wrapper from './Wrapper';
import ProgressBar from './ProgressBar';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import RenderImages from './RenderImages';
import RichEditorWithImageUploader from './Inputs/RichEditorWithImageUploader';
import { useDropzone } from 'react-dropzone';

import {
    updateLogs,
    updateDailyLog,
    updateLogsImages,
    getTimesheetById,
} from '../utils/calls';
import base64ToBlob from '../utils/base64ToBlob';

import CalendarLogsFilters from './CalendarLogsFilters';
import SelectableBox from './SelectableBox';
import SearchInput from './Inputs/SearchInput';
import HoverPopup from './HoverPopup';
import TimeEditForm from './Inputs/TimeEditForm';
import ConfirmModal from './ConfirmModal';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';

const StyledButtons = styled.div`
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .next {
        margin-right: 1em;
    }
    .prev {
        margin-left: 1em;
    }
    svg {
        font-size: 2.5em;
        animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.2);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
  
`;


const CalendarView = styled.div`
  display: flex;
  flex-direction: column;
`;

const WeekView = styled.div`
  display: flex;
`;

const DayColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // border-right: 1px solid ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
    padding-right: 0.5em;
  &:last-child {
    border-right: none;
    padding-right: 0;
}
  .wrapper {
    display: flex;
    flex-direction: column;
  }
`;

const DateHeader = styled.div`
  text-align: center;
  padding: 10px;
  background-color: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
  span {
      font-family: ${colors.openSans};
  }
  display flex;
  align-items: center;
  justify-content: center;
`;

const StyledUploader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dropzone {
        display: flex;
        flex-direction: column;
        margin: 0.5em 0;
        padding: 1em;
        background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
        cursor: pointer;
        transition: all 0.2s ease 0s;
        &:hover {
            background: ${colors.lightGray};
        }
        span {
            font-family: ${colors.roboto};
            color: ${props => props.theme === 'dark' ? colors.black : colors.black};
        }
    }
`

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CalendarLogs = ({
    tasks,
    timeLogs,
    transformTimesToEvents,
    getTitleFromMilliseconds,
    fetchTimeLogs,
    logsIsLoading,
    user,
    employeeId,
    employees,
    setEmployee,
    employee,
    fetchEmployeeData,
    isLoading,
    fetchProjectTasks,
    weeklyTotalTime,
    fetchWeeklyTime,
    currentWeekStart,
    setCurrentWeekStart,
    projects,
    setProject,
    project
}) => {
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();
    const [weekDisplay, setWeekDisplay] = useState('');

    const location = useLocation();

    useEffect(() => {
        // Calculate the end of the week (6 days after the start)
        const currentWeekEnd = moment(currentWeekStart).add(6, 'days');

        // Format the range as "May 13 2025 - May 19 2025"
        const formattedStart = currentWeekStart.format('MMMM D YYYY');
        const formattedEnd = currentWeekEnd.format('MMMM D YYYY');

        // Update the display state
        setWeekDisplay(`${formattedStart} - ${formattedEnd}`);
    }, [currentWeekStart]);

    const [selectedDay, setSelectedDay] = useState({});

    const [openDailyLog, setOpenDailyLog] = useState({});

    const handleOpenDailyLog = (open, dayValue) => {
        if (open) {
            setOpenDailyLog(dayValue);
        } else {
            setOpenDailyLog({});
        }
    };

    // const handleSaveDailyLog = async ({ dailyLog }) => {

    //     try {
    //         const res = await updateDailyLog(dailyLog, openDailyLog?.clockIn, employeeId);
    //         if (res.status === 200) {
    //             handleOpenDailyLog(false, {});
    //             notify(text?.notificationsUI?.timeLog?.saved, 'success');
    //         } else {
    //             notify(text?.notificationsUI?.timeLog?.error, 'error')
    //             console.error(res.message)
    //             handleOpenDailyLog(false, {});
    //         }

    //     } catch (error) {
    //         notify(`Error: ${error.message}`, 'error')
    //         console.error(error)
    //     }
    // }

    const removeImage = (imageIndex) => {
        setDailyLogsImgs((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
    };

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeCarouselModal = () => {
        setIsModalOpen(false);
    };

    const [imageError, setImageError] = useState('')

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setDailyLogsImgs((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setImageError('')
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    const [loading, setLoading] = useState(false);
    const [dailyLogsImgs, setDailyLogsImgs] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useMemo(() => {
        setDailyLogsImgs(openDailyLog?.imageUrls)
    }, [openDailyLog.imageUrls])

    const handleSaveDailyLog = async (values) => {
        const userId = employeeId || user?._id;
        const projectId = project?.value || null;
        // Helper function to get the start of the day in UTC
        const getStartOfDayInUTC = (dateStr) => {
            if (!dateStr || isNaN(new Date(dateStr).getTime())) {
                return null;
            }

            const date = new Date(dateStr);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();
        };
        setLoading(true);
        const processProjectImages = async () => {
            const formData = new FormData();

            dailyLogsImgs.forEach((image) => {
                if (image.new) {
                    const imageBlob = base64ToBlob(image?.url);
                    formData.append('newImages', imageBlob, image?.originalName);
                } else {
                    formData.append('oldImages', JSON.stringify(image));
                }
            });

            formData.append('timesheetId', openDailyLog?._id);

            // Send to the API
            const res = await updateLogsImages(formData);
            if (res.status === 200) {
                console.log('Images updated');
            } else {
                setLoading(false);
                throw new Error('Images update failed');
            }

            return res;
        };

        try {
            // Step 1: Use fallback for dailyLog if it's undefined or null
            const logToUpdate = values?.dailyLog || openDailyLog?.dailyLog

            // Ensure dailyLog is not empty before proceeding
            if (!logToUpdate) {
                throw new Error('No daily log found to update.');
            }
            // Step 2: Update the daily log
            const updateLogRes = await updateDailyLog(logToUpdate, openDailyLog?.clockIn, userId);

            if (updateLogRes.status === 200) {
                // Step 3: Update project images
                await processProjectImages();
                // Step 4: Fetch the latest timesheet after all updates
                let { data: updatedTimesheet, status } = await getTimesheetById(openDailyLog?._id);
                console.log(updatedTimesheet);
                if (status === 200) {
                    updatedTimesheet = Object.keys(updatedTimesheet || {}).reduce((result, key) => {
                        if (key === "clockIn") { // Check if the key is "clockIn"
                            const entry = updatedTimesheet[key]; // Get the clockIn value

                            if (entry) { // Ensure the clockIn value exists
                                const clockInStartOfDay = getStartOfDayInUTC(entry);
                                if (clockInStartOfDay) {
                                    result[key] = clockInStartOfDay;
                                }
                            }
                        } else {
                            result[key] = updatedTimesheet[key];
                        }
                        return result;
                    }, {});
                    setOpenDailyLog(updatedTimesheet);
                    setLoading(false);
                } else {
                    setLoading(false);
                    throw new Error('Failed to fetch updated timesheet after updates');
                }

                // Step 5: Refresh time logs after everything is done
                const isAdmin = projects?.some(
                    (project) =>
                        project._id === projectId &&
                        (project.adminEmployees?.some((employee) => employee?.id === user?.id) || user?.roleName === 'Admin')
                );
                await fetchTimeLogs(userId, projectId, isAdmin);

                // handleOpenDailyLog(); // Close the daily log UI
            } else {
                console.error(updateLogRes.message);
                handleOpenDailyLog();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchTimeLogs(employeeId, project?.value || null);
    }, [location.search])

    const [isOpen, setIsOpen] = useState(false)

    const nextWeek = () => {
        setCurrentWeekStart(currentWeekStart.clone().add(1, 'weeks'));
    };

    const prevWeek = () => {
        setCurrentWeekStart(currentWeekStart.clone().subtract(1, 'weeks'));
    };

    const openModal = (day, key) => {
        const id = employeeId;
        const filteredTasks = tasks?.filter((x) => !x.isArchived)?.map((task) => {
            return {
                id: task._id,
                ...task,
            }
        })

        // Combine tasks from `day.tasks` and the global `tasks` array
        const combinedTasks = [
            ...(day?.tasks?.map((task) => {
                // Enrich day tasks with taskProject details if missing
                return {
                    ...task,
                    taskProject: task.taskProject || {
                        projectName: task.projectName || 'Untitled Project',
                        adminEmployees: task.adminEmployees || [],
                        assignedEmployees: task.assignedEmployees || [],
                    },
                };
            }) || []),
            ...filteredTasks, // Include tasks from the global `tasks` array
        ];

        // Deduplicate tasks based on `taskId`, ensuring a fallback for undefined taskIds
        const uniqueTasks = combinedTasks.reduce((acc, task) => {
            const taskKey = task.taskId || task._id; // Fallback to `_id` if `taskId` is not defined
            if (taskKey && !acc[taskKey]) {
                acc[taskKey] = task;
            }
            return acc;
        }, {});

        const allTasks = Object.values(uniqueTasks).map((task) => {
            const projectName = task.taskProject?.projectName || task.projectName || 'Untitled Project';
            const projectStatus = task.taskProject?.projectStatus || 'pending';
            const taskTitle = task.taskTitle || 'Untitled Task';
            const timeSpent = day?.tasks?.find((t) => t.taskId === task.taskId)?.timeSpent || task.timeSpent || 0;

            return {
                ...task,
                id: task.taskId || task._id,
                projectName,
                projectStatus,
                taskTitle,
                timeSpent,
            };
        });


        // Reduce tasks into the accumulator
        const newObj = allTasks.reduce((acc, task) => {
            const { projectName, projectStatus, taskTitle, timeSpent, taskId, taskProject } = task;

            if (id) {
                if (
                    (
                        taskProject?.adminEmployees?.some((x) => x.id === user?.id) &&
                        (taskProject?.assignedEmployees?.some((x) => x.id === id) || taskProject?.adminEmployees?.some((x) => x.id === id))
                    ) ||
                    (user?.roleName === 'Admin' && taskProject?.assignedEmployees?.some((x) => x.id === id))
                ) {
                    acc[`${projectName} - ${taskTitle}`] = {
                        id: taskId || task._id,
                        taskTitle,
                        projectName,
                        projectStatus,
                        totalTime: timeSpent, // Include totalTime even if it is 0
                    };
                }
            } else {
                if (
                    user?.roleName === 'Admin' ||
                    (
                        taskProject?.adminEmployees?.some((x) => x.id === user?.id) ||
                        taskProject?.assignedEmployees?.some((x) => x.id === user?.id)
                    )
                ) {
                    acc[`${projectName} - ${taskTitle}`] = {
                        id: taskId || task._id,
                        taskTitle,
                        projectName,
                        projectStatus,
                        totalTime: timeSpent,
                    };
                }
            }
            return acc;
        }, {});

        // Create the day object
        const createDay = !day
            ? {
                tasks: {
                    ...newObj,
                },
                clockIn: key,
            }
            : { ...day, tasks: newObj };

        setIsOpen(!isOpen);
        setSelectedDay(createDay);
    };

    const closeModal = () => {
        setIsOpen(false)
        setSelectedDay({})
    }

    const onSubmit = async (values) => {

        // Transform the values into the desired format
        const logs = Object.keys(values)?.reduce((acc, key) => {
            const [type, taskId] = key.split("_"); // Use taskId instead of projectId
            if (!acc[taskId]) {
                acc[taskId] = { hours: 0, minutes: 0 };
            }
            if (type === "hours") {
                acc[taskId].hours += parseInt(values[key], 10);
            } else if (type === "minutes") {
                acc[taskId].minutes += parseInt(values[key], 10);
            }
            return acc;
        }, {});

        const logsArray = Object.entries(logs).map(([taskId, timeData]) => ({
            taskId,
            totalTime: (timeData.hours * 60 + timeData.minutes) * 60000,
        }));

        try {
            // Use logsArray for the update
            const res = await updateLogs(logsArray, selectedDay?.clockIn, employeeId);
            if (res.status === 200) {
                closeModal();
                fetchTimeLogs(employeeId, project?.value || null);
                notify(text?.notificationsUI?.timeLog?.saved, "success");
                fetchWeeklyTime(employeeId, project?.value || null);

            }
        } catch (error) {
            notify(`Error: ${error.message}`, "error");
            console.error(error);
        }
    };


    const [filteredSelectedDay, setFilteredSelectedDay] = useState(selectedDay);

    useMemo(() => {
        setFilteredSelectedDay(selectedDay);
    }, [selectedDay])
    const [query, setQuery] = useState('');

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setQuery(query);

        if (selectedDay?.tasks) {
            // Filter tasks based on project name or task title
            const filteredTasks = Object.keys(selectedDay.tasks)
                ?.filter(key => {
                    const task = selectedDay.tasks[key];
                    const matchesProjectName = task.projectName?.toLowerCase().includes(query);
                    const matchesTaskName = task.taskTitle?.toLowerCase().includes(query);
                    return matchesProjectName || matchesTaskName;
                })
                ?.reduce((result, key) => {
                    result[key] = selectedDay.tasks[key];
                    return result;
                }, {});

            setFilteredSelectedDay(prevState => ({
                ...prevState,
                tasks: filteredTasks
            }));
        } else {
            setFilteredSelectedDay(prevState => ({
                ...prevState,
                tasks: {}
            }));
        }
    };


    // Function to transform timeLogs to a daily format for the current week
    const eventsForWeek = transformTimesToEvents(timeLogs);

    const renderWeek = () => {
        const currentWeekStartDate = new Date(currentWeekStart); // Ensure currentWeekStart is a Date object
        return daysOfWeek.map((day, index) => {
            const now = new Date();

            // Calculate yesterday's date
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1); // Subtract one day

            const yesterdaysTime = new Date(yesterday.getTime() - yesterday.getTimezoneOffset() * 60000)
                .toISOString()
                .split('.')[0] + 'Z';

            const dayDate = new Date(currentWeekStartDate);
            dayDate.setDate(currentWeekStartDate.getDate() + index);
            const dayKey = new Date(Date.UTC(dayDate.getUTCFullYear(), dayDate.getUTCMonth(), dayDate.getUTCDate(), 0, 0, 0, 0)).toISOString();
            const dayValues = eventsForWeek[dayKey];
            const isRunning = dayValues?.isRunning;

            // Compare with yesterday's date instead of today's date
            const isFutureDate = dayDate > new Date(yesterdaysTime);

            return (
                <DayColumn key={day} theme={theme} style={{
                    height: '100%',
                }}>
                    <DateHeader theme={theme} style={{
                        height: '10%',
                    }}>
                        <div className="flex items-center">
                            <span className="mr-sm">
                                {dayDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                            </span>
                            <ConditionalRender renderIf={!isRunning && !isFutureDate}>
                                <HoverPopup
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        background: 'transparent',
                                        border: 'none',
                                        hoverBackground: 'transparent',
                                        padding: '0',
                                    }}
                                    placement={'right'}
                                    id={`${day}_${dayValues?.clockIn ? new Date(dayValues.clockIn).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }).replace(/\//g, '-') : ''}`}
                                    text={text?.logs?.edit}
                                >
                                    <FontAwesomeIcon
                                        onClick={() => openModal(dayValues, dayKey)}
                                        icon={faPencil}
                                        style={{
                                            color: theme === 'dark' ? colors.white : colors.black,
                                            cursor: 'pointer',
                                        }}
                                    />
                                </HoverPopup>
                            </ConditionalRender>
                        </div>
                    </DateHeader>

                    <div className="wrapper h-90">
                        <ConditionalRender renderIf={isEmpty(dayValues)}>
                            <p className='mt-sm'>{text?.logs?.notTimeLogged}</p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isEmpty(dayValues)}>
                            <div>
                                <CallToAction
                                    className='mb-sm mt-sm'
                                    type="button"
                                    onClick={() => handleOpenDailyLog(true, dayValues)}
                                    text={text?.logs?.dailyLogs}
                                    btnHeight={'25px'}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                                <div className='flex flex-column pr-sm pl-sm'>
                                    <p>{text?.logs?.totalTimeLogged}</p>
                                    <p>{dayValues?.totalTimeText}</p>
                                </div>
                            </div>

                            <ConditionalRender renderIf={!isEmpty(dayValues?.tasks)}>
                                <div className="flex flex-column  scroll-container " style={{
                                    height: '100%',
                                }}>
                                    {dayValues?.tasks
                                        ?.filter((task) => task.timeSpent > 0)
                                        ?.map((task, index) => (
                                            <SelectableBox
                                                isLink
                                                to={`/tasks/details?id=${task.taskId}`}
                                                padding={'0.5em'}
                                                key={task.taskId || index}
                                                className="mb-sm">
                                                <p className="b align-self-start">
                                                    {task.taskTitle}
                                                </p>
                                                <p className="b align-self-start">
                                                    {task.projectName}
                                                </p>
                                                <ProgressBar
                                                    value={((task.timeSpent / dayValues.totalTime) * 100).toFixed(2)}
                                                    maxValue={100}
                                                />
                                                <p>{task.taskTotalTimeText}</p>

                                            </SelectableBox>
                                        ))}
                                </div>
                                <ConfirmModal
                                    text={text?.logs?.modal?.button}
                                    toggle={() => handleOpenDailyLog(false, dayValues)}
                                    isOpen={!isEmpty(openDailyLog) && openDailyLog?.clockIn === dayKey}
                                    btnStyles={{
                                        color: colors.blue,
                                    }}
                                    onSubmit={handleSaveDailyLog}
                                    isForm
                                    projectId={project?.value || null}
                                    width={isDesktop ? '80%' : '100%'}
                                    height={isDesktop ? '80%' : '100%'}
                                >
                                    <Wrapper
                                        width={'100%'}
                                        isLoading={loading}
                                        title={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(openDailyLog?.clockIn)}`}
                                        button={

                                            <StyledUploader
                                                theme={theme}
                                            >
                                                {
                                                    <div
                                                        className='dropzone'
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <span>
                                                            {text?.inventory?.details?.uploadImages}
                                                        </span>
                                                    </div>

                                                }
                                            </StyledUploader>
                                        }
                                    >
                                        <ConditionalRender renderIf={!isEmpty(dailyLogsImgs)}>
                                            <RenderImages
                                                images={dailyLogsImgs}
                                                removeImage={removeImage}
                                                openModal={openCarousel}
                                                closeModal={closeCarouselModal}
                                                setActiveIndex={setActiveIndex}
                                                activeIndex={activeIndex}
                                                isModalOpen={isModalOpen}
                                            />
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={isEmpty(dailyLogsImgs)}>
                                            <p className='mb-md'>
                                                {text?.projects?.create?.noFoundMsgs}
                                            </p>
                                        </ConditionalRender>
                                        <div className="editor w-100">
                                            <Field
                                                name={'dailyLog'}
                                            >
                                                {({ input }) => {
                                                    return (
                                                        <RichEditorWithImageUploader
                                                            noGif
                                                            showForm={false}
                                                            name="dailyLog"
                                                            initialValue={openDailyLog?.dailyLog}
                                                            onChange={(value) => {
                                                                input.onChange(value)
                                                            }}
                                                            height={false}
                                                            noKeyDown
                                                            noButton
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                    </Wrapper>
                                </ConfirmModal>
                            </ConditionalRender>
                        </ConditionalRender>
                    </div>
                </DayColumn >
            );
        });
    };
    return (
        <div className='flex flex-column h-100'>
            <ConditionalRender isLoading={isLoading} renderIf={true}>
                <div className='flex flex-column' style={{
                    height: '30%'
                }}>
                    <h4 className='mb-sm flex'>
                        {text?.logs?.timeLogs}
                    </h4>
                    {/* <ConditionalRender renderIf={user.roleName === 'Admin'}> */}
                    <CalendarLogsFilters
                        employee={employee}
                        fetchEmployeeData={fetchEmployeeData}
                        employeeId={employeeId}
                        employees={employees}
                        user={user}
                        fetchProjectTasks={fetchProjectTasks}
                        fetchTimeLogs={fetchTimeLogs}
                        fetchWeeklyTime={fetchWeeklyTime}
                        setEmployee={setEmployee}
                        projects={projects}
                        setProject={setProject}
                        project={project}
                        setCurrentWeekStart={setCurrentWeekStart}
                    />
                    {/* </ConditionalRender> */}
                    <ConditionalRender renderIf={user.roleName === 'Admin'}>
                        <p className='flex'>
                            {text?.logs?.info}
                            <Link className='ml-sm underline' to={`/analytics`}>
                                {text?.logs?.title}
                            </Link>
                        </p>
                    </ConditionalRender>
                    <div className='flex mt-md'>
                        <span className='mr-sm'>
                            {text?.logs?.totalTimeWeek}
                        </span>
                        <span>
                            {getTitleFromMilliseconds(weeklyTotalTime)}
                        </span>
                    </div>
                    <StyledButtons className={'flex justify-end mb-sm buttons-container'}>
                        <button className='next' onClick={prevWeek}>
                            <FontAwesomeIcon
                                icon={faCircleDot} style={{
                                    cursor: 'pointer'
                                }}
                            />
                        </button>
                        <p style={{
                            fontSize: '1.5em'
                        }}>
                            {weekDisplay}
                        </p>
                        <button className='prev' onClick={nextWeek}>
                            <FontAwesomeIcon
                                icon={faCircleDot} style={{
                                    cursor: 'pointer'
                                }}
                            />
                        </button>
                    </StyledButtons>
                </div>

                <CalendarView theme={theme} style={{
                    height: '70%'
                }}>
                    <WeekView style={{
                        height: '100%'
                    }}>
                        {renderWeek()}
                    </WeekView>

                    <ConfirmModal
                        onSubmit={onSubmit}
                        isForm
                        text={text?.logs?.editForm?.button}
                        toggle={closeModal}
                        isOpen={isOpen}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '60%' : '100%'}
                        btnStyles={{
                            color: colors.blue,
                        }}
                    >
                        <div className="flex flex-column w-100">
                            <p className='mb-sm'>
                                {text?.logs?.editForm?.archivedInfo}
                            </p>
                            <SearchInput
                                className="mb-md w-100 "
                                value={query}
                                onChange={handleSearchChange}
                                placeHolder={text?.tasks?.home?.filters?.search}
                            />
                            <ConditionalRender renderIf={isEmpty(filteredSelectedDay?.tasks)}>
                                <h4 className="flex justify-center items-center">{text?.logs?.noTasks}</h4>
                            </ConditionalRender>
                            {Object.entries(filteredSelectedDay?.tasks || {})
                                ?.filter(([taskName, taskDetails]) =>
                                    taskDetails?.projectStatus !== 'pending' &&
                                    taskDetails?.projectStatus !== 'paused' &&
                                    taskDetails?.projectStatus !== 'completed'
                                )
                                ?.map(([taskName, taskDetails]) => {
                                    return (
                                        <TimeEditForm
                                            key={taskDetails?.id}
                                            id={taskDetails?.id}
                                            initialTimeInMilliseconds={taskDetails?.totalTime}
                                            label={taskName}
                                            clockIn={selectedDay?.clockIn}
                                        />
                                    );
                                })}
                        </div>
                    </ConfirmModal>

                </CalendarView>
            </ConditionalRender>
        </div>
    );
};

export default CalendarLogs;
