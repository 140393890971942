import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import isEmpty from 'lodash/isEmpty';
import HoverPopup from '../Core/HoverPopup';
import CustomSelect from '../Core/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import SearchInput from '../Core/Inputs/SearchInput';
import PaginatedData from '../Core/PaginatedData';
import colors from '../globalStyles.scss';
import SelectableBox from '../Core/SelectableBox';
import moment from 'moment';

const RunningTimeSheets = ({ runningTimesheets, setIsOpen, isOpen }) => {
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();

    const [timeSheets, setTimeSheets] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [projects, setProjects] = useState([]);

    // Extract unique projects from taskTimes
    const extractProjects = (timesheets) => {
        const projectMap = new Map();
        timesheets.forEach((timesheet) => {
            timesheet.taskTimes.forEach((task) => {
                if (task.projectId && !projectMap.has(task.projectId._id)) {
                    projectMap.set(task.projectId._id, {
                        label: task.projectId.projectName,
                        value: task.projectId._id,
                    });
                }
            });
        });
        return Array.from(projectMap.values());
    };

    // Update projects and timesheets on changes
    useMemo(() => {
        const extractedProjects = extractProjects(runningTimesheets);
        setProjects(extractedProjects);
        setTimeSheets(runningTimesheets);
    }, [runningTimesheets]);

    const handleSearch = (e) => {
        setFilters({
            ...filters,
            searchQuery: e.target?.value,
        });
    };

    const formatTime = useCallback((time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }, []);

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };

    const filterTableData = () => {
        const filterOptions = {};

        if (filters?.searchQuery) {
            filterOptions.searchQuery = filters?.searchQuery.toLowerCase();
        }

        if (filters?.projects) {
            filterOptions.projects = filters?.projects;
        }

        let filteredTimeSheets = runningTimesheets;

        if (filterOptions?.searchQuery) {
            filteredTimeSheets = runningTimesheets.filter((timesheet) =>
                timesheet.taskTimes.some((task) => {
                    const fullName = `${timesheet?.userId?.firstName} ${timesheet?.userId?.lastName}`.toLowerCase();
                    return fullName.includes(filterOptions.searchQuery);
                })
            );
        }

        if (filterOptions?.projects) {
            filteredTimeSheets = filteredTimeSheets.filter((timesheet) =>
                timesheet.taskTimes.some((task) => task.projectId?._id === filterOptions.projects)
            );
        }

        setTimeSheets(filteredTimeSheets);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, runningTimesheets]);

    return (
        <ConfirmModal
            height={isDesktop ? '60%' : '100%'}
            width={isDesktop ? '50%' : '100%'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggle={() => {
                setIsOpen(!isOpen);
                setShowFilters(false);
                setFilters({});
            }}
        >
            <div className="flex flex-column mt-md w-100">
                <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                    <div className="mb-md">
                        <h4 className="mb-md">
                            {`${runningTimesheets?.length} ${text?.dashboard?.timeClockInfo}`}
                        </h4>

                        <div className="flex flex-column w-100">
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start',
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id="toggle-filters-popup-employee"
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    style={{ color: showFilters ? colors.yellow : colors.white }}
                                />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className="flex flex-column filters mt-md w-100">
                                    <SearchInput
                                        style={{
                                            width: '100%',
                                            marginBottom: '1rem',
                                        }}
                                        value={filters?.searchQuery}
                                        onChange={handleSearch}
                                        placeHolder={text?.employees?.landing?.search}
                                    />
                                </div>
                                <CustomSelect
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'projects')}
                                    options={projects}
                                    name={'projects'}
                                    placeHolder={text?.tasks?.home?.filters?.project}
                                />
                            </ConditionalRender>
                        </div>

                        <PaginatedData
                            data={timeSheets}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        className='w-100'
                                        showLightColors
                                    >
                                        <div className="flex" key={item?._id}>
                                            <p className="mr-sm">
                                                {`${item?.userId?.firstName} ${item?.userId?.lastName},`}
                                            </p>
                                            <p className="mr-sm">
                                                {`${text?.dashboard?.in} ${item?.taskTimes
                                                    ?.find((task) => task.isRunning)?.projectId?.projectName || ''
                                                    },`}
                                            </p>
                                            <p className='mr-sm'>
                                                {`${item?.taskTimes
                                                    ?.find((task) => task.isRunning)?.taskId?.taskTitle || ''
                                                    }`}
                                            </p>
                                            <p>
                                                {`${text?.dashboard?.at} ${moment.utc(item?.clockIn).format('HH:mm:ss A MM-DD-YYYY')}`}
                                            </p>
                                        </div>
                                    </SelectableBox>
                                );
                            }}
                        />
                    </div>
                </ConditionalRender>
            </div>
        </ConfirmModal>
    );
};

export default RunningTimeSheets;
