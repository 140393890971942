import request from '../request';

/**
 * @param {String} conversationId - the id of the conversation
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const deleteConversation = async (conversationId) => {
    try {
        const response = await request('DELETE', `/chat/conversation/${conversationId}`);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to delete conversation: ${error.message}`);
    }
};

export default deleteConversation;
