import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { TimerContext } from '../context/TimeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from './ConfirmModal';
import CallToAction from './CallToAction';
import { GlobalContext } from '../context/GlobalContext';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import classNames from 'classnames';
import CreateNewTaskContainer from '../Tasks/CreateNewTaskContainer';
import RichEditorWithImageUploader from './Inputs/RichEditorWithImageUploader';
import Wrapper from './Wrapper';
import RenderImages from './RenderImages';
import { useDropzone } from 'react-dropzone';
import base64ToBlob from '../utils/base64ToBlob';

import {
    updateDailyLog,
    getTimesheetById,
    getTimesheetByTaskId,
    updateLogsImages
} from '../utils/calls';
import styled from 'styled-components';
import useScreenSize from '../context/useScreenSize';
import { Form, FormSpy, Field } from 'react-final-form';
import ConditionalRender from './ConditionalRender';
import { isEmpty } from 'lodash';
import SelectInput from './SelectInput';

const StyledDiv = styled.div`
    display: ${props => props.isFlex ? 'flex' : 'block'};
    justify-content: space-between;
    items-center;
    margin-top: 1em;
    

    .TimeTracker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time {
            font-family: ${colors.roboto};
            margin-right: 1rem;
        }
            
    }

`

const StyledUploader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dropzone {
        display: flex;
        flex-direction: column;
        margin: 0.5em 0;
        padding: 1em;
        background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
        cursor: pointer;
        transition: all 0.2s ease 0s;
        &:hover {
            background: ${colors.lightGray};
        }
        span {
            font-family: ${colors.roboto};
            color: ${props => props.theme === 'dark' ? colors.black : colors.black};
        }
    }
`


const TimeTracker = ({
    isFlex = true,
    projects,
    user,
    showLightColors
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();

    // Access the timer state and functions from the context
    const {
        isRunning,
        elapsedTime,
        handleStart,
        handlePause,
        projectId,
        setProjectId,
        timesheet,
        setTimesheet,
        fetchTimeLogs,
        taskId,
        setIsRunning,
        setElapsedTime,
        updateLocalStorage,
        initializeTimerState,
        setTaskId,
        tasksForTimeIsLoading,
        tasksForTime,
        getProjectTasksForTimeTracking,
    } = useContext(TimerContext);
    const { fetchProjectTasks, tasks, employees, handleGetProject, isProject } = useContext(GlobalContext);
    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    const [initialProject, setInitialProject] = useState();
    const [initialTask, setInitialTask] = useState();

    const [dailyLog, setDailyLog] = useState(timesheet?.dailyLog)
    const disabled = !projectId || !taskId
    const [dailyLogsImgs, setDailyLogsImgs] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleImageChange = (imageData) => {
        setDailyLogsImgs((prevPreviews) => [...prevPreviews, ...imageData]);
    };

    const removeImage = (imageIndex) => {
        setDailyLogsImgs((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
    };

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (projectId) {
            const project = projects?.find(p => p._id === projectId);
            if (project) {
                setInitialProject({
                    label: project?.projectName,
                    value: project?._id
                });

                // Fetch tasks for the selected project
                getProjectTasksForTimeTracking(projectId);
            }
        } else {
            if (projects?.length) {
                setInitialProject({
                    label: text?.inventory?.create?.selectProject,
                    value: null
                })
            }
        }
    }, [projectId, projects]);

    const [isRefreshing, setIsRefreshing] = useState(false);

    const refreshTimeTracker = () => {
        setIsRefreshing(true);
        sessionStorage.removeItem('timeTracker');
        if (user) {
            initializeTimerState();
            setIsRefreshing(false);
        }
    };

    const cleanUpTimerState = () => {
        if (user) {
            initializeTimerState();
        }
    };

    useEffect(() => {
        refreshTimeTracker();

        return () => cleanUpTimerState();
        // eslint-disable-next-line
    }, []);

    const handleButtonClick = () => {
        refreshTimeTracker();
    };

    const [addTaskIsOpen, setAddTaskIsOpen] = useState(false);

    const handleAddTaskToggle = () => {
        setAddTaskIsOpen(!addTaskIsOpen)
    }

    useEffect(() => {
        if (tasksForTime?.length && taskId) {
            const findTask = tasksForTime.find(t => t._id === taskId);
            if (findTask) {
                setInitialTask({
                    label: findTask?.taskTitle,
                    value: findTask?._id
                });
            }
        }
    }, [tasksForTime, taskId]);

    const handleFetchTimeSheet = async (taskId) => {
        const now = new Date();
        // Adjust to local time
        const todaysTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';
        try {
            const res = await getTimesheetByTaskId({ taskId, todaysTime });
            if (res.status === 200) {
                const findId = res?.data?.taskTimes?.find(t => t.taskId === taskId)
                if (findId) {
                    updateLocalStorage(
                        false,
                        findId?.timeSpent,
                        findId?.projectId,
                        res?.data?._id,
                        findId?.taskId,
                        todaysTime,
                    )
                    setElapsedTime(findId?.timeSpent)
                    setIsRunning(findId?.isRunning)
                } else {
                    setElapsedTime(0)
                    setIsRunning(false)
                }
            }
        } catch (error) {
            console.error('Error fetching timesheet:', error);
        }
    }

    useEffect(() => {
        setDailyLog(timesheet?.dailyLog)
    }, [timesheet?.dailyLog, projectId])

    useMemo(() => {
        setDailyLogsImgs(timesheet?.imageUrls)
    }, [timesheet?.imageUrls])

    const handleChange = (e) => {
        setProjectId(e?.value)
        setTaskId(null)
        getProjectTasksForTimeTracking(e?.value)
        setElapsedTime(0)
        setIsRunning(false)
    }

    const handleTaskChange = (e) => {
        setTaskId(e?.value)
        handleFetchTimeSheet(e?.value)
    }
    const [loading, setLoading] = useState(false);
    const handleSaveDailyLog = async ({ dailyLog }) => {
        setLoading(true);
        const processProjectImages = async () => {
            const formData = new FormData();

            dailyLogsImgs.forEach((image) => {
                if (image.new) {
                    const imageBlob = base64ToBlob(image?.url);
                    formData.append('newImages', imageBlob, image?.originalName);
                } else {
                    formData.append('oldImages', JSON.stringify(image));
                }
            });

            formData.append('timesheetId', timesheet?._id);

            // Send to the API
            const res = await updateLogsImages(formData);
            if (res.status === 200) {
                console.log('Images updated');
            } else {
                setLoading(false);
                throw new Error('Images update failed');
            }

            return res;
        };

        try {
            // Step 1: Use fallback for dailyLog if it's undefined or null
            const logToUpdate = dailyLog || timesheet?.dailyLog;

            // Ensure dailyLog is not empty before proceeding
            if (!logToUpdate) {
                throw new Error('No daily log found to update.');
            }

            // Step 2: Update the daily log
            const updateLogRes = await updateDailyLog(logToUpdate, timesheet?.clockIn);

            if (updateLogRes.status === 200) {
                // Step 3: Update project images
                await processProjectImages();

                // Step 4: Fetch the latest timesheet after all updates
                const { data: updatedTimesheet, status } = await getTimesheetById(timesheet?._id);
                if (status === 200) {
                    setTimesheet(updatedTimesheet); // Update the state with the latest data
                    setDailyLog(updatedTimesheet.dailyLog); // Ensure the daily log is up-to-date
                    setLoading(false);
                } else {
                    setLoading(false);
                    throw new Error('Failed to fetch updated timesheet after updates');
                }

                // Step 5: Refresh time logs after everything is done
                const isAdmin = projects?.some(
                    (project) =>
                        project._id === projectId &&
                        (project.adminEmployees?.some((employee) => employee?.id === user?.id) || user?.roleName === 'Admin')
                );

                await fetchTimeLogs(null, projectId, isAdmin);

                // handleOpenDailyLog(); // Close the daily log UI
            } else {
                console.error(updateLogRes.message);
                handleOpenDailyLog();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleOpenDailyLog = () => {
        setIsOpen(!isOpen)
    }

    const formatTime = (time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));

        const parts = [];

        if (days > 0) parts.push(`${days}d`);
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);
        if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

        return parts.join(' ');
    };


    const [imageError, setImageError] = useState('')

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setDailyLogsImgs((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setImageError('')
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });



    const iconColor = theme === 'light' ? colors.black : colors.secondary;

    return (
        <ConditionalRender renderIf={true} isLoading={isRefreshing}>
            <Form
                onSubmit={() => null}
                render={({ handleSubmit, form }) => (
                    <form className='w-100' onSubmit={handleSubmit}>
                        <div className='flex flex-column'>
                            <FontAwesomeIcon
                                icon={faRefresh}
                                style={{
                                    display: "flex",
                                    alignSelf: "flex-end",
                                    fontSize: '2em',
                                    color: colors.blue,
                                    cursor: 'pointer',
                                }}
                                onClick={handleButtonClick}
                            />
                            <p style={{
                                color: showLightColors ? colors.black : colors.white
                            }} className='mb-sm flex'>
                                {text?.logs?.selectProject}
                            </p>
                            <SelectInput
                                name='selectProject'
                                isClearable={false}
                                isValidNewOption={() => false}
                                isSearchable
                                initialValue={initialProject}
                                menuPlacement={'bottom'}
                                options={projects?.filter((x) =>
                                    x?.projectStatus !== 'completed' &&
                                    x?.projectStatus !== 'pending' &&
                                    x?.projectStatus !== 'paused'
                                )?.map((x) => {
                                    return {
                                        label: x?.projectName,
                                        value: x?._id
                                    }
                                })}
                                onChange={(e) => {
                                    handleChange(e)
                                    form.change('selectTask', null)
                                }}
                                disabled={isRunning}
                                style={{
                                    width: '100%'
                                }}
                            />
                            <FormSpy subscription={{ values: true }}>
                                {({ values }) => {
                                    const selectProject = values?.selectProject?.value;

                                    return (
                                        <ConditionalRender renderIf={!isEmpty(selectProject)}>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={isEmpty(tasksForTime)}>
                                                <p className='mt-md'>
                                                    {
                                                        adminProjects?.some(project => project._id === selectProject && (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin')) ? text?.logs?.noTasks : text?.logs?.noTaskEmployee
                                                    }
                                                </p>
                                            </ConditionalRender>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={isEmpty(tasksForTime)}>
                                                <ConditionalRender renderIf={
                                                    adminProjects?.some(project => project._id === selectProject && (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin'))
                                                }>
                                                    <CallToAction
                                                        className='flex-one mt-md'
                                                        text={text?.tasks?.home?.createNew}
                                                        type='button'
                                                        onClick={() => handleAddTaskToggle()}
                                                    />
                                                    <ConditionalRender renderIf={addTaskIsOpen}>
                                                        <CreateNewTaskContainer
                                                            isOpen={addTaskIsOpen}
                                                            toggle={handleAddTaskToggle}
                                                            projects={projects}
                                                            adminProjects={adminProjects}
                                                            user={user}
                                                            employees={employees}
                                                            fetchProjectTasks={fetchProjectTasks}
                                                            isProject={isProject}
                                                            projectId={projectId}
                                                            tasks={tasks}
                                                            handleGetProject={handleGetProject}
                                                        />
                                                    </ConditionalRender>
                                                </ConditionalRender>
                                            </ConditionalRender>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={!isEmpty(tasksForTime)}>
                                                <p style={{
                                                    color: showLightColors ? colors.black : colors.white
                                                }} className='mb-sm flex mt-md'>
                                                    {text?.logs?.selectTask}
                                                </p>
                                                <SelectInput
                                                    name='selectTask'
                                                    isClearable={false}
                                                    menuPlacement={'bottom'}
                                                    isValidNewOption={() => false}
                                                    isSearchable
                                                    initialValue={initialTask}
                                                    options={tasksForTime?.map((x) => {
                                                        return {
                                                            label: x?.taskTitle,
                                                            value: x?._id,
                                                            isDisabled: x?.isRunning
                                                        }
                                                    })}
                                                    onChange={(e) => handleTaskChange(e)}
                                                    disabled={isRunning}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />
                                            </ConditionalRender>

                                        </ConditionalRender>
                                    )
                                }}
                            </FormSpy>
                            <StyledDiv isFlex={isFlex}>
                                <div className='w-100'>
                                    <div className='TimeTracker mb-md'>
                                        <p className={classNames({
                                            time: true,
                                            'black': showLightColors
                                        })}>
                                            {formatTime(elapsedTime)}
                                        </p>
                                        {!isRunning && (
                                            <FontAwesomeIcon
                                                onClick={() => !disabled && handleStart()}
                                                icon={faPlay}
                                                style={{
                                                    color: disabled ? colors.lightGray : iconColor,
                                                    fontSize: '2rem',
                                                    cursor: disabled ? 'default' : 'pointer'
                                                }}
                                            />
                                        )}
                                        {isRunning && (
                                            <FontAwesomeIcon
                                                onClick={!disabled && handlePause}
                                                icon={faStop}
                                                style={{
                                                    color: disabled ? colors.lightGray : iconColor,
                                                    fontSize: '2rem',
                                                    cursor: disabled ? 'default' : 'pointer'
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className='log mb-md'>
                                        <CallToAction
                                            type="button"
                                            onClick={handleOpenDailyLog}
                                            text={text?.timeTracker?.dailyLogTitle}
                                            style={{
                                                alignSelf: 'start'
                                            }}
                                            disabled={!timesheet}
                                        />
                                    </div>
                                </div>
                                <ConfirmModal
                                    text={text?.timeTracker?.modal?.button}
                                    toggle={() => setIsOpen(!isOpen)}
                                    isOpen={isOpen}
                                    noOverlay
                                    btnStyles={{
                                        color: colors.blue
                                    }}
                                    projectId={projectId}
                                    onSubmit={handleSaveDailyLog}
                                    isForm
                                    width={isDesktop ? '80%' : '100%'}
                                    height={isDesktop ? '80%' : '100%'}
                                >
                                    <Wrapper
                                        width={'100%'}
                                        isLoading={loading}
                                        title={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(timesheet?.clockIn)}`}
                                        button={

                                            <StyledUploader
                                                theme={theme}
                                            >
                                                {
                                                    <div
                                                        className='dropzone'
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <span>
                                                            {text?.inventory?.details?.uploadImages}
                                                        </span>
                                                    </div>

                                                }
                                            </StyledUploader>
                                        }
                                    >
                                        <ConditionalRender renderIf={!isEmpty(dailyLogsImgs)}>
                                            <RenderImages
                                                images={dailyLogsImgs}
                                                removeImage={removeImage}
                                                openModal={openCarousel}
                                                closeModal={closeModal}
                                                setActiveIndex={setActiveIndex}
                                                activeIndex={activeIndex}
                                                isModalOpen={isModalOpen}
                                            />
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={isEmpty(dailyLogsImgs)}>
                                            <p className='mb-md'>
                                                {text?.projects.create?.noFoundMsgs}
                                            </p>
                                        </ConditionalRender>
                                        <div className="editor w-100">
                                            <Field
                                                name={'dailyLog'}
                                            >
                                                {({ input }) => {
                                                    return (
                                                        <RichEditorWithImageUploader
                                                            noGif
                                                            showForm={false}
                                                            name="dailyLog"
                                                            initialValue={dailyLog}
                                                            onChange={(value) => {
                                                                input.onChange(value)
                                                            }}
                                                            height={false}
                                                            noKeyDown
                                                            noButton
                                                        />
                                                    )
                                                }}
                                            </Field>

                                        </div>
                                    </Wrapper>
                                </ConfirmModal>
                            </StyledDiv>
                        </div>
                    </form>
                )}
            />
        </ConditionalRender>
    );
};


export default TimeTracker;
