import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { FieldStyles } from './FieldStyles';
import styled from 'styled-components';
import colors from '../../globalStyles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faClock } from '@fortawesome/free-solid-svg-icons';
import TimePicker from 'rc-time-picker';
import { isEmpty } from 'lodash';
import ConditionalRender from '../ConditionalRender';
import 'rc-time-picker/assets/index.css';
import useScreenSize from '../../context/useScreenSize';
import { useTheme } from '../../context/ThemeContext';

const StyledDatePicker = styled.div`

display: flex;
align-items: center;
    .time-picker {
        position: relative;
        width: 100%;
        background: none;
        border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? colors.white : colors.blue)};
        input {
            border: none;
            font-family: ${colors.roboto};
            color: ${(props) => (props.theme === 'dark' ? colors.white : colors.black)};
            font-size: 1em;
        }
    }
    .date-picker {
        position: relative;
        width: 100%;
        background: none;
        border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? colors.white : colors.blue)};
        input {
            border: none;
            font-family: ${colors.roboto};
            color: ${(props) => (props.theme === 'dark' ? colors.white : colors.black)};
            font-size: 1em;
        }
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
        font-size: 1.3em;
        cursor: pointer;
        filter: ${(props) =>
        props.theme === 'dark'
            ? 'invert(100%) brightness(100%)' // White icon for dark theme
            : 'invert(30%) sepia(78%) saturate(5825%) hue-rotate(184deg) brightness(93%) contrast(95%)'}; // Blue icon for light theme
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        font-size: 1.3em;
        cursor: pointer;
        filter: ${(props) =>
        props.theme === 'dark'
            ? 'invert(100%) brightness(100%)' // White icon for dark theme
            : 'invert(30%) sepia(78%) saturate(5825%) hue-rotate(184deg) brightness(93%) contrast(95%)'}; // Blue icon for light theme
    }
    .time-picker input[type="time"] {
        width: 100%;
        padding-right: 40px;
    }

    .time-picker .custom-clock-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        // pointer-events: none;
    }
    .time-input {
        width: 100%;
        -webkit-appearance: none; 
        -moz-appearance: textfield;
        position: relative;
}
.CalendarMonth_caption, .DayPicker_weekHeader_li   {
    color: ${colors.black};
}
.SingleDatePickerInput__withBorder {
    border: none;
    input {
        color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    }
    border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? colors.white : colors.blue)};
    border-bottom-color: ${props => props.showLightColors && colors.blue};
    .DateInput {
        .DateInput_input {
            background: ${props => props.disabled
        ? (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.disabledDark : colors.lightBlue))
        : (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray))};
        }

    }
}
  .CalendarDay__selected {
    background: ${colors.blue};
    &:hover {
        background: ${colors.blue};
    }
  }
  .TimePicker {
    input {
        border-radius: 0;
        background: ${props => props.disabled
        ? (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.disabledDark : colors.lightGray))
        : (props.showLightColors ? colors.lightGray : (props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray))};
        &focus: {
            border-bottom: 1px solid ${colors.blue};
        }
    }

  }
`;

const SingleDateInput = ({
    input,
    meta,
    setIsEditing,
    onChange,
    disabled,
    form,
    withTimePicker,
    showLightColors,
    className,
    allowEmptyInitialValue = false,
    isClearable = false,
    initialValue,
    style,
    minDate = moment().format('YYYY-MM-DD'),
    isOutsideRange = (day) => day.isBefore(moment.utc(), 'day'),
    ...rest
}) => {
    const { isPhone } = useScreenSize();
    const { theme } = useTheme();
    const [focused, setFocused] = useState(false);
    const [selectedTime, setSelectedTime] = useState(moment.utc('00:00', 'HH:mm'));

    // Handles date change
    const handleChange = (e) => {
        const selectedDate = e.target.value ? moment(e.target.value) : null;
        handleDateChange(selectedDate);
    };

    // Combine date and time, keeping local time (without converting to UTC)
    const combineDateAndTime = (date, time) => {
        const dateTime = date.clone().set({
            hour: time.hour(),
            minute: time.minute(),
            second: time.second(),
        });

        input.onChange(dateTime.format('YYYY-MM-DDTHH:mm:ss'));
        if (onChange) onChange({ name: input.name, value: dateTime });
        if (setIsEditing) setIsEditing(true);
    };

    const handleDateChange = (date) => {
        if (date) {
            combineDateAndTime(date, selectedTime);
        }
    };

    const handleTimeChange = (time) => {
        if (time) {
            setSelectedTime(time);
            const currentMoment = moment.utc(input.value);
            combineDateAndTime(currentMoment, time);
        }
    };

    useEffect(() => {
        if (!isEmpty(input?.value)) {
            setSelectedTime(moment.utc(input.value));
        } else {
            setSelectedTime(moment.utc('00:00', 'HH:mm'));
            form?.change(input?.name, moment.utc('00:00', 'HH:mm'));
        }
    }, [input]);

    const handleClear = () => {
        input.onChange(null);
        setSelectedTime(moment.utc('00:00', 'HH:mm'));
        if (onChange) onChange({ name: input.name, value: null });
        if (setIsEditing) setIsEditing(true);
    };

    return (
        <StyledDatePicker
            theme={theme}
            showLightColors={showLightColors}
            disabled={disabled}
            style={style}
        >
            <div className='flex flex-column'>
                <div className='date-picker'>
                    <input
                        type="date"
                        className="w-100"
                        value={!isEmpty(input.value) ? moment(input.value).format('YYYY-MM-DD') : allowEmptyInitialValue ? '' : moment().format('YYYY-MM-DD')}
                        onChange={handleChange}
                        disabled={disabled}
                        min={minDate}
                        {...rest}
                    />
                </div>
                <ConditionalRender renderIf={withTimePicker}>
                    <ConditionalRender renderIf={true}>
                        <div className='time-picker'>
                            <input
                                type="time"
                                onChange={(e) => handleTimeChange(moment(e.target.value, 'HH:mm'))}
                                value={selectedTime ? moment(selectedTime, 'HH:mm').format('HH:mm') : ''}
                                disabled={disabled}
                                className='time-input'
                                {...rest}
                            />
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(input.value) && isClearable && !disabled}>
                    <FontAwesomeIcon
                        className="ml-md"
                        onClick={handleClear}
                        icon={faClose}
                        style={{
                            color: colors.red,
                            cursor: 'pointer',
                        }}
                    />
                </ConditionalRender>
                {meta.touched && meta.error && <span className='small error align-self-end'>{meta.error}</span>}
            </div>
        </StyledDatePicker>
    );
};

export const SelectDate = ({ name, label, className, initialValue, ...rest }) => {
    return (
        <FieldStyles className={className}
        >
            {label && (
                <label className='flex b mb-sm'>{label}</label>
            )}
            <div className='custom-field'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Field
                        className='w-100'
                        name={name}
                        component={SingleDateInput}
                        initialValue={initialValue}
                        {...rest}
                    />
                </div>
            </div>
        </FieldStyles>
    );
};

export default SelectDate;
