import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import {
    clockIn,
    updateTime,
    getTimesheetById,
    getAllTasks,
    getRunningTimesheet,
    getTodaysRunningTimesheets,
    updateTaskHistory
} from '../utils/calls';
import { getStartAndEndOfDay } from '../utils/helperFunctions';
import { GlobalContext } from './GlobalContext';
import { formatDateToLocal, getQueryParams } from '../utils/helperFunctions';


export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {

    const [projectId, setProjectId] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [tasksForTime, setTasksForTime] = useState([])
    const [tasksForTimeIsLoading, setTasksForTimeIsLoading] = useState(false)
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timesheet, setTimesheet] = useState(null);
    const id = getQueryParams(window.location.href).id;


    const { fetchTimeLogs, fetchProjectTasks, user } = useContext(GlobalContext);

    const getProjectTasksForTimeTracking = async (projectId) => {
        try {
            setTasksForTimeIsLoading(true)
            const res = await getAllTasks(projectId);
            if (res.status === 200) {
                setTasksForTime(res.data?.tasks?.filter(task => task.taskStatus !== 'completed'))
                setTasksForTimeIsLoading(false)
            } else {
                setTasksForTimeIsLoading(false)
            }
        } catch (error) {
            console.error('Error fetching tasks', error)
            setTasksForTimeIsLoading(false)
        }
    }

    const [runningTimesheets, setRunningTimesheets] = useState([])

    const fetchRunningTimesheet = async () => {
        try {
            const { startOfToday, endOfToday } = getStartAndEndOfDay();
            const res = await getTodaysRunningTimesheets({
                startOfToday,
                endOfToday
            });
            if (res.status === 200) {
                setRunningTimesheets(res.data?.timesheets)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const updateLocalStorage = useCallback((running, time, currentProjectId, timesheetId, taskId, clockIn) => {

        const now = new Date();
        // Use adjusted local time for `nowLocal`
        const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000);

        const data = {
            isRunning: running,
            elapsedTime: time,
            projectId: currentProjectId,
            taskId: taskId,
            timesheetId: timesheetId,
            lastUpdateTime: nowLocal.toISOString(), // Use consistent ISO format
            clockIn: clockIn
        };

        sessionStorage.setItem('timeTracker', JSON.stringify(data));
    }, []);


    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                setElapsedTime((prevElapsedTime) => {
                    // Calculate updated elapsed time
                    const updatedElapsedTime = prevElapsedTime + 1000;

                    // Retrieve existing local storage data
                    const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};


                    const now = new Date();
                    // Adjust to local time
                    const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000); // Local adjusted time

                    // Prevent resetting the elapsedTime in localStorage
                    const baseElapsedTime = storedData.elapsedTime ?? 0;
                    const totalElapsedTime = Math.max(baseElapsedTime, updatedElapsedTime); // Ensure the correct elapsed time is preserved

                    // Update only the elapsedTime in local storage
                    const updatedData = {
                        ...storedData, // Preserve other fields
                        elapsedTime: totalElapsedTime, // Correctly update elapsed time
                        lastUpdateTime: nowLocal.toISOString(), // Use consistent ISO format
                    };
                    if (storedData.timesheetId) {
                        sessionStorage.setItem('timeTracker', JSON.stringify(updatedData));
                        return totalElapsedTime;
                    } else {
                        setProjectId(null);
                        setTaskId(null);
                        setIsRunning(false);
                        setTimesheet(null);
                        setTasksForTime([]);
                        return 0;
                    }

                });
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning, projectId, taskId, timesheet]);



    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};

        if (storedData) {
            setProjectId(storedData?.projectId);
            setTaskId(storedData?.taskId);
            setElapsedTime(storedData?.elapsedTime ?? 0);
        } else {
            setProjectId(null);
            setTaskId(null);
            setElapsedTime(0);
            setIsRunning(false);
        }

        return () => {
            if (!isRunning) {
                sessionStorage.removeItem('timeTracker');

            }
        }

    }, []);

    const initializeTimerState = useCallback(async () => {
        fetchRunningTimesheet();
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};

        const now = new Date();
        const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().split('T')[0];
        const todaysDate = nowLocal;
        const lastUpdateTime = storedData?.lastUpdateTime ? new Date(storedData.lastUpdateTime) : null;

        // Check if lastUpdateTime is from today
        if (lastUpdateTime) {
            const lastUpdateDate = lastUpdateTime.toISOString().split('T')[0];
            if (lastUpdateDate !== todaysDate) {
                sessionStorage.removeItem('timeTracker');
                setProjectId(null);
                setTaskId(null);
                setElapsedTime(0);
                setIsRunning(false);
                return;
            }
        }


        if (storedData?.timesheetId) {


            try {
                // Fetch timesheet by ID
                const { data, status } = await getTimesheetById(storedData.timesheetId);
                if (status === 200) {
                    setTimesheet(data);

                    if (data.isRunning) {
                        setIsRunning(true);

                        // Find the task that is running
                        const runningTask = data.taskTimes.find(task => task.isRunning);
                        if (runningTask) {
                            setTaskId(runningTask.taskId);
                            setProjectId(runningTask.projectId);

                            // Safeguard against invalid `lastUpdateTime`
                            if (!lastUpdateTime || lastUpdateTime > nowLocal) {

                                // Reset elapsed time and update local storage with nowLocal
                                updateLocalStorage(true, 0, runningTask.projectId, data._id, runningTask.taskId, nowLocal);

                                setElapsedTime(0);
                                return;
                            }

                            // Calculate elapsed time correctly
                            const timeElapsedSinceLastUpdate = nowLocal.getTime() - new Date(lastUpdateTime).getTime(); // Use nowLocal and parse lastUpdateTime
                            const baseElapsedTime = storedData.elapsedTime ?? runningTask.timeSpent ?? 0;
                            const updatedElapsedTime = baseElapsedTime + (storedData.isRunning ? timeElapsedSinceLastUpdate : 0);

                            setElapsedTime(updatedElapsedTime);

                            // Update local storage with updated elapsed time
                            updateLocalStorage(
                                true,
                                updatedElapsedTime,
                                runningTask.projectId,
                                data._id,
                                runningTask.taskId,
                                storedData.clockIn
                            );
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching timesheet:', error);
            }
        } else {
            // No timesheet found in local storage; search for running timesheet
            try {
                const { data, status } = await getRunningTimesheet();
                if (status === 200 && data) {
                    setTimesheet(data);

                    // Update state and localStorage with the running timesheet
                    setIsRunning(true);

                    // Find the running task
                    const runningTask = data.taskTimes.find(task => task.isRunning);
                    if (runningTask) {
                        setTaskId(runningTask.taskId);
                        setProjectId(runningTask.projectId);

                        // Use local time for calculations
                        const now = new Date();
                        const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000); // Adjust to local time
                        const lastUpdateTime = data.lastUpdateTime ? new Date(data.lastUpdateTime) : null;

                        if (lastUpdateTime) {
                            // Directly compare the UTC times without additional normalization
                            const timeElapsedSinceLastUpdate = nowLocal - lastUpdateTime;
                            const baseElapsedTime = runningTask.timeSpent ?? 0;
                            const updatedElapsedTime = baseElapsedTime + timeElapsedSinceLastUpdate;

                            // Handle negative or zero elapsed time to avoid adding incorrect offsets
                            const finalElapsedTime = updatedElapsedTime > 0 ? updatedElapsedTime : baseElapsedTime;

                            // Set elapsed time in state
                            setElapsedTime(finalElapsedTime);

                            // Update local storage with running timesheet data
                            updateLocalStorage(
                                true,
                                finalElapsedTime,
                                runningTask.projectId,
                                data._id,
                                runningTask.taskId,
                                data.clockIn
                            );
                        } else {
                            setElapsedTime(runningTask.timeSpent ?? 0);

                            // Update local storage with running task data
                            updateLocalStorage(
                                true,
                                runningTask.timeSpent ?? 0,
                                runningTask.projectId,
                                data._id,
                                runningTask.taskId,
                                data.clockIn
                            );
                        }
                    } else {
                        setProjectId(null);
                        setTaskId(null);
                        setElapsedTime(0);
                        setIsRunning(false);
                    }
                } else {
                    // No running timesheet found; reset state
                    setProjectId(null);
                    setTaskId(null);
                    setElapsedTime(0);
                    setIsRunning(false);
                }
            } catch (error) {
                console.error('Error retrieving running timesheet:', error);
            }

        }
    }, []);

    const formatTime = useCallback((time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }, []);

    const handleStart = useCallback(async () => {
        try {
            const now = new Date();
            // Adjust to local time
            const todaysTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000)
                .toISOString()
                .split('.')[0] + 'Z';

            const elapsedTime = 0; // Start with 0 elapsed time for a new session

            // Call the clockIn API
            const res = await clockIn({ taskId, isRunning: true, elapsedTime, todaysTime });
            if (res.status === 200) {
                const timesheet = res.data.timesheet || res.data;

                // Save to local state
                setTimesheet(timesheet);
                setIsRunning(true);

                // Update local storage
                updateLocalStorage(true, elapsedTime, projectId, timesheet._id, taskId, todaysTime);
                fetchProjectTasks(id);
                updateTaskHistory(
                    taskId,
                    `Started on ${formatDateToLocal(todaysTime)}.`
                );
            } else {
                console.error('Failed to start time tracking:', res.data.message);
            }
        } catch (error) {
            console.error('Error starting time tracking:', error);
        }
    }, [taskId, projectId, updateLocalStorage, id]);


    const handlePause = useCallback(async () => {
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};

        const now = new Date();
        const nowLocal = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().split('T')[0];
        const todaysDate = nowLocal;

        try {
            const now = new Date();
            // Adjust the current time to the local timezone
            const localClockOutTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

            const body = {
                totalTime: elapsedTime,
                clockIn: timesheet?.clockIn,
                clockOut: localClockOutTime,
                lastUpdateTime: timesheet?.lastUpdateTime,
                taskId,
                isRunning: false
            };

            const res = await updateTime(timesheet?._id, body);
            if (res.status === 200) {
                setIsRunning(false);
                fetchProjectTasks(id);
                fetchTimeLogs();
                updateTaskHistory(
                    taskId,
                    `Paused. Total logged time: ${formatTime(elapsedTime)} on ${formatDateToLocal(localClockOutTime)}.`
                );


                // TODO CHECK IF IT BREAKS
                // Check clockIn against today's date
                if (storedData?.clockIn) {
                    const clockInDate = new Date(storedData.clockIn).toISOString().split('T')[0];
                    if (clockInDate !== todaysDate) {
                        setProjectId(null);
                        setTaskId(null);
                        setElapsedTime(0);
                        updateLocalStorage(false, elapsedTime, projectId, timesheet?._id, taskId, storedData.clockIn);
                        sessionStorage.removeItem('timeTracker');
                        return;
                    }
                } else {
                    updateLocalStorage(false, elapsedTime, projectId, timesheet?._id, taskId, storedData.clockIn);

                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [elapsedTime, timesheet, updateLocalStorage, projectId, id]);

    useEffect(() => {
        // Then initialize the timer state with the fetched data
        if (user) {
            initializeTimerState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = {
        isRunning,
        elapsedTime,
        timesheet,
        setTimesheet,
        handleStart,
        handlePause,
        formatTime,
        setProjectId,
        setTaskId,
        taskId,
        projectId,
        fetchTimeLogs,
        getProjectTasksForTimeTracking,
        tasksForTime,
        tasksForTimeIsLoading,
        updateLocalStorage,
        setElapsedTime,
        setIsRunning,
        initializeTimerState,
        runningTimesheets
    };

    return (
        <TimerContext.Provider value={value}>
            {children}
        </TimerContext.Provider>
    );
};
