import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import HoverPopup from '../Core/HoverPopup';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext';
import classnames from 'classnames';

const UnreadIndicator = ({ firstUnreadRef, theme, markAllAsRead, isClient }) => {
    const [isUnreadDividerVisible, setIsUnreadDividerVisible] = useState(false);
    const { text } = useLanguage();
    useEffect(() => {
        if (!firstUnreadRef?.current) {
            console.warn('firstUnreadRef is not attached to an element.');
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsUnreadDividerVisible(entry.isIntersecting);
            },
            {
                root: null, // Viewport as the container
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );

        observer.observe(firstUnreadRef.current);

        return () => {
            if (firstUnreadRef.current) {
                observer.unobserve(firstUnreadRef.current);
            }
        };
    }, [firstUnreadRef]);

    return (
        <div className={classnames({
            'flex': true,
            'ml-md': isClient,
        })}>
            <HoverPopup
                isDark={isClient}
                className='mr-md'
                onClick={markAllAsRead}
                placement={'bottom'}
                id='check-unread-popup-indicator'
                text={text?.chat?.markAllRead}
            >
                <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: theme === 'dark' ? colors.primary : colors.white }}
                />
            </HoverPopup>
            <ConditionalRender renderIf={!isUnreadDividerVisible}>
                <HoverPopup
                    isDark={isClient}
                    onClick={() => {
                        if (firstUnreadRef.current) {
                            firstUnreadRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                    }}
                    placement={'bottom'}
                    id='unread-popup-indicator'
                    text={text?.chat?.scrollToTop}
                >
                    <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: theme === 'dark' ? colors.red : colors.red }}
                    />
                </HoverPopup>
            </ConditionalRender>
        </div>
    );
};

export default UnreadIndicator;
