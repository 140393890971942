import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import logo from './images/logo.png';
import { useLanguage } from './context/LanguageContext';
import queryString from 'query-string';

const HelmetWrapper = () => {
    const location = useLocation();
    const { language } = useLanguage();
    const queryResourceTitle = queryString.parse(location.search).title;

    // Define dynamic titles for each route
    const titles = {
        en: {
            '/': 'Ardidflow.com | Home',
            '/flow': 'Ardidflow.com | Flow',
            '/contact-us': 'Ardidflow.com | Contact Us',
            '/subscriptions': 'Ardidflow.com | Subscriptions',
            '/register': 'Ardidflow.com | Register',
            '/login': 'Ardidflow.com | Login',
            '/resources': 'Ardidflow.com | Resources',
            '/analytics': 'Ardidflow.com | Analytics',
            '/settings': 'Ardidflow.com | Settings',
            '/chat': 'Ardidflow.com | Chat',
            '/calendar': 'Ardidflow.com | Calendar',
        },
        es: {
            '/': 'Ardidflow.com | Inicio',
            '/flow': 'Ardidflow.com | Flujo',
            '/contact-us': 'Ardidflow.com | Contáctanos',
            '/subscriptions': 'Ardidflow.com | Suscripciones',
            '/register': 'Ardidflow.com | Registrarse',
            '/login': 'Ardidflow.com | Iniciar sesión',
            '/resources': 'Ardidflow.com | Recursos',
            '/analytics': 'Ardidflow.com | Analítica',
            '/settings': 'Ardidflow.com | Configuración',
            '/chat': 'Ardidflow.com | Chat',
            '/calendar': 'Ardidflow.com | Calendario',
        },
    };

    const descriptions = {
        en: 'Project management toolkit for your business',
        es: 'Herramientas de gestión de proyectos para tu negocio',
    };

    const keywords = {
        en: 'Project Management Toolkit',
        es: 'Herramientas de Gestión de Proyectos',
    };

    const currentLanguage = titles[language] || titles.en;
    let title = currentLanguage[location.pathname] || 'Ardidflow.com';

    // Append query title for /resources and its sub-paths
    if (location.pathname.startsWith('/resources') && queryResourceTitle) {
        const decodedTitle = decodeURIComponent(queryResourceTitle); // Decode the title from the query string
        title = `${currentLanguage['/resources']} | ${decodedTitle}`;
    }

    const description = descriptions[language] || descriptions.en;
    const keyword = keywords[language] || keywords.en;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={logo} />
        </Helmet>
    );
};

export default HelmetWrapper;
