import React, { useState, useEffect } from 'react';
import CreateNewTaskContainer from './CreateNewTaskContainer';
import ConditionalRender from '../Core/ConditionalRender';
import CallToAction from '../Core/CallToAction';
import CustomTable from '../Core/Tables/CustomTable';
import ConfirmModal from '../Core/ConfirmModal';
import SearchInput from '../Core/Inputs/SearchInput';
import HoverPopup from '../Core/HoverPopup';
import CustomSelect from '../Core/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import { tasksColumns } from '../Invetory/columns';
import { projectStatus, priority } from '../utils/helperFunctions';
import { useNavigate } from 'react-router';
import { removeTasks } from '../utils/calls';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import useScreenSize from '../context/useScreenSize';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss';
import Wrapper from '../Core/Wrapper';
import TasksQuickView from '../QuickView/TasksQuickView';
import CheckBox from '../Core/Inputs/CheckBox';

const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;

const TasksContainer = ({
    projects,
    project,
    user,
    employees,
    tasks,
    fetchProjectTasks,
    adminProjects,
    isProject,
    isDetails,
    projectId,
    title = 'Tasks',
    projectIsCompleted,
    tasksIsLoading,
    setExpanded,
    handleGetProject,
    handleGetTask,
    setSubtasks,
    subtasks
}) => {
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [assignedEmployees, setAssignedEmployees] = useState([]);
    const [filters, setFilters] = useState({});
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [quickViewData, setQuickViewData] = useState(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

    const handleQuickView = (rowData) => {
        setQuickViewData(rowData);
        setIsQuickViewOpen(!isQuickViewOpen);
    };

    useEffect(() => {
        if (tasks?.length === 0) {
            setTableData([]);
        } else {
            setTableData(tasks);
        }
    }, [tasks]);

    useEffect(() => {
        const assignedEmployees = tasks
            ?.map(task => task?.taskAssignedTo)
            .flat()
            .filter(employee => employee);

        const uniqueEmployees = Array.from(
            new Set(assignedEmployees.map(employee => employee._id))
        ).map(id => assignedEmployees.find(employee => employee._id === id));

        const employeeOptions = uniqueEmployees?.map(employee => ({
            value: employee?._id,
            label: `${employee?.firstName} ${employee?.lastName}`
        }));

        setAssignedEmployees(employeeOptions);
    }, [tasks]);


    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };

    const [focusedInput, setFocusedInput] = useState(null);
    const handleDatesChange = ({ startDate, endDate }) => {
        setFilters({
            ...filters,
            startDate,
            endDate
        });
    };

    const handleArchivedChange = () => {
        setFilters({ ...filters, isArchived: !filters.isArchived });
    }

    const HandleSubtaskChange = () => {
        setFilters({ ...filters, isSubtask: !filters.isSubtask });
    }

    const fetchArchivedTasks = (isArchived) => {
        fetchProjectTasks && fetchProjectTasks(projectId, isArchived);
    }

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        if (filters.taskStatus) {
            filterOptions.taskStatus = filters.taskStatus;
        }

        if (filters.taskPriority) {
            filterOptions.taskPriority = filters.taskPriority;
        }

        if (filters.taskProject) {
            filterOptions.taskProject = filters.taskProject;
        }

        if (filters.startDate && filters.endDate) {
            filterOptions.startDate = filters.startDate;
            filterOptions.endDate = filters.endDate;
        }

        if (filters.taskAssignedTo) {
            filterOptions.taskAssignedTo = filters.taskAssignedTo;
        }

        if (filters.isSubtask) {
            filterOptions.isSubtask = filters.isSubtask;
        }

        let filteredTasks = tasks;

        if (filterOptions.query) {
            filteredTasks = filteredTasks.filter(task =>
                task?.taskTitle.toLowerCase().includes(filterOptions.query)
            );
        }

        if (filterOptions.taskStatus) {
            filteredTasks = filteredTasks.filter(task => task?.taskStatus === filterOptions.taskStatus);
        }

        if (filterOptions.taskPriority) {
            filteredTasks = filteredTasks.filter(task => task?.taskPriority === filterOptions.taskPriority);
        }

        if (filterOptions.taskProject) {
            filteredTasks = filteredTasks.filter(task => task?.taskProject?.id === filterOptions.taskProject);
        }

        if (filterOptions.taskAssignedTo) {
            filteredTasks = filteredTasks.filter(task => task?.taskAssignedTo?._id === filterOptions.taskAssignedTo);
        }

        if (filterOptions.startDate && filterOptions.endDate) {
            filteredTasks = filteredTasks.filter(task =>
                moment(task.taskDeadline).isBetween(filterOptions.startDate, filterOptions.endDate, 'day', '[]')
            );
        }

        if (filterOptions.isSubtask) {
            filteredTasks = filteredTasks.filter(task => task?.isSubtask === false);
        }

        setTableData(filteredTasks);
    };

    useEffect(() => {
        if (filters.isArchived) {
            fetchArchivedTasks(true);
            if (isDetails) {
                setSubtasks(subtasks?.filter(task => task?.isArchived === true))
            }
        } else {
            fetchArchivedTasks(false);
            if (isDetails) {
                setSubtasks(subtasks?.filter(task => task?.isArchived === false))
            }
        }
    }, [filters.isArchived]);

    useEffect(() => {
        filterTableData();
    }, [filters, tasks]);

    const [addTaskIsOpen, setAddTaskIsOpen] = useState(false);

    const handleAddTaskToggle = () => {
        setAddTaskIsOpen(!addTaskIsOpen)
    }

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const [toggleModal, setToggleModal] = useState(false);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const handleDeleteSelection = async () => {
        try {
            const res = await removeTasks(selectedIds);
            if (res.status === 200) {
                setToggleModal(!toggleModal)
                fetchProjectTasks && fetchProjectTasks(projectId)
                setSelectedIds([])
                setSelectedItems([])
                notify(text?.notificationsUI?.task?.deleted, 'success')

                if (isDetails) {
                    handleGetTask()
                }
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const renderSubtasks = (subtasks) => {
        if (!subtasks || subtasks.length === 0) return null;

        return (
            <ul className='ml-md'>
                {subtasks.map((subtask) => (
                    <li key={subtask._id} style={{ fontSize: '0.9rem' }}>
                        - {subtask.taskTitle}
                        {renderSubtasks(subtask.subtasks)}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Wrapper
            isLoading={tasksIsLoading}
            setExpanded={setExpanded}
            section={'tasks'}
            expandable={!isDetails}
            title={title}
            disableButton={projectIsCompleted || isEmpty(adminProjects) || isDetails}
            button={
                <CallToAction
                    className='flex-one'
                    text={text?.tasks?.home?.createNew}
                    type='button'
                    onClick={() => handleAddTaskToggle()}
                />
            }
        >
            <div className='TasksContainer mb-md'>
                <div className='flex flex-column'>
                    <SearchInput
                        className='mb-md w-100'
                        value={filters.query}
                        onChange={handleSearchChange}
                        placeHolder={text?.tasks?.home?.filters?.search}
                    />
                    <ConditionalRender renderIf={true}>
                        <HoverPopup
                            className='align-self-start'
                            style={{
                                marginRight: '1rem',
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-tasks'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>
                    </ConditionalRender>
                    <ConditionalRender renderIf={showFilters}>
                        <div
                            className='filters mt-md'
                            style={{
                                flexWrap: 'wrap',
                            }}>
                            <ConditionalRender renderIf={!isEmpty(tasks)}>
                                <CustomSelect
                                    className='mt-md w-100'
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskStatus')}
                                    options={projectStatus(text)}
                                    name={'taskStatus'}
                                    placeHolder={text?.tasks?.home?.filters?.status}
                                />
                                <CustomSelect
                                    className='mt-md w-100'
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskPriority')}
                                    options={priority(text)}
                                    name={'taskPriority'}
                                    placeHolder={text?.tasks?.home?.filters?.priority}
                                />
                                <ConditionalRender renderIf={!isProject && !isDetails}>
                                    <CustomSelect
                                        className='mt-md'
                                        handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskProject')}
                                        options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => ({
                                            label: x?.projectName,
                                            value: x?._id
                                        }))}
                                        name={'taskProject'}
                                        placeHolder={text?.tasks?.home?.filters?.project}
                                    />
                                </ConditionalRender>
                                <CustomSelect
                                    className='mt-md'
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskAssignedTo')}
                                    options={assignedEmployees}
                                    name={'taskAssignedTo'}
                                    placeHolder={text?.tasks?.details?.assignedTo}
                                />
                                <p className='flex mt-md'>
                                    {text?.tasks?.create?.deadline}
                                </p>
                                <StyledDateRangePicker theme={theme} className='flex'>
                                    <DateRangePicker
                                        startDate={filters.startDate}
                                        startDateId="start_date_id"
                                        endDate={filters.endDate}
                                        endDateId="end_date_id"
                                        onDatesChange={handleDatesChange}
                                        focusedInput={focusedInput}
                                        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                        isOutsideRange={() => false}
                                        displayFormat={'MM-DD-YYYY'}
                                        showClearDates
                                        numberOfMonths={!isDesktop ? 1 : 2}
                                        hideKeyboardShortcutsPanel
                                        startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                                        endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                                    />
                                </StyledDateRangePicker>
                            </ConditionalRender>
                            <CheckBox
                                className='mt-md'
                                name={`isArchived`}
                                component="input"
                                type="checkbox"
                                label={text?.tasks?.archived}
                                checked={filters.isArchived}
                                onChange={handleArchivedChange}
                            />
                            <ConditionalRender renderIf={!isDetails}>
                                <CheckBox
                                    className='mt-md'
                                    name={`isSubtask`}
                                    component="input"
                                    type="checkbox"
                                    label={text?.tasks?.details?.filterSubtasks}
                                    checked={filters.isSubtask}
                                    onChange={HandleSubtaskChange}
                                />
                            </ConditionalRender>

                        </div>
                    </ConditionalRender>
                </div>
                <div className='flex flex-column align-start mt-md'>
                    <CustomTable
                        keyField="_id"
                        columns={tasksColumns({
                            theme,
                            text,
                            status: projectStatus,
                            priority,
                            isProject,
                            navigate,
                            handleQuickView,
                            isDesktop
                        })}
                        rows={tableData
                            ?.map(item => {
                                // Filter admin projects
                                const adminProjects = projects?.filter(project =>
                                    project.adminEmployees.some(employee => employee.id === user?.id)
                                );
                                // Add isAdmin flag to each inventory item
                                const isAdmin = adminProjects?.some(project => project?.id === item.taskProject?.id) || user?.roleName === 'Admin';
                                return { ...item, isAdmin };
                            })}
                        theme={theme}
                        noDataText={text?.tasks?.home?.noTasks}
                        onRowSelectionChange={handleRowSelectionChange}
                    />
                    <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                        <div style={{
                            color: colors.red,
                            fontFamily: colors.openSans,
                            cursor: 'pointer',
                            display: 'flex',
                            marginBottom: '5px'
                        }} onClick={() => setToggleModal(true)}>
                            {text?.tasks?.home?.deleteSelected}
                        </div>
                        <ConfirmModal
                            onClick={handleDeleteSelection}
                            text={text?.tasks?.home?.modal?.button}
                            toggle={() => setToggleModal(!toggleModal)}
                            isOpen={toggleModal}
                            btnStyles={{
                                color: colors.red
                            }}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            <div>
                                <h4 style={{ marginBottom: '15px' }}>
                                    {text?.tasks?.home?.modal?.title}
                                </h4>
                                <p className='mb-md'>
                                    {text?.tasks?.home?.modal?.info}
                                </p>
                                <p className='mb-md'>
                                    {text?.tasks?.details?.deleteModal?.info}
                                </p>
                                <ul>
                                    {selectedItems?.map((task) => (
                                        <li key={task._id} style={{ fontSize: '1rem', marginBottom: '10px' }}>
                                            - {task.taskTitle}
                                            {renderSubtasks(task.subtasks)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </ConfirmModal>
                    </ConditionalRender>
                </div>
                <ConditionalRender renderIf={!isEmpty(adminProjects)}>
                    <ConditionalRender renderIf={addTaskIsOpen}>
                        <CreateNewTaskContainer
                            isOpen={addTaskIsOpen}
                            toggle={handleAddTaskToggle}
                            projects={projects}
                            adminProjects={adminProjects}
                            user={user}
                            employees={employees}
                            fetchProjectTasks={fetchProjectTasks}
                            isProject={isProject}
                            projectId={projectId}
                            tasks={tasks}
                            handleGetProject={handleGetProject}
                        />
                    </ConditionalRender>
                </ConditionalRender>

                {/* Quick View */}
                <TasksQuickView
                    isQuickViewOpen={isQuickViewOpen}
                    setIsQuickViewOpen={setIsQuickViewOpen}
                    quickViewData={quickViewData}
                    noOverlay={false}
                />

            </div >
        </Wrapper >
    )
}

export default TasksContainer