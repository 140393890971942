import React, { useState, useEffect, useContext } from 'react';
import { useWebSocket } from '../context/WebSocketContext';
import styled from 'styled-components';
import colors from '../globalStyles.scss'; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/GlobalContext';
import { useToast } from '../context/ToastContext';
import ConditionalRender from '../Core/ConditionalRender';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLanguage } from '../context/LanguageContext';
import {
    updateNotification,
    markAllNotificationsAsRead,
    deleteAllNotifications
} from '../utils/calls';
import OutsideClickHandler from '../Core/OutsideClickHandler';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClose, faCheck, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../Core/HoverPopup';
import useScreenSize from '../context/useScreenSize';

// Styled components
const NotificationsWrapper = styled.div`
    transform: ${({ show }) => show ? 'translateX(0)' : 'translateX(200%)'};
    transition: transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    &.desktop {    
        flex-direction: column;
        position: fixed;
        top: 5%;
        right: 0;
        width: 400px;
        padding-bottom: 1em;
        padding-top: 1em;
        background: ${colors.darkGray};
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        height: 500px;
        margin-right: 1em;
    }

    &.tablet,  &.phone {
        flex-direction: column;
        position: fixed;
        top: 5%;
        left: 0;
        width: 100%;
        height: 50%;
        background: ${colors.darkGray};
        z-index: 1;
        overflow-y: auto;
        padding-bottom: 1em;
        padding-top: 1em;
    }


    .buttons-wrapper {
        position: sticky;
        bottom: 0;
        z-index: 1001;
        display: ${props => (props.visible ? 'flex' : 'none')};
        justify-content: space-between;
        padding: 0.5em 1em;
    }
`;

const NotificationList = styled.div`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`;

const NotificationItem = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: ${colors.darkGray};
    transition: background 0.3s;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        background: ${colors.secondary};
    }
    &.unread {
        position: relative;
        background: ${colors.lightGray};
         &::after {
                content: '';
                color: ${colors.white};
                position: absolute;
                height: 100%;
                width: 5px;
                left: 0;
                top: 0;
                background: ${colors.secondary};
            }
        &:hover {
            background: ${colors.secondary};
        }
    }
`;

const BackToTopButton = styled(HoverPopup)`
    font-family: ${colors.roboto};
    background: ${colors.blue};
    color: ${colors.white};
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    &:hover {
        background: ${colors.darkBlue};
    }
    svg {
            padding: 0.5em;
    }
`;
const MarkAllButton = styled(HoverPopup)`
    font-family: ${colors.roboto};
    background: ${colors.red};
    color: ${colors.white};
    border: none;
    cursor: pointer;
    &:hover {
        background: ${colors.darkBlue};
    }
    svg {
            padding: 0.5em;
    }
`;
const DeleteAllButton = styled(HoverPopup)`
    font-family: ${colors.roboto};
    background: ${colors.red};
    color: ${colors.white};
    border: none;
    cursor: pointer;
    &:hover {
        background: ${colors.darkBlue};
    }
    svg {
            padding: 0.5em;
    }
`;

const NotificationsContainer = ({
    userId,
    setCollapsed
}) => {
    const { hasNewNotifications, setHasNewNotifications } = useContext(GlobalContext);
    const { notify } = useToast();
    const { text } = useLanguage();
    const { isDesktop, isTablet } = useScreenSize();
    const [showNotifications, setShowNotifications] = useState(false);
    const [visibleNotifications, setVisibleNotifications] = useState(10);
    const [showBackToTop, setShowBackToTop] = useState(false);

    const { notifications, setNotifications } = useWebSocket();
    const navigate = useNavigate();
    const [hasUnread, setHasUnread] = useState(false);

    useEffect(() => {
        const unreadNotifications = notifications.some(notification => !notification.read);
        setHasUnread(unreadNotifications);

    }, [notifications]);

    useEffect(() => {
        if (hasNewNotifications) {
            setHasNewNotifications(false);
            notify('You have new notifications', 'info');
        }
    }, [hasNewNotifications]);


    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const loadMoreNotifications = () => {
        setVisibleNotifications(prev => prev + 3);
    };

    const handleNotificationClick = async (notification) => {
        try {
            const res = await updateNotification(notification._id)
            if (res.status === 200) {
                // Update the notification read status in the local state
                setNotifications(prevNotifications =>
                    prevNotifications.map(n =>
                        n._id === notification._id ? { ...n, read: true } : n
                    )
                );
                if (notification?.resourceUrl) {
                    navigate(notification.resourceUrl)
                }
                if (!isDesktop && notification?.resourceUrl !== '/') {
                    setShowNotifications(false);
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleMarkAllNotificationsAsRead = async () => {
        try {
            const res = await markAllNotificationsAsRead();
            if (res.status === 200) {
                // Update the notification read status in the local state
                setNotifications(prevNotifications =>
                    prevNotifications.map(n => ({ ...n, read: true }))
                );
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleDeleteAllNotifications = async () => {
        try {
            const res = await deleteAllNotifications();
            if (res.status === 200) {
                setNotifications([]);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleScroll = (event) => {
        if (event.target.scrollTop > 200) {
            setShowBackToTop(true);
        } else {
            setShowBackToTop(false);
        }
    };

    const scrollToTop = () => {
        document.getElementById('scrollableDiv').scrollTop = 0;
    };

    return (
        <OutsideClickHandler onOutsideClick={() => setShowNotifications(false)}>
            <div className='relative flex notifications-container'>
                <FontAwesomeIcon
                    onClick={toggleNotifications}
                    icon={faBell} style={{
                        color: colors.white,
                        cursor: 'pointer',
                    }}
                />
                <ConditionalRender renderIf={hasUnread}>
                    <div style={{
                        background: colors.red,
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '0.5em',
                        height: '0.5em',
                        borderRadius: '50%',
                    }} />
                </ConditionalRender>
                <NotificationsWrapper
                    className={isDesktop ? 'desktop scroll-container' : isTablet ? 'tablet scroll-container' : 'phone scroll-container'}

                    show={showNotifications}
                    id={'scrollableDiv'}
                    onScroll={handleScroll}
                    visible={showBackToTop}
                >
                    <ConditionalRender renderIf={notifications.length === 0}>
                        <h4
                            style={{
                                color: colors.blue
                            }}
                        >
                            {text?.notificationsUI?.noNotifications}
                        </h4>
                    </ConditionalRender>
                    <InfiniteScroll
                        dataLength={visibleNotifications}
                        next={loadMoreNotifications}
                        hasMore={visibleNotifications < notifications.length}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                        style={{
                            overflow: 'hidden',
                            position: 'relative'
                        }}
                    >
                        <NotificationList>
                            {notifications.slice(0, visibleNotifications).map((notification, index) => (
                                <NotificationItem
                                    className={classnames({
                                        'read': notification.read,
                                        'unread': !notification.read
                                    })}
                                    onClick={() => {
                                        setCollapsed && setCollapsed(true);
                                        handleNotificationClick(notification);
                                    }}
                                    key={index}
                                >
                                    <div className='flex items-center justify-space-between w-100'>
                                        <p style={{
                                            color: colors.black,
                                            fontWeight: 'bold',
                                            fontFamily: colors.roboto
                                        }} className="tx-left">
                                            {notification.title}
                                        </p>
                                        <p style={{
                                            color: colors.black,
                                            fontSize: '0.8em'
                                        }} className="tx-left">
                                            {moment(notification.createdAt).fromNow()}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            height: '1px',
                                            width: '100%',
                                            background: colors.darkGray,
                                        }}
                                    />
                                    <p style={{
                                        color: colors.black
                                    }} className="tx-left">
                                        {notification.message}
                                    </p>
                                </NotificationItem>
                            ))}
                        </NotificationList>
                    </InfiniteScroll>
                    <div className='flex buttons-wrapper'>
                        <MarkAllButton
                            onClick={handleMarkAllNotificationsAsRead}
                            placement={'top'}
                            id='markAllAsRead'
                            text={isDesktop && text?.notificationsUI?.markAllAsRead}
                            className='align-self-start ml-md'
                            style={{
                                background: colors.green,
                            }}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </MarkAllButton>
                        <BackToTopButton
                            onClick={scrollToTop}
                            placement={'top'}
                            id='backToTop'
                            text={isDesktop && text?.notificationsUI?.backToTop}
                            className='align-self-center'
                        >
                            <FontAwesomeIcon icon={faCaretUp} />
                        </BackToTopButton>
                        <DeleteAllButton
                            onClick={handleDeleteAllNotifications}
                            placement={'top'}
                            id='deleteAllNotifications'
                            text={isDesktop && text?.notificationsUI?.clearAll}
                            className='align-self-end mr-md'
                            style={{
                                background: colors.red,
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </DeleteAllButton>
                    </div>

                </NotificationsWrapper>
            </div>
        </OutsideClickHandler>
    );
};

export default NotificationsContainer;
