import React from 'react'
import SelectInput from '../Core/SelectInput'
import { isEmpty } from 'lodash'
import ConditionalRender from '../Core/ConditionalRender'
import { useLanguage } from '../context/LanguageContext'
import BarChartContainer from './BarChartContainer'
import AreaChartContainer from './AreaChartContainer'

import LineChartContainer from './LineChartContainer'
import Wrapper from '../Core/Wrapper'

const ChartsContainer = ({
    handleChartChange,
    spyValues,
    projects,
    form,
    data,
    inventoryColumns,
    edit,
    setFormattedData,
    formattedData,
    handleCombineChange,
    setCombineKey,
    combineKey,
    setStringData,
    setNumberData,
    stringData,
    numberData,
    dataCheckedKeys,
    setDataCheckedKeys,
    setDataCheckedKeysProject,
    setInfo,
    setInitialGroupBy,
    initialGroupBy,
    setChartIsEditing,
    initialChartType,
    initialProject,
    analyticsWidgetsLoading,
    fieldNames,
    formRef,
    isProject,
    analyticsWidgets

}) => {
    const { text } = useLanguage()


    return (
        <>

            {/* <SelectInput
                name={`chartType`}
                isValidNewOption={() => false}
                isSearchable={false}
                label={text?.analytics?.chartType}
                options={[
                    // TODO: Add more chart types
                    { value: 'barChart', label: text?.analytics?.barChart },
                    { value: 'areaChart', label: text?.analytics?.areaChart },
                    { value: 'lineChart', label: text?.analytics?.lineChart },
                    // { value: 'pieChart', label: 'Pie Chart' },
                ]}
                onChange={(e) => handleChartChange(e, form)}
                isClearable={false}
                initialValue={initialChartType}
            /> */}

            <BarChartContainer
                data={data}
                inventoryColumns={inventoryColumns}
                form={form}
                edit={edit}
                setFormattedData={setFormattedData}
                formattedData={formattedData}
                handleCombineChange={handleCombineChange}
                combineKey={combineKey}
                setCombineKey={setCombineKey}
                setStringData={setStringData}
                setNumberData={setNumberData}
                stringData={stringData}
                numberData={numberData}
                setDataCheckedKeys={setDataCheckedKeys}
                dataCheckedKeys={dataCheckedKeys}
                setInfo={setInfo}
                setInitialGroupBy={setInitialGroupBy}
                initialGroupBy={initialGroupBy}
                setChartIsEditing={setChartIsEditing}
                fieldNames={fieldNames}
                formRef={formRef}
                isProject={isProject}
                spyValues={spyValues}
                analyticsWidgets={analyticsWidgets}
            />
            <ConditionalRender renderIf={!isEmpty(formattedData) && !isEmpty(spyValues?.selectProject) && spyValues?.chartType?.value === 'areaChart'}>
                <AreaChartContainer
                    data={data}
                    inventoryColumns={inventoryColumns}
                    form={form}
                    edit={edit}
                    setFormattedData={setFormattedData}
                    formattedData={formattedData}
                    handleCombineChange={handleCombineChange}
                    combineKey={combineKey}
                    setCombineKey={setCombineKey}
                    setStringData={setStringData}
                    setNumberData={setNumberData}
                    stringData={stringData}
                    numberData={numberData}
                    setDataCheckedKeys={setDataCheckedKeys}
                    dataCheckedKeys={dataCheckedKeys}
                    setInfo={setInfo}
                    setInitialGroupBy={setInitialGroupBy}
                    initialGroupBy={initialGroupBy}
                    setChartIsEditing={setChartIsEditing}
                    fieldNames={fieldNames}
                    formRef={formRef}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={!isEmpty(formattedData) && !isEmpty(spyValues?.selectProject) && spyValues?.chartType?.value === 'lineChart'}>
                <LineChartContainer
                    data={data}
                    inventoryColumns={inventoryColumns}
                    form={form}
                    edit={edit}
                    setFormattedData={setFormattedData}
                    formattedData={formattedData}
                    handleCombineChange={handleCombineChange}
                    combineKey={combineKey}
                    setCombineKey={setCombineKey}
                    setStringData={setStringData}
                    setNumberData={setNumberData}
                    stringData={stringData}
                    numberData={numberData}
                    setDataCheckedKeys={setDataCheckedKeys}
                    dataCheckedKeys={dataCheckedKeys}
                    setInfo={setInfo}
                    setInitialGroupBy={setInitialGroupBy}
                    initialGroupBy={initialGroupBy}
                    setChartIsEditing={setChartIsEditing}
                    fieldNames={fieldNames}
                    formRef={formRef}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isEmpty(formattedData)}>
                <div className='text-center'>
                    <h3>
                        {text?.table?.noDataText}
                    </h3>
                </div>
            </ConditionalRender>
        </>
    )
}

export default ChartsContainer
