import colors from '../globalStyles.scss';
import {
    one,
    twoVideo,
    oneVideo,
    three_one,
    three_two,
    three_three,
    four_one,
    four_two,
    four_three,
    four_four,
    four_five,
    five_one,
    five_two,
    five_three,
    six_one,
    six_two,
    six_three,
    six_four,
    six_five,
    six_six,
    seven_one,
    seven_two,
} from './flow_en';
import CallToAction from '../Core/CallToAction';

export const pages_es = [
    {
        id: "page1",
        index: 0,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                {/* Sección de Imagen */}
                <div
                    style={{
                        gridRow: "2/7",
                        gridColumn: "2/4",
                    }}
                >
                    <img
                        height="100%"
                        width={"100%"}
                        src={one}
                        alt="Diagrama de flujo"
                    />
                </div>

                {/* Sección de Información de Texto */}
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "5/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            textAlign: "right",
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {"Crear un Proyecto"}
                    </h1>
                    <p
                        style={{ textAlign: "right" }}
                        className="mt-md"
                    >
                        {
                            "Donde comienza tu viaje con Ardidflow y el rendimiento de tu negocio crece."
                        }
                    </p>
                </div>

                {/* Sección de Video */}
                <div
                    style={{
                        gridRow: "4/7",
                        gridColumn: "3/6",
                    }}
                >
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        src={oneVideo}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    ></video>
                </div>

                {/* Sección de Características Detalladas */}
                <div
                    style={{
                        gridRow: "3/6",
                        gridColumn: "6/7",
                        marginRight: '50px',
                    }}
                >
                    <p
                        className="mt-sm"
                    >
                        {
                            "Una aplicación de gestión de proyectos es una herramienta poderosa que ayuda a los equipos a planificar, organizar y gestionar proyectos de manera eficiente. Centraliza las tareas, los recursos y la comunicación, permitiendo una colaboración fluida, un mejor seguimiento, decisiones más rápidas y una mayor productividad, asegurando que los proyectos se mantengan en curso."
                        }
                    </p>
                    <p
                        className="mt-md"
                    >
                        {
                            "Al crear un proyecto, puedes agregar un nombre, categoría, ubicación y descripción, asignar o crear un equipo y líderes de equipo, seleccionar o crear un cliente, actualizar imágenes y archivos, y establecer atributos personalizados adaptados a tus necesidades. Además, la opción de incluir gestión de inventarios dentro de los proyectos asegura que todo se mantenga centralizado y fácilmente accesible."
                        }
                    </p>
                    <p
                        className="mt-sm"
                    >
                        {
                            "Comenzar tu proyecto en Ardidflow es simple e intuitivo, asegurando que puedas centrarte rápidamente en lo que más importa: entregar resultados y alcanzar tus objetivos."
                        }
                    </p>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page2" },
    },


    {
        id: "page2",
        index: 1,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "2/5",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Detalles del Proyecto'}
                    </h1>
                    <p>
                        {
                            'Puedes actualizar los detalles del proyecto en cualquier momento y cambiar el estado para realizar un seguimiento del progreso hasta su finalización.'
                        }
                    </p>
                </div>

                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "6/7",
                    }}
                >
                    <div>
                        <h2 className="mb-md">
                            {'Gestiona tus proyectos fácilmente desde la página de detalles:'}
                        </h2>
                        <ul>
                            <li>{'Crear y rastrear tareas.'}</li>
                            <li>{'Guardar y enviar propuestas a tus clientes.'}</li>
                            <li>{'Crear y rastrear inventario.'}</li>
                            <li>{'Consultar tu calendario para todos los eventos del proyecto.'}</li>
                            <li>{'Leer mensajes de chat relacionados con el proyecto para obtener actualizaciones rápidas.'}</li>
                            <li>{'Revisar y actualizar el equipo, ver sus registros diarios y su progreso.'}</li>
                            <li>{'Crear y actualizar la información del cliente.'}</li>
                            <li>{'Crear atributos personalizados con campos avanzados (presupuesto, notas, fechas, etc...).'}</li>
                            <li>{'Acceder a todas tus imágenes y archivos en un solo lugar.'}</li>
                            <li>{'Monitorear las actualizaciones del proyecto a través del registro de historial.'}</li>
                            <li>{'Interfaces dedicadas para empleados y administradores.'}</li>
                        </ul>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "3/6",
                        color: colors.white,
                        marginRight: '10px',
                    }}
                >
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        src={twoVideo}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    ></video>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page1", down: "page3" },
    },
    {
        id: "page3",
        index: 2,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "100px 1fr 1fr 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/4",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Tareas'}
                    </h1>
                    <p>
                        {'Las tareas serán el motor que impulsa tu proyecto, manteniéndolo en funcionamiento sin problemas.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "5/7",
                    }}
                >
                    <h2>
                        {'Crea una tarea desde el panel de control o la página de detalles del proyecto'}
                    </h2>
                    <img
                        style={{
                            width: '75%',
                            display: 'flex',
                            marginTop: '10px',
                            objectFit: 'contain',
                        }}
                        src={three_one}
                        alt="Crear una tarea"
                    />
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "5/7",
                    }}
                >
                    <div className='flex items-center mt-md'>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_two}
                            alt="Asignar empleados"
                        />
                        <h2 className='ml-md' style={{ textAlign: 'left' }}>
                            {'Asigna empleados para que todos conozcan su rol en el proyecto'}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "5/7",
                    }}
                >
                    <div className='flex items-center mt-md'>
                        <h2 className='mt-md mr-md' style={{ textAlign: 'right' }}>
                            {'Establece prioridades y plazos para mantenerte en el camino'}
                        </h2>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_three}
                            alt="Establecer prioridades y plazos"
                        />
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "3/6",
                        gridColumn: "3/5",
                    }}
                >
                    <div className='mt-md'>
                        <h2 className='mb-md' style={{ textAlign: 'right' }}>
                            {'& Más...'}
                        </h2>
                        <ul style={{ textAlign: 'right' }}>
                            <li style={{ textAlign: 'right' }}>{'Crea subtareas para dividir el trabajo en partes manejables.'}</li>
                            <li style={{ textAlign: 'right' }}>{'Cada tarea puede incluir tareas pendientes para delinear los pasos o detalles de lo que debe hacerse.'}</li>
                            <li style={{ textAlign: 'right' }}>{'Agrega comentarios/imágenes para mejorar la comunicación.'}</li>
                            <li style={{ textAlign: 'right' }}>{'Clona tareas para crear trabajos similares más rápidamente.'}</li>
                            <li style={{ textAlign: 'right' }}>{'Recibe notificaciones, recordatorios en el calendario/correo electrónico cuando se acerque la fecha límite de una tarea o se haya realizado un cambio.'}</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),

        background: colors.black,
        neighbors: { up: "page2", left: "page4" },
    },

    {
        id: "page4",
        index: 3,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "6/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right',
                        }}
                    >
                        {'Seguimiento de Tiempo, Registros y Gestión'}
                    </h1>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/3",
                    }}
                >
                    <img width={'100%'} src={four_two} alt="Seguimiento de tiempo" />
                    <img width={'100%'} src={four_five} alt="Seguimiento de tiempo" />
                    <p className='mt-md'>
                        {
                            'El seguimiento de tiempo ayudará a monitorear las horas de trabajo de los empleados, garantizando una mejor gestión del tiempo y eficiencia en las tareas.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/3",
                    }}
                >
                    <img width={'100%'} src={four_three} alt="Seguimiento de tiempo" />
                    <p
                        className='mt-md'
                        style={{ textAlign: 'right' }}
                    >
                        {
                            'Los administradores pueden ver quién está actualmente registrado, en qué están trabajando, cuándo se registraron y cuánto tiempo han estado trabajando.'
                        }
                    </p>
                    <p className='mt-md mb-md'>
                        {
                            'Los registros diarios pueden actualizarse para reflejar el progreso, ayudando al equipo a mantenerse alineado y mejorando la colaboración general.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "4/6",
                    }}
                >
                    <img width={'80%'} src={four_one} alt="Seguimiento de tiempo" />
                    <div className='flex justify-between mt-md'>
                        <p
                            style={{ textAlign: 'left' }}
                        >
                            {
                                'Automáticamente rastreará el estado de las tareas al registrar la entrada y salida, haciendo que el seguimiento del tiempo sea más preciso y confiable.'
                            }
                        </p>
                        <p
                            style={{ textAlign: 'right' }}
                        >
                            {
                                'Tendrás la capacidad de actualizar entradas de tiempo en cualquier momento mediante la función de gestión de registros para ti y tu equipo.'
                            }
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "4/6",
                    }}
                >
                    <img width={'100%'} src={four_four} alt="Seguimiento de tiempo" />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page3", down: "page5" },
    },

    {
        id: "page5",
        index: 4,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "6/7",
                        gridColumn: "4/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            textAlign: 'center',
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Clientes y Propuestas'}
                    </h1>
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {
                            'Crea, actualiza, elimina y gestiona clientes fácilmente y mantenlos informados a través del portal del cliente. Los clientes pueden ver los detalles del proyecto, seguir el progreso, y aprobar/rechazar y firmar propuestas con solo unos clics.'
                        }
                    </p>
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {
                            'Comunícate con los clientes a través del chat y obtén retroalimentación instantánea sobre las actualizaciones del proyecto, tareas y más.'
                        }
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {
                            'Guarda borradores de propuestas, envíalos directamente a los clientes por correo electrónico con un PDF adjunto y recibe notificaciones instantáneas cuando el estado de una propuesta cambie.'
                        }
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {
                            'Realiza ajustes rápidos a las propuestas y vuelve a enviarlas sin esfuerzo para lograr tiempos de respuesta más rápidos y una mejor colaboración.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={five_one} alt="Seguimiento de tiempo" />
                    <img width={'100%'} src={five_two} alt="Seguimiento de tiempo" />
                </div>
                <div
                    style={{
                        gridRow: "2/6",
                        gridColumn: "4/7",
                    }}
                >
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        src={five_three}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    ></video>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { up: "page4", left: "page6" },
    },

    {
        id: "page6",
        index: 5,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 50px 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/4",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Chat, Calendario y Notificaciones'}
                    </h1>
                    <img width={'100%'} src={six_four} alt="Analíticas" />
                    <p className="mt-md">
                        {
                            'El calendario te permite programar reuniones, establecer recordatorios y realizar un seguimiento de cuándo se deben completar las tareas, asegurando que cumplas con todos los plazos y compromisos del proyecto.'
                        }
                    </p>
                    <p className="mt-md">
                        {
                            'Usa el calendario para coordinar horarios, gestionar eventos y asegurarte de que se cumplan las fechas límite de las tareas, todo en una interfaz intuitiva.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "4/5",
                    }}
                >
                    <img width={'100%'} src={six_five} alt="Notificaciones" />
                    <p>
                        {
                            'Recibe notificaciones instantáneas sobre actualizaciones de proyectos, fechas límite de tareas, mensajes y más para mantenerte informado y mejorar la colaboración del equipo.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "5/7",
                    }}
                >
                    <p>
                        {
                            'La función de chat está diseñada para una comunicación fluida entre los miembros del equipo y con los clientes. Úsala para discutir proyectos, compartir actualizaciones y colaborar de manera efectiva. Ya sea sobre el progreso del proyecto, la coordinación de tareas o consultas generales, este chat asegura que todos estén conectados e informados.'
                        }
                    </p>
                    <img width={'100%'} src={six_six} alt="Chat" />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page5", down: "page7" },
    },

    {
        id: "page7",
        index: 6,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/4",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Analíticas, Ayuda de IA y Gestión de Empleados'}
                    </h1>
                    <p>
                        {
                            'La función de Gestión de Empleados es una herramienta integral diseñada para simplificar la supervisión y organización del personal. Proporciona una plataforma centralizada donde los gerentes pueden gestionar eficientemente los detalles de los empleados, incluidos los títulos de trabajo, salarios, habilidades y más. Los empleados pueden acceder a sus perfiles personales para ver información clave, como roles y responsabilidades laborales. La función también permite rastrear y actualizar datos esenciales, como certificaciones, métricas de rendimiento y otra información relevante. Con una base de datos robusta de empleados, los gerentes pueden buscar rápidamente cualquier empleado y sus detalles para tomar mejores decisiones, mientras que cualquier persona en la empresa puede buscar fácilmente información de contacto o colaborar con colegas, asegurando una comunicación fluida y un trabajo en equipo eficiente.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "5/6",
                    }}
                >
                    <img width={'100%'} src={six_three} alt="Ayuda de IA" />
                    <p className="mt-md" style={{ textAlign: 'right' }}>
                        {
                            'Obtén una vista clara del rendimiento de tu proyecto con analíticas avanzadas y toma decisiones basadas en datos para mejorar la eficiencia empresarial.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "4/5",
                    }}
                >
                    <img width={'100%'} src={seven_two} alt="empleado" />
                    <img width={'100%'} src={seven_one} alt="empleado" />
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "5/7",
                    }}
                >
                    <img width={'100%'} src={six_two} alt="Analíticas" />
                </div>
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "6/7",
                    }}
                >
                    <p className="mt-md">
                        {
                            'La Ayuda de IA es tu asistente para responder preguntas sobre tareas, inventario, proyectos y cómo usar la aplicación. Obtén rápidamente la ayuda que necesitas para navegar por las funciones y resolver problemas.'
                        }
                    </p>
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        src={six_one}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                    ></video>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { down: "page8", up: "page6" },
    },
    {
        id: "page8",
        index: 7,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "50px 1fr 50px 1fr 1fr 1fr 50px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 50px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "3",
                        gridColumn: "3/6",
                        color: colors.white,
                    }}
                >
                    <CallToAction
                        className="mt-md"
                        btnWidth="auto"
                        btnHeight="auto"
                        btnStyle={{
                            padding: '0.3em 1em',
                            border: `2px solid ${colors.blue}`,
                            color: colors.white,
                            hoverBackgroundColor: colors.lightblue,
                            fontSize: '2.5em',
                        }}
                        text="Comenzar"
                        onClick={() => (window.location.href = '/register')}
                    />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { up: "page7" },
    },


];

export default pages_es;
