import request from '../request';

/**
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const checkUnreadConversations = async (params) => {
    try {
        const response = await request('POST', `/chat/conversations/unread`, params);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to get unrad convos: ${error.message}`);
    }
};

export default checkUnreadConversations;
