import React from "react";
import styled from "styled-components";
import { pages } from "./pages";
import { pages_sm } from "./pages_sm";
import { pages_es } from "./pages_es";
import { pages_es_sm } from "./pages_es_sm";
import { useLanguage } from "../context/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../globalStyles.scss";
import classnames from "classnames";
import useScreenSize from "../context/useScreenSize";
import ConditionalRender from "../Core/ConditionalRender";
import Arrow, { DIRECTION } from 'react-arrows'
import { isEmpty } from "lodash";

import {
    faCircleDot
} from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  width: 100vw;
  height: ${(props) => (props.isDesktop ? "90vh" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: ${colors.black};
  h1, h2, h3, p, span, ul, li {
    color: ${colors.white};
    text-align: left;
  }
    p, li {
        font-size: 0.9em;
        font-family: ${colors.openSans};
        margin-top: 0.5em;
    }
`;

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transform: ${(props) => `translate(${props.offsetX}%, ${props.offsetY}%)`};
  opacity: ${(props) => (props.visible ? 1 : 0)};
    .hide {
        opacity: 0;
    }
    .active {
        opacity: 1;
    }
`;

const ArrowDiv = styled.button`
  position: absolute;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
  }
  &.up {
    top: 10px;
  }
  &.down {
    bottom: 10px;
  }
svg {
    color: ${colors.white};
    font-size: 2em;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
`;

const Flowchart = () => {
    const [currentPage, setCurrentPage] = React.useState({});
    const [direction, setDirection] = React.useState({ x: 0, y: 0 });
    const [isScrolling, setIsScrolling] = React.useState(false);
    const { isDesktop, isPhone } = useScreenSize();
    const [isLoading, setIsLoading] = React.useState(true);
    const { language } = useLanguage();
    const [pageLn, setPagesLn] = React.useState([]);

    React.useEffect(() => {
        if (language === 'es') {
            if (isEmpty(pages)) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
            setCurrentPage(pages[0]);
        } else {
            if (isEmpty(pages_es)) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
            setCurrentPage(pages_es[0]);
        }
    }, [language]);

    React.useEffect(() => {
        if (language === 'es') {
            if (isDesktop) {
                setPagesLn(pages_es);
            } else {
                setPagesLn(pages_es_sm);
            }
        } else {
            if (isDesktop) {
                setPagesLn(pages);
            } else {
                setPagesLn(pages_sm);
            }
        }
    }, [language, pages, pages_es, pages_sm, isDesktop]);

    const handleNavigate = (dir) => {
        const nextPageId = currentPage?.neighbors[dir];
        if (nextPageId) {
            const nextPage = pageLn?.find((page) => page?.id === nextPageId);
            if (nextPage) {
                setDirection(() => {
                    switch (dir) {
                        case "left":
                            return { x: 100, y: 0 };
                        case "right":
                            return { x: -100, y: 0 };
                        case "up":
                            return { x: 0, y: 100 };
                        case "down":
                            return { x: 0, y: -100 };
                        default:
                            return { x: 0, y: 0 };
                    }
                });
                setCurrentPage(nextPage);
            }
        }
    };


    const handleScroll = (e) => {
        if (isScrolling) return;

        const direction = e.deltaY > 0 ? "down" : "up";
        const deltaX = e.deltaX > 0 ? "right" : "left";

        setIsScrolling(true);

        if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
            handleNavigate(deltaX);
        } else {
            handleNavigate(direction);
        }

        setTimeout(() => setIsScrolling(false), 2000); // Buffer time for smooth transitions
    };

    React.useEffect(() => {
        if (!isDesktop) {
            return;
        }
        window.addEventListener("wheel", handleScroll);
        return () => window.removeEventListener("wheel", handleScroll);
    }, [currentPage, isScrolling, isDesktop]);

    React.useEffect(() => {
        // Ensure the initial page transforms are set properly
        setDirection({ x: 0, y: 0 });
    }, []);

    return (
        <ConditionalRender renderIf={true} isLoading={isLoading}>
            <Wrapper
                isDesktop={isDesktop}
            >
                <ArrowDiv
                    id="left"
                    className="left"
                    onClick={() => handleNavigate("left")}
                    visible={!!currentPage?.neighbors?.left}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="right"
                    className="right"
                    onClick={() => handleNavigate("right")}
                    visible={!!currentPage?.neighbors?.right}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="up"
                    className="up"
                    onClick={() => handleNavigate("up")}
                    visible={!!currentPage?.neighbors?.up}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="down"
                    className="down"
                    onClick={() => handleNavigate("down")}
                    visible={!!currentPage?.neighbors?.down}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>

                {pageLn?.map((page) => {
                    const isCurrent = page.id === currentPage.id;

                    const offset = isCurrent
                        ? { x: 0, y: 0 }
                        : {
                            x: currentPage.id === page?.neighbors.left
                                ? -100
                                : currentPage.id === page?.neighbors.right
                                    ? 100
                                    : 0,
                            y: currentPage.id === page?.neighbors.up
                                ? -100
                                : currentPage.id === page?.neighbors.down
                                    ? 100
                                    : 0,
                        };

                    return (
                        <Page
                            className={classnames({
                                active: isCurrent,
                                'hide': !isCurrent
                            })}
                            key={page.id}
                            visible={isCurrent}
                            offsetX={offset.x}
                            offsetY={offset.y}
                            style={{
                                background: page.background,
                                zIndex: isCurrent ? 1 : 0,
                            }}
                        >
                            {page.content}
                        </Page>
                    );
                })}
                {(currentPage?.neighbors?.right && currentPage?.neighbors?.down) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`right`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`down`),
                            translation: [0, -1],
                        }}
                    />
                )}
                {(currentPage?.neighbors?.down && currentPage?.neighbors?.left) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`down`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`left`),
                            translation: [0, -1],
                        }}
                    />
                )}
                {(currentPage?.neighbors?.left && currentPage?.neighbors?.up) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`left`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`up`),
                            translation: [0, -1],
                        }}
                    />
                )}


                {currentPage?.up && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`up`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`right`),
                            translation: [0, -1],
                        }}
                    />
                )}

            </Wrapper>
        </ConditionalRender>
    );
};

export default Flowchart;
