import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Field } from 'react-final-form';
import { isRequired, composeValidators } from '../utils/validators';
import ImageUploader from '../Core/ImageUploader';
import { useLanguage } from '../context/LanguageContext';
import { languageOptions } from '../utils/helperFunctions';
import ConditionalRender from '../Core/ConditionalRender';
import SelectInput from '../Core/SelectInput';
import { isEmpty } from 'lodash';
import base64ToBlob from '../utils/base64ToBlob';
import useScreenSize from '../context/useScreenSize';

import {
    createResources,
    createResourceImage
} from '../utils/calls';
import MultiFields from '../Core/MultiFields';
import FormActions from '../Core/FormActions';
import colors from '../globalStyles.scss';

const CreateResourcesContainer = ({ fetchResources }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { text, language } = useLanguage();
    const navigate = useNavigate();
    const { isDesktop, isTablet } = useScreenSize();

    const required = composeValidators(isRequired('Required'));

    const handleImageSelect = (imageData) => {
        setSelectedImages(imageData);
    };

    const onSubmit = async (values) => {
        values.language = language
        values.hasImages = !isEmpty(selectedImages);

        setIsLoading(true);
        const res = await createResources(values);
        try {
            if (res.status === 200) {
                const resourceId = res.data._id;
                if (!isEmpty(selectedImages)) {
                    const imageUploadPromises = selectedImages.map(async (image) => {
                        const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
                        const formData = new FormData();
                        formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                        formData.append("resourceId", resourceId);

                        console.log("Uploading image...");

                        // Send the image to a separate endpoint
                        return createResourceImage(formData)
                            .then((imageRes) => {
                                if (imageRes?.status === 200) {
                                    console.log("Image uploaded");
                                    setIsLoading(false);
                                    navigate(`/resources/details?id=${resourceId}`);
                                    fetchResources();
                                } else {
                                    throw new Error("Image upload failed");
                                }
                            });
                    }) || [];

                    await Promise.all(imageUploadPromises);
                } else {
                    setIsLoading(false);
                    navigate(`/resources/details?id=${resourceId}`);
                    fetchResources();
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <ConditionalRender renderIf={true} isLoading={isLoading}>
            <p>
                {`You are creating a new resource in ${languageOptions.find((x) => x.value === language)?.label || 'Unknown Language'}.`}
            </p>
            <p>
                {'only users with this language selected will be able to see this resource.'}
            </p>
            <p>
                {'You can change the language in the settings page.'}
            </p>

            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <MultiFields
                            name="title"
                            component="input"
                            type="text"
                            label="Title"
                            validate={required}
                            block
                            style={{ margin: '15px 0' }}
                        />
                        <MultiFields
                            name="description"
                            component="textarea"
                            type="text"
                            label="Description"
                            validate={required}
                            block
                            style={{ margin: '15px 0' }}
                        />
                        <SelectInput
                            isSearchable={false}
                            className='mb-md'
                            menuPlacement='top'
                            validate={required}
                            initialValue={{
                                value: 'Announcement',
                                label: 'Announcement'
                            }}
                            placeholder={'Category'}
                            options={[
                                { value: 'Announcement', label: 'Announcement' },
                                { value: 'Tutorial', label: 'Tutorial' },
                                { value: 'NewsLetter', label: 'NewsLetter' },
                                { value: 'Job', label: 'Job' },
                                { value: 'Event', label: 'Event' }
                            ]}
                            name={'category'}
                            isClearable={false}
                            isValidNewOption={() => false}
                            style={
                                isDesktop ? { width: '30%' } : isTablet ? { width: '50%' } : { width: '100%' }
                            }
                        />
                        <p>
                            {'* select the image thumbnail for the resource on the landing page. otherwise a default image will be used.'}
                        </p>
                        <ImageUploader
                            onImageUpload={handleImageSelect}
                            maxFiles={1}
                            title={'Title Image'}
                            customMessage={'Upload a title image'}
                            isLoading={isLoading}
                        />
                        <ConditionalRender isLoading={isLoading} renderIf={isEmpty(selectedImages)}>
                            <p className='mb-md'>
                                {text?.projects?.create?.notFoundMsgs?.images}
                            </p>
                        </ConditionalRender>

                        {/* TinyMCE editor with a custom button to insert video embeds */}
                        <Field name="body" validate={required}>
                            {({ input }) => (
                                <Editor
                                    apiKey="ufnzdbkgsq9gt4qexsl47ib3zlt5dr49kfnxd00tp8vxa5m0"
                                    value={input.value}
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen",
                                            "insertdatetime media table paste code help wordcount",
                                            "textcolor",
                                            "fontselect",
                                            "fontsizeselect",
                                            "code",
                                        ],
                                        toolbar:
                                            "fontsize | formatselect | fontselect fontsizeselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | customInsertVideo | customInsertImage | customInsertLink | customInsertBlock",
                                        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                        setup: function (editor) {
                                            // Custom button to insert blocks with background color
                                            editor.ui.registry.addButton("customInsertBlock", {
                                                text: "Insert Block",
                                                icon: "insert",
                                                onAction: function () {
                                                    const backgroundColor = prompt("Enter Background Color (e.g., #FFFFFF or red)"); // Prompt for background color
                                                    if (backgroundColor) {
                                                        // Insert a block (div) with the chosen background color and ensure a new line is added after the block
                                                        const blockHtml = `
                  <div style="background-color: ${backgroundColor}; padding: 20px; margin-bottom: 10px;">
                    <p>New Block Content</p>
                  </div>
                  <p><br/></p>
                `;
                                                        editor.insertContent(blockHtml); // Insert the block followed by a new line
                                                    }
                                                },
                                            });

                                            // Custom button to insert videos
                                            editor.ui.registry.addButton("customInsertVideo", {
                                                text: "Insert Video",
                                                icon: "embed",
                                                onAction: function () {
                                                    const videoUrl = prompt("Enter YouTube Video ID (v=)");
                                                    if (videoUrl) {
                                                        const videoId = videoUrl.split("v=")[1] || videoUrl.split("/").pop();
                                                        const embedHtml = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                                                        editor.insertContent(embedHtml);
                                                    }
                                                },
                                            });

                                            // Custom button for inserting images
                                            editor.ui.registry.addButton("customInsertImage", {
                                                text: "Insert Image",
                                                icon: "image",
                                                onAction: function () {
                                                    const imageUrl = prompt("Enter Image URL (.jpg, .jpeg)");
                                                    if (imageUrl) {
                                                        const imgHtml = `<img src="${imageUrl}" alt="Image" style="max-width: 100%; height: auto;" />`;
                                                        editor.insertContent(imgHtml);
                                                    }
                                                },
                                            });

                                            // Custom button for inserting links
                                            editor.ui.registry.addButton("customInsertLink", {
                                                text: "Insert Link",
                                                icon: "link",
                                                onAction: function () {
                                                    const linkUrl = prompt("Enter URL");
                                                    const linkText = prompt("Enter Link Text");
                                                    if (linkUrl && linkText) {
                                                        const linkHtml = `<a href="${linkUrl}" target="_blank">${linkText}</a>`;
                                                        editor.insertContent(linkHtml);
                                                    }
                                                },
                                            });
                                        },
                                    }}
                                    onEditorChange={(content) => {
                                        input.onChange(content);
                                    }}
                                />
                            )}
                        </Field>




                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px'
                        }}>
                            <FormActions
                                type="submit"
                                onClick={() => {
                                    form.reset();
                                    setSelectedImages([]);
                                }}
                                resetForm={() => {
                                    form.reset();
                                    setSelectedImages([]);
                                }}
                                submitText={"Create"}
                                secondaryText={"Reset"}
                                form={form}
                                btnStyle={{ color: colors.blue }}
                            />
                        </div>
                    </form>
                )}
            />
        </ConditionalRender>
    );
};

export default CreateResourcesContainer;
