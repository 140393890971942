import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { FormSpy, Field } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import MultiFields from '../Core/MultiFields';
import { noSpecialChars, noNumbers, composeValidators, requiredNumber } from '../utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldWithValidation from '../Core/FieldWithValidation';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import CustomField from '../Core/CustomField';
import SelectInput from '../Core/SelectInput';
import CheckBox from '../Core/Inputs/CheckBox';
import { isEmpty } from 'lodash';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTheme } from '../context/ThemeContext'
import CallToAction from '../Core/CallToAction';
import inputTypeOptions from './inputTypeOptions';
import { capitalize } from 'lodash';
import { toCamelCase, compareStrings } from '../utils/helperFunctions';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';

const StyledExtraFields = styled.div`

    &.desktop {
        .ExtraFields {
            display: flex;
        }
    }

    .ExtraFields-wrapper-1 {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ExtraFields-wrapper-inputs {
            flex: 1;
            .ExtraFields-wrapper-input {
                display: flex;
                padding: 15px;
                button.delete {
                    color: ${colors.red};
                    display: flex;
                    height: 20%;
                }
            }
        }

        .ExtraFields-wrapper-fields {
            display: flex;
            flex-direction: column;
            padding-bottom: 1em;
            .error {
                align-self: end;
            }
        }
    }
    
`;

const ExtraFields = ({
    id,
    fields = [],
    setFields = () => [],
    withTimePicker,
    resetChild,
    resetChildDone,
    form,
    fieldNames,
    setFieldNameAndValues,
    fieldNameAndValues,
    onFieldRemove,
    setDuplicatedFields
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const { inventory, inventoryColumns } = useContext(GlobalContext)

    const [newFieldName, setNewFieldName] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldError, setFieldError] = useState('');

    const [selectedFieldType, setSelectedFieldType] = useState('Field Type'); // New state for selected input type

    const addField = (e) => {
        // prevents field creatiion without selecting a type of input
        if (!isEmpty(newFieldName) && (selectedFieldType && selectedFieldType !== 'Field Type')) {
            if (fields.some((field) => compareStrings(field.fullName.split('-')[1], newFieldName?.value))) {
                setFieldError(text?.inventory?.create?.form?.extraFields?.validations?.exists);
            } else {
                const newId = uuidv4(); // Generate a new UUID
                const newField = {
                    id: newId,
                    fullName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                    label: capitalize(newFieldName?.label),
                    fieldLabel: capitalize(newFieldName?.label),
                    name: toCamelCase(newFieldName?.value),
                    inputType: selectedFieldType
                };

                setFields([
                    ...fields,
                    newField
                ]);
                setDuplicatedFields((prevFields) => {
                    // If the product id exists in the state, append the new field to it, otherwise create a new array
                    const updatedFieldsForProduct = prevFields[id] ? [...prevFields[id], newField] : [newField];

                    return { ...prevFields, [id]: updatedFieldsForProduct };
                });
                setFieldNameAndValues([
                    ...fieldNameAndValues,
                    {
                        id: newId,
                        label: capitalize(newFieldName?.label),
                        fieldLabel: capitalize(newFieldName?.label),
                        name: toCamelCase(newFieldName?.value),
                        fullName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                        inputType: selectedFieldType
                    }
                ])

                setNewFieldName({});
                setFieldError('');
                setErrorMessage('');
                setSelectedFieldType('Field Type');
                form.change(`inputType`, null)
                form.change(`fieldName`, null);
            }
        } else {
            if (isEmpty(newFieldName)) {
                setErrorMessage(text?.inventory?.details?.validations?.fieldName)
            } else {
                setErrorMessage(text?.inventory?.create?.form?.extraFields?.validations?.fieldType)
            }
            return null
        }

    };

    const handleFieldRemoval = (id, field) => {
        setDuplicatedFields(prevFields => {
            if (prevFields[id]) {
                // Filter out the field with the given fieldId from the array of fields for the given id
                const updatedFieldArray = prevFields[id].filter(x => x.id !== field?.id);

                // Update the product's fields in the state
                return {
                    ...prevFields,
                    [id]: updatedFieldArray
                };
            }
            return prevFields;
        });
    }

    const handleInputChange = (e) => {
        setSelectedFieldType(e?.value);
    }

    const removeField = (field) => {

        // Remove the field from the fields state
        setFields(fields.filter(x => x.id !== field?.id));

        // Remove the field from the fieldNameAndValues state
        setFieldNameAndValues(fieldNameAndValues.filter(x => x.fullName !== field?.fullName));

        // Access the current form values
        handleFieldRemoval(id, field);

        // remove the field from the form
        form.change(`advanceFields.${field.fullName}`, null);

    };


    const handleOnChange = (e) => {
        const eValue = e?.value.replace(/\s/g, "");
        const eLabel = e?.label;

        setNewFieldName({
            label: eLabel,
            value: eValue
        });
        if (fields.some((field) => compareStrings(field.fullName.split('-')[1], eValue))) {
            setFieldError(text?.inventory?.create?.form?.extraFields?.validations?.exists);
        } else {
            setFieldError(
                noNumbers(e?.value) ||
                noSpecialChars(e?.value)
            );
        }
    };

    useEffect(() => {

        // resets all fields in the form as well as dependencies
        if (resetChild) {
            setNewFieldName({});
            resetChildDone();
            setFields([]);
        }
    }, [resetChild, resetChildDone]);

    return (
        <StyledExtraFields
            className={classnames({
                'mt-md': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
        >
            <div className='ExtraFields'>
                <div className='ExtraFields-wrapper-1'>
                    {

                        <>
                            <div className='ExtraFields-wrapper-fields'>
                                <CustomField
                                    value={newFieldName?.value}
                                    placeholder={text?.inventory?.details?.form?.selectFieldName}
                                    onChange={handleOnChange}
                                    errorMessage={fieldError}
                                    type={'text'}
                                    wrapperStyles={{ width: 'auto' }}
                                    fieldNames={fieldNames}
                                    name={`fieldName`}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                                <SelectInput
                                    name={`inputType`}
                                    options={inputTypeOptions(text)}
                                    onChange={handleInputChange}
                                    placeholder={text?.inventory?.create?.form?.extraFields?.fieldType}
                                    width={100}
                                    isSearchable
                                    isClearable
                                    isValidNewOption={() => false}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                                <CallToAction
                                    wrapperClassName={classnames({
                                        'mt-md': true
                                    })}
                                    type="button"
                                    onClick={addField}
                                    disabled={!isEmpty(fieldError)}
                                    text={text?.inventory?.create?.form?.extraFields?.addField}
                                    style={{
                                        alignSelf: 'flex-end',
                                    }}
                                />
                                <span className='small error mt-md align-self-end'>
                                    {errorMessage}
                                </span>
                            </div>
                            <div className='ExtraFields-wrapper-inputs'>
                                {fields?.map((field) => {
                                    const fieldLabel = field.label;
                                    return (
                                        <div className='ExtraFields-wrapper-input flex-column' key={field.id}>
                                            <div className='flex w-100'>
                                                {
                                                    field.inputType === 'number' ? (
                                                        <div className='flex flex-column w-100'>
                                                            <MultiFields
                                                                className='field-style'
                                                                name={`advanceFields.${field.fullName}.value`}
                                                                component={field.inputType}
                                                                min="0"
                                                                type={field.inputType}
                                                                label={fieldLabel}
                                                                form={form}
                                                                block
                                                                width={field?.fieldName === 'number' && isDesktop ? 30 : isTablet ? 50 : 100}
                                                            />
                                                            <FormSpy>
                                                                {({ values }) => {
                                                                    const productAdvanceFields = values?.advanceFields?.[field?.fullName];
                                                                    const hasValue = productAdvanceFields?.value;
                                                                    const isChecked = productAdvanceFields?.notification?.isChecked;

                                                                    return (
                                                                        <>
                                                                            {(hasValue || hasValue === 0) && (
                                                                                <Field name={`advanceFields.${field.fullName}.notification.isChecked`}>
                                                                                    {({ input }) => (
                                                                                        <CheckBox
                                                                                            {...input}
                                                                                            name={`advanceFields.${field.fullName}.notification.isChecked`}
                                                                                            type="checkbox"
                                                                                            checked={input.value}
                                                                                            onChange={input.onChange}
                                                                                            label={text?.inventory?.create?.form?.extraFields?.notifyMe}
                                                                                            checkStyle={{ background: colors.blue }}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            )}
                                                                            {(isChecked && (hasValue || hasValue === 0)) && (
                                                                                <div className='flex items-center'>
                                                                                    <p className='mr-md'>{text?.inventory?.create?.form?.extraFields?.numberBellow}</p>
                                                                                    <FieldWithValidation
                                                                                        validate={composeValidators(
                                                                                            requiredNumber('Required')
                                                                                        )}
                                                                                        className='field-style'
                                                                                        name={`advanceFields.${field.fullName}.notification.value`}
                                                                                        component="input"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        label={text?.inventory?.create?.form?.extraFields?.quantity}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    );
                                                                }}
                                                            </FormSpy>
                                                        </div>
                                                    ) : (
                                                        <MultiFields
                                                            className='field-style'
                                                            name={`advanceFields.${field.fullName}`}
                                                            component={field.inputType}
                                                            type={field.inputType}
                                                            fieldName={field.name}
                                                            label={fieldLabel}
                                                            form={form}
                                                            withTimePicker={withTimePicker}
                                                            autoComplete
                                                            suggestionData={inventory}
                                                            fields={inventoryColumns}
                                                            block
                                                        />
                                                    )
                                                }
                                                <FormSpy>
                                                    {({ form }) => (
                                                        <button className='delete' type="button" onClick={() => removeField(field)}>
                                                            <FontAwesomeIcon icon={faClose} />
                                                        </button>
                                                    )}
                                                </FormSpy>
                                            </div>
                                            <hr className='w-100' />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            </div>
        </StyledExtraFields>
    );
};

export default ExtraFields;
