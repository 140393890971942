// RadioButton.js
import React from 'react';
import styled from 'styled-components';
import ConditionalRender from '../ConditionalRender';
import HoverPopup from '../HoverPopup';
import { useTheme } from '../../context/ThemeContext';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';
import colors from '../../globalStyles.scss';

const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: ${(props) => props.width || 'auto'};
    
    input[type="radio"] {
        appearance: none; /* Remove default styling */
        width: 1em;
        height: 1em;
        margin-right: 1em;
        border: 2px solid ${colors.gray}; /* Border color for unselected state */
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: ${colors.white}; /* Background for unselected state */
        transition: all 0.2s ease;

        &:checked {
            border-color: ${colors.primary}; /* Border color for selected state */
            background-color: ${colors.primary}; /* Background color for selected state */
        }

        &:hover {
            border-color: ${colors.lightGray}; /* Border color on hover */
        }

        &::after {
            content: '';
            width: 0.6em;
            height: 0.6em;
            background-color: ${colors.white}; /* Inner circle color for selected state */
            border-radius: 50%;
            transform: scale(0); /* Initially hidden */
            transition: transform 0.2s ease;
        }

        &:checked::after {
            transform: scale(1); /* Show inner circle on checked */
        }
    }

    label {
        cursor: pointer;
        color: ${colors.white};
    }
`;


const RadioButton = ({
    id,
    name,
    value,
    checked,
    onChange,
    label,
    width,
    showLightColors,
    goto
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();


    return (
        <RadioButtonWrapper
            width={width}
            showLightColors={showLightColors}
            theme={theme}
        >
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <ConditionalRender renderIf={!goto}>
                <label htmlFor={id}>
                    {label}
                </label>
            </ConditionalRender>
            <ConditionalRender renderIf={goto}>
                <HoverPopup
                    style={{
                        display: 'flex',
                        background: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray,
                        background: showLightColors && colors.backgroundSecondary,
                        color: theme === 'dark' ? colors.white : colors.black,
                        color: showLightColors && colors.white,
                    }}
                    placement={'top'}
                    id={'client-popup-radio-button'}
                    className={'hover-popup'}
                    text={text?.inventory?.landing?.table?.columns?.details}
                >
                    <Link to={goto}>
                        {label}
                    </Link>
                </HoverPopup>
            </ConditionalRender>
        </RadioButtonWrapper>
    );
};

export default RadioButton;
