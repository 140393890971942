import React, { useState, useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTheme } from '../context/ThemeContext'

import "./styles.scss";

const StyledDiv = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .wrapper {
        width: 100%;
        height: 100%;
       ${(props) => props.customStyled && { ...props.customStyled }}
    }
`

const Loader = ({
    count = 3,
    customHeight,
    customWidth,
    customStyled,
    isDark = false
}) => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const div = useCallback((node) => {
        if (node !== null) {
            setHeight(customHeight || node.getBoundingClientRect().height);
            setWidth(customWidth || node.getBoundingClientRect().width);
        }
    }, []);
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;

    // Define color themes for light and dark modes
    const lightTheme = {
        baseColor: "#f0f0f0",
        highlightColor: "#e0e0e0"
    };

    const darkTheme = {
        baseColor: "#202020",
        highlightColor: "#343434"
    };
    const currentTheme = theme === 'dark' ? darkTheme : lightTheme;

    return (
        <StyledDiv customStyled={customStyled} >
            <div
                className="flex Loading"
                style={{ height: '100%', width: '100%', justifyContent: 'center' }}
                ref={div}>
                <div style={{ height: '100%', width: '100%' }}
                >
                    <SkeletonTheme style={{
                        height: '100%',
                    }} baseColor={currentTheme.baseColor} highlightColor={currentTheme.highlightColor}>
                        <Skeleton
                            containerClassName='wrapper'
                            style={{
                                border: '0px solid black',
                                display: 'inline-block',
                                borderRadius: '0'
                            }}
                            width={width}
                            height={height}
                            count={count}
                        />
                    </SkeletonTheme>
                </div>
            </div>
        </StyledDiv>
    );
};

export default Loader;
