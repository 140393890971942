import React, { useContext, useEffect } from 'react'
import { Field } from 'react-final-form';
import CheckBox from '../Core/Inputs/CheckBox';
import { GlobalContext } from '../context/GlobalContext';
import { findFieldByName } from '../utils/helperFunctions'
import SelectInput from '../Core/SelectInput';
import { isEmpty } from 'lodash'
import { useLanguage } from '../context/LanguageContext'

import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender';

const Filters = ({
    stringData,
    numberData,
    formattedData,
    dataCheckedKeys,
    setDataCheckedKeysProject,
    setDataCheckedKeys,
    handleCombineChange,
    initialGroupBy,
    setChartIsEditing,
    formRef,
    isProject,
    spyValues,
    analyticsWidgets

}) => {
    const { text } = useLanguage()
    const { fieldNames } = useContext(GlobalContext)

    const advanceFieldOptionsSet = new Set();

    stringData?.forEach(data => {
        if (data.advanceFields) {
            Object.keys(data.advanceFields).forEach(key => {
                advanceFieldOptionsSet.add(key);
            });
        }
    });

    const advanceFieldOptions = Array.from(advanceFieldOptionsSet)?.map(key => ({
        value: `${key}`,
        label: findFieldByName(key, fieldNames, text)?.label
    }));

    useEffect(() => {
        const updatedKeys = dataCheckedKeys.filter((key) => {
            return formattedData.some((item) => {
                const isInAdvanceFields = Object.keys(item?.advanceFields || {}).some((advanceKey) => {
                    return advanceKey === key;
                });
                const isAtParentLevel = Object.keys(item || {}).includes(key);
                return isInAdvanceFields || isAtParentLevel;
            });
        });

        const updatedObject = updatedKeys.reduce((acc, key) => {
            const keyValue = formattedData.find((item) => {
                return item[key] || (item?.advanceFields && item.advanceFields[key]);
            });

            if (keyValue) {
                // Handle keys with a `.value` property
                const value = keyValue[key]?.value || keyValue?.advanceFields?.[key]?.value;
                if (value !== undefined) {
                    acc[key] = true; // Use the `.value` if available
                } else {
                    acc[key] = true; // Default to true if `.value` is not present
                }
            } else {
                acc[key] = true;
            }

            return acc;
        }, {});

        if (JSON.stringify(updatedKeys) !== JSON.stringify(dataCheckedKeys)) {
            setTimeout(() => {
                if (updatedKeys.length > 0) {
                    setDataCheckedKeys(updatedKeys);
                }
            }, 1000)
        }
        if (isProject) {
            formRef?.current?.change('dataCheckedKeysProject', updatedObject);
        } else {
            formRef?.current?.change('dataCheckedKeys', updatedObject);
        }
    }, [dataCheckedKeys, spyValues?.selectProject, formattedData, analyticsWidgets, formRef, isProject]);



    const handleCheckboxChange = (key, input) => (e) => {
        if (!key) return;

        setDataCheckedKeys(prevKeys => {
            const updatedKeys = e.target.checked
                ? [...prevKeys, key]
                : prevKeys.filter(prevKey => prevKey !== key);
            setChartIsEditing(true);

            return updatedKeys;
        });

        // Update the input value for form handling
        input.onChange(e.target.checked);
    };

    // Create unique keys list from data
    const numberUniqueKeys = Array.from(new Set(formattedData?.flatMap(item => [...Object.keys(item), ...Object.keys(item.advanceFields || {})])));


    const filteredNumberUniqueKeys = numberUniqueKeys.filter(key => {
        const findField = findFieldByName(key, fieldNames);

        if (!findField || !findField.inputType) {
            return false;
        }

        const excludedTypes = ['phone', 'date', 'link', 'text', 'textarea', 'volume', 'length', 'weight', 'location'];
        const shouldExclude = excludedTypes.includes(findField.inputType);

        return !shouldExclude;
    });

    // Manually add `inventoryQuantity` if `!isProject` and it wasn't included
    if (!isProject && !filteredNumberUniqueKeys.includes('inventoryQuantity')) {
        filteredNumberUniqueKeys.push('inventoryQuantity');
    }


    // filter out checks by field type, that won't display anything in the chart
    const barChartDataCheckBoxes = filteredNumberUniqueKeys
        .filter(key =>
            // filter out unwanted fields that don't add up to anything
            key !== "inventoryName" &&
            key !== "_id" &&
            key !== "id" &&
            key !== "__v" &&
            key !== 'advanceFields' &&
            key !== 'projectName' &&
            key !== 'isPriority' &&
            key !== 'productId' &&
            key !== 'projectId' &&
            key !== 'images' &&
            key !== 'inventoryCategory' &&
            key !== 'projectName' &&
            key !== 'projectStatus'
        )
        ?.map(key => {
            const findField = findFieldByName(key, fieldNames, text);
            return (
                <div key={key} className='checkbox-item mt-md' style={{
                    display: 'flex',
                }}>
                    <ConditionalRender renderIf={!isProject}>
                        <Field name={`dataCheckedKeys[${key}]`}>
                            {({ input, meta }) => (
                                <CheckBox
                                    name={`dataCheckedKeys[${key}]`}
                                    type="checkbox"
                                    checked={dataCheckedKeys?.includes(key)}
                                    onChange={handleCheckboxChange(key, input)}
                                    label={findField?.label}
                                    checkStyle={{
                                        background: colors.blue
                                    }}
                                />
                            )}
                        </Field>
                    </ConditionalRender>
                    <ConditionalRender renderIf={isProject}>
                        <Field name={`dataCheckedKeysProject[${key}]`}>
                            {({ input, meta }) => (
                                <CheckBox
                                    name={`dataCheckedKeysProject[${key}]`}
                                    type="checkbox"
                                    checked={dataCheckedKeys?.includes(key)}
                                    onChange={handleCheckboxChange(key, input)}
                                    label={findField?.label}
                                    checkStyle={{
                                        background: colors.blue
                                    }}
                                />
                            )}
                        </Field>
                    </ConditionalRender>
                </div>
            )
        })

    return (
        <>
            <div className='mt-md mb-md w-50'>
                <ConditionalRender renderIf={!isProject}>
                    <SelectInput
                        name={`groupBy`}
                        initialValue={initialGroupBy}
                        label={text?.analytics?.groupBy}
                        isClearable={false}
                        options={[
                            { value: 'inventoryName', label: text?.analytics?.name },
                            { value: 'inventoryCategory', label: text?.analytics?.category },
                            ...advanceFieldOptions
                        ]
                        }
                        onChange={(e) => {
                            handleCombineChange(e?.value, numberData)
                            if (!isEmpty(dataCheckedKeys)) {
                                setChartIsEditing(true)
                            }
                        }}
                        isValidNewOption={() => false}
                        style={{
                            width: '100%'
                        }}

                    />
                </ConditionalRender>
                <ConditionalRender renderIf={isProject}>
                    <SelectInput
                        name={`groupByProject`}
                        initialValue={initialGroupBy}
                        label={text?.analytics?.groupBy}
                        isClearable={false}
                        options={[
                            { value: 'projectCategory', label: text?.analytics?.category },
                            ...advanceFieldOptions
                        ]
                        }
                        onChange={(e) => {
                            handleCombineChange(e?.value, numberData)
                            if (!isEmpty(dataCheckedKeys)) {
                                setChartIsEditing(true)
                            }
                        }}
                        isValidNewOption={() => false}
                        style={{
                            width: '100%'
                        }}

                    />
                </ConditionalRender>
                <div>
                    <p className='flex mt-md'>
                        {text?.analytics?.checkInfo}
                    </p>
                    {barChartDataCheckBoxes}
                </div>
            </div>
        </>
    )
}

export default Filters
