import React, { useEffect } from 'react';
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faDownload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { streamFileFromS3 } from '../utils/calls';
import colors from '../globalStyles.scss'
import ConditionalRender from './ConditionalRender';

const StyledCarousel = styled(Carousel)`
 svg {
        font-size: 2.5em;
        animation: pulse 1.5s infinite;
    }

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
`;

const StyledSavedButton = styled.div`
    svg {
        font-size: 1em;
        animation: pulse 1.5s infinite;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        background: ${colors.white};
        padding: 0.5em;
        border-radius: 50%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background 0.3s;
    }
`

const CarouselWithCustomArrows = ({
    images,
    activeIndex,
    setActiveIndex,
    hideSave = false,
}) => {
    const [selectedImage, setSelectedImage] = React.useState('');
    const [hideSaveIcon, setHideSaveIcon] = React.useState(false);
    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        setSelectedImage(images[(activeIndex + 1) % images.length]?.url);
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
        setSelectedImage(images[(activeIndex - 1 + images.length) % images.length]?.url);
    };

    useEffect(() => {
        setSelectedImage(images[activeIndex]?.url);
        setHideSaveIcon((prev) => {
            if (images[activeIndex]?.url?.startsWith('data:')) {
                return true;
            } else {
                return false;
            }
        })

    }, [activeIndex, images]);

    const handleDownload = async () => {
        try {
            // Send the full URL to the backend
            const response = await streamFileFromS3(selectedImage);

            if (response?.data) {
                // Create a Blob and download the file
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = selectedImage.split('/').pop(); // Extract filename from the URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Failed to download the file');
        }
    };



    return (
        <div style={{
            position: 'relative',
        }}>
            <StyledCarousel
                selectedItem={activeIndex}
                showThumbs={false}
                showStatus={false}
                dynamicHeight={true}
                renderArrowNext={(clickHandler, hasNext) => (
                    hasNext && (
                        <button
                            onClick={handleNext}
                            style={{
                                position: 'absolute',
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 1000,
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '10px',
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleDot} />
                        </button>
                    )
                )}
                renderArrowPrev={(clickHandler, hasPrev) => (
                    hasPrev && (
                        <button
                            onClick={handlePrev}
                            style={{
                                position: 'absolute',
                                left: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 1000,
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '10px',
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleDot} />
                        </button>
                    )
                )}
                showIndicators={false}
                onChange={(index) => setActiveIndex(index)}
            >
                {images?.map((image, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={image.url || image?.dataUrl}
                            alt={`Uploaded ${index + 1}`}
                            style={{
                                height: '80vh',
                                width: '100vw',
                                objectFit: 'contain',
                            }}
                        />

                    </div>
                ))}
            </StyledCarousel>
            <ConditionalRender renderIf={!hideSave}>
                <ConditionalRender renderIf={!hideSaveIcon}>
                    <StyledSavedButton>
                        <FontAwesomeIcon
                            className="saveIcon"
                            onClick={() => handleDownload()}
                            icon={faDownload}
                        />
                    </StyledSavedButton>
                </ConditionalRender>
            </ConditionalRender>

        </div>
    );
};

export default CarouselWithCustomArrows;
