import React, { useState, useEffect } from 'react';
import ChatContainer from '../Chat/ChatContainer';
import styled from 'styled-components';
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import CallToAction from '../Core/CallToAction';
import SubmitModal from '../Core/SubmitModal';
import {
    updateClient,
    downloadProjectFile,
    getAllSubmittedProposal,
    checkUnreadConversations
} from '../utils/calls'
import { saveAs } from 'file-saver';
import InventoryQuickView from '../QuickView/InventoryQuickView';
import TasksQuickView from '../QuickView/TasksQuickView';
import ProposalQuickView from '../QuickView/ProposalQuickView';
import RenderImages from '../Core/RenderImages';
import { Form } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import MultiFields from '../Core/MultiFields';
import RenderFiles from '../Core/RenderFiles';
import { isEmpty } from 'lodash';
import FieldWrapper from '../Core/FieldWrapper';
import SelectableBox from '../Core/SelectableBox';
import classnames from 'classnames';
import { status, proposalStatus } from '../utils/helperFunctions';
import ShowJustMap from '../Core/ShowJustMap';
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import moment from 'moment';

const StyledDiv = styled.div`
    display: flex;
    height: 100%;
    .client_sidebar {
        height: 100%;
        background-color: ${colors?.background};
        border-right: 2px solid ${colors?.backgroundSecondary};
        display: flex;
        padding: 1em;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        h4 {
                color: ${colors?.white};
            }
        .active {
            background-color: ${colors?.blue};
        }
        h1 {
            color: ${colors?.primary};
            font-size: 1.2em;
            text-align: left;
            margin-bottom: 1em;
        }
        p {
            color: ${colors.white};
            font-size: 1em;
            text-align: left;
        }
        img {
            margin-top: 1em;
            max-width: 250px;
            height: auto;
        }
        .projects {
            height: 100%;
            width: 100%;
            margin-top: 1em;
            h2 {
                color: ${colors?.white};
                font-size: 1.2em;
                text-align: center;
            }
        }
    }
    .client_main_body {
        background-color: ${colors?.background};
        width: 100%;
        display: flex;
        flex-direction: column;
        .client_body_wrapper {        
            h2 {
                display: flex;
                color: ${colors?.white};
                font-size: 1.2em;
            }
            h3 {
                display: flex;
                color: ${colors?.white};
                font-size: 1em;
            }
            span, label, textarea, a {
                color: ${colors?.white};
            }
            padding-bottom: 1em;
        }
        .clientWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${props => props.isCollapsed ? '0' : '1em 1em 0 1em'};
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
            height: ${props => props.isCollapsed ? '0' : '100%'};
            transform: ${props => props.isCollapsed ? 'translateY(-100%)' : 'translateY(0)'};  
            opacity: ${props => props.isCollapsed ? '0' : '1'};
            transition: opacity 0.5s ease;
            transition: transform 0.5s ease;
            h2 {
                color: ${colors?.white};
                font-size: 1.2em;
                text-align: left;
                margin-bottom: 1em;
                flex: 1;
                display: flex;
                justify-content: center;
            }
            h1 {
                color: ${colors?.white};
                font-size: 2em;
                text-align: left;
                margin-bottom: 1em;
                flex: 1;
                display: flex;
                justify-content: flex-start;
            }

            p {
                color: ${colors.white};
            }
            
        }

    }
`

const ClientDisplayContainer = ({
    clientInfo,
    fetchClientDetails,
    fetchFieldNames,
    fieldNames
}) => {
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const renderFieldLabel = (key) => {
        return fieldNames?.find(field => key === `${field?.inputType}-${field?.name}`)?.label
    }
    const client = clientInfo?.client;
    const projects = clientInfo?.projects;
    const companyInfo = client?.companyId;
    const { text } = useLanguage();
    const [page, setPage] = useState('projects');
    const [clientIsEditing, setClientIsEditing] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
    const [inventoryQuickViewData, setInventoryQuickViewData] = useState(null);
    const [TasksQuickViewData, setTasksQuickViewData] = useState(null);
    const [proposalQuickViewData, setProposalQuickViewData] = useState(null);
    const [initialStatusValue, setInitialStatusValue] = useState(
        proposalStatus(text).find(status => status.value === proposalQuickViewData?.status)
    );
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        setInitialStatusValue(
            proposalStatus(text).find(status => status.value === proposalQuickViewData?.status)
        )
    }, [proposalQuickViewData])

    const [hasUnreadConversations, setHasUnreadConversations] = useState(false);

    const checkUnread = async () => {
        try {
            const res = await checkUnreadConversations({
                clientId: client?._id,
                companyId: client?.companyId?._id
            })
            if (res.status === 200) {
                setHasUnreadConversations(res.data?.hasUnreadMessages)
            }
        } catch (error) {
            console.error(error
            )

        }
    }


    const handleQuickView = (type, rowData, showProject = false) => {
        if (type === 'inventory') {
            setTasksQuickViewData(null);
            setProposalQuickViewData(null);
            delete rowData?.projectId;
            setInventoryQuickViewData(rowData);
            setIsQuickViewOpen(!isQuickViewOpen);
        }
        if (type === 'task') {
            setInventoryQuickViewData(null);
            setProposalQuickViewData(null);
            delete rowData?.taskProject;
            setTasksQuickViewData(rowData);
            setIsQuickViewOpen(!isQuickViewOpen);
        }
        if (type === 'proposal') {
            setInventoryQuickViewData(null);
            setTasksQuickViewData(null);
            setProposalQuickViewData(rowData);
            setIsQuickViewOpen(!isQuickViewOpen);
            if (!showProject) {
                delete rowData?.projectId;
            }
            delete rowData?.additionalEmails;
            delete rowData?.isSubmitted;
            delete rowData?.emailSubject;
            delete rowData?.emailMessage;
            delete rowData?.clientId;
        }
    };

    const changeProject = (project) => {
        setSelectedProject(project)
        setActiveIndex(0)
        setIsModalOpen(false)
        setPage('projects')
    }

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDownloadFile = async (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const res = await downloadProjectFile({ projectId: selectedProject?._id, file, companyId: client?.companyId?._id });
            if (res.status === 200) {
                const contentType = res.headers['content-type'];
                const blob = new Blob([res.data], { type: contentType });
                saveAs(blob, file.originalName);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const [proposals, setProposals] = useState([])
    const handleFetchSubmittedProposal = async (clientId) => {
        try {
            const res = await getAllSubmittedProposal(clientId)
            if (res.status === 200) {
                setProposals(res.data?.proposals)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!isEmpty(projects)) {
            changeProject(projects[0])
        }

        handleFetchSubmittedProposal(client?._id)
    }, [clientInfo])

    useEffect(() => {
        fetchFieldNames(client?.companyId?._id)
        checkUnread()
    }, [])

    const handleUpdateClientInfo = async (values) => {
        if (client?.notes) {
            values.notes = client?.notes
        }
        if (!values.address) {
            values.address = ''
        }
        if (!values.phone) {
            values.phone = ''
        }
        try {
            const res = await updateClient(client?._id, values)
            if (res.status === 200) {
                setClientIsEditing(false)
                fetchClientDetails(client?._id)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const required = composeValidators(
        isRequired(text?.projects?.details?.validations?.required)
    )
    const emailValidator = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
        isValidEmail(text?.employees?.createNew?.validation?.validEmail)
    )

    const renderCompanyInfo = () => {
        return (
            <div className='companyWrapper w-100'>
                <ConditionalRender renderIf={companyInfo?.companyName}>
                    <h1>{companyInfo?.companyName}</h1>
                </ConditionalRender>
                <ConditionalRender renderIf={companyInfo?.companyAddress}>
                    <a rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${companyInfo?.companyAddress}`} target='_blank'>
                        <p>
                            {companyInfo?.companyAddress}
                        </p>
                    </a>
                </ConditionalRender>
                <ConditionalRender renderIf={companyInfo?.companyPhone}>
                    <a href={`tel:${companyInfo?.companyPhone}`}>
                        <p>
                            {`+${companyInfo?.companyPhone}`}
                        </p>
                    </a>
                </ConditionalRender>
                <ConditionalRender renderIf={companyInfo?.companyPhone}>
                    <img src={companyInfo?.companyLogo} alt={companyInfo?.companyName} />
                </ConditionalRender>
            </div>
        )
    }

    const renderClientInfo = () => {
        return (
            <div className='relative'>
                <div className='clientWrapper w-100'>
                    <h1 className='mt-md title'>
                        {text?.login?.clientPortal}
                    </h1>
                    <div className='flex flex-column items-end'>
                        <ConditionalRender renderIf={client?.name}>
                            <h2>{client?.name}</h2>
                        </ConditionalRender>
                        <ConditionalRender renderIf={client?.email}>
                            <p>{client?.email}</p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={client?.phone}>
                            <p>{client?.phone}</p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={client?.address}>
                            <p>{client?.address}</p>
                        </ConditionalRender>
                        <CallToAction
                            className='mt-md'
                            text={text?.logs?.edit}
                            height={isDesktop ? '80%' : '100%'}
                            btnStyles={{
                                color: colors.blue,
                            }}
                            onClick={() => { setClientIsEditing(!clientIsEditing) }}
                        />
                    </div>
                </div>
                <FontAwesomeIcon
                    icon={isCollapsed ? faCaretDown : faCaretUp}
                    style={{
                        position: 'absolute',
                        color: colors.primary,
                        fontSize: '3em',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                        bottom: isCollapsed ? '-25px' : '0',
                        right: '50%'
                    }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
            </div>
        )
    }

    return (
        <StyledDiv
            isCollapsed={isCollapsed}
        >
            <div className='h-100 w-100 flex'>
                <div className='client_sidebar'>
                    {renderCompanyInfo()}
                    <div className='scroll-container projects'>
                        <div>
                            <SelectableBox
                                isDark
                                onClick={() => setPage('proposals')}
                                className={classnames({
                                    'mr-md pointer': true,
                                    'active': page === 'proposals'
                                })}
                            >
                                <h4>
                                    {text?.homeLandingContainer?.services?.proposals?.title}
                                </h4>
                            </SelectableBox>
                            <SelectableBox
                                isDark
                                onClick={() => {
                                    setPage('chat')
                                    checkUnread()
                                }}
                                className={classnames({
                                    'mr-md pointer relative': true,
                                    'active': page === 'chat'
                                })}
                            >
                                <h4>
                                    {text?.chat?.chatButton}
                                </h4>
                                <ConditionalRender renderIf={hasUnreadConversations}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '-5px',
                                            top: '-5px',
                                            backgroundColor: colors.red,
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%'
                                        }}
                                        className='unread-indicator'></div>
                                </ConditionalRender>
                            </SelectableBox>
                            <hr />
                        </div>
                        <div>
                            <h2 className='mt-md mb-md'>
                                {text?.projects?.details?.inventoryModal?.projects}
                            </h2>
                            {
                                projects?.map((project, index) => {
                                    return (
                                        <SelectableBox
                                            isDark
                                            onClick={() => changeProject(project)}
                                            key={index}
                                            className={classnames({
                                                'mr-md pointer': true,
                                                'active': (selectedProject?._id === project?._id) && (page === 'projects')
                                            })}
                                        >
                                            <h4>
                                                {project?.projectName}
                                            </h4>
                                        </SelectableBox>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='client_main_body h-100'>
                    {renderClientInfo()}
                    <div className='scroll-container h-100 client_body_wrapper mt-md flex flex-column ml-md pr-md'>
                        <ConditionalRender renderIf={page === 'projects'}>
                            <div className='mb-md mr-md'>
                                <div className='flex justify-start'>
                                    <h2>
                                        {status(text).find(status => status.value === selectedProject?.projectStatus)?.label}
                                    </h2>
                                    <ConditionalRender renderIf={selectedProject?.projectStatus === 'completed'}>
                                        <FontAwesomeIcon icon={faCheck} style={{
                                            color: colors.green,
                                            fontSize: '1.5em',
                                            marginLeft: '1em'
                                        }} />
                                    </ConditionalRender>
                                </div>
                                <hr />
                            </div>
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.latestSubmittedProposal)}>
                                <h2 className='mb-md'>
                                    {text?.projects?.details?.proposal}
                                </h2>
                                <div>
                                    <SelectableBox
                                        isDark
                                        onClick={() => handleQuickView('proposal', selectedProject?.latestSubmittedProposal)}
                                        showLightColors
                                        className='flex flex-column items-start'>
                                        <div>
                                            <h3 style={{
                                                color: colors.white
                                            }}>
                                                {text?.projects?.details?.viewProposal}
                                            </h3>
                                        </div>
                                    </SelectableBox>
                                </div>
                                <ConditionalRender renderIf={!isEmpty(proposalQuickViewData)}>
                                    <ProposalQuickView
                                        isQuickViewOpen={isQuickViewOpen}
                                        setIsQuickViewOpen={setIsQuickViewOpen}
                                        quickViewData={proposalQuickViewData}
                                        noOverlay={false}
                                        isClient
                                        showLightColors

                                        openModal={openCarousel}
                                        closeModal={closeModal}
                                        setActiveIndex={setActiveIndex}
                                        activeIndex={activeIndex}
                                        isModalOpen={isModalOpen}
                                        handleFetchSubmittedProposal={handleFetchSubmittedProposal}
                                        clientId={client?._id}
                                    />
                                </ConditionalRender>
                                <hr />

                            </ConditionalRender>
                            <FieldWrapper
                                className='mb-md'
                                label={selectedProject?.projectName}
                                name={text?.projects?.create?.name}
                                noHr
                            />
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.projectDescription)}>
                                <FieldWrapper
                                    className='mb-md'
                                    label={selectedProject?.projectDescription}
                                    name={text?.tasks?.create?.description}
                                    noHr
                                />
                            </ConditionalRender>
                            <FieldWrapper
                                className='mb-md'
                                label={selectedProject?.projectCategory?.label}
                                name={text?.projects?.create?.category}
                                noHr
                            />
                            <Form
                                onSubmit={() => { }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <ConditionalRender renderIf={!isEmpty(selectedProject?.projectLocation)}>
                                            <FieldWrapper
                                                noHr
                                                name={text?.projects?.details?.location}
                                            />
                                            <ShowJustMap
                                                location={selectedProject?.projectLocation}
                                            />
                                        </ConditionalRender>

                                        <ConditionalRender renderIf={!isEmpty(selectedProject?.advanceFields)}>
                                            <hr />
                                            <h2 className='mb-md'>
                                                {text?.inventory?.details?.form?.title}
                                            </h2>
                                            <div>
                                                {
                                                    Object.entries(selectedProject?.advanceFields || {}).map(([key, value], index) => {
                                                        const keyName = key.split('-')[0];
                                                        if (keyName === 'number') {
                                                            return (
                                                                <FieldWrapper
                                                                    key={index}
                                                                    name={renderFieldLabel(key)}
                                                                    label={
                                                                        <div className='flex flex-column'>
                                                                            <span className='flex mb-sm'>{value?.value}</span>
                                                                            <ConditionalRender renderIf={value?.notification?.isChecked}>
                                                                                <FieldWrapper
                                                                                    name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                                                                    label={value?.notification?.value}
                                                                                    noHr
                                                                                />
                                                                            </ConditionalRender>
                                                                        </div>
                                                                    }
                                                                />
                                                            )
                                                        } else if (keyName === 'date') {
                                                            return (
                                                                <FieldWrapper
                                                                    key={index}
                                                                    name={renderFieldLabel(key)}
                                                                    label={moment(value).format('MM-DD-YYYY hh:mm A')}
                                                                />
                                                            )
                                                        } else if (keyName === 'location') {
                                                            return (
                                                                <div>
                                                                    <FieldWrapper
                                                                        noHr
                                                                        key={index}
                                                                        name={renderFieldLabel(key)}
                                                                    />
                                                                    <ConditionalRender renderIf={!isEmpty(value)}>
                                                                        <ShowJustMap
                                                                            location={value}
                                                                        />
                                                                    </ConditionalRender>
                                                                </div>
                                                            )
                                                        } else if (keyName === 'phone') {
                                                            return (
                                                                <FieldWrapper
                                                                    key={index}
                                                                    name={`${renderFieldLabel(key)}`}
                                                                    label={<a
                                                                        href={`tel:${value}`}
                                                                    >
                                                                        {`+${value}`}
                                                                    </a>}
                                                                />

                                                            );
                                                        }
                                                        return (
                                                            <FieldWrapper
                                                                key={index}
                                                                name={renderFieldLabel(key)}
                                                                label={`${keyName === 'amount' ? '$' : ''}${value}`}
                                                                isLink={keyName === 'link'}
                                                            />
                                                        );
                                                    })
                                                }
                                            </div>
                                        </ConditionalRender>
                                    </form>
                                )}
                            />
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.designs)}>
                                <RenderImages
                                    images={selectedProject?.designs}
                                    roleName={'Employee'}
                                    openModal={openCarousel}
                                    closeModal={closeModal}
                                    setActiveIndex={setActiveIndex}
                                    activeIndex={activeIndex}
                                    isModalOpen={isModalOpen}
                                    disabled={true}
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.files)}>
                                <hr />
                                <h2 className='mb-md'>
                                    {text?.projects?.details?.filesTitle}
                                </h2>
                                <RenderFiles
                                    files={selectedProject?.files}
                                    roleName={'Employee'}
                                    disabled={true}
                                    handleDownloadFile={handleDownloadFile}
                                    readOnly={true}
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.inventoryItems)}>
                                <hr />
                                <h2 className='mb-md'>
                                    {text?.projects?.details?.inventory}
                                </h2>
                                <div>
                                    {
                                        selectedProject?.inventoryItems?.map((item, index) => {
                                            return (
                                                <div
                                                    className={classnames({
                                                        'w-100': !isDesktop,
                                                        'w-70': isDesktop

                                                    })}
                                                    key={index}
                                                >
                                                    <SelectableBox
                                                        isDark
                                                        onClick={() => handleQuickView('inventory', item)}
                                                        showLightColors
                                                        className='flex flex-column items-start'>
                                                        <div>
                                                            <h3 style={{
                                                                color: colors.white
                                                            }}>
                                                                {item?.inventoryName}
                                                            </h3>
                                                        </div>
                                                    </SelectableBox>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <ConditionalRender renderIf={!isEmpty(inventoryQuickViewData)}>
                                    <InventoryQuickView
                                        isQuickViewOpen={isQuickViewOpen}
                                        setIsQuickViewOpen={setIsQuickViewOpen}
                                        quickViewData={inventoryQuickViewData}
                                        noOverlay={false}
                                        fieldNames={fieldNames}

                                        openModal={openCarousel}
                                        closeModal={closeModal}
                                        setActiveIndex={setActiveIndex}
                                        activeIndex={activeIndex}
                                        isModalOpen={isModalOpen}
                                    />
                                </ConditionalRender>
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isEmpty(selectedProject?.recentTasks)}>
                                <hr />
                                <h2 className='mb-md'>
                                    {text?.projects?.details?.recentTasks}
                                </h2>
                                <div>
                                    {
                                        selectedProject?.recentTasks?.map((task, index) => {
                                            return (
                                                <div
                                                    className={classnames({
                                                        'w-100': !isDesktop,
                                                        'w-70': isDesktop

                                                    })}
                                                    key={index}
                                                >
                                                    <SelectableBox
                                                        isDark
                                                        onClick={() => handleQuickView('task', task)}
                                                        showLightColors
                                                        className='flex flex-column items-start'>
                                                        <div>
                                                            <h3 style={{
                                                                color: colors.white
                                                            }}>
                                                                {task?.taskTitle}
                                                            </h3>
                                                        </div>
                                                    </SelectableBox>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <ConditionalRender renderIf={!isEmpty(TasksQuickViewData)}>
                                    <TasksQuickView
                                        isQuickViewOpen={isQuickViewOpen}
                                        setIsQuickViewOpen={setIsQuickViewOpen}
                                        quickViewData={TasksQuickViewData}
                                        noOverlay={false}
                                    />
                                </ConditionalRender>
                            </ConditionalRender>
                        </ConditionalRender>
                        <ConditionalRender renderIf={page === 'proposals'}>
                            <div className='mb-md mr-md'>
                                <h2>
                                    {text?.homeLandingContainer?.services?.proposals?.title}
                                </h2>
                                <hr />
                                {
                                    proposals?.map((proposal, index) => {
                                        return (
                                            <SelectableBox
                                                isDark
                                                onClick={() => handleQuickView('proposal', proposal, true)}
                                                showLightColors
                                                className='flex flex-column items-start'>
                                                <div className='flex'>
                                                    <h3 style={{
                                                        color: colors.white
                                                    }}>
                                                        {`#${proposal?.uuid} - ${proposal?.title}`}
                                                    </h3>
                                                    <ConditionalRender renderIf={proposal?.status === 'Approved'}>
                                                        <FontAwesomeIcon icon={faCheck} style={{
                                                            color: colors.green,
                                                            fontSize: '1.5em',
                                                            marginLeft: '1em'
                                                        }} />
                                                    </ConditionalRender>
                                                    <ConditionalRender renderIf={proposal?.status === 'Rejected'}>
                                                        <FontAwesomeIcon icon={faClose} style={{
                                                            color: colors.red,
                                                            fontSize: '1.5em',
                                                            marginLeft: '1em'
                                                        }} />
                                                    </ConditionalRender>
                                                </div>
                                            </SelectableBox>
                                        )
                                    })
                                }
                            </div>
                            <ConditionalRender renderIf={!isEmpty(proposalQuickViewData)}>
                                <ProposalQuickView
                                    isQuickViewOpen={isQuickViewOpen}
                                    setIsQuickViewOpen={setIsQuickViewOpen}
                                    quickViewData={proposalQuickViewData}
                                    noOverlay={false}
                                    isClient
                                    showLightColors

                                    openModal={openCarousel}
                                    closeModal={closeModal}
                                    setActiveIndex={setActiveIndex}
                                    activeIndex={activeIndex}
                                    isModalOpen={isModalOpen}
                                    handleFetchSubmittedProposal={handleFetchSubmittedProposal}
                                    clientId={client?._id}

                                />
                            </ConditionalRender>
                        </ConditionalRender>
                        <ConditionalRender renderIf={page === 'chat'}>
                            <div className='h-100'>
                                <ChatContainer
                                    user={client}
                                    isClient
                                />
                            </div>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <SubmitModal
                onClick={handleUpdateClientInfo}
                text={text?.subscription?.updateModal?.button}
                toggle={() => { setClientIsEditing(!clientIsEditing) }}
                isOpen={clientIsEditing}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                btnStyles={{
                    color: colors.blue
                }}
            >
                <div className='mb-lg tx-left'>
                    <h2>
                        {text?.projects?.details?.details}
                    </h2>
                    <hr />
                </div>

                <MultiFields
                    className='field-style'
                    name="name"
                    component="input"
                    type="text"
                    label={text?.client?.create?.name}
                    block
                    initialValue={client?.name}
                    validate={required}
                />
                <p>
                    {text?.client?.create?.emailChanged}
                </p>
                <MultiFields
                    name="email"
                    component="input"
                    type="text"
                    label={text?.client?.create?.email}
                    validate={emailValidator}
                    block
                    initialValue={client?.email}
                />
                <MultiFields
                    className='field-style'
                    name="phone"
                    component="phone"
                    type="text"
                    label={text?.client?.create?.phone}
                    block
                    initialValue={client?.phone}
                />
                <MultiFields
                    className='field-style'
                    name="address"
                    component="location"
                    label={text?.client?.create?.address}
                    block
                    initialValue={client?.address}
                    showMapLabel={text?.client?.create?.showMapLabel}
                />
            </SubmitModal>
        </StyledDiv >
    )
}

export default ClientDisplayContainer
