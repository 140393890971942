import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { GlobalContext } from './GlobalContext';
import base64ToBlob from '../utils/base64ToBlob';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, userId, projectId }) => {
    const { setHasNewNotifications, hasNewNotifications } = useContext(GlobalContext);
    const [notifications, setNotifications] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [clientParticipants, setClientParticipants] = useState([]);
    const [projectConversations, setProjectConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);


    const ws = useRef(null);
    const websocketUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        if (!ws.current) return;

        const handleConversationUpdated = ({ conversationId, hasUnreadMessages, }) => {
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, hasUnreadMessages }
                        : conversation
                )
            );

            setProjectConversations((prevProjectConversations) =>
                prevProjectConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, hasUnreadMessages }
                        : conversation
                )
            );
        };

        ws.current.on('conversationUpdated', handleConversationUpdated);

        return () => {
            ws.current.off('conversationUpdated', handleConversationUpdated);
        };
    }, [ws.current]);

    useEffect(() => {
        if (!ws.current) return;

        const handleMessageDeleted = ({ messageId, conversationId: deletedConversationId }) => {
            // Ensure the event is for the current conversation
            if (deletedConversationId === selectedConversationId) {
                setMessages((prev) => prev.filter((msg) => msg._id !== messageId));
            }
        };

        // Attach listener
        ws.current.on('messageDeleted', handleMessageDeleted);

        return () => {
            // Cleanup listener
            ws.current.off('messageDeleted', handleMessageDeleted);
        };
    }, [selectedConversationId, setMessages]);

    useEffect(() => {
        if (!ws.current) return;

        const handleMessageUpdated = ({ conversationId: updatedConversationId, updatedMessage }) => {

            if (updatedConversationId === selectedConversationId) {
                setMessages((prevMessages) => {
                    console.log('Previous Messages:', prevMessages);
                    const newMessages = prevMessages.map((msg) =>
                        msg._id === updatedMessage._id
                            ? { ...msg, ...updatedMessage }
                            : msg
                    );
                    return newMessages;
                });
            }
        };

        ws.current.on('messageUpdated', handleMessageUpdated);

        return () => {
            ws.current.off('messageUpdated', handleMessageUpdated);
        };
    }, [selectedConversationId, setMessages]);



    useEffect(() => {
        if (!ws.current) return;

        // Listen for delete conversation events
        ws.current.on('deleteConversation', ({ conversationId }) => {

            // Remove the deleted conversation from the state
            setConversations((prevConversations) =>
                prevConversations.filter((conversation) => conversation._id !== conversationId)
            );

            // Clear selected conversation if it was the one deleted
            setSelectedConversationId((prevId) =>
                prevId === conversationId ? null : prevId
            );

            // Clear participants if the current conversation was deleted
            setParticipants((prevParticipants) =>
                selectedConversationId === conversationId ? [] : prevParticipants
            );
            setClientParticipants((prevClientParticipants) =>
                selectedConversationId === conversationId ? [] : prevClientParticipants
            );
        });

        return () => {
            ws.current.off('deleteConversation'); // Cleanup listener
        };
    }, [ws.current]);


    useEffect(() => {
        if (!ws.current) return;

        // Listen for new conversations
        ws.current.on('newConversation', (data) => {
            const { conversation } = data;

            // Update the conversations list
            setConversations((prevConversations) => {
                const exists = prevConversations.some((c) => c._id === conversation._id);
                if (exists) {
                    return prevConversations.map((c) =>
                        c._id === conversation._id ? conversation : c
                    );
                }
                return [...prevConversations, conversation];
            });

            // Update participants list
            setParticipants(conversation.participants.map((p) => p._id));
            setClientParticipants(conversation.clientParticipants.map((c) => c._id));
        });

        return () => {
            ws.current.off('newConversation'); // Clean up listener
        };
    }, [ws.current]);


    useEffect(() => {
        if (!ws.current) return;

        // Listen for updated reactions
        ws.current.on('reactionUpdated', (data) => {
            const { messageId, reactions } = data;

            // Update the message with the new reactions
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message._id === messageId
                        ? { ...message, reactions } // Update reactions for the specific message
                        : message // Leave other messages unchanged
                )
            );
        });

        return () => {
            ws.current.off('reactionUpdated'); // Clean up listener
        };
    }, [ws.current, setMessages]);


    useEffect(() => {
        // Initialize WebSocket connection
        ws.current = io(websocketUrl, {
            transports: ['websocket'],
        });

        ws.current.on('connect', () => {
            console.log('WebSocket connection established');
            ws.current.emit('register', userId);



            // Join project room if projectId is provided
            if (projectId) {
                ws.current.emit('join', projectId);
            }
        });

        ws.current.on('disconnect', () => {
            console.log('WebSocket connection closed');
        });

        ws.current.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
        });

        return () => {
            if (ws.current) {
                ws.current.disconnect();
            }
        };
    }, [websocketUrl, userId, projectId]);


    useEffect(() => {
        const handleExistingNotifications = (existingNotifications) => {
            setNotifications(existingNotifications);
            const oldNotificationCount = parseInt(localStorage.getItem('notificationCount'), 10) || 0;

            if (existingNotifications.length > oldNotificationCount) {
                setHasNewNotifications(true);
            }

            localStorage.setItem('notificationCount', JSON.stringify(existingNotifications.length));
        };

        const handleNewNotification = (newNotification) => {
            setNotifications((prev) => {
                const updatedNotifications = [newNotification, ...prev];
                localStorage.setItem('notificationCount', JSON.stringify(updatedNotifications.length));
                if (!hasNewNotifications) {
                    setHasNewNotifications(true);
                }

                return updatedNotifications;
            });
        };

        ws.current?.on('notifications', handleExistingNotifications);
        ws.current?.on('notification', handleNewNotification);

        return () => {
            ws.current?.off('notifications', handleExistingNotifications);
            ws.current?.off('notification', handleNewNotification);
        };
    }, [notifications]);




    const [newMessageReceived, setNewMessageReceived] = useState(false); // Track new messages

    useEffect(() => {
        if (!ws.current) return;

        const handleConversation = (conversation) => {
            if (conversation._id === selectedConversationId) {
                setMessages(conversation.messages);
            }
        };

        const handleNewMessage = ({ message, userId }) => {
            if (!message) {
                console.error('Message object is missing from the payload.');
                return;
            }

            if (message.conversationId === selectedConversationId) {
                setMessages((prev) => [...prev.filter((msg) => msg._id !== message._id), message]);

                // Check if the message was sent by another user
                if (message.sender?._id !== userId) {
                    setNewMessageReceived(true);
                }
            }
        };


        // Attach listeners
        ws.current.on('conversation', handleConversation);
        ws.current.on('newMessage', handleNewMessage);

        return () => {
            // Cleanup listeners
            ws.current.off('conversation', handleConversation);
            ws.current.off('newMessage', handleNewMessage);
        };
    }, [selectedConversationId]);

    useEffect(() => {
        if (!ws.current) return;

        const handleNewReply = ({ parentId, reply, replyId, deletedReplyId, updatedReactions, userId }) => {
            if (!parentId) {
                console.error('ParentId is missing from the payload.');
                return;
            }

            setMessages((prevMessages) =>
                prevMessages.map((msg) => {
                    if (msg._id === parentId) {
                        // Ensure replies array exists
                        const replies = msg.replies || [];

                        // Handle reply deletion
                        if (deletedReplyId) {
                            return {
                                ...msg,
                                replies: replies.filter((r) => r._id !== deletedReplyId),
                            };
                        }

                        // Handle reaction updates for a reply
                        if (updatedReactions && replyId) {
                            return {
                                ...msg,
                                replies: replies.map((r) =>
                                    r._id === replyId ? { ...r, reactions: updatedReactions } : r
                                ),
                            };
                        }

                        // Handle new or updated reply
                        if (reply) {
                            const replyIndex = replies.findIndex((r) => r._id === reply._id);

                            if (replyIndex === -1) {
                                // If the reply does not exist, add it
                                return {
                                    ...msg,
                                    replies: [...replies, reply],
                                };
                            } else if (replyIndex >= 0) {
                                // If the reply exists, update it
                                return {
                                    ...msg,
                                    replies: replies.map((r) =>
                                        r._id === reply._id ? { ...r, ...reply } : r
                                    ),
                                };
                            }
                        }
                    }
                    return msg;
                })
            );


            if (reply && reply.sender?._id !== userId && reply.clientSender?._id !== userId) {
                setNewMessageReceived(true);
            }
        };


        ws.current.on('newReply', handleNewReply);

        return () => {
            ws.current.off('newReply', handleNewReply);
        };
    }, [ws.current, selectedConversationId]);




    useEffect(() => {
        if (!ws.current) return;


        const handleParticipantsUpdated = ({ conversationId, participants, clientParticipants, conversationName }) => {
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, participants, conversationName }
                        : conversation
                )
            );

            // If the selected conversation is updated, update its details
            if (selectedConversationId === conversationId) {
                setParticipants(participants?.map((p) => p._id));
                setClientParticipants(clientParticipants?.map((c) => c._id));
                setSelectedConversation((prev) => ({ ...prev, participants, conversationName }));
            }
        };

        ws.current.on('participantsUpdated', handleParticipantsUpdated);


        // Cleanup listener on unmount
        return () => {
            ws.current.off('participantsUpdated');
        };
    }, [ws.current, selectedConversationId]);


    const [conversationIdIsLoading, setConversationIdIsLoading] = useState(false);
    const selectConversation = (conversation, companyId, userId) => {
        setConversationIdIsLoading(true);
        ws.current.emit('join', { conversationId: conversation._id, companyId, userId }, (response) => {
            if (response.success) {
                setSelectedConversationId(conversation._id);
                setSelectedConversation(response.conversation);
                setMessages(response.conversation.messages);
                setConversationIdIsLoading(false);
            } else {
                setConversationIdIsLoading(false);
                console.error('Failed to join conversation:', response.message);
            }
        });
    };

    const createReply = async (
        conversationId,
        parentId,
        messageContent,
        messageType = 'text',
        userId,
        imagePreviews = [],
        callback
    ) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            setIsSendingMessage(false);
            return;
        }

        try {
            const hasImages = imagePreviews.length > 0;
            const newReply = {
                conversationId: selectedConversationId,
                parentId,
                sender: userId,
                messageContent,
                messageType,
                hasImages,
            };

            ws.current.emit('createReply', newReply, async (response) => {
                if (response.success) {
                    const createdReply = response.reply;

                    setIsSendingMessage(true);
                    if (hasImages) {
                        // Handle image uploads if necessary
                        await uploadImagesAndSendMessage(
                            conversationId,
                            createdReply._id,
                            imagePreviews,
                            (finalizedReply) => {
                                if (finalizedReply) {
                                    // Add or update the reply in the parent message
                                    setMessages((prevMessages) =>
                                        prevMessages.map((msg) =>
                                            msg._id === parentId
                                                ? {
                                                    ...msg,
                                                    replies: msg.replies?.find((r) => r._id === finalizedReply._id)
                                                        ? msg.replies.map((r) =>
                                                            r._id === finalizedReply._id ? finalizedReply : r
                                                        ) // Replace the existing reply
                                                        : [...(msg.replies || []), finalizedReply],
                                                }
                                                : msg
                                        )
                                    );

                                    callback?.(finalizedReply);
                                    setIsSendingMessage(false);
                                    setImagePreviews([]);
                                } else {
                                    callback?.(null, 'Failed to upload images.');
                                }
                            }
                        );
                    } else {
                        // Add reply to the parent message directly
                        setMessages((prevMessages) =>
                            prevMessages.map((msg) =>
                                msg._id === parentId
                                    ? {
                                        ...msg,
                                        replies: msg.replies?.find((r) => r._id === createdReply._id)
                                            ? msg.replies
                                            : [...(msg.replies || []), createdReply],
                                    }
                                    : msg
                            )
                        );

                        setIsSendingMessage(false);
                        callback?.(createdReply);
                    }
                } else {
                    console.error('Failed to create reply:', response.message);
                    callback?.(null, response.message || 'Failed to create reply');
                    setIsSendingMessage(false);
                }
            });
        } catch (error) {
            console.error('Error creating reply:', error);
            callback?.(null, 'Failed to send reply due to an error.');
            setIsSendingMessage(false);
        }
    };

    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const createMessage = async (
        conversationId,
        messageContent,
        messageType = 'text',
        userId,
        imagePreviews,
        callback
    ) => {

        if (!ws.current) {
            console.error('WebSocket is not connected.');
            setIsSendingMessage(false);
            return;
        }

        try {
            // Step 1: Create the initial message
            const hasImages = imagePreviews.length > 0;
            const newMessage = {
                conversationId,
                sender: userId,
                messageContent,
                messageType,
                hasImages,
            };

            ws.current.emit('createMessage', newMessage, async (response) => {
                if (response.success) {
                    const createdMessage = response.message;

                    // Step 2: Handle image uploads if necessary
                    setIsSendingMessage(true);
                    if (hasImages) {
                        await uploadImagesAndSendMessage(
                            conversationId,
                            createdMessage._id,
                            imagePreviews,
                            (finalizedMessage) => {
                                if (finalizedMessage) {
                                    // Update local state with the finalized message
                                    setMessages((prevMessages) => {
                                        const updatedMessages = prevMessages.map((msg) =>
                                            msg._id === createdMessage._id ? finalizedMessage : msg
                                        );

                                        if (!updatedMessages.some((msg) => msg._id === createdMessage._id)) {
                                            updatedMessages.push(finalizedMessage);
                                        }

                                        return updatedMessages;
                                    });

                                    callback?.(finalizedMessage);
                                    setIsSendingMessage(false);
                                    setImagePreviews([]);
                                } else {
                                    callback?.(null, 'Failed to upload images.');
                                }

                            }
                        );
                    } else {
                        const updatedMessage = { ...createdMessage, messageContent };
                        setMessages((prevMessages) => {
                            const updatedMessages = prevMessages.map((msg) =>
                                msg._id === createdMessage._id ? { ...msg, ...updatedMessage } : msg
                            );

                            if (!updatedMessages.some((msg) => msg._id === createdMessage._id)) {
                                updatedMessages.push(updatedMessage);
                            }

                            return updatedMessages;
                        });
                        setIsSendingMessage(false);
                        callback?.(createdMessage);
                    }
                    // Notify other participants
                    ws.current.emit('notifyUnreadMessage', {
                        conversationId,
                        sender: userId,
                        newMessage
                    });
                } else {
                    console.error('Failed to create message:', response.message);
                    callback?.(null, response.message || 'Failed to create message');
                    setIsSendingMessage(false);
                }
            });
        } catch (error) {
            console.error('Error creating message:', error);
            callback?.(null, 'Failed to send message due to an error.');
            setIsSendingMessage(false);
        }
    };


    const uploadImagesAndSendMessage = async (
        conversationId,
        messageId,
        imagePreviews,
        callback
    ) => {
        const images = imagePreviews.map((image) => ({
            buffer: base64ToBlob(image?.dataUrl),
            mimetype: image?.type,
            originalName: image?.originalName,
        }));

        ws.current.emit(
            'uploadMessageImage',
            { messageId, conversationId, images }, // Send all images in one go
            (response) => {
                console.log('uploadMessageImage response:', response);
                if (response.success) {
                    callback?.(response.message);
                } else {
                    console.error('Failed to finalize message:', response.message);
                    callback?.(null, response.message);
                }
            }
        );
    };

    const updateMessageImage = async (data, callback) => {
        const { images, message, projectId } = data;

        const serializedData = {
            body: {
                messageId: message?._id,
                conversationId: selectedConversationId,
                projectId,
                companyId: message?.companyId,
                oldImages: [],
            },
            files: { newImages: [] },
        };

        // Serialize old and new images
        images.forEach((image) => {
            if (image.new) {
                const imageBlob = base64ToBlob(image?.url);
                serializedData.files.newImages.push({
                    buffer: imageBlob,
                    mimetype: image.type,
                    originalName: image.originalName,
                });
            } else {
                serializedData.body.oldImages.push(image);
            }
        });

        try {
            ws.current.emit('updateMessageImage', serializedData, (response) => {
                if (response.success) {
                    const updatedMessage = response.message;

                    setMessages((prevMessages) => {
                        const updatedMessages = prevMessages.map((msg) => {
                            // Check if the updated message is a main message
                            if (msg._id === updatedMessage._id) {
                                return { ...msg, ...updatedMessage };
                            }

                            // Check if the updated message exists as a reply in this message's replies
                            if (msg.replies) {
                                const updatedReplies = msg.replies.map((reply) =>
                                    reply._id === updatedMessage._id ? { ...reply, ...updatedMessage } : reply
                                );

                                return { ...msg, replies: updatedReplies };
                            }

                            return msg; // Return unchanged message
                        });

                        return updatedMessages;
                    });

                    callback?.(updatedMessage);
                } else {
                    console.error('Failed to update message images:', response.message);
                    callback?.(null, response.message);
                }
            });
        } catch (error) {
            console.error('Error updating message images:', error);
            callback?.(null, 'Failed to update message images due to an error.');
        }
    };




    const deleteMessage = async (messageId) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            return;
        }

        try {
            ws.current.emit(
                'deleteMessage',
                { messageId, conversationId: selectedConversationId },
                (response) => {
                    if (response.success) {
                        console.log('Message deleted successfully:', messageId);
                        setMessages((prevMessages) =>
                            prevMessages
                                .filter((msg) => msg._id !== messageId)
                                .map((msg) => {
                                    if (msg.replies) {
                                        return {
                                            ...msg,
                                            replies: msg.replies.filter((reply) => reply._id !== messageId),
                                        };
                                    }
                                    return msg;
                                })
                        );
                    } else {
                        console.error('Failed to delete message:', response.message);
                    }
                }
            );
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    };


    const updateMessage = async (messageId, updatedContent, senderId, callback) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            return;
        }

        try {
            // Prepare the data to send
            const updates = {
                messageContent: updatedContent,
            };

            // Emit the updateMessage event
            ws.current.emit('updateMessage', { messageId, conversationId: selectedConversationId, updates }, (response) => {
                if (response.success) {
                    const updatedMessage = response.message;

                    // Update the local messages state with the updated message
                    setMessages((prevMessages) => {
                        return prevMessages.map((msg) =>
                            msg._id === messageId ? { ...msg, ...updatedMessage } : msg
                        );
                    });

                    // Notify all recipients about the updated message
                    ws.current.emit('notifyMessageUpdated', {
                        conversationId: selectedConversationId,
                        senderId,
                        updatedMessage,
                    });

                    // Notify success via callback
                    callback?.(updatedMessage, null);
                } else {
                    console.error('Failed to update message:', response.message);
                    callback?.(null, response.message || 'Failed to update message');
                }
            });
        } catch (error) {
            console.error('Error updating message:', error);
            callback?.(null, 'Failed to update message due to an error.');
        }
    };



    const createPrivateConversation = (userIds, companyId, currentUserId, clientIds, callback) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            return;
        }

        // Emit the event to create a private conversation
        ws.current.emit('createPrivateConversation', { userIds, companyId, currentUserId, clientIds }, (response) => {
            if (response.success) {
                callback && callback(response.conversation); // Pass the created conversation to the callback
            } else {
                console.error('Failed to create private conversation:', response.message);
                callback && callback(null, response.message);
            }
        });
    };

    const handleUpdateParticipants = (conversationId, updatedUserParticipants, updatedClientParticipants, user) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            return;
        }

        ws.current.emit(
            'updateParticipants',
            { conversationId, updatedUserParticipants, updatedClientParticipants, companyId: user?.companyId, currentUserId: user?._id },
            (response) => {
                if (response.success) {

                    // Update the local state immediately to reflect changes
                    setConversations((prevConversations) =>
                        prevConversations.map((conversation) =>
                            conversation._id === response.conversation._id
                                ? response.conversation
                                : conversation
                        )
                    );
                    setParticipants(response.conversation.participants.map((p) => p._id));
                    setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));

                    if (selectedConversationId === response.conversation._id) {
                        setSelectedConversation(response.conversation);
                        setSelectedConversationId(response.conversation._id);
                    } else {
                        setParticipants(response.conversation.participants.map((p) => p._id));
                        setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));
                        setSelectedConversation(response.conversation);
                        setSelectedConversationId(response.conversation._id);

                        // update messages
                        setMessages(response.conversation.messages);
                    }
                } else {
                    console.error('Failed to update participants:', response.message);
                }
            }
        );
    };

    const handleDeleteConversation = async (conversationId, userId, companyId) => {
        if (!ws.current) {
            console.error('WebSocket is not connected.');
            return;
        }

        ws.current.emit(
            'deletePrivateConversation',
            { conversationId, currentUserId: userId, companyId },
            (response) => {
                if (response.success) {

                    setConversations((prevConversations) =>
                        prevConversations.filter((conversation) => conversation._id !== conversationId)
                    );
                    setSelectedConversationId(null);
                    setParticipants([]);
                    setClientParticipants([]);
                } else {
                    console.error('Failed to delete conversation:', response.message);
                }
            }
        );
    };



    const markAsRead = (conversationId, messageIds, user) => {
        const uniqueMessageIds = [...new Set(messageIds)]; // Ensure unique message IDs
        ws.current.emit('markAsRead', { conversationId, userId: user._id, messageIds: uniqueMessageIds });
    };

    const updateReaction = (messageId, reactionType, userId) => {
        if (!messageId || !reactionType || !userId) {
            console.error('Invalid parameters for updating reaction');
            return;
        }

        ws.current.emit(
            'updateReaction',
            {
                messageId,
                reactionType,
                userId,
            },
            (response) => {
                if (response.success) {
                    const { reactions, parentId } = response;

                    setMessages((prevMessages) =>
                        prevMessages.map((msg) => {
                            if (msg._id === messageId) {
                                // Update reactions for a main message
                                return { ...msg, reactions };
                            }

                            if (msg._id === parentId) {
                                return {
                                    ...msg,
                                    replies: msg.replies?.map((reply) =>
                                        reply._id === messageId
                                            ? { ...reply, reactions }
                                            : reply
                                    ),
                                };
                            }

                            return msg; // Return other messages as-is
                        })
                    );
                } else {
                    console.error('Error updating reaction:', response.message);
                }
            }
        );
    };



    const disabledNotification = (conversationId, userId, disable) => {
        ws.current.emit('toggleNotification', { conversationId, userId, disable }, (response) => {
            if (response.success) {

                // Update regular conversations
                setConversations((prevConversations) =>
                    prevConversations.map((conversation) =>
                        conversation._id === conversationId && !conversation.projectId
                            ? {
                                ...conversation,
                                silenceNotifications: disable
                                    ? [...conversation.silenceNotifications, { recipient: userId }]
                                    : conversation.silenceNotifications.filter(
                                        (notification) => notification.recipient !== userId
                                    ),
                            }
                            : conversation
                    )
                );

                // Update project conversations
                setProjectConversations((prevProjectConversations) =>
                    prevProjectConversations.map((conversation) =>
                        conversation._id === conversationId && conversation.projectId
                            ? {
                                ...conversation,
                                silenceNotifications: disable
                                    ? [...conversation.silenceNotifications, { recipient: userId }]
                                    : conversation.silenceNotifications.filter(
                                        (notification) => notification.recipient !== userId
                                    ),
                            }
                            : conversation
                    )
                );

                // Update selected conversation if applicable
                if (selectedConversationId === conversationId) {
                    setSelectedConversation((prev) => ({
                        ...prev,
                        silenceNotifications: disable
                            ? [...prev.silenceNotifications, { recipient: userId }]
                            : prev.silenceNotifications.filter(
                                (notification) => notification.recipient !== userId
                            ),
                    }));
                }
            } else {
                console.error('Failed to toggle notification:', response.message);
            }
        });
    };

    return (
        <WebSocketContext.Provider value={{
            notifications,
            setNotifications,
            messages,
            setMessages,
            selectedConversationId,
            setSelectedConversationId,
            selectConversation,
            setProjectConversations,
            projectConversations,
            createPrivateConversation,
            setConversations,
            conversations,
            setParticipants,
            participants,
            setClientParticipants,
            clientParticipants,
            handleUpdateParticipants,
            setSelectedConversation,
            selectedConversation,
            createMessage,
            createReply,
            markAsRead,
            disabledNotification,
            conversationIdIsLoading,
            newMessageReceived,
            setNewMessageReceived,
            handleDeleteConversation,
            isSendingMessage,
            setIsSendingMessage,
            setImagePreviews,
            imagePreviews,
            deleteMessage,
            updateMessage,
            updateMessageImage,
            updateReaction
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
