import React from 'react';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'


const ProgressBar = ({ value, maxValue, forceFullWidth }) => {
    const { theme } = useTheme();

    // Check if forceFullWidth is true, if so set to 100%, else calculate widthPercentage normally
    const widthPercentage = forceFullWidth ? 100 : (value / maxValue) * 100;

    return (
        <div style={{
            padding: '3px',
            width: '100%',
            background: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
        }}>
            <div
                style={{
                    height: '20px',
                    width: `${widthPercentage}%`,
                    backgroundColor: theme === 'dark' ? colors.secondary : colors.black,
                    color: theme === 'dark' ? colors.black : colors.white,
                    transition: 'width 0.5s'
                }}
            >
                {`${widthPercentage?.toFixed(2) || 0}%`}
            </div>
        </div>
    );
};

export default ProgressBar;
