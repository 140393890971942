import React, { useState } from 'react';
import { Form } from 'react-final-form';
import MultiFields from '../Core/MultiFields';
import styled from 'styled-components'
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import { contactUs } from '../utils/calls';
import FormActions from '../Core/FormActions';
import ConditionalRender from '../Core/ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faContactBook } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';


const Styledform = styled.div`
    display: flex;
    justify-content: center;
    &.tablet, &.phone {
        .form-wrapper {
            width: 100%;
            padding: 1em;
        }
    }
     &.desktop {
            .form-wrapper {
                width: 100%;
                max-width: 600px;
            }
        }
    .form-wrapper {
        display: flex;
        flex-direction: column;
        flex-basis: 35%;
        margin: 0 auto;

    }
        .error {
            color: ${colors.red};
        }
`

const ContactForm = ({
    isLoggedIn
}) => {
    const [emailSent, setEmailSent] = useState(false);
    const [successMessage, setSuccesMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();


    const onSubmit = async values => {
        try {
            const res = await contactUs(values)
            if (res.status === 200) {
                setEmailSent(true)
                setSuccesMessage(text?.contact?.successMessage)
            } else {
                setEmailSent(false);
                setSuccesMessage('');
                setErrorMessage(text?.contact?.errorMessage)
            }
        } catch (error) {
            setEmailSent(false);
            setSuccesMessage('');
            setErrorMessage(text?.contact?.errorMessage)

        }
    }

    const nameValidator = composeValidators(
        isRequired(text?.contact?.validation?.required)
    )

    const emailValidator = composeValidators(
        isRequired(text?.contact?.validation?.required),
        isValidEmail('Invalid email address')
    )

    return (
        <Styledform
            className={isDesktop ? 'desktop' : isTablet ? 'tablet h-100' : 'phone h-100'}
        >
            {
                emailSent ? (
                    <p style={{
                        fontFamily: colors.openSans,
                        marginTop: '1em'
                    }}>
                        {successMessage}
                    </p>
                ) : (
                    <div className='w-100' style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div className='form-wrapper'>
                            <h1 className='mt-md flex'>
                                {text?.footer?.contactUs}
                            </h1>
                            <div>
                                <Form
                                    onSubmit={onSubmit}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <ConditionalRender renderIf={!isLoggedIn}>
                                                <MultiFields
                                                    className='field-style'
                                                    name="name"
                                                    component="input"
                                                    type="text"
                                                    label={text?.contact?.fields?.name}
                                                    block
                                                    validate={nameValidator}
                                                    style={{
                                                        margin: '15px 0'
                                                    }}
                                                />
                                                <MultiFields
                                                    className='field-style'
                                                    name="email"
                                                    component="input"
                                                    type="email"
                                                    block
                                                    label={text?.contact?.fields?.email}
                                                    validate={emailValidator}
                                                    style={{
                                                        margin: '15px 0'
                                                    }}
                                                />
                                            </ConditionalRender>
                                            <MultiFields
                                                className='field-style'
                                                name="message"
                                                component="textarea"
                                                label={text?.contact?.fields?.message}
                                                block
                                                validate={nameValidator}
                                            />
                                            <FormActions
                                                type="submit"
                                                onClick={form.reset}
                                                submitText={text?.contact?.button}
                                                form={form}
                                            />
                                        </form>
                                    )}
                                />
                                <span className='error small'>
                                    {errorMessage}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }


        </Styledform>
    );
};

export default ContactForm;
