import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { WebSocketProvider, useWebSocket } from '../context/WebSocketContext'
import SearchInput from '../Core/Inputs/SearchInput'
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import useScreenSize from '../context/useScreenSize'
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender'
import SelectableBox from '../Core/SelectableBox'
import CheckBox from '../Core/Inputs/CheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faClose } from '@fortawesome/free-solid-svg-icons'
import Switch from '@mui/material/Switch';
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import ChatWindow from './ChatWindow'
import 'react-chat-elements/dist/main.css';
import PaginatedData from '../Core/PaginatedData'
import ConfirmModal from '../Core/ConfirmModal'
import RadioButton from '../Core/Inputs/RadioButton'
import HoverPopup from '../Core/HoverPopup'
import UnreadIndicator from './UnreadIndicator'
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader'

import styled from 'styled-components'

import { getConversations, getClientConversations } from '../utils/calls'

const StyledDiv = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
        svg {
                color: ${colors.white};
        }
    .sidebar {
        width: 25%;
        background-color: ${({ theme }) => theme === 'dark' ? colors.background : colors.darkGray};
        padding: 0.5em;
        .selected {
            background-color: ${({ theme }) => theme === 'dark' ? colors.secondary : colors.primary};
            p {
                color: ${({ theme }) => theme === 'dark' ? colors.background : colors.white};
            }
        }
        .conversations {
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            .SelectableBox {
                position: relative;
            .unread {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: ${colors.red};
                position: absolute;
                right: -5px;
                top: -5px;
            }
           }
        }
    }
`

const ChatWrapper = ({
    user,
    employees,
    clients,
    adminOrAssignedProjects,
    isClient
}) => {
    const location = useLocation();
    const id = queryString.parse(location.search)?.id
    const { text } = useLanguage();
    let { theme } = useTheme();
    theme = isClient ? 'dark' : theme;
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [conversationsIsLoading, setConversationsIsLoading] = useState(true);
    const {
        selectedConversationId,
        createPrivateConversation,
        setConversations,
        conversations,
        participants,
        setParticipants,
        setClientParticipants,
        clientParticipants,
        handleUpdateParticipants,
        setProjectConversations,
        projectConversations,
        setSelectedConversation,
        selectedConversation,
        selectConversation,
        setSelectedConversationId,
        createMessage,
        disabledNotification,
        setMessages,
        messages,
        markAsRead,
        handleDeleteConversation,
        isSendingMessage,
        setIsSendingMessage,
        setImagePreviews,
        imagePreviews,
    } = useWebSocket();
    const firstUnreadRef = useRef(null);

    const [messageContent, setMessageContent] = useState('');
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [participantsIsOpen, setParticipantsIsOpen] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [projectSelected, setProjectSelected] = useState(false);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});

    const [isDisabled, setIsDisabled] = useState(false);
    const [firstUnreadIndex, setFirstUnreadIndex] = useState(null);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);


    useEffect(() => {
        // Assuming `selectedConversation` contains the conversation data
        if (selectedConversation) {
            const isUserSilenced = selectedConversation.silenceNotifications?.some(
                (notification) => notification.recipient === user?._id
            );
            setIsDisabled(isUserSilenced);
        }
    }, [selectedConversation, user]);


    useEffect(() => {
        if (id && conversations.length > 0) {
            const conversation = conversations.find((conversation) => conversation._id === id);
            if (conversation) {
                handleSelectConversation(conversation);
            } else {
                console.log(`Conversation with ID ${id} not found.`);
            }
        }
        if (id && projectConversations.length > 0) {
            const conversation = projectConversations.find((conversation) => conversation._id === id);
            if (conversation) {
                handleSelectConversation(conversation);
            } else {
                console.log(`Conversation with ID ${id} not found.`);
            }
        }
    }, [id, conversations.length, projectConversations.length]);


    useEffect(() => {
        const employeeData = employees?.map(employee => {
            const fullName = `${employee?.firstName} ${employee?.lastName}`;
            return {
                _id: employee?._id,
                name: fullName,
                type: 'employee',
            };
        }).filter((x) => x?._id !== user?._id);

        const projectData = adminOrAssignedProjects?.map(project => ({
            _id: project?._id,
            name: project?.projectName,
            type: 'project',
        }));

        const clientData = user?.roleName === 'Admin' ? clients?.map(client => ({
            _id: client?._id,
            name: client?.name,
            type: 'client',
        })) : clients?.filter((x) => x?.editableBy?.includes(user?.id))?.map(client => ({
            _id: client?._id,
            name: client?.name,
            type: 'client',
        }));

        setData([...(employeeData || []), ...(projectData || [], clientData || [])]);
    }, [employees, adminOrAssignedProjects, user]);

    const containerRef = useRef(null);
    const editorRef = useRef(null);
    const conversationIdRef = useRef(selectedConversationId); // Ref to track the current conversation ID

    // Sync the ref with the latest selected conversation ID
    useEffect(() => {
        conversationIdRef.current = selectedConversationId;
    }, [selectedConversationId]);


    const handleImageChange = (imageData) => {
        setImagePreviews(imageData);
    };

    const handleSendMessage = useCallback(
        ({ message, images }) => {
            const text = message;
            const contentToSend = text || messageContent.trim();
            const imgs = images || imagePreviews;

            if (!isEmpty(imgs) || (isEmpty(imgs) && contentToSend)) {
                // Start sending state
                createMessage(
                    conversationIdRef.current,
                    contentToSend,
                    'text',
                    user?._id,
                    imgs,
                    (message, error) => {
                        // Stop sending state after callback

                        if (message) {
                            setMessageContent('');
                            setTimeout(() => {
                                if (containerRef.current) {
                                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                                }
                            }, 100);
                        } else {
                            console.error('Error sending message:', error);
                        }
                    }
                );
            }
        },
        [imagePreviews, messageContent, user?._id]
    );


    const handleCreateConversation = () => {
        const companyId = user?.companyId;
        const currentUserId = user?._id;

        if (!projectSelected) {
            createPrivateConversation(userIds, companyId, currentUserId, clientIds, (conversation, error) => {
                if (conversation) {
                    // Optionally, set it as the selected conversation
                    handleSelectConversation(conversation);
                    setSearchIsOpen(false);
                    handleGetConversations();

                } else {
                    console.error('Error creating conversation:', error);
                }
            });
        } else {
            const conversation = projectConversations.find(conversation => conversation?.projectId?._id === userIds[0]);
            if (conversation) {
                handleSelectConversation(conversation);
                setSearchIsOpen(false);
            }
        }

    };

    const toggleSearch = () => {
        setSearchIsOpen(!searchIsOpen);
        setFilters({});
        setUserIds([]);
        setClientIds([]);
        setProjectSelected(false);
    }

    const toggleParticipants = () => {
        setParticipantsIsOpen(!participantsIsOpen);
        setParticipants(selectedConversation?.participants?.map(participant => participant?._id));
        setClientParticipants(selectedConversation?.clientParticipants?.map(client => client?._id));
    }

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const filterData = async () => {
        const employeeData = employees?.map(employee => {
            const fullName = `${employee?.firstName} ${employee?.lastName}`;
            return {
                _id: employee?._id,
                name: fullName,
                type: 'employee',
            };
        }).filter((x) => x?._id !== user?._id);

        const projectData = adminOrAssignedProjects?.map(project => ({
            _id: project?._id,
            name: project?.projectName,
            type: 'project',
        }));

        const clientData = user?.roleName === 'Admin' ? clients?.map(client => ({
            _id: client?._id,
            name: client?.name,
            type: 'client',
        })) : clients?.filter((x) => x?.editableBy?.includes(user?.id))?.map(client => ({
            _id: client?._id,
            name: client?.name,
            type: 'client',
        }));


        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }
        let filterData = [...(employeeData || []), ...(projectData || []), ...(clientData || [])];
        if (filterOptions.query) {
            filterData = filterData.filter(data =>
                data?.name.toLowerCase().includes(filterOptions.query) ||
                data?.type.toLowerCase().includes(filterOptions.query)
            );
        }

        setData(filterData);
    }

    useEffect(() => {
        filterData();
    }, [filters]);


    const handleGetConversations = async () => {
        setConversationsIsLoading(true);
        try {
            const res = isClient ? await getClientConversations({
                companyId: user?.companyId,
                clientId: user?._id
            }) : await getConversations();
            if (res.status === 200) {
                setConversationsIsLoading(false);
                setConversations(res.data?.filter((x) => !x?.projectId));
                setProjectConversations(res.data?.filter((x) => x?.projectId));
                setMessageContent('');
            }
        } catch (error) {
            setConversationsIsLoading(false);
            console.error('Failed to fetch conversations:', error);
        }
    }


    const handleSelectConversation = (conversation) => {
        const companyId = user?.companyId;
        const userId = user?._id;
        setSelectedConversation(conversation);
        setParticipants(conversation?.participants?.map(participant => participant?._id));
        setClientParticipants(conversation?.clientParticipants?.map(client => client?._id));
        selectConversation(conversation, companyId, userId);
        setSelectedConversationId(conversation?._id);
        if (selectedConversationId !== conversation?._id) {
            setMessageContent('');
        }
    }

    useEffect(() => {
        handleGetConversations();
    }, [])

    const initialFilteredData = useMemo(() => {
        return data
            ?.filter((x) => x?.type === 'employee' || x?.type === 'client')
            ?.filter((x) => x?.createdBy !== user?._id)
            ?.filter((x) => x?._id !== user?._id)
            ?.filter((x) => x?._id !== selectedConversation?.createdBy);
    }, [data]);

    const sortedFilteredData = useMemo(() => {
        return [...initialFilteredData].sort((a, b) => {
            const isAChecked = participants.includes(a._id);
            const isBChecked = participants.includes(b._id);
            return isBChecked - isAChecked; // Checked items first
        });
    }, [participants, initialFilteredData]);


    const [allRead, setAllRead] = useState(false);

    // Mark all messages as read manually
    const handleMarkAllAsRead = () => {
        const unreadMessageIds = messages
            .filter((message) => message.isUnread)
            .map((message) => message._id);

        if (unreadMessageIds.length > 0) {
            markAsRead(selectedConversationId, unreadMessageIds, user);
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    unreadMessageIds.includes(message._id)
                        ? { ...message, isUnread: false }
                        : message
                )
            );

            // Determine if the conversation is a project conversation
            setConversations((prevConversations) =>
                prevConversations.map((conversation) => {
                    if (conversation._id === selectedConversationId && !conversation.projectId) {
                        return { ...conversation, hasUnreadMessages: false };
                    }
                    return conversation;
                })
            );

            setProjectConversations((prevProjectConversations) =>
                prevProjectConversations.map((conversation) => {
                    if (conversation._id === selectedConversationId && conversation.projectId) {
                        return { ...conversation, hasUnreadMessages: false };
                    }
                    return conversation;
                })
            );

            setAllRead(true);
        }
    };

    return (
        <StyledDiv
            theme={isClient ? 'dark' : theme}
        >
            <ConditionalRender renderIf={true} isLoading={conversationsIsLoading}>
                <ConditionalRender renderIf={isDesktop}>
                    <div className='sidebar h-100'>
                        <div className='conversations scroll-container h-100 pr-md'>
                            <div className='h-100'>
                                <ConditionalRender renderIf={!isEmpty(projectConversations)}>
                                    <h4 className='tx-left'>
                                        {text?.projects?.details?.inventoryModal?.projects}
                                    </h4>
                                    {projectConversations
                                        ?.map(conversation => (
                                            <SelectableBox
                                                className={classnames({
                                                    'selected': selectedConversationId === conversation._id
                                                })}
                                                onClick={() => handleSelectConversation(conversation)}
                                                key={conversation._id}
                                            >
                                                <p className='tx-left'>
                                                    {conversation.conversationName}
                                                </p>
                                                <ConditionalRender renderIf={conversation.hasUnreadMessages}>
                                                    <div className='unread' />
                                                </ConditionalRender>
                                            </SelectableBox>
                                        ))
                                    }
                                </ConditionalRender>
                                <ConditionalRender renderIf={!isEmpty(conversations)}>
                                    <h4 className='mt-md tx-left' style={{
                                        color: (theme === 'dark' || isClient) ? colors.white : colors.black
                                    }}>
                                        {text?.chat?.conversations}
                                    </h4>
                                    {conversations
                                        ?.map(conversation => (
                                            <SelectableBox
                                                className={classnames({
                                                    'selected': selectedConversationId === conversation._id
                                                })}
                                                onClick={() => handleSelectConversation(conversation)}
                                                key={conversation._id}
                                            >
                                                <p className='tx-left'>
                                                    {conversation.conversationName}
                                                </p>
                                                <ConditionalRender renderIf={conversation.hasUnreadMessages}>
                                                    <div className='unread' />
                                                </ConditionalRender>
                                            </SelectableBox>
                                        ))
                                    }
                                </ConditionalRender>

                            </div>
                        </div>
                    </div>
                </ConditionalRender>
                <div className="w-100 chat-wrapper">
                    <div style={{
                        height: '5%'
                    }}>
                        <div className='flex items-start justify-space-between'>
                            <div className='flex items-center'>
                                <ConditionalRender renderIf={selectedConversationId}>
                                    <ConditionalRender renderIf={!isClient}>
                                        <HoverPopup
                                            className='mr-md'
                                            onClick={() => { }}
                                            style={{
                                                padding: '0',
                                                background: 'transparent'
                                            }}
                                            placement={'bottom'}
                                            id='silance-popup-employee_create_project_employee'
                                            text={text?.chat?.disabledNotification}
                                        >
                                            <Switch
                                                checked={isDisabled} // Boolean to track the switch state
                                                onChange={(event) => {
                                                    const newDisabledState = event.target.checked; // Get new state
                                                    setIsDisabled(newDisabledState); // Update local state

                                                    // Emit the toggle event
                                                    disabledNotification(selectedConversationId, user?._id, newDisabledState);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}

                                            />
                                        </HoverPopup>
                                    </ConditionalRender>
                                    {
                                        messages?.map((message, index) => (
                                            <React.Fragment key={index}>
                                                {!allRead && index === firstUnreadIndex && (
                                                    <UnreadIndicator
                                                        firstUnreadRef={firstUnreadRef}
                                                        theme={isClient ? 'dark' : theme}
                                                        markAllAsRead={handleMarkAllAsRead}
                                                        isClient={isClient}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </ConditionalRender>
                            </div>
                            <ConditionalRender renderIf={!isClient}>

                                <div className='flex'>
                                    <HoverPopup
                                        onClick={toggleSearch}
                                        placement={'bottom'}
                                        id='search-popup-employee_create_project_employee'
                                        text={text?.employees?.landing?.search}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            style={{
                                                fontSize: '1.5em'
                                            }} />
                                    </HoverPopup>
                                    <ConditionalRender renderIf={selectedConversationId && !selectedConversation?.projectId}>
                                        <HoverPopup
                                            className='ml-sm'
                                            onClick={toggleParticipants}
                                            placement={'bottom'}
                                            id='participants-popup-employee_create_project_employee'
                                            style={{
                                                width: '100%'
                                            }}
                                            text={text?.chat?.participants}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{
                                                    fontSize: '1.5em'
                                                }} />
                                        </HoverPopup>
                                        <HoverPopup
                                            className='ml-sm'
                                            onClick={() => setToggleDeleteModal(!toggleDeleteModal)}
                                            placement={'bottom'}
                                            id='participants-popup-remove-participants'
                                            style={{
                                                width: '100%'
                                            }}
                                            text={text?.chat?.deleteConversation}
                                        >
                                            <FontAwesomeIcon
                                                icon={faClose}
                                                style={{
                                                    fontSize: '1.5em',
                                                    color: colors.red
                                                }} />
                                        </HoverPopup>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>

                    {selectedConversationId ? (
                        <div className="flex flex-column h-95">
                            <h4 className='mb-md'
                                style={{
                                    color: (theme === 'dark' || isClient) ? colors.white : colors.black
                                }}
                            >
                                {selectedConversation?.conversationName}
                            </h4>
                            <ChatWindow
                                user={user}
                                containerRef={containerRef}
                                firstUnreadRef={firstUnreadRef}
                                setAllRead={setAllRead}
                                allRead={allRead}
                                setFirstUnreadIndex={setFirstUnreadIndex}
                                firstUnreadIndex={firstUnreadIndex}
                                isClient={isClient}
                            />
                            <RichEditorWithImageUploader
                                onImageUpload={handleImageChange}
                                maxFiles={5}
                                customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                                title={text?.chat?.message}
                                showForm={false}
                                onSubmit={(values) => handleSendMessage(values)}
                                text={text}
                                submitText={text?.tasks?.details?.button}
                                name="message"
                                isSendingMessage={isSendingMessage}
                                setIsSendingMessage={setIsSendingMessage}
                                imagePreviews={imagePreviews}
                                setImagePreviews={setImagePreviews}
                                isDark={isClient}
                                hideSave
                            />
                        </div>
                    ) : (
                        <h1
                            style={{
                                color: (theme === 'dark' || isClient) ? colors.white : colors.black
                            }}
                            className="h-95 w-100 flex justify-center items-center">
                            {text?.chat?.selectConversation}
                        </h1>
                    )}
                </div>
            </ConditionalRender>

            <ConfirmModal isOpen={toggleDeleteModal} toggle={() => setToggleDeleteModal(!toggleDeleteModal)} width='50%' height='50%' onClick={() => {
                handleDeleteConversation(selectedConversationId, user?._id, user?.companyId);
                setToggleDeleteModal(false);
            }} text={text?.chat?.deleteConversation}>
                <div className='w-100'>
                    <h4 className='mb-md'>{text?.chat?.deleteModal}</h4>
                </div>
            </ConfirmModal>

            <ConfirmModal
                isOpen={searchIsOpen}
                toggle={toggleSearch}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '60%' : '100%'}
                onClick={(!isEmpty(userIds) || !isEmpty(clientIds)) ? handleCreateConversation : null}
                text={text?.chat?.chatButton}
            >
                <div className='w-100'>
                    <h4 className='mb-md'>{text?.paginated?.search}</h4>

                    <SearchInput
                        className='pb-md w-100'
                        value={filters.query}
                        onChange={handleSearchChange}
                        placeHolder={text?.employees?.landing?.search}
                    />
                    <PaginatedData
                        className='mt-md'
                        data={data}
                        defaultItemsPerPage={5}
                        noDataText={text?.employees?.landing?.noData}
                        searching={filters?.query}
                        showLightColors
                        ItemComponent={({ item }) => {
                            console.log(item)
                            return (
                                <SelectableBox
                                    showLightColors
                                    className='w-100'
                                    onClick={() => {
                                        if (item.type === 'employee') {
                                            setUserIds([item._id]);
                                        }
                                        if (item.type === 'client') {
                                            setClientIds([item._id]);
                                        }
                                        if (item.type === 'project') {
                                            setUserIds([item._id]);
                                            setProjectSelected(true);
                                        } else {
                                            setProjectSelected(false);
                                        }
                                    }}
                                >
                                    <RadioButton
                                        id={`chat_${item._id}`}
                                        name={`chat_${item._id}`}
                                        value={`${item._id}`}
                                        checked={
                                            userIds.includes(item._id) ||
                                            clientIds.includes(item._id)
                                        }
                                        label={`${item.name} (${item.type})`}
                                        showLightColors
                                    />
                                </SelectableBox>
                            )
                        }
                        }
                    />
                </div>
            </ConfirmModal>
            <ConfirmModal
                isOpen={participantsIsOpen}
                toggle={toggleParticipants}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                onClick={() => {
                    handleUpdateParticipants(selectedConversationId, participants, clientParticipants, user);
                    setParticipantsIsOpen(false);
                }}
                text={participants.length > 1 ? text?.chat?.update : null}
            >
                <div className={classnames({
                    'flex w-100': true,
                    'flex-column': isPhone,
                })}>
                    <div className={classnames({
                        'w-50': !isPhone,
                        'mr-md': !isPhone,
                    })}>
                        <h4 className='mb-md'>{text?.paginated?.search}</h4>
                        <SearchInput
                            className="pb-md w-100"
                            value={filters.query}
                            onChange={handleSearchChange}
                            placeHolder={text?.employees?.landing?.search}
                        />
                        <PaginatedData
                            className="mt-md"
                            data={sortedFilteredData.filter(item =>
                                !participants.includes(item._id) && !clientParticipants.includes(item._id)
                            )} // Exclude checked items
                            defaultItemsPerPage={5}
                            noDataText={text?.table?.noDataText}
                            searching={filters?.query}
                            showLightColors
                            ItemComponent={({ item }) => (
                                <SelectableBox
                                    style={{
                                        width: '100%'
                                    }}
                                    showLightColors
                                >
                                    <CheckBox
                                        id={`participants_${item._id}`}
                                        disabledPopUp
                                        checked={participants.includes(item._id)} // Check state from participants
                                        label={`${item.name} (${item.type})`}
                                        disabled={item._id === user?._id}
                                        onChange={() => {
                                            if (item?.type === 'employee') {
                                                setParticipants((prevParticipants) => {
                                                    if (prevParticipants.includes(item._id)) {
                                                        // Remove from participants
                                                        return prevParticipants.filter(participant => participant !== item._id);
                                                    } else {
                                                        // Add to participants
                                                        return [...prevParticipants, item._id];
                                                    }
                                                });
                                            }
                                            if (item?.type === 'client') {
                                                setClientParticipants((prevParticipants) => {
                                                    if (prevParticipants.includes(item._id)) {
                                                        // Remove from participants
                                                        return prevParticipants.filter(participant => participant !== item._id);
                                                    } else {
                                                        // Add to participants
                                                        return [...prevParticipants, item._id];
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </SelectableBox>
                            )}
                        />
                    </div>
                    <div className={classnames({
                        'w-50': !isPhone,
                        'ml-md': !isPhone,
                    })}>
                        <h4 className='mb-md'>{text?.paginated?.selected}</h4>
                        <div>
                            {participants.map(participantId => {
                                const participant = sortedFilteredData.find(item => item._id === participantId);
                                if (!participant) return null;
                                return (
                                    <SelectableBox key={participant._id} showLightColors>
                                        <CheckBox
                                            id={`selected_${participant._id}`}
                                            disabledPopUp
                                            checked
                                            label={`${participant.name} (${participant.type})`}
                                            onChange={() => {
                                                setParticipants((prevParticipants) =>
                                                    prevParticipants.filter(id => id !== participant._id)
                                                );
                                            }}
                                        />
                                    </SelectableBox>
                                );
                            })}
                            {
                                clientParticipants.map(clientId => {
                                    const client = sortedFilteredData.find(item => item._id === clientId);
                                    if (!client) return null;
                                    return (
                                        <SelectableBox key={client._id} showLightColors>
                                            <CheckBox
                                                id={`selected_${client._id}`}
                                                disabledPopUp
                                                checked
                                                label={`${client.name} (${client.type})`}
                                                onChange={() => {
                                                    setClientParticipants((prevParticipants) =>
                                                        prevParticipants.filter(id => id !== client._id)
                                                    );
                                                }}
                                            />
                                        </SelectableBox>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </ConfirmModal>

        </StyledDiv>
    )
}

const ChatContainer = ({
    user,
    employees,
    projects,
    clients,
    isClient,
    setHasUnreadConversations = null
}) => {
    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.assignedEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    return (
        <WebSocketProvider
            userId={user._id}
        >
            <ChatWrapper
                user={user}
                employees={employees}
                clients={clients}
                adminOrAssignedProjects={adminProjects}
                isClient={isClient}
            />
        </WebSocketProvider>
    )
}

export default ChatContainer
