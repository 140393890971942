import React, { useState, useMemo } from 'react';
import FieldWithValidation from './FieldWithValidation';
import styled from 'styled-components'
import TextAreaField from './Inputs/TextAreaField';
import TelephoneInput from './Inputs/TelephoneInput';
import SelectDate from './Inputs/SingleDateInput';
import SelectTime from './Inputs/TimeInput';
import AmountInput from './Inputs/AmountInput';
import LengthInput from './Inputs/LengthInput';
import WeightInput from './Inputs/WeightInput';
import VolumeInput from './Inputs/VolumeInput';
import LinkField from './Inputs/LinkField';
import InternalLocationSearchInput from './Inputs/LocationSearchInput';
import CodeEditorField from './Inputs/CodeEditorField';
import { useTheme } from '../context/ThemeContext';
import colors from '../globalStyles.scss'

import './styles.scss'

const StyledFormGroup = styled.div`
    .form-group {
        margin: ${(props) => props.styles ? props.styles.margin : '0'};
        div {
        }
        .toggle-password-visibility {
            position: absolute;
            right: -30px;
            top: 0;
            cursor: pointer;
        }
    }
    input:focus,
    textarea:focus {
      outline: 0;
    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    top: -10px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
    }
    .rc-time-picker-input {
        height: 35px;
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
`

const MultiFields = React.memo(({
    label,
    name,
    component,
    validate,
    setIsEditing,
    fieldName,
    value,
    style,
    width,
    block,
    ...rest
}) => {
    const { theme } = useTheme();

    const RenderedComponent = useMemo(() => {
        switch (component) {
            case 'textarea':
                return TextAreaField;
            case 'phone':
                return TelephoneInput;
            case 'date':
                return SelectDate;
            case 'time':
                return SelectTime;
            case 'amount':
                return AmountInput;
            case 'length':
                return LengthInput;
            case 'weight':
                return WeightInput;
            case 'volume':
                return VolumeInput;
            case 'link':
                return LinkField;
            case 'code':
                return CodeEditorField;
            case 'location':
                return InternalLocationSearchInput;
            default:
                return FieldWithValidation;
        }
    }, [component]);

    return (
        <StyledFormGroup
            styles={style}
            value={value}
            theme={theme}
            className='MultiFields'
            style={{
                width: width ? `${width}%` : '100%',
                display: !block ? `flex` : 'block',
                ...style
            }}
        >
            <RenderedComponent
                name={name}
                label={label}
                validate={validate}
                setIsEditing={setIsEditing}
                fieldName={fieldName}
                {...rest}
            />
        </StyledFormGroup>
    );
});


export default MultiFields