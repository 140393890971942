export { default as one } from './one.png';
export { default as two } from './two.png';
export { default as oneVideo } from './one.mp4';
export { default as twoVideo } from './two.mp4';
export { default as three_one } from './three_one.png';
export { default as three_two } from './three_two.png';
export { default as three_three } from './three_three.png';
export { default as four_one } from './four_one.png';
export { default as four_two } from './four_two.png';
export { default as four_three } from './four_three.png';
export { default as four_four } from './four_four.png';
export { default as four_five } from './four_five.png';
export { default as five_one } from './five_one.png';
export { default as five_two } from './five_two.png';
export { default as five_three } from './five_three.mp4';
export { default as six_one } from './six_one.mp4';
export { default as six_two } from './six_two.png';
export { default as six_three } from './six_three.png';
export { default as six_four } from './six_four.png';
export { default as six_five } from './six_five.png';
export { default as six_six } from './six_six.png';
export { default as seven_one } from './seven_one.png';
export { default as seven_two } from './seven_two.png';
export { default as collage_en } from './collage_en.png';
export { default as collage_es } from './collage_es.png';