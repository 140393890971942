import React, { useState } from 'react';
import { ComposedChart, ReferenceLine, Brush, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Wrapper from '../Core/Wrapper';
import { status, priority } from '../utils/helperFunctions';
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import ConfirmModal from '../Core/ConfirmModal';
import moment from 'moment';
import FieldWrapper from '../Core/FieldWrapper';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import MultiFields from '../Core/MultiFields';
import SelectInput from '../Core/SelectInput';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';

const StyledDiv = styled.div`
    .DateInput {
        width: 100%;
    }
`

const TasksAnalysisContainer = ({
    tasks,
    tasksGroupBy,
    setTasksGroupBy,
    setChartIsEditing,
    analyticsWidgetsLoading,
    tasksIsLoading,
    form,
    handleSelectedDateChange,
    spyValues
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();
    const [info, setInfo] = useState(null);
    const [infoIsOpen, setInfoIsOpen] = useState(false);

    const getOptions = (groupBy) => {
        if (groupBy === 'taskStatus') {
            return status(text);
        }
        if (groupBy === 'taskPriority') {
            return priority(text);
        }
        return [];
    };

    const handleLineClick = (data) => {
        const payload = data.activePayload?.[0]?.payload;
        setInfo(payload);
    }

    const getTitleFromMilliseconds = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let hoursText = hours === 1 ? text?.analytics?.hour : text?.analytics?.hours;
        let minutesText = remainingMinutes === 1 ? text?.analytics?.minute : text?.analytics?.minutes;

        if (hours > 0) {
            if (remainingMinutes > 0) {
                // Both hours and minutes
                return `${hours} ${hoursText} ${text?.analytics?.and} ${remainingMinutes} ${minutesText}`;
            } else {
                // Only whole hours
                return `${hours} ${hoursText}`;
            }
        } else {
            // Only minutes
            return `${minutes} ${minutesText}`;
        }
    }

    const CustomTooltip = ({ payload, label, active }) => {
        if (active && payload && payload.length) {
            const { totalTimeFormatted, count } = payload[0]?.payload || {};

            return (
                <div style={{ backgroundColor: colors.backgroundSecondary, border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <div style={{ margin: 0, color: colors.white }}>
                        <strong className='mr-md'>
                            {label}
                        </strong>
                        {count}
                    </div>
                    <div style={{ margin: 0, color: colors.white }}>
                        <strong className='mr-md'>
                            {text?.analytics?.totalTime}
                        </strong>
                        {totalTimeFormatted}
                    </div>
                </div>
            );
        }

        return null;
    };

    const CustomLegend = ({ payload }) => {
        return (
            <div className='flex items-center justify-end'>
                {payload.map((entry, index) => {
                    const { dataKey, color } = entry;
                    return (
                        <div className='mr-sm' key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
                            <span style={{ color: color }}>
                                <strong>{dataKey === 'count' ? text?.analytics?.taskCount : text?.analytics?.totalTime}
                                </strong>
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const processTasksData = (tasksGroupBy) => {
        const groupBy = tasksGroupBy?.value;

        if (!groupBy) {
            console.error('groupBy is not defined');
            return [];
        }

        const options = getOptions(groupBy);

        const groupedData = tasks.reduce((acc, task) => {
            const key = task[groupBy];
            const optionLabel = options.find(option => option.value === key)?.label || key || 'Unknown';

            if (!acc[optionLabel]) {
                acc[optionLabel] = {
                    count: 0,
                    totalTime: 0,
                    totalTimeFormatted: '',
                    tasks: [], // Store task details here
                };
            }

            acc[optionLabel].count += 1;
            acc[optionLabel].totalTime += task.totalTime / (1000 * 60 * 60);
            acc[optionLabel].totalTimeFormatted = getTitleFromMilliseconds(acc[optionLabel].totalTime * 60 * 60 * 1000);
            acc[optionLabel].tasks.push({
                taskId: task._id,
                taskTitle: task.taskTitle,
                taskProject: task.taskProject,
                taskAssignedTo: task.taskAssignedTo,
                dateStarted: task.dateStarted,
                dateCompleted: task.dateCompleted,
                taskPriority: task.taskPriority,
                taskStatus: task.taskStatus,
                totalTime: task.totalTime,
                totalTimeFormatted: getTitleFromMilliseconds(task.totalTime),
            });

            return acc;
        }, {});

        return Object.entries(groupedData).map(([key, { count, totalTime, totalTimeFormatted, tasks }]) => ({
            name: key,
            count,
            totalTime: Math.round(totalTime * 100) / 100,
            totalTimeFormatted,
            tasks, // Include tasks array here
        }));
    };


    const chartData = processTasksData(tasksGroupBy);
    const maxNumber = Math.max(...chartData.map(item => item.totalTime));

    return (
        // <Wrapper
        //     title={text?.dashboard?.tasks}
        //     isLoading={analyticsWidgetsLoading && tasksIsLoading}
        // >
        <StyledDiv className={classnames({
            'flex': true,
            'flex-column': !isDesktop
        })}>
            <div className='w-50'>
                <h4 className='flex mb-md'>
                    {text?.dashboard?.tasks}
                </h4>
                <SelectInput
                    name="tasksGroupBy"
                    initialValue={tasksGroupBy}
                    label={text?.analytics?.groupBy}
                    isClearable={false}
                    options={[
                        { value: 'taskStatus', label: text?.analytics?.status },
                        { value: 'taskPriority', label: text?.analytics?.priority },
                    ]}
                    onChange={(e) => {
                        setTasksGroupBy(e)
                        setChartIsEditing(true)
                    }}
                    isValidNewOption={() => false}
                    style={{ width: '100%', marginBottom: '20px' }}
                    isSearchable={false}
                />
                <MultiFields
                    name="dateStarted"
                    style={{
                        width: '100%'
                    }}
                    component="date"
                    type="text"
                    label={text?.analytics?.dateStarted}
                    placeholder={text?.analytics?.selectDate}
                    onChange={(e) => handleSelectedDateChange(e, form)}
                    allowEmptyInitialValue
                    isOutsideRange={() => false}
                    isClearable
                    minDate={null}
                    block
                />
                <MultiFields
                    className='mt-md'
                    name="dateCompleted"
                    component="date"
                    type="text"
                    placeholder={text?.analytics?.selectDate}
                    onChange={(e) => handleSelectedDateChange(e, form)}
                    label={text?.analytics?.dateCompleted}
                    allowEmptyInitialValue
                    isOutsideRange={() => false}
                    minDate={null}
                    isClearable
                    block
                />
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    onClick={handleLineClick}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={{ fill: theme === 'dark' ? colors.white : colors.black }} // Change label color here
                    />
                    <YAxis
                        domain={[0, maxNumber]}
                        label={{ value: text?.analytics?.hours, angle: -90, position: 'insideLeft', offset: -5 }}
                        tick={{ fill: theme === 'dark' ? colors.white : colors.black }} // Change label color here

                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />

                    <Brush dataKey={'count'} height={20} stroke={theme === 'dark' ? colors.secondary : colors.black} />
                    <ReferenceLine y={0} stroke={theme === 'dark' ? colors.white : colors.black} />

                    <Bar dataKey="count" name={`Task Count by ${tasksGroupBy === 'taskStatus' ? text?.analytics?.status : text?.analytics?.priority}`} fill="#8884d8" />
                    <Line type="monotone" dataKey="totalTime" name={text?.analytics?.totalTime} stroke="#82ca9d" connectNulls />
                </ComposedChart>
            </ResponsiveContainer>

            <ConfirmModal
                isOpen={!isEmpty(info)}
                toggle={() => {
                    setInfoIsOpen(!infoIsOpen);
                    setInfo([]);
                }}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
            >
                <div className='w-100'>
                    <FieldWrapper
                        noHr
                        name={text?.analytics?.project}
                        label={spyValues?.selectProject?.label}
                        className='mb-md'
                    />
                    <FieldWrapper
                        noHr
                        name={text?.analytics?.employee}
                        label={spyValues?.selectEmployee?.label}
                        className='mb-md'
                    />
                    <FieldWrapper
                        name={text?.analytics?.totalTime}
                        label={info?.totalTimeFormatted}
                        className='mb-md'
                    />
                    <div className='mb-md'>
                        {info?.tasks?.map((task, index) => (
                            <div
                                key={index}
                                className='mb-sm'
                                style={{
                                    background: theme === 'dark' ? colors.backgroundSecondary : colors.white,
                                    padding: '1em',
                                    marginBottom: '1em'
                                }}
                            >
                                <FieldWrapper
                                    label={task.taskTitle}
                                    name={text?.analytics?.title}
                                    noHr
                                    goto={`/tasks/details?id=${task.taskId}`}

                                />
                                <ConditionalRender renderIf={spyValues?.selectProject?.value !== task?.taskProject?._id}>
                                    <FieldWrapper
                                        label={task.taskProject?.projectName}
                                        name={text?.analytics?.project}
                                        noHr
                                    />
                                </ConditionalRender>
                                <ConditionalRender renderIf={spyValues?.selectEmployee?.value !== task?.taskAssignedTo?._id}>
                                    <FieldWrapper
                                        label={task?.taskAssignedTo ? `${task?.taskAssignedTo?.firstName} ${task?.taskAssignedTo?.lastName}` : text?.analytics?.unassigned}
                                        name={text?.analytics?.assignedTo}
                                        noHr
                                    />
                                </ConditionalRender>
                                <FieldWrapper
                                    label={status(text)?.find((x) => x?.value === task.taskStatus)?.label}
                                    name={text?.analytics?.status}
                                    noHr
                                />
                                <FieldWrapper
                                    label={priority(text)?.find((x) => x?.value === task.taskPriority)?.label}
                                    name={text?.analytics?.priority}
                                    noHr
                                />
                                <ConditionalRender renderIf={!isEmpty(task.dateStarted)}>
                                    <FieldWrapper
                                        label={moment(task.dateStarted).format('MM-DD-YYYY')}
                                        name={text?.analytics?.dateStarted}
                                        noHr
                                    />
                                </ConditionalRender>
                                <ConditionalRender renderIf={!isEmpty(task.dateCompleted)}>
                                    <FieldWrapper
                                        label={moment(task.dateCompleted).format('MM-DD-YYYY')}
                                        name={text?.analytics?.dateCompleted}
                                        noHr
                                    />
                                </ConditionalRender>
                                <FieldWrapper
                                    label={task.totalTimeFormatted}
                                    name={text?.analytics?.totalTime}
                                    noHr
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </ConfirmModal>

        </StyledDiv>
        // </Wrapper>
    );
};

export default TasksAnalysisContainer;
