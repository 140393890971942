// context/GlobalContext.js
import React, { createContext, useState, useEffect } from "react";
import {
    setToken,
    removeToken,
    setUserId,
    getUserId,
    removeUserId,
    setCompanyId,
    getCompanyId,
    removeCompanyId,
    getToken,
    removeAccessToken,
    setLoggedInAsClientId,
    removeLoggedInAsClientId,
    getLoggedInAsClientId
} from "../utils/auth";
import queryString from "query-string";
import {
    fetchUser,
    fetchCompany,
    getProducts,
    getPriorityProducts,
    getProductsCategories,
    categoryRecommendations,
    getInventoryWithDateFields,
    getAttributes,
    chatAdvanceMessage,
    chatMessage,
    getResources,
    getFeaturedResources,
    getLogs,
    getAllTemplates,
    getAllTasks,
    getAllArchivedTasks,
    getAllClients,
    getAllProposals,
    retrieveSubscription,
    getEvents,
    getEventCategories,
    getFieldNames,
    getClientProjectsWithDetails,
    getFifteenMinutesEventsFromNow
} from "../utils/calls";
import parse from 'html-react-parser';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const GlobalContext = createContext();


const GlobalProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [employeesIsLoading, setEmployeesIsLoading] = useState(false)
    const [clients, setClients] = useState(null)
    const [clientsIsLoading, setClientsIsLoading] = useState(false)
    const [proposals, setProposals] = useState(null)
    const [proposalsIsLoading, setProposalsIsLoading] = useState(false)
    const [projects, setProjects] = useState(null);
    const [projectsIsLoading, setProjectsIsLoading] = useState(false)
    const [userIsLoading, setUserIsLoading] = useState(true);
    const [companyIsLoading, setCompanyIsLoading] = useState(true);
    const [inventorysLoading, settInventoryIsLoading] = useState(true);
    const [isCompanyPremium, setIsCompanyPremium] = useState(false);
    const [trialIsActive, setTrialIsActive] = useState(false);
    const [hasFreeAccess, setHasFreeAccess] = useState(false);
    const [inventory, setInventory] = useState([])
    const [priorityData, setPriorityData] = useState([]);
    const [priorityIsLoading, setPriorityIsLoading] = useState(false);
    const [typing, setTyping] = useState(false);


    const [categories, setCategories] = useState([])
    const [productsWithDates, setProductsWithDates] = useState([])
    const [productsWithDatesIsLoading, setProductsWithDatesIsLoading] = useState(false);

    const [attributes, setAttributes] = useState([])
    const [fieldNames, setFieldNames] = useState([])
    const [inventoryColumns, setInventoryColumns] = useState([])
    const [widgets, setWidgets] = useState([])
    const [attributesIsLoading, setAttributesIsLoading] = useState(false)

    const [resources, setResources] = useState([])
    const [resourcesIsLoading, setResourcesIsLoading] = useState(true)
    const [featuredResources, setFeaturedResources] = useState([])
    const [featuredResourcesIsLoading, setFeaturedResourcesIsLoading] = useState(true)

    const [showHelp, setShowHelp] = useState(false);

    const [conversationHistory, setConversationHistory] = useState([]);
    const [thinking, setThinking] = useState(false);
    const [chatErrorMessage, setChatErrorMessage] = useState('');

    const [timeLogs, setTimeLogs] = useState([])
    const [logsIsLoading, setLogsIsLoading] = useState(false);

    const [templates, setTemplates] = useState([])
    const [templatesIsLoading, setTemplatesIsLoading] = useState(false)

    const [tasks, setTasks] = useState([])
    const [tasksIsLoading, setTasksIsLoading] = useState(false)

    const [categoryRecommendationData, setCategoryRecommendationData] = useState([])
    const [categoryRecommendationIsLoading, setCategoryRecommendationIsLoading] = useState(false)

    const [hasNewNotifications, setHasNewNotifications] = useState(false)

    const [subscription, setSubscription] = useState(null)
    const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(false)

    const [events, setEvents] = useState([]);
    const [eventsIsLoading, setEventsIsLoading] = useState(false);
    const [eventCategories, setEventCategories] = useState([]);
    const [eventCategoriesIsLoading, setEventCategoriesIsLoading] = useState(false);


    const handleToggleChat = () => {
        setShowHelp(!showHelp);
    }
    const handleSendMessage = async (values, form, ref, isAdvance) => {
        const message = values?.message;
        const lastRef = ref
        // Reset the form
        if (form) form.reset();
        const errMessage = parse(`Help is unavailable at this time, send us a message here: <a href='${process.env.REACT_APP_URL}/contact-us'> Contact Us </a>`)

        // Add the user's message to the conversation history
        setConversationHistory((prevHistory) => [
            ...prevHistory,
            { role: 'user', content: message },
        ]);

        if (lastRef || ref) lastRef.current.scrollIntoView({ behavior: 'smooth' });
        setThinking(true);
        try {
            const response = isAdvance ? await chatAdvanceMessage(message) : await chatMessage(message);
            if (response.status === 200) {
                setThinking(false);
                // Add the server's response to the conversation history
                setConversationHistory((prevHistory) => [
                    ...prevHistory,
                    { role: 'assistant', content: response?.data?.message ? response?.data?.message : response?.data?.content },
                ]);
                setChatErrorMessage('')
            } else {
                setChatErrorMessage(errMessage)
                setThinking(false);
            }
        } catch (error) {
            setChatErrorMessage(errMessage)
            setThinking(false);

        }
    };

    const fetchCompanyEmployees = async (companyId) => {
        const id = companyId || getCompanyId()
        try {
            setEmployeesIsLoading(true)
            const res = await fetchCompany(id);
            if (res?.status === 200) {
                setEmployees(res?.data?.employees)
                setEmployeesIsLoading(false)
            } else {
                setEmployeesIsLoading(false)
            }
        } catch (error) {
            setEmployeesIsLoading(false)
            console.error("Error fetching company employees data:", error);
        }
    }

    const fetchAllClients = async () => {
        try {
            setClientsIsLoading(true)
            const res = await getAllClients()
            if (res?.status === 200) {
                setClients(res?.data?.clients)
                setClientsIsLoading(false)
            } else {
                setClientsIsLoading(false)
            }
        } catch (error) {
            setClientsIsLoading(false)
            console.error("Error fetching company clients data:", error);
        }
    }

    const fetchEvents = async (projectId = null) => {
        try {
            setEventsIsLoading(true)
            const res = await getEvents(projectId);
            if (res.status === 200) {
                setEvents(res.data)
                setEventsIsLoading(false)
            } else {
                setEventsIsLoading(false)
            }
        } catch (error) {
            setEventsIsLoading(false);
            console.error("Error fetching events data:", error);

        }
    }

    const fetchFifteenMinutesEventsFromNow = async () => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';
        const fifteenMinutesFromNow = new Date(now.getTime() + 15 * 60000);
        const fifteenMinutesFromNowLocal = new Date(fifteenMinutesFromNow.getTime() - (fifteenMinutesFromNow.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        try {
            const res = await getFifteenMinutesEventsFromNow({
                now: nowLocal,
                fifteenMinutes: fifteenMinutesFromNowLocal
            });
            if (res.status === 200) {
                console.log('events fetched')
            }
        } catch (error) {
            console.error("Error fetching events data:", error);
        }
    }

    const fetchEventCategories = async () => {
        try {
            setEventCategoriesIsLoading(true)
            const res = await getEventCategories();
            if (res.status === 200) {
                setEventCategories(res.data)
                setEventCategoriesIsLoading(false)
            } else {
                setEventCategoriesIsLoading(false)
            }
        } catch (error) {
            setEventCategoriesIsLoading(false);
            console.error("Error fetching event categories data:", error);
        }
    }

    const fetchAllProposals = async () => {
        try {
            setProposalsIsLoading(true)
            const res = await getAllProposals()
            if (res?.status === 200) {
                setProposals(res?.data)
                setProposalsIsLoading(false)
            } else {
                setProposalsIsLoading(false)
            }
        } catch (error) {
            setProposalsIsLoading(false)
            console.error("Error fetching company proposals data:", error);
        }
    }


    const fetchProjectTasks = async (projectId, archived = false, userId = false) => {
        try {
            setTasksIsLoading(true)
            const res = archived ? await getAllArchivedTasks(projectId) : await getAllTasks(projectId, userId);
            if (res.status === 200) {
                setTasks(res.data?.tasks)
                setTasksIsLoading(false)
            } else {
                setTasksIsLoading(false)
            }
        } catch (error) {
            console.error('Error fetching tasks', error)
            setTasksIsLoading(false)
        }
    }

    const fetchCategoryRecommendations = async () => {
        try {
            setCategoryRecommendationIsLoading(true);
            const res = await categoryRecommendations();
            if (res.status === 200) {
                const categoryArray = res.data.map(category => ({
                    value: category,
                    label: category
                }));

                // Remove duplicates
                const uniqueCombinedCategories = categoryArray?.reduce((acc, current) => {
                    if (!acc.some(cat => cat.value === current.value)) {
                        acc.push(current);
                    }
                    return acc;
                }, []).sort((a, b) => a.value.localeCompare(b.value));

                // Save to local storage
                localStorage.setItem('categoryRecommendations', JSON.stringify(uniqueCombinedCategories));

                setCategoryRecommendationData(uniqueCombinedCategories);
                setCategoryRecommendationIsLoading(false);
            } else {
                setCategoryRecommendationIsLoading(false);
            }
        } catch (error) {
            setCategoryRecommendationIsLoading(false);
            console.error("Error fetching category recommendations:", error);
        }
    }

    const checkAndFetchCategoryRecommendations = () => {
        const storedCategories = localStorage.getItem('categoryRecommendations');
        if (storedCategories) {
            setCategoryRecommendationData(JSON.parse(storedCategories));
        } else {
            fetchCategoryRecommendations();
        }
    };



    const fetchCompanyProjects = async (companyId, callRecomm = true) => {
        const id = companyId || getCompanyId();
        try {
            setProjectsIsLoading(true);
            const res = await fetchCompany(id);
            if (res?.status === 200) {
                const projects = res?.data?.projects || [];
                setProjects(projects);
                checkAndFetchCategoryRecommendations();
                setProjectsIsLoading(false);
            } else {
                setProjectsIsLoading(false);
            }
        } catch (error) {
            setProjectsIsLoading(false);
            console.error("Error fetching company projects:", error);
        }
    };


    const fetchCompanyDetails = async (companyId) => {
        const id = companyId || getCompanyId()
        try {
            setCompanyIsLoading(true)
            const res = await fetchCompany(id);
            if (res?.status === 200) {
                setCompany(res?.data);
                setCompanyIsLoading(false);
                setIsCompanyPremium(res?.data?.isPremium);
                setTrialIsActive(res?.data?.trialIsActive);
                setHasFreeAccess(res?.data?.hasFreeAccess);
            } else {
                setCompanyIsLoading(false);
            }
        } catch (error) {
            setCompanyIsLoading(false);
            console.error("Error fetching company data:", error);
        }
    }

    const fetchUserDetails = async (userId) => {
        const id = userId || getUserId()

        try {
            setUserIsLoading(true);
            const res = await fetchUser(id);
            if (res?.status === 200) {
                setUser(res?.data);
                setUserIsLoading(false);
            } else {
                setUserIsLoading(false);
            }
        } catch (error) {
            setUserIsLoading(false);
            console.error("Error fetching user data:", error);
        }
    };

    const fetchPriorityData = async () => {
        try {
            setPriorityIsLoading(true)
            const res = await getPriorityProducts();
            if (res.status === 200) {
                setPriorityData(res.data)
                setPriorityIsLoading(false)
            } else {
                setPriorityIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setPriorityIsLoading(false)
        }
    }

    const fetchResources = async (language) => {
        try {
            setResourcesIsLoading(true)
            const res = await getResources(language);
            if (res.status === 200) {
                setResources(res.data)
                setResourcesIsLoading(false)
            } else {
                setResourcesIsLoading(false)
            }


        } catch (error) {
            console.error(error)
            setResourcesIsLoading(false)
        }
    }
    const fetchFeaturedResources = async (language) => {
        try {
            setFeaturedResourcesIsLoading(true)
            const res = await getFeaturedResources(language);
            if (res.status === 200) {
                setFeaturedResources(res.data)
                setFeaturedResourcesIsLoading(false)
            } else {
                setFeaturedResourcesIsLoading(false)
            }


        } catch (error) {
            console.error(error)
            setFeaturedResourcesIsLoading(false)
        }
    }

    const fetchSubscriptionData = async () => {
        try {
            setSubscriptionIsLoading(true)
            const res = await retrieveSubscription();
            if (res.status === 200) {
                setSubscription(res.data)
                setSubscriptionIsLoading(false)
            } else {
                setSubscriptionIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setSubscriptionIsLoading(false)
        }
    }

    const fetchTemplates = async () => {
        try {
            setTemplatesIsLoading(true)
            const res = await getAllTemplates();
            if (res.status === 200) {
                const formattedTemplates = res.data.map((template) => {
                    const { _id, companyId, __v, updatedAt, createdAt, ...rest } = template;
                    return rest;
                });

                setTemplates(formattedTemplates)
                setTemplatesIsLoading(false)
            } else {
                setTemplatesIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setTemplatesIsLoading(false)
        }
    }

    const fetchInventoryProducts = async (projectId = null) => {
        try {
            settInventoryIsLoading(true);
            const res = await getProducts(null, projectId)
            if (res.status === 200) {
                setInventory(res.data)
                settInventoryIsLoading(false)
            } else {
                settInventoryIsLoading(false)
            }
        } catch (error) {
            settInventoryIsLoading(false);
            console.error("Error fetching inventory data:", error);
        }
    }

    const fetchInventoryCategories = async () => {
        try {
            const res = await getProductsCategories();
            if (res.status === 200) {
                setCategories(res?.data);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    }

    const fetchProductsWithDates = async () => {
        try {
            setProductsWithDatesIsLoading(true)
            const res = await getInventoryWithDateFields();
            if (res.status === 200) {
                setProductsWithDatesIsLoading(false)
                setProductsWithDates(res?.data);
            } else {
                setProductsWithDatesIsLoading(false)
            }
        } catch (error) {
            setProductsWithDatesIsLoading(false)
            console.error("Error fetching inventory data:", error);
        }
    }

    const fetchFieldNames = async (companyId = null) => {
        try {
            const res = await getFieldNames(companyId);
            if (res.status === 200) {
                setFieldNames(res?.data);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    }

    const fetchAttributes = async () => {
        try {
            setAttributesIsLoading(true)
            const res = await getAttributes();
            if (res.status === 200) {
                setAttributes(res?.data);
                setWidgets(res?.data?.widgets);
                const sortedInventoryColumns = res?.data?.inventoryColumns.sort((a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder));
                setInventoryColumns(sortedInventoryColumns);
                setAttributesIsLoading(false);
            } else {
                setAttributesIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
            setAttributesIsLoading(false)

        }
    }

    const fetchTimeLogs = async (id = null, projectId = null, showAll = false) => {
        try {
            setLogsIsLoading(true)
            const res = await getLogs(id, projectId, showAll);
            if (res.status === 200) {
                setTimeLogs(res.data)
                setLogsIsLoading(false)
            } else {
                setLogsIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLogsIsLoading(false)
        }
    }

    const fetchClientDetails = async (clientId) => {
        try {
            setClientIsLoading(true)
            const res = await getClientProjectsWithDetails(clientId)
            if (res.status === 200) {
                setClientInfo(res.data)
                setClientIsLoading(false)
                setIsLoggedInAsClient(true)
            } else {
                setClientIsLoading(false)
                setIsLoggedInAsClient(false)
            }
        } catch (error) {
            setClientIsLoading(false)
            setIsLoggedInAsClient(false)
            console.error("Error fetching client data:", error);
        }
    }

    useEffect(() => {
        let sessionLanguage = sessionStorage.getItem('language');
        let ln = sessionLanguage || 'en';
        fetchResources(ln);
        fetchFeaturedResources(ln);
        const projectId = queryString.parse(history.location.search)?.id || null


        if (getToken() || getUserId() || getCompanyId()) {
            fetchUserDetails(getUserId());
            fetchCompanyDetails()
            fetchCompanyEmployees()
            fetchProjectTasks()
            fetchAllClients()
            fetchAllProposals()
            fetchEvents(projectId)
            fetchEventCategories()
            fetchCompanyProjects()
            fetchPriorityData()
            fetchInventoryProducts(projectId);
            fetchInventoryCategories();
            fetchProductsWithDates()
            fetchAttributes();
            fetchFieldNames()
            fetchTimeLogs()
            fetchTemplates()
            fetchSubscriptionData()
            removeLoggedInAsClientId();
        } else {
            setUserIsLoading(false);
            setCompanyIsLoading(false)
            setProjectsIsLoading(false)
            setEmployeesIsLoading(false)
            settInventoryIsLoading([]);

        }
        if (getLoggedInAsClientId()) {
            // get all client details
            fetchClientDetails(getLoggedInAsClientId())
        }
    }, []);

    const loginUser = (token, id, companyId) => {
        const projectId = queryString.parse(history.location.search)?.id || null

        setToken(token);
        setUserId(id);
        fetchUserDetails(id);
        fetchCompanyDetails(companyId)
        fetchCompanyEmployees(companyId)
        fetchProjectTasks()
        fetchAllClients()
        fetchAllProposals()
        fetchEvents(projectId)
        fetchEventCategories()
        fetchCompanyProjects(companyId)
        setCompanyId(companyId)
        fetchPriorityData()
        fetchResources()
        fetchFeaturedResources()
        fetchInventoryProducts(projectId);
        fetchInventoryCategories()
        fetchProductsWithDates()
        fetchAttributes()
        fetchFieldNames()
        fetchTimeLogs()
        fetchTemplates()
        fetchCategoryRecommendations()
        fetchSubscriptionData()
        removeLoggedInAsClientId();

        fetchFifteenMinutesEventsFromNow()

    };

    const logoutUser = () => {
        // Set user to null and remove tokens
        setUser(null);
        removeToken();
        removeCompanyId()
        removeAccessToken()
        removeUserId();
        settInventoryIsLoading([]);
        localStorage.removeItem('categoryRecommendations');
        sessionStorage.removeItem('timeTracker');
        // Replace the current entry in the history stack and reload the page
        history.replace('/login');
        window.location.reload();
    };


    const [clientInfo, setClientInfo] = useState(null);
    const [isLoggedInAsClient, setIsLoggedInAsClient] = useState(false);
    const [clientIsLoading, setClientIsLoading] = useState(false);

    const clientLogin = (clienId, companyId) => {
        setLoggedInAsClientId(clienId);
        fetchClientDetails(clienId);
        fetchFieldNames(companyId);
    }

    const clientLogout = () => {
        removeLoggedInAsClientId();
        history.replace('/login');
        setClientInfo(null);
        setIsLoggedInAsClient(false);
    }

    return (
        <GlobalContext.Provider value={{
            user,
            userRole: user?.roleName,
            isAccountSetUp: user?.isAccountSetUp,
            industry: user?.companyIndustry,
            loginUser,
            logoutUser,
            company,
            employees,
            employeesIsLoading,
            companyIsLoading,
            clients,
            clientsIsLoading,
            fetchAllClients: fetchAllClients,
            proposals,
            proposalsIsLoading,
            fetchAllProposals: fetchAllProposals,
            projects,
            setProjects,
            projectsIsLoading,
            fetchCompanyDetails: fetchCompanyDetails,
            fetchCompanyEmployees: fetchCompanyEmployees,
            categoryRecommendationData,
            categoryRecommendationIsLoading,
            tasks,
            tasksIsLoading,
            fetchProjectTasks: fetchProjectTasks,
            fetchCompanyProjects: fetchCompanyProjects,
            history,
            inventory,
            fetchInventoryProducts: fetchInventoryProducts,
            inventorysLoading,
            fetchPriorityData: fetchPriorityData,
            resourcesIsLoading,
            resources,
            featuredResourcesIsLoading,
            featuredResources,
            fetchResources: fetchResources,
            fetchFeaturedResources: fetchFeaturedResources,
            priorityData,
            priorityIsLoading,
            userIsLoading,
            fetchUserDetails: fetchUserDetails,
            setInventory: setInventory,
            trialIsActive: trialIsActive,
            isCompanyPremium: (company !== null && (isCompanyPremium || trialIsActive || hasFreeAccess)),
            isLoggedIn: user !== null,
            categories,
            fetchInventoryCategories: fetchInventoryCategories,
            productsWithDates,
            productsWithDatesIsLoading,
            fetchProductsWithDates: fetchProductsWithDates,
            fieldNames,
            fetchAttributes: fetchAttributes,
            fetchFieldNames: fetchFieldNames,
            attributes,
            attributesIsLoading,
            setInventoryColumns: setInventoryColumns,
            inventoryColumns,
            widgets,
            handleToggleChat: handleToggleChat,
            showHelp,
            setShowHelp: setShowHelp,
            handleSendMessage: handleSendMessage,
            conversationHistory,
            thinking,
            typing,
            chatErrorMessage,
            fetchTimeLogs: fetchTimeLogs,
            fetchTemplates: fetchTemplates,
            templates,
            templatesIsLoading,
            timeLogs,
            logsIsLoading,

            hasNewNotifications,
            setHasNewNotifications,

            subscription,
            subscriptionIsLoading,

            events,
            eventsIsLoading,
            fetchEvents: fetchEvents,

            eventCategories,
            eventCategoriesIsLoading,
            fetchEventCategories: fetchEventCategories,

            clientInfo,
            isLoggedInAsClient,
            clientIsLoading,
            setClientIsLoading,
            setClientInfo,
            setIsLoggedInAsClient,
            clientLogin,
            clientLogout,
            fetchClientDetails: fetchClientDetails,
            fetchFifteenMinutesEventsFromNow: fetchFifteenMinutesEventsFromNow

        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
