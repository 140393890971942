import requestWithConfig from '../requestWithConfig';

/**
 * Streams a file from S3 by sending a key to the API.
 * @param {string} url - The S3 url (path of the file) to stream.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const streamFileFromS3 = async (url) => {
    try {
        const config = {
            responseType: 'blob',
        };
        const { data, status } = await requestWithConfig('POST', '/time/stream-file', { url }, config);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to stream file: ${error.message}`);
    }
};

export default streamFileFromS3;
