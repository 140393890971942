import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from '../Core/ConditionalRender';
import SenderName from '../Chat/SenderName';
import EditableField from '../Core/Inputs/EditableField';
import { useLanguage } from '../context/LanguageContext';
import colors from '../globalStyles.scss';
import ConfirmModal from '../Core/ConfirmModal';
import HoverPopup from '../Core/HoverPopup';
import { useTheme } from '../context/ThemeContext';
import useScreenSize from '../context/useScreenSize';
import styled from 'styled-components'
import OutsideClickHandler from '../Core/OutsideClickHandler';
import { isEmpty } from 'lodash';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme === 'light' ? colors.darkGray : colors.lightBlue};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'light') && colors.lightGray)};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'dark') && colors.backgroundSecondary)};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1);
    transition: box-shadow 0.2s ease;
    transition: transform 0.2s ease;
    &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        transform: scale(1.009);
    }
    
    position: relative;
    padding: 1em;
    margin-top: 1em;
    .buttons {
        opacity: ${(props) => (props.isEditing ? 1 : 0)};
        transition: opacity 0.1s ease-out 0s;
    }
    &:hover {
        .buttons {
            opacity: 1;
        }
    }

    p, span {

        font-family: ${colors.openSans};
        text-align: left;
        strong {
            font-weight: bold;
        }
        em {
            font-style: italic;
        }
        s {
            text-decoration: line-through;
        }
    }

    .reaction-container {
        position: relative;
        cursor: pointer;
    }

    .reaction-count {
        position: absolute;
        top: 0;
        right: -5px;
        font-size: 0.5em;
    }

    .custom-tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: 0.75em;
        white-space: nowrap;
        display: none; /* Initially hidden */
        z-index: 1000;
    }

    .reaction-container:hover .custom-tooltip {
        display: block; /* Show the tooltip */
    }


`

const ReactionsPopup = styled.div`
    position: absolute;
    top: 0; 
    left: -4em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${(props) => ((props.theme === 'dark' && props.allowEdits) ? colors.backgroundSecondary : colors.secondary)};
    padding: 0.5em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;

    .reaction {
        cursor: pointer;
        font-size: 1.2em;
        margin: 0 0.5em;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
        }
    }
`;

const Reactions = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    bottom: -5px;
    left: 0;
    cursor: pointer;
    .reaction {
        font-size: 1.2em;
        margin: 0 0.2em;
        transition: transform 0.2s ease;
        &:hover {
            transform: scale(1.2);
        }
    }
`

const ReplyWrapper = ({
    index,
    message,
    handleSave,
    handleReply,
    handleCancel,
    handleDeleteComment,
    toggleDeleteModal,
    selectedCommentId,
    handleToggleDelete,
    user,
    projectId,
    processImages,
    name,
    isChat,
    senderFullName,
    createdAt,
    profilePhoto,
    value,
    isProject,
    isClient,
    updateReaction,
}) => {
    const { text } = useLanguage();
    let { theme } = useTheme();
    theme = isClient ? 'dark' : theme;
    const { isDesktop } = useScreenSize();
    const [isEditing, setIsEditing] = useState(false);
    const [showReactions, setShowReactions] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const allowEdits = isChat ? user?.id === message?.sender?._id : user?.id === message?.commentedBy?.id;

    const reactionTypes = [
        { value: 'like', label: '👍', id: 0 },
        { value: 'love', label: '❤️', id: 1 },
        { value: 'laugh', label: '😂', id: 2 },
        { value: 'sad', label: '😢', id: 3 },
        { value: 'angry', label: '😡', id: 4 },
    ];


    const handleReactionClick = (reaction, messageId) => {
        setShowReactions(false);
        updateReaction(messageId, reaction, user?.id);
    };

    return (
        <StyledDiv
            key={index}
            theme={isClient ? 'dark' : theme}
            isOwnMessage={allowEdits}
            isEditing={isEditing}
        >
            <OutsideClickHandler
                className='flex flex-column'
                onOutsideClick={() => {
                    if (showReply) setShowReply(false);
                    if (showReactions) setShowReactions(false);
                    if (isEditing) setIsEditing(false);
                }}>

                <div className="w-100 flex flex-column">
                    <div className="flex flex-column">
                        <SenderName
                            isDark={isClient}
                            isOwnMessage={allowEdits}
                            message={{
                                senderFullName,
                                sender: {
                                    profilePhoto,
                                },
                                createdAt,
                            }}
                        />
                        <EditableField
                            value={value}
                            onSave={(value) => handleSave(value, message?._id)}
                            onCancel={handleCancel}
                            placeholder=""
                            allowEdit={allowEdits}
                            name={name}
                            initialValue={value}
                            message={message}
                            projectId={projectId}
                            canUpload
                            processImages={processImages}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            isClient={isClient}
                            isDark={isClient || allowEdits}
                            disabled={!allowEdits || isProject}
                            notCancelOutsideClick
                        />

                    </div>
                </div>
                <div
                    className="buttons"
                    style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <ConditionalRender renderIf={!isProject && updateReaction}>
                            <OutsideClickHandler onOutsideClick={() => {
                                setShowReactions(false);
                            }}>
                                <HoverPopup
                                    noBG
                                    isDark={isClient}
                                    onClick={() => setShowReactions((prev) => !prev)}
                                    placement={'top'}
                                    id={`reaction-message-${message?._id}`}
                                    text={text?.chat?.react}
                                    wrapperStyle={{
                                        padding: '0.5em',
                                        cursor: 'pointer',
                                        width: '2em',
                                        height: '2em',
                                    }}
                                >
                                    👍
                                </HoverPopup>

                                {showReactions && (
                                    <ReactionsPopup
                                        theme={theme}
                                        allowEdits={allowEdits}
                                    >
                                        {reactionTypes?.map((reaction) => (
                                            <span
                                                key={reaction?.value}
                                                className="reaction"
                                                onClick={() => handleReactionClick(reaction?.value, message?._id)}
                                            >
                                                {reaction?.label}
                                            </span>
                                        )
                                        )}
                                    </ReactionsPopup>
                                )}
                            </OutsideClickHandler>
                        </ConditionalRender>
                    </div>

                    <ConditionalRender renderIf={allowEdits && !isProject}>
                        <HoverPopup
                            noBG
                            isDark={isClient}
                            onClick={() => setIsEditing((prev) => !prev)}
                            placement={'top'}
                            id={`edit-comment-${message?._id}`}
                            text={text?.logs?.edit}
                            wrapperStyle={{
                                padding: '0.5em',
                                cursor: 'pointer',
                                width: '2em',
                                height: '2em',
                                border: 'none',
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} style={{
                                color: theme === 'dark' ? colors.white : colors.primary,
                            }} />
                        </HoverPopup>
                        <HoverPopup
                            noBG
                            isDark={isClient}
                            onClick={() => handleToggleDelete(message._id)}
                            placement={'top'}
                            id={`delete-comment-${message?._id}`}
                            text={text?.tasks?.details?.delete}
                            wrapperStyle={{
                                padding: '0.5em',
                                cursor: 'pointer',
                                width: '2em',
                                height: '2em',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faClose}
                                style={{
                                    color: colors.red,
                                }}
                            />
                        </HoverPopup>

                        {toggleDeleteModal && selectedCommentId === message._id && (
                            <ConfirmModal
                                onClick={() => {
                                    handleDeleteComment(message._id);
                                    handleToggleDelete(null);
                                }}
                                text={text?.tasks?.details?.deleteModal?.button}
                                toggle={() => handleToggleDelete(null)}
                                isOpen={toggleDeleteModal}
                                btnStyles={{
                                    color: colors.red,
                                }}
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '50%' : '100%'}
                            >
                                {text?.tasks?.details?.deleteModal?.title}
                            </ConfirmModal>
                        )}
                    </ConditionalRender>
                </div>
                <ConditionalRender renderIf={message?.isEdited}>
                    <p
                        className="mt-sm"
                        style={{
                            color: theme === 'dark' ? colors.white : colors.backgroundSecondary,
                            alignSelf: 'flex-end',
                            fontSize: '0.7em',
                        }}
                    >
                        {text?.chat?.edited}
                    </p>
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(message?.reactions)}>
                    <Reactions className="flex flex-row">
                        {Object.entries(
                            message?.reactions.reduce((acc, reaction) => {
                                if (!acc[reaction.type]) {
                                    acc[reaction.type] = {
                                        count: 0,
                                        names: [],
                                    };
                                }
                                acc[reaction.type].count += 1;

                                const name = reaction.user
                                    ? `${reaction.user.firstName} ${reaction.user.lastName}` // User name
                                    : reaction.client?.name; // Client name
                                acc[reaction.type].names.push(name);

                                return acc;
                            }, {})
                        ).map(([type, { count, names }]) => (
                            <HoverPopup
                                noBG
                                key={type}
                                isDark={isClient}
                                onClick={() => handleReactionClick(type, message?._id)} // Handle reaction clicks
                                placement={'top'}
                                id={`reaction-message-${message?._id}-${type}`}
                                text={names.join(', ')}
                                style={{
                                    padding: '0 0.2em',
                                }}
                                wrapperStyle={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'none',
                                }}
                            >
                                {/* Emoji */}
                                <span
                                    className='reaction'
                                >
                                    {reactionTypes.find((reactionType) => reactionType.value === type)?.label}
                                </span>

                                {/* Count */}
                                {count > 1 && (
                                    <span
                                        style={{
                                            fontSize: '0.6em',
                                            color: colors.textSecondary,
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}
                                    >
                                        x{count}
                                    </span>
                                )}
                            </HoverPopup>
                        ))}
                    </Reactions>
                </ConditionalRender>
            </OutsideClickHandler>
        </StyledDiv >
    );
};

export default ReplyWrapper;
