import React from 'react'
import HomeLandingContainer from './HomeLandingContainer'
import ConditionalRender from '../Core/ConditionalRender'
import WelcomeContainer from '../Welcome/WelcomeContainer'
import MembershipContainer from '../Membership/MembershipContainer'
import WaitListContainer from './WaitListContainer'
import ClientDisplayContainer from './ClientDisplayContainer'
import { WebSocketProvider } from '../context/WebSocketContext'



const HomeContainer = ({
    isCompanyPremium,
    isLoggedIn,
    isLoggedInAsClient,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    attributesIsLoading,
    widgets,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    priorityData,
    priorityIsLoading,
    productsWithDates,
    productsWithDatesIsLoading,
    accessGranted,
    isAccountVerified,
    userRole,
    industry,
    isAccountSetUp,
    user,
    company,
    fetchCompanyDetails,
    companyIsLoading,
    projects,
    employees,
    employeesIsLoading,
    projectsIsLoading,
    fetchCompanyEmployees,
    setInventoryColumns,
    fetchUserDetails,
    mdWindow,
    setInventory,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    fetchCompanyProjects,
    clientInfo,
    fetchClientDetails,
    fetchFieldNames,
    fieldNames,
    featuredResources,
    featuredResourcesIsLoading
}) => {
    return (
        <>
            <ConditionalRender renderIf={isCompanyPremium && isLoggedIn}>
                <WelcomeContainer
                    userRole={userRole}
                    industry={industry}
                    user={user}
                    company={company}
                    fetchCompanyDetails={fetchCompanyDetails}
                    companyIsLoading={companyIsLoading}
                    projects={projects}
                    projectsIsLoading={projectsIsLoading}
                    employees={employees}
                    employeesIsLoading={employeesIsLoading}
                    fetchCompanyEmployees={fetchCompanyEmployees}
                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    inventoryColumns={inventoryColumns}
                    fetchAttributes={fetchAttributes}
                    setInventoryColumns={setInventoryColumns}
                    fetchUserDetails={fetchUserDetails}
                    isCompanyPremium={isCompanyPremium}
                    handleToggleChat={handleToggleChat}
                    showHelp={showHelp}
                    handleSendMessage={handleSendMessage}
                    setInventory={setInventory}
                    tasks={tasks}
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    fetchAllClients={fetchAllClients}
                    clients={clients}
                    clientsIsLoading={clientsIsLoading}
                    proposals={proposals}
                    proposalsIsLoading={proposalsIsLoading}
                    fetchAllProposals={fetchAllProposals}
                    fetchCompanyProjects={fetchCompanyProjects}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={!isLoggedIn && !isLoggedInAsClient}>
                <HomeLandingContainer
                    isLoggedIn={isLoggedIn}
                    accessGranted={accessGranted}
                    isAccountVerified={isAccountVerified}
                    featuredResources={featuredResources}
                    featuredResourcesIsLoading={featuredResourcesIsLoading}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isLoggedInAsClient}>
                <ClientDisplayContainer
                    clientInfo={clientInfo}
                    user={user}
                    company={company}
                    fetchClientDetails={fetchClientDetails}
                    fetchFieldNames={fetchFieldNames}
                    fieldNames={fieldNames}
                />
            </ConditionalRender>

            <ConditionalRender renderIf={isLoggedIn}>
                <ConditionalRender renderIf={accessGranted}>
                    <ConditionalRender renderIf={!isCompanyPremium}>
                        <MembershipContainer
                            isLoggedIn={isLoggedIn}
                            fetchUserDetails={fetchUserDetails}
                            user={user}
                            company={company}
                        />
                    </ConditionalRender>
                </ConditionalRender>
            </ConditionalRender>
            <ConditionalRender renderIf={!accessGranted}>
                <ConditionalRender renderIf={!isCompanyPremium}>
                    <ConditionalRender renderIf={isLoggedIn}>
                        <WaitListContainer
                            isLoggedIn={isLoggedIn}
                            fetchUserDetails={fetchUserDetails}
                            user={user}
                            company={company}
                        />
                    </ConditionalRender>
                </ConditionalRender>
            </ConditionalRender>
        </>
    )
}

export default HomeContainer