import React from 'react'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import classnames from 'classnames'
import HoverPopup from './HoverPopup'
import ConditionalRender from './ConditionalRender'
import useScreenSize from '../context/useScreenSize'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


const StyledSelectableBox = styled.div`
        position: relative;
        margin-top: 0.5em;
        padding: ${props => props.padding || '1em'};
        background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.white) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
        box-shadow: ${props => props.theme === 'dark' ? '0 0 8px 0 rgba(0, 0, 0, 0.2)' : '0 0 5px 0 rgba(0, 0, 0, 0.1)'};
        p, label {
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white} !important;
        }
        transition: background-color 0.1s;
        &:hover {
                background-color: ${props => props.theme === 'dark' ? colors.backgroundOverlay : colors.lightGray};
                box-shadow: ${props => props.theme === 'dark' ? '0 0 10px 0 rgba(0, 0, 0, 0.3)' : '0 0 8px 0 rgba(0, 0, 0, 0.2)'};
            }

`
const StyledLinkBox = styled(Link)`
        position: relative;
        margin-top: 0.5em;
        padding: ${props => props.padding || '1em'};
        background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.white) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
        box-shadow: ${props => props.theme === 'dark' ? '0 0 8px 0 rgba(0, 0, 0, 0.2)' : '0 0 5px 0 rgba(0, 0, 0, 0.1)'};
        p, label {
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white};
        }
        transition: background-color 0.1s;
        &:hover {
                background-color: ${props => props.theme === 'dark' ? colors.backgroundOverlay : colors.lightGray};
                box-shadow: ${props => props.theme === 'dark' ? '0 0 10px 0 rgba(0, 0, 0, 0.3)' : '0 0 8px 0 rgba(0, 0, 0, 0.2)'};
            }

`

const SelectableBox = ({
    children,
    className,
    onClick,
    showLightColors = false,
    isLink = false,
    hasHoverPopup = false,
    placement = 'top',
    id = 'hover-popup',
    text = '',
    to = '',
    removeButton,
    isDark = false,
    onClickRemove = () => { },
    ...rest
}) => {
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;
    const { isDesktop, isTablet } = useScreenSize();

    const handleClickRemove = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onClickRemove();
    }

    const BoxComponent = isLink ? StyledLinkBox : StyledSelectableBox;

    return (
        <BoxComponent
            {...rest}
            theme={theme}
            to={to}
            showLightColors={showLightColors}
            onClick={onClick && onClick}
            className={classnames(className, {
                'SelectableBox': true,
                'pointer': onClick,
            })}
        >
            <ConditionalRender renderIf={hasHoverPopup}>
                <HoverPopup
                    noBG
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        color: theme === 'dark' ? colors.white : colors.black,
                    }}
                    className={classnames({
                        'w-100': !isDesktop && !isTablet,
                        'w-50': isDesktop || isTablet,
                    })}
                    placement={placement}
                    id={id}
                    text={text}
                >
                    {children}
                </HoverPopup>
            </ConditionalRender>
            <ConditionalRender renderIf={!hasHoverPopup}>
                <div
                    className={'flex flex-column justify-space-between w-100'}
                    style={{ pointerEvents: 'auto' }}
                >
                    {children}
                </div>
            </ConditionalRender>
            <ConditionalRender renderIf={removeButton && handleClickRemove}>
                <FontAwesomeIcon
                    onClick={handleClickRemove}
                    style={{
                        color: colors.white,
                        backgroundColor: colors.red,
                        padding: '0.2em',
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                    }}
                    icon={faClose} />
            </ConditionalRender>
        </BoxComponent >
    )
}

export default SelectableBox