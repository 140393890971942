import React from 'react';
import { findMaxNumber, sortByKeyWithHighestValue } from './utils/helperFunctions';
import CustomToolTip from '../Dashboard/Charts/CustomToolTip';
import { findFieldByName } from '../utils/helperFunctions';
import Filters from './Filters';
import { useLanguage } from '../context/LanguageContext';
import generatePrimaryColors from '../utils/generatePrimaryColors';
import { useTheme } from '../context/ThemeContext';
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss';
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';

import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    Bar,
    Brush
} from 'recharts';
const BarChartContainer = ({
    data,
    inventoryColumns,
    form,
    setFormattedData,
    formattedData,
    handleCombineChange,
    setCombineKey,
    combineKey,
    stringData,
    numberData,
    setDataCheckedKeys,
    setDataCheckedKeysProject,
    dataCheckedKeys,
    handleClear,
    setInfo,
    initialGroupBy,
    setInitialGroupBy,
    setChartIsEditing,
    fieldNames,
    formRef,
    isProject,
    spyValues,
    analyticsWidgets
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();
    const colorsArray = generatePrimaryColors(dataCheckedKeys?.length)
    let maxNumber = findMaxNumber(formattedData, dataCheckedKeys);

    const handleBarClick = (data, index) => {
        const selectedValue = data[combineKey];  // This will give you "Blue", "Red", etc.

        // Filter the formattedData based on the selected value
        const filteredData = numberData.filter(item => item[combineKey] === selectedValue);

        // Set the filtered data as the new Info state
        setInfo(filteredData);
    };

    const parseDataForChart = (formattedData) => {
        return formattedData.map(item => {
            let chartItem;
            if (isProject) {
                chartItem = {
                    projectCategory: item.projectCategory,
                };
            } else {
                chartItem = {
                    inventoryName: item.inventoryName,
                    inventoryCategory: item.inventoryCategory,
                };
            }

            // Dynamically add all keys from the main object except for specific exclusions
            for (const key in item) {
                if (key !== 'inventoryName' && key !== 'inventoryCategory' && key !== 'advanceFields') {
                    if (item[key]?.value !== undefined) {
                        chartItem[key] = item[key].value;
                    } else {
                        chartItem[key] = item[key];
                    }
                }
            }

            // Add keys from advanceFields if they exist
            if (item.advanceFields) {
                for (const key in item.advanceFields) {
                    if (item.advanceFields[key]?.value !== undefined) {
                        chartItem[key] = Number(item.advanceFields[key].value);
                    } else {
                        chartItem[key] = item.advanceFields[key];
                    }
                }
            }

            return chartItem;
        });
    };


    const renderLegend = ({ payload }) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontFamily: colors.openSans,
                marginTop: '5px'
            }}>
                <div style={{
                    fontWeight: 'bold',
                    alignSelf: 'end',
                    display: 'flex',
                    justifyContent: 'end',
                    flex: '1'
                }}>
                    {
                        payload?.map((entry, index) => {
                            const field = findFieldByName(entry?.dataKey, fieldNames, text);
                            return (
                                <span style={{
                                    color: entry.color,
                                    padding: '0 5px'
                                }} key={`item-${index}`}>{field?.label}</span>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    const parsedData = parseDataForChart(formattedData);

    return (
        <div className={classnames({
            'flex mt-md': true,
            'flex-column': !isDesktop,
        })}
        >
            <Filters
                stringData={stringData}
                numberData={numberData}
                inventoryColumns={inventoryColumns}
                formattedData={formattedData}
                setFormattedData={setFormattedData}
                handleClear={() => handleClear(form)}
                setCombineKey={setCombineKey}
                combineKey={combineKey}
                setDataCheckedKeys={setDataCheckedKeys}
                dataCheckedKeys={dataCheckedKeys}
                handleCombineChange={handleCombineChange}
                initialGroupBy={initialGroupBy}
                setInitialGroupBy={setInitialGroupBy}
                setChartIsEditing={setChartIsEditing}
                formRef={formRef}
                isProject={isProject}
                spyValues={spyValues}
                analyticsWidgets={analyticsWidgets}
            />
            <ConditionalRender renderIf={!isEmpty(dataCheckedKeys)}>
                {/* <ConditionalRender renderIf={!isEmpty(data) && !isEmpty(spyValues?.selectProject) && spyValues?.chartType?.value === 'barChart'}> */}
                <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                        data={sortByKeyWithHighestValue(dataCheckedKeys, parsedData)}
                    >
                        <CartesianGrid strokeDasharray="3 2" />

                        {
                            isProject ? (
                                <XAxis dataKey={combineKey || 'projectCategory'} stroke={theme === 'dark' ? colors.white : colors.black} />
                            ) : (
                                <XAxis dataKey={combineKey || "inventoryName"} stroke={theme === 'dark' ? colors.white : colors.black} />
                            )
                        }

                        <YAxis type="number" domain={[0, maxNumber]} />
                        <Tooltip
                            content={({ payload }) => (
                                <CustomToolTip
                                    payload={payload}
                                    combineKey={combineKey}
                                    numberData={numberData}
                                    inventoryColumns={inventoryColumns}
                                    dataCheckedKeys={dataCheckedKeys}
                                    fieldNames={fieldNames}
                                    text={text}
                                />
                            )}
                        />
                        <Legend content={renderLegend} />
                        <ReferenceLine y={0} stroke={theme === 'dark' ? colors.white : colors.black} />
                        <Brush dataKey={combineKey || isProject ? 'projectCategory' : "inventoryName"} height={20} stroke={theme === 'dark' ? colors.secondary : colors.black} />

                        {dataCheckedKeys?.map((key, index) => {
                            const color = colorsArray[index % colorsArray?.length];

                            if (data?.some(d => key in d)) {
                                return <Bar
                                    key={key}
                                    dataKey={key}
                                    fill={color}
                                    onClick={combineKey && handleBarClick}
                                    cursor={combineKey && "pointer"}
                                >
                                </Bar>
                            } else {
                                return <Bar
                                    key={`${key}`}
                                    dataKey={`${key}`}
                                    fill={color}
                                    onClick={combineKey && handleBarClick}
                                    cursor={combineKey && "pointer"}
                                >
                                </Bar>
                            }
                        })}
                    </BarChart>

                </ResponsiveContainer>
            </ConditionalRender>
            {/* </ConditionalRender> */}
        </div >
    )
}

export default BarChartContainer
