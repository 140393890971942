import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import CallToAction from '../Core/CallToAction'
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import SearchInput from '../Core/Inputs/SearchInput';
import SelectableBox from '../Core/SelectableBox';
import { useLanguage } from '../context/LanguageContext';
import PaginatedData from '../Core/PaginatedData';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlusCircle, faClock, faEllipsis, faPause, faCheck } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import HoverPopup from '../Core/HoverPopup';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import { status } from '../utils/helperFunctions';


const StyledDiv = styled.div`
    width: 100%;
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .menu_wrapper {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 2px solid ${(props) => props.theme === 'dark' ? colors.primary : colors.backgroundSecondary};
        padding-top: 1rem;
        .callToAction__wrapper {
            margin-top: 1em;
            width: 33.3%;
        }
    }
`


const LandingProjectContainer = ({
    projects,
    user,
    setCollapsed
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const { theme } = useTheme();

    const [projectList, setProjectList] = useState(projects);
    const [pendingProjectList, setPendingProjectList] = useState(projects);
    const [inProgressProjectList, setInProgressProjectList] = useState(projects);
    const [pausedProjectList, setPausedProjectList] = useState(projects);
    const [completedProjectList, setCompletedProjectList] = useState(projects);
    const [showFilters, setShowFilters] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchPendingQuery, setSearchPendingQuery] = useState('');
    const [searchInProgressQuery, setSearchInProgressQuery] = useState('');
    const [searchPausedQuery, setSearchPausedQuery] = useState('');
    const [searchCompletedQuery, setSearchCompletedQuery] = useState('');

    useEffect(() => {
        setPendingProjectList(
            projects?.filter(x => x?.projectStatus === 'pending')
        )
        setInProgressProjectList(
            projects?.filter(x => x?.projectStatus === 'in-progress')
        )
        setPausedProjectList(
            projects?.filter(x => x?.projectStatus === 'paused')
        )
        setCompletedProjectList(
            projects?.filter(x => x?.projectStatus === 'completed')
        )

    }, [projects])

    const pendingProjects = pendingProjectList?.filter(x => x?.projectStatus === 'pending');
    const inProgressProjects = inProgressProjectList?.filter(x => x?.projectStatus === 'in-progress');
    const pausedProjects = pausedProjectList?.filter(x => x?.projectStatus === 'paused');
    const completedProjects = completedProjectList?.filter(x => x?.projectStatus === 'completed');


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchPendingChange = (e) => {
        setSearchPendingQuery(e.target.value);
    }

    const handleSearchInProgressChange = (e) => {
        setSearchInProgressQuery(e.target.value);
    }

    const handleSearchPausedChange = (e) => {
        setSearchPausedQuery(e.target.value);
    }

    const handleSearchCompletedChange = (e) => {
        setSearchCompletedQuery(e.target.value);
    }

    const [projectStatus, setProjectStatus] = useState('');

    useEffect(() => {
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredProjects = projects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setProjectList(filteredProjects);
        } else {
            setProjectList(projects);
        }
    }, [searchQuery, projects]);

    useEffect(() => {
        if (searchPendingQuery) {
            const query = searchPendingQuery.toLowerCase();
            const filteredProjects = pendingProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setPendingProjectList(filteredProjects);
        } else {
            setPendingProjectList(projects);
        }

        if (searchInProgressQuery) {
            const query = searchInProgressQuery.toLowerCase();
            const filteredProjects = inProgressProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setInProgressProjectList(filteredProjects);
        } else {
            setInProgressProjectList(projects);
        }

        if (searchPausedQuery) {
            const query = searchPausedQuery.toLowerCase();
            const filteredProjects = pausedProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setPausedProjectList(filteredProjects);
        } else {
            setPausedProjectList(projects);
        }

        if (searchCompletedQuery) {
            const query = searchCompletedQuery.toLowerCase();
            const filteredProjects = completedProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setCompletedProjectList(filteredProjects);
        } else {
            setCompletedProjectList(projects);
        }

    }, [projects, searchPendingQuery, searchInProgressQuery, searchPausedQuery, searchCompletedQuery]);

    const statuses = ['pending', 'in-progress', 'paused', 'completed'];


    return (
        <StyledDiv className='flex flex-column' theme={theme}>
            <div className='menu_wrapper'>
                <h4 className='mb-md'>{text?.projects?.details?.inventoryModal?.projects}</h4>
                <div className='flex items-center mb-sm w-100 flex-wrap'>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'pending')}>
                        <CallToAction
                            onClick={() => setProjectStatus('pending')}
                            text={status(text).find(x => x.value === 'pending')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faEllipsis}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'in-progress')}>
                        <CallToAction
                            onClick={() => setProjectStatus('in-progress')}
                            text={status(text).find(x => x.value === 'in-progress')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faClock}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'paused')}>
                        <CallToAction
                            onClick={() => setProjectStatus('paused')}
                            text={status(text).find(x => x.value === 'paused')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faPause}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'completed')}>
                        <CallToAction
                            onClick={() => setProjectStatus('completed')}
                            text={status(text).find(x => x.value === 'completed')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faCheck}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                        <CallToAction
                            onClick={() => {
                                navigate('/project/create')
                                if (!isDesktop) {
                                    setCollapsed(true)
                                }
                            }}
                            text={text?.projects?.landing?.createNew}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faPlusCircle}
                                />
                            }
                        />
                    </ConditionalRender>
                </div>
                <div className='flex flex-column align-start mt-md '>
                    <h5 className='mb-sm'>{text?.projects?.landing?.lastUpdated}</h5>
                    <ul className='w-100'>
                        {
                            projects
                                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt date in descending order
                                ?.slice(0, 1)
                                ?.map?.((x) => {
                                    return (
                                        <SelectableBox
                                            className='w-100'
                                            key={x?.id}
                                            to={`/project/details?id=${x?.id}`}
                                            isLink
                                            onClick={() => {
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                            }}>

                                            {x?.projectName}
                                        </SelectableBox>
                                    )
                                })
                        }
                    </ul>
                </div>
            </div>

            <ConditionalRender renderIf={['pending', 'in-progress', 'paused', 'completed'].includes(projectStatus)}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => setProjectStatus('')}
                    isOpen={!!projectStatus}
                    height={isDesktop ? '70%' : '100%'}
                    width={isDesktop ? '50%' : '100%'}
                    withPagination={{
                        paginationItems: statuses.filter((status) => {
                            if (status === 'pending') return pendingProjects?.length > 0;
                            if (status === 'in-progress') return inProgressProjects?.length > 0;
                            if (status === 'paused') return pausedProjects?.length > 0;
                            if (status === 'completed') return completedProjects?.length > 0;
                            return false;
                        }),
                        currentIndex: statuses.filter((status) => {
                            if (status === 'pending') return pendingProjects?.length > 0;
                            if (status === 'in-progress') return inProgressProjects?.length > 0;
                            if (status === 'paused') return pausedProjects?.length > 0;
                            if (status === 'completed') return completedProjects?.length > 0;
                            return false;
                        }).indexOf(projectStatus),
                        onNext: () => {
                            const validStatuses = statuses.filter((status) => {
                                if (status === 'pending') return pendingProjects?.length > 0;
                                if (status === 'in-progress') return inProgressProjects?.length > 0;
                                if (status === 'paused') return pausedProjects?.length > 0;
                                if (status === 'completed') return completedProjects?.length > 0;
                                return false;
                            });
                            const nextIndex = validStatuses.indexOf(projectStatus) + 1;
                            if (nextIndex < validStatuses.length) setProjectStatus(validStatuses[nextIndex]);
                        },
                        onPrevious: () => {
                            const validStatuses = statuses.filter((status) => {
                                if (status === 'pending') return pendingProjects?.length > 0;
                                if (status === 'in-progress') return inProgressProjects?.length > 0;
                                if (status === 'paused') return pausedProjects?.length > 0;
                                if (status === 'completed') return completedProjects?.length > 0;
                                return false;
                            });
                            const prevIndex = validStatuses.indexOf(projectStatus) - 1;
                            if (prevIndex >= 0) setProjectStatus(validStatuses[prevIndex]);
                        }
                    }}
                >
                    <div className='flex flex-column mt-md w-100'>
                        <h4 className='mb-md'>
                            {projectStatus === 'pending' && text?.projects?.landing?.pending}
                            {projectStatus === 'in-progress' && text?.projects?.landing?.inProgress}
                            {projectStatus === 'paused' && text?.projects?.landing?.paused}
                            {projectStatus === 'completed' && text?.projects?.landing?.completed}
                        </h4>
                        <div className='flex flex-column'>
                            <div className='flex flex-column filters mt-md'>
                                <SearchInput
                                    className='pb-md w-100'
                                    value={
                                        projectStatus === 'pending' ? searchPendingQuery :
                                            projectStatus === 'in-progress' ? searchInProgressQuery :
                                                projectStatus === 'paused' ? searchPausedQuery :
                                                    searchCompletedQuery
                                    }
                                    onChange={
                                        projectStatus === 'pending' ? handleSearchPendingChange :
                                            projectStatus === 'in-progress' ? handleSearchInProgressChange :
                                                projectStatus === 'paused' ? handleSearchPausedChange :
                                                    handleSearchCompletedChange
                                    }
                                    placeHolder={text?.employees?.landing?.search}
                                />
                            </div>
                        </div>
                        <PaginatedData
                            className='mt-md'
                            data={
                                projectStatus === 'pending' ? pendingProjects :
                                    projectStatus === 'in-progress' ? inProgressProjects :
                                        projectStatus === 'paused' ? pausedProjects :
                                            completedProjects
                            }
                            itemsPerPage={5}
                            noDataText={text?.employees?.landing?.notFound}
                            ItemComponent={({ item }) => (
                                <SelectableBox
                                    className='flex justify-space-between w-100'
                                    showLightColors
                                    isLink
                                    to={`/project/details?id=${item?.id}`}
                                    onClick={() => {
                                        if (projectStatus === 'pending') setSearchPendingQuery('');
                                        if (projectStatus === 'in-progress') setSearchInProgressQuery('');
                                        if (projectStatus === 'paused') setSearchPausedQuery('');
                                        if (projectStatus === 'completed') setSearchCompletedQuery('');
                                        setProjectStatus('');
                                        if (!isDesktop) setCollapsed(true);
                                        setShowFilters(false);
                                    }}
                                >
                                    <p>{item?.projectName}</p>
                                    <p>{moment(item?.updatedAt).format('MM/DD/YYYY')}</p>
                                </SelectableBox>
                            )}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>



        </StyledDiv>
    )
}

export default LandingProjectContainer