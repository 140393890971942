import React, { useEffect, useState, useCallback } from 'react';
import useInViewport from './useInViewport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useWebSocket } from '../context/WebSocketContext';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import ConditionalRender from '../Core/ConditionalRender';
import MessageWrapper from '../Tasks/MessageWrapper';

const UnreadDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.red};
    margin: 10px 0;
    text-align: center;
    color: ${colors.red};
    font-size: 12px;
    font-weight: bold;

    &::before {
        content: ${(props) => `"${props.text?.chat?.unread}"`};
    }
`;

const StyledWindow = styled.div`
.messages-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5em;
    color: #aaa;
    animation: fade-in 0.5s ease-in-out;
}

.chat-message {
    opacity: 0;
    animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

`

const ScrollDownButton = styled.button`
    position: sticky;
    bottom: 0;
    right: 0;
    background-color: ${colors.primary};
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    transition: visibility 0.2s, opacity 0.2s;
    z-index: 1;
`;

const ChatWindow = ({
    user,
    containerRef,
    firstUnreadRef,
    allRead,
    setAllRead,
    setFirstUnreadIndex,
    firstUnreadIndex,
    isProject = false,
    isClient = false
}) => {
    const {
        selectedConversationId,
        messages,
        markAsRead,
        setMessages,
        setConversations,
        setProjectConversations,
        conversationIdIsLoading,
        newMessageReceived,
        setNewMessageReceived,
        deleteMessage,
        updateMessage,
        updateMessageImage,
        updateReaction,
        createReply,
        isSendingMessage
    } = useWebSocket();
    const { isDesktop } = useScreenSize();
    const { theme } = useTheme();
    const { text } = useLanguage();

    // Ref to store the first unread message
    const [isAtBottom, setIsAtBottom] = useState(true);


    // Scroll to bottom
    const scrollToBottom = (e) => {
        e.preventDefault();
        containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    // Track if the user is at the bottom
    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
            setNewMessageReceived(false);
        }
    };

    // Attach scroll listener
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [containerRef]);

    useEffect(() => {
        const index = messages.findIndex((message) => message.isUnread);
        setFirstUnreadIndex(index);
        if (isAtBottom) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }

    }, [selectedConversationId, conversationIdIsLoading, newMessageReceived]);

    const visibleMessages = useInViewport(messages, containerRef);

    useEffect(() => {
        const index = messages.findIndex((message) => message.isUnread);
        setFirstUnreadIndex(index);
        setAllRead(index === -1);
    }, [messages]);

    // Mark visible messages as read
    useEffect(() => {
        if (!selectedConversationId || !visibleMessages.length) return;

        const timeoutId = setTimeout(() => {
            markAsRead(selectedConversationId, visibleMessages, user);
        }, 1000);

        // Clear timeout if effect re-runs
        return () => clearTimeout(timeoutId);
    }, [visibleMessages, selectedConversationId]);

    useEffect(() => {
        if (!selectedConversationId || !visibleMessages.length) return;

        const timeoutId = setTimeout(() => {
            const unreadMessageIds = messages
                .filter((message) => visibleMessages.includes(message._id) && message.isUnread)
                .map((message) => message._id);

            if (unreadMessageIds.length > 0) {
                // Mark as read via WebSocket
                markAsRead(selectedConversationId, unreadMessageIds, user);

                // Update local message state
                setMessages((prevMessages) =>
                    prevMessages.map((message) =>
                        unreadMessageIds.includes(message._id)
                            ? { ...message, isUnread: false }
                            : message
                    )
                );
            }

            // Check if all messages are now read
            const allMessagesRead = messages.every((message) => !message.isUnread);

            if (allMessagesRead) {
                setConversations((prevConversations) =>
                    prevConversations.map((conversation) =>
                        conversation._id === selectedConversationId && !conversation.projectId
                            ? { ...conversation, hasUnreadMessages: false }
                            : conversation
                    )
                );

                setProjectConversations((prevProjectConversations) =>
                    prevProjectConversations.map((conversation) =>
                        conversation._id === selectedConversationId && conversation.projectId
                            ? { ...conversation, hasUnreadMessages: false }
                            : conversation
                    )
                );

                setAllRead(true);
            }
        }, 1000);

        // Clear timeout if effect re-runs
        return () => clearTimeout(timeoutId);
    }, [visibleMessages, selectedConversationId, setMessages, setConversations, setProjectConversations]);

    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);

    const handleToggleDelete = (commentId) => {
        setSelectedCommentId(commentId);
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleSave = (value, id) => {
        updateMessage(id, value, user?._id, (updatedMessage, error) => {
            if (error) {
                console.error(error);
            }
        });
    };

    const handleDeleteComment = (id) => {
        deleteMessage(id);
    }

    const [replyImgs, setReplyImgs] = useState([]);

    const handleReplyImageChange = (imageData) => {
        setReplyImgs(imageData);
    }

    const handleReply = useCallback(
        ({ message, images }, parentId) => {
            const replyContent = message
            const replyImages = images || replyImgs;

            if (!isEmpty(replyImages) || (isEmpty(replyImages) && replyContent)) {
                createReply(
                    selectedConversationId,
                    parentId,
                    replyContent,
                    'text',
                    user?._id,
                    replyImages,
                    (reply, error) => {
                        if (reply) {
                            handleReplyImageChange([]);

                        } else {
                            console.error('Error sending reply:', error);
                        }
                    }
                );
            }
        },
        [replyImgs, user?._id, selectedConversationId]
    );


    const processImages = async (data) => {
        updateMessageImage(data, (updatedMessage, error) => {
            if (error) {
                console.error(error);
            }
        });

    };

    return (
        <StyledWindow
            className="chat-window scroll-container"
            ref={containerRef}
            style={{
                height: '80%',
                overflowY: 'auto',
                padding: isDesktop ? '1em' : '0',
            }}
        >
            <div
                className="flex flex-column relative"
                style={{
                    padding: '1em',
                    overflowY: 'auto',
                    width: '100%',
                    height: conversationIdIsLoading ? '100%' : 'auto',
                }}
            >
                <ConditionalRender isDark={isClient} count={1} renderIf={true} isLoading={conversationIdIsLoading}>
                    {conversationIdIsLoading ? (
                        <div className="messages-loader">Loading messages...</div>
                    ) : (
                        messages?.map((message, index) => (
                            <React.Fragment key={index}>
                                <MessageWrapper
                                    index={index}
                                    message={message}
                                    handleToggleDelete={handleToggleDelete}
                                    handleSave={handleSave}
                                    handleReply={handleReply}
                                    handleDeleteComment={handleDeleteComment}
                                    selectedCommentId={selectedCommentId}
                                    user={user}
                                    projectId={message?.projectId}
                                    toggleDeleteModal={toggleDeleteModal}
                                    processImages={processImages}
                                    name={'message'}
                                    isChat
                                    senderFullName={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                                    createdAt={message?.createdAt}
                                    profilePhoto={message?.sender?.profilePhoto}
                                    value={message?.messageContent}
                                    isProject={isProject}
                                    isClient={isClient}
                                    updateReaction={updateReaction}
                                    setReplyImgs={setReplyImgs}
                                    replyImgs={replyImgs}
                                    handleReplyImageChange={handleReplyImageChange}
                                    isSendingMessage={isSendingMessage}
                                />
                                {!allRead && index === firstUnreadIndex && (
                                    <UnreadDivider text={text} ref={firstUnreadRef} />
                                )}
                            </React.Fragment>
                        ))
                    )}
                </ConditionalRender>

            </div>
            <ScrollDownButton
                isVisible={!isAtBottom || newMessageReceived}
                onClick={scrollToBottom}
            >
                <FontAwesomeIcon icon={faCaretDown} />
            </ScrollDownButton>
        </StyledWindow>
    );
};

export default ChatWindow;
