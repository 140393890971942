import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import colors from '../../globalStyles.scss';
import useScreenSize from '../../context/useScreenSize';
import { useTheme } from '../../context/ThemeContext';

const StyledDatePicker = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .time-picker,
    label {
        display: flex;
        }
    .date-picker {
        position: relative;
        width: 100%;
        margin-left: 1em;
        background: none;
        height: 100%;
        border-bottom: 1px solid ${(props) => (props.theme === 'dark' ? colors.white : colors.blue)};
        input {
            height: 100%;
            border: none;
            font-family: ${colors.roboto};
            color: ${(props) => (props.theme === 'dark' ? colors.white : colors.black)};
            font-size: 1em;
        }
    }
    input[type="time"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: ${(props) =>
        props.theme === 'dark'
            ? 'invert(100%) brightness(100%)'
            : 'invert(30%) sepia(78%) saturate(5825%) hue-rotate(184deg) brightness(93%) contrast(95%)'};
    }
    .clear-icon {
        margin-left: 1em;
        cursor: pointer;
        color: ${colors.red};
    }
`;

const StandaloneDatePicker = ({
    label,
    initialValue,
    onChange,
    withTimePicker = false,
    isClearable = false,
    disabled = false,
    minDate = moment().format('YYYY-MM-DD'),
    onClear
}) => {
    const { isPhone } = useScreenSize();
    const { theme } = useTheme();

    const [selectedDate, setSelectedDate] = useState(initialValue ? moment(initialValue) : null);
    const [selectedTime, setSelectedTime] = useState(initialValue ? moment(initialValue) : moment.utc('00:00', 'HH:mm'));

    const handleDateChange = (e) => {
        const date = e.target.value ? moment(e.target.value) : null;
        setSelectedDate(date);
        combineDateAndTime(date, selectedTime);
    };

    const handleTimeChange = (e) => {
        const time = e.target.value ? moment(e.target.value, 'HH:mm') : null;
        setSelectedTime(time);
        combineDateAndTime(selectedDate, time);
    };

    const combineDateAndTime = (date, time) => {
        if (date && time) {
            const combinedDate = date.clone().set({
                hour: time.hour(),
                minute: time.minute(),
                second: time.second(),
            });
            if (onChange) {
                onChange(combinedDate.format('YYYY-MM-DDTHH:mm:ss'));
            }
        } else if (date) {
            if (onChange) {
                onChange(date.format('YYYY-MM-DD'));
            }
        } else {
            if (onChange) {
                onChange(null);
            }
        }
    };

    const handleClear = () => {
        setSelectedDate(null);
        setSelectedTime(moment.utc('00:00', 'HH:mm'));
        if (onChange) onChange(null);
        if (onClear) onClear();
    };

    useEffect(() => {
        if (initialValue) {
            const date = moment(initialValue);
            setSelectedDate(date);
            setSelectedTime(moment(initialValue));
        }
    }, [initialValue]);

    return (
        <StyledDatePicker theme={theme}>
            {label && <label>{label}</label>}
            <div className="date-picker">
                <input
                    type="date"
                    value={selectedDate ? selectedDate.format('YYYY-MM-DD') : ''}
                    onChange={handleDateChange}
                    disabled={disabled}
                    min={minDate}
                />
            </div>
            {withTimePicker && (
                <div className="time-picker">
                    <input
                        type="time"
                        value={selectedTime ? selectedTime.format('HH:mm') : ''}
                        onChange={handleTimeChange}
                        disabled={disabled}
                    />
                </div>
            )}
            {isClearable && selectedDate && (
                <FontAwesomeIcon
                    className="clear-icon"
                    icon={faClose}
                    onClick={handleClear}
                />
            )}
        </StyledDatePicker>
    );
};

export default StandaloneDatePicker;
