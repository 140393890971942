import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ConditionalRender from './ConditionalRender';

const StyledDiv = styled.div`
    display: flex;
    .wrapper {
        .name {
            font-weight: bold;
            margin-right: 1rem;
        }
    }
`;

const FieldWrapper = ({
    label = '',
    name,
    goto,
    noHr,
    className = '',
    labelClassName = '',
    nameClassName = '',
    style,
    callToAction,
    isLink = false,
    isMail = false,
    isPhone = false

}) => {
    return (
        <StyledDiv>
            <div className={`${className} wrapper`} style={style}>
                <span className={`name ${nameClassName}`}>
                    {name}
                </span>
                <ConditionalRender renderIf={goto}>
                    <Link to={goto}>
                        {label}
                    </Link>
                </ConditionalRender>
                <ConditionalRender renderIf={!goto}>
                    <ConditionalRender renderIf={isMail}>
                        <a
                            href={`mailto:${label}`}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {label}
                        </a>
                    </ConditionalRender>
                    <ConditionalRender renderIf={isPhone}>
                        <a
                            href={`tel:${label}`}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {label}
                        </a>
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isMail && !isPhone && !isLink}>
                        <span className={`label mr-md ${labelClassName}`}>
                            {label}
                        </span>
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isMail && !isPhone && isLink}>
                        <a
                            href={
                                typeof label === 'string' && (label.startsWith('http://') || label.startsWith('https://'))
                                    ? label
                                    : `https://${label}`
                            }
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            {label}
                        </a>
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender renderIf={callToAction}>
                    {callToAction}
                </ConditionalRender>
                <ConditionalRender renderIf={!noHr}>
                    <hr />
                </ConditionalRender>
            </div>
        </StyledDiv>
    );
};

export default FieldWrapper;
