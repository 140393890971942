import colors from '../globalStyles.scss'
import {
    one,
    twoVideo,
    oneVideo,
    three_one,
    three_two,
    three_three,
    four_one,
    four_two,
    four_three,
    four_four,
    five_one,
    five_two,
    five_three,
    six_one,
    six_two,
    six_three,
    six_four,
    six_five,
    six_six,
    seven_one,
    seven_two,
} from './flow_en';
import CallToAction from '../Core/CallToAction';


export const pages_es_sm = [
    {
        id: "page1",
        index: 0,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right'
                        }}
                    >
                        {"Crear un Proyecto"}
                    </h1>
                    <p className='mt-md' style={{ textAlign: 'right' }}>
                        {'Donde comienza tu viaje con Ardidflow, y el rendimiento de tu negocio crece'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/7",
                    }}
                >
                    <p
                        className='mt-md'
                    >
                        {
                            "Al crear un proyecto, puedes agregar un nombre, categoría, ubicación y descripción, asignar o crear un equipo y líderes de equipo, seleccionar o crear un cliente, actualizar imágenes y archivos, y establecer atributos personalizados adaptados a tus necesidades. Además, la opción de incluir gestión de inventarios dentro de los proyectos asegura que todo se mantenga centralizado y fácilmente accesible."
                        }
                    </p>
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            pointerEvents: "none",
                        }}
                        src={oneVideo}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        playsInline // Evitar pantalla completa en móvil
                    ></video>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page2" },
    },
    {
        id: "page2",
        index: 1,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/7",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Detalles del Proyecto'}
                    </h1>
                    <p>
                        {'Puedes actualizar los detalles del proyecto en cualquier momento y actualizar el estado para realizar un seguimiento del progreso hasta la finalización.'}
                    </p>
                    <video
                        style={{
                            width: "100%",
                            maxHeight: "50%",
                            objectFit: "contain",
                            pointerEvents: "none",
                            marginTop: '1em',
                            marginBottom: '1em',
                        }}
                        src={twoVideo}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        playsInline
                    />
                    <div
                    >
                        <h2
                            style={{ textAlign: 'right' }}
                            className='mb-md'
                        >
                            Gestiona tus proyectos fácilmente desde la página de detalles:
                        </h2>
                        <ul style={{ textAlign: 'right' }}>
                            <li style={{ textAlign: 'right' }}>Crea y rastrea tareas.</li>
                            <li style={{ textAlign: 'right' }}>Guarda y envía propuestas a tus clientes.</li>
                            <li style={{ textAlign: 'right' }}>Crea y rastrea inventarios.</li>
                            <li style={{ textAlign: 'right' }}>Leer mensajes de chat relacionados con el proyecto para obtener actualizaciones rápidas.</li>
                            <li style={{ textAlign: 'right' }}>Consulta tu calendario para todos los eventos del proyecto.</li>
                            <li style={{ textAlign: 'right' }}>Revisa y actualiza el equipo, y observa sus registros diarios y progreso.</li>
                            <li style={{ textAlign: 'right' }}>Crea y actualiza la información del cliente.</li>
                            <li style={{ textAlign: 'right' }}>Crea atributos personalizados con campos avanzados (presupuesto, notas, fechas, etc.).</li>
                            <li style={{ textAlign: 'right' }}>Accede a todas tus imágenes y archivos en un solo lugar.</li>
                            <li style={{ textAlign: 'right' }}>Monitorea las actualizaciones del proyecto a través del registro histórico.</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page1", down: "page3" },
    },
    {
        id: "page3",
        index: 2,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Tareas'}
                    </h1>
                    <p>
                        {'Las tareas serán el motor que impulsa tu proyecto, manteniendo todo funcionando sin problemas.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_two}
                            alt="Asignar empleados"
                        />
                        <h2 className='ml-md' style={{ textAlign: 'left' }}>
                            {'Asigna empleados para que todos conozcan su rol en el proyecto'}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "3/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <h2 className='mt-md mr-md' style={{ textAlign: 'right' }}>
                            {'Establece prioridades y plazos para mantenerte en el camino'}
                        </h2>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_three}
                            alt="Establecer prioridades y plazos"
                        />
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "5/6",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='mt-md'>
                        <h2 className='mb-md' style={{ textAlign: 'right' }}>
                            {'& Más...'}
                        </h2>
                        <ul style={{ textAlign: 'right' }}>
                            <li style={{ textAlign: 'right' }}>Crea subtareas para dividir el trabajo en partes manejables.</li>
                            <li style={{ textAlign: 'right' }}>Cada tarea puede incluir listas de pendientes para detallar los pasos o detalles necesarios.</li>
                            <li style={{ textAlign: 'right' }}>Agrega comentarios/imágenes para una mejor comunicación.</li>
                            <li style={{ textAlign: 'right' }}>Clona tareas para una creación más rápida de trabajos similares.</li>
                            <li style={{ textAlign: 'right' }}>Recibe notificaciones/calendario/correos electrónicos como recordatorios cuando se acerque el plazo de una tarea o se haya realizado un cambio.</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { up: "page2", left: "page4" },
    },
    {
        id: "page4",
        index: 3,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right',
                        }}
                    >
                        {'Seguimiento de Tiempo, Registros y Gestión'}
                    </h1>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='flex'>
                        <img style={{ objectFit: 'cover' }} width={'50%'} height={'50%'} src={four_two} alt="Time tracking" />
                        <img style={{ objectFit: 'cover' }} width={'50%'} src={four_one} alt="Time tracking" />
                    </div>
                    <p
                        className='mt-md'
                    >
                        {'El seguimiento de tiempo ayudará a monitorear las horas de trabajo de los empleados, asegurando una mejor gestión del tiempo y eficiencia en las tareas.'}
                    </p>
                    <p className='mt-md mb-md'>
                        {'Los registros diarios pueden actualizarse para reflejar el progreso, ayudando al equipo a mantenerse alineado y mejorando la colaboración general.'}
                    </p>
                </div>
                <div

                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/7",
                    }}
                >
                    <img width={'80%'} src={four_four} alt="Seguimiento de tiempo" />

                    <div className='flex justify-between mt-md'>
                        <p
                            style={{ textAlign: 'left' }}
                        >
                            {'Automáticamente hará un seguimiento del estado de las tareas al registrar entradas y salidas, haciendo que el seguimiento de tiempo sea más preciso y confiable.'}
                        </p>
                        <p
                            style={{ textAlign: 'right' }}
                        >
                            {'Tendrás la posibilidad de actualizar las entradas de tiempo en cualquier momento a través de la función de gestión de registros para ti y tu equipo.'}
                        </p>
                    </div>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page3", down: "page5" },
    },
    {
        id: "page5",
        index: 4,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "5/7",
                        gridColumn: "2/8",
                        color: colors.white,
                    }}
                >
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {'Crea, actualiza o elimina y gestiona clientes fácilmente, manteniéndolos informados a través del portal del cliente. Los clientes pueden ver detalles de proyectos, seguir el progreso, aprobar/rechazar y firmar propuestas con solo unos clics.'}
                    </p>
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {
                            'Comunícate con los clientes a través del chat y obtén retroalimentación instantánea sobre las actualizaciones del proyecto, tareas y más.'
                        }
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Guarda borradores de propuestas, envíalos directamente a los clientes por correo electrónico con un PDF adjunto, y recibe notificaciones instantáneas cuando cambie el estado de una propuesta.'}
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Realiza ajustes rápidos a las propuestas y reenvíalas sin esfuerzo para una mayor rapidez y mejor colaboración.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/8",
                    }}
                >
                    <img width={'100%'} src={five_one} alt="Seguimiento de tiempo" />
                    <img width={'100%'} src={five_two} alt="Seguimiento de tiempo" />
                    <h1
                        className="mt-md"
                        style={{
                            fontSize: "2em",
                            textAlign: 'center',
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Clientes y Propuestas'}
                    </h1>
                </div>
                <div
                    style={{
                        gridRow: "3/6",
                        gridColumn: "2/8",
                    }}
                >
                    <video
                        style={{
                            width: "100%",
                            minHeight: "50%",
                            objectFit: "contain",
                            pointerEvents: "none",
                        }}
                        src={five_three}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        playsInline
                    ></video>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { up: "page4", left: "page6" },
    },
    {
        id: "page6",
        index: 5,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Chat, Calendario y Notificaciones'}
                    </h1>
                    <img width={'100%'} src={six_four} alt="Analíticas" />
                    <p className="mt-md">
                        {
                            'El calendario te permite programar reuniones, establecer recordatorios y realizar un seguimiento de cuándo se deben completar las tareas, asegurando que cumplas con todos los plazos y compromisos del proyecto.'
                        }
                    </p>
                    <p className="mt-md">
                        {
                            'Usa el calendario para coordinar horarios, gestionar eventos y asegurarte de que se cumplan las fechas límite de las tareas, todo en una interfaz intuitiva.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_five} alt="Notificaciones" />
                    <p>
                        {
                            'Recibe notificaciones instantáneas sobre actualizaciones de proyectos, fechas límite de tareas, mensajes y más para mantenerte informado y mejorar la colaboración del equipo.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "5/7",
                    }}
                >
                    <p>
                        {
                            'La función de chat está diseñada para una comunicación fluida entre los miembros del equipo y con los clientes. Úsala para discutir proyectos, compartir actualizaciones y colaborar de manera efectiva. Ya sea sobre el progreso del proyecto, la coordinación de tareas o consultas generales, este chat asegura que todos estén conectados e informados.'
                        }
                    </p>
                    <img width={'100%'} src={six_six} alt="Chat" />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page5", down: "page7" },
    },
    {
        id: "page7",
        index: 6,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 50px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 50px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Analíticas, Ayuda de IA y Gestión de Empleados'}
                    </h1>
                    <p>
                        {
                            'La función de Gestión de Empleados simplifica la supervisión del personal con una plataforma centralizada para gestionar detalles como títulos de trabajo, salarios y habilidades. Los empleados pueden ver información clave en sus perfiles, mientras que los gerentes pueden rastrear certificaciones, rendimiento y más. Una base de datos robusta permite búsquedas rápidas de empleados para una mejor toma de decisiones, y cualquier persona en la empresa puede encontrar fácilmente información de contacto o colaborar con colegas.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_three} alt="Ayuda de IA" />
                    <p className="mt-md" style={{ textAlign: 'right' }}>
                        {
                            'Obtén una visión clara del rendimiento de tu proyecto con analíticas avanzadas y toma decisiones basadas en datos para mejorar la eficiencia empresarial.'
                        }
                    </p>
                    <video
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        src={six_one}
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        playsInline
                    ></video>
                </div>
                <div
                    style={{
                        gridRow: "3/6",
                        gridColumn: "5/7",
                    }}
                >
                    <img width={'100%'} src={seven_two} alt="empleado" />
                    <p className="mt-md">
                        {
                            'La Ayuda de IA es tu asistente para responder preguntas sobre tareas, inventario, proyectos y cómo usar la aplicación. Obtén rápidamente la ayuda que necesitas para navegar por las funciones y resolver problemas.'
                        }
                    </p>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { down: "page8", up: "page6" },
    },

    {
        id: "page8",
        index: 6,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "3",
                        gridColumn: "3/6",
                        color: colors.white,
                    }}
                >
                    <CallToAction
                        className="mt-md"
                        btnWidth="auto"
                        btnHeight="auto"
                        btnStyle={{
                            padding: '0.3em 1em',
                            border: `2px solid ${colors.blue}`,
                            color: colors.white,
                            hoverBackgroundColor: colors.lightblue,
                            fontSize: '2.5em',
                        }}
                        text="Comenzar"
                        onClick={() => (window.location.href = '/register')}
                    />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { up: "page7" },
    }

];

export default pages_es_sm;
