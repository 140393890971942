import React, { useCallback, useMemo } from 'react';
import { composeValidators, isRequired } from '../utils/validators';
import MultiFields from '../Core/MultiFields';
import FieldWithValidation from '../Core/FieldWithValidation';
import CheckBox from '../Core/Inputs/CheckBox';
import { FormSpy, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';

const RenderAdvanceFields = React.memo(({
    updatedKeys,
    newAdvanceFields,
    fieldNames,
    setIsEditing,
    formRef,
    disabled,
    inventory,
    inventoryColumns,
    removeField
}) => {
    const { isDesktop, isTablet } = useTheme();
    const { text } = useLanguage();

    const categoryValidator = composeValidators(
        isRequired(text?.inventory?.details?.form?.validations?.required)
    );

    const renderLabelMap = useMemo(() => {
        const map = new Map();
        fieldNames?.forEach((x) => {
            map.set(x.name?.toLowerCase(), x.label);
        });
        return map;
    }, [fieldNames]);

    const allFields = useMemo(() => {
        return updatedKeys ? [...newAdvanceFields, ...updatedKeys] : [];
    }, [updatedKeys, newAdvanceFields]);

    const handleRemoveField = useCallback((field, id) => {
        removeField(field, id);
    }, [removeField]);

    const renderAdvanceFields = () => {

        return allFields?.map((field) => {
            const renderLabel = renderLabelMap.get(field.fieldValue?.toLowerCase());
            return (
                <div className='flex flex-column mt-md' key={field.id}>
                    <div className='flex wrapper'>
                        {field.fieldName === 'number' ? (
                            <div className='flex flex-column w-100'>
                                <MultiFields
                                    name={`advanceFields.${field?.fullFieldName}.value`}
                                    label={renderLabel?.label || field.fieldLabel}
                                    component={field?.fieldName}
                                    min={field?.fieldName === 'number' && { value: '0' }}
                                    setIsEditing={setIsEditing}
                                    initialValue={Number(field?.value?.value)}
                                    form={formRef}
                                    width={field?.fieldName === 'number' && isDesktop ? 30 : isTablet ? 50 : 100}
                                    block
                                    edit
                                    disabled={disabled}
                                />

                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) => {
                                        const fieldData = values?.advanceFields?.[field?.fullFieldName];
                                        const hasValue = fieldData?.value !== undefined;
                                        const isChecked = fieldData?.notification?.isChecked;

                                        return (
                                            <>
                                                {(hasValue || hasValue === 0) && (
                                                    <Field name={`advanceFields.${field?.fullFieldName}.notification.isChecked`}>
                                                        {({ input }) => (
                                                            <CheckBox
                                                                className='mt-md'
                                                                {...input}
                                                                name={`advanceFields.${field?.fullFieldName}.notification.isChecked`}
                                                                type="checkbox"
                                                                checked={input.value}
                                                                onChange={(value) => {
                                                                    input.onChange(value);
                                                                    setIsEditing(true);
                                                                }}
                                                                initialValue={field?.notification?.isChecked}
                                                                disabled={disabled}
                                                                label={text?.inventory?.details?.form?.nodifyMe}
                                                                checkStyle={{
                                                                    background: colors.blue
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                                {isChecked && (hasValue || hasValue === 0) ? (
                                                    <div className='flex items-center'>
                                                        <p className='mr-md'>{text?.inventory?.details?.form?.numberBellow}</p>
                                                        <FieldWithValidation
                                                            validate={categoryValidator}
                                                            disabled={disabled}
                                                            setIsEditing={setIsEditing}
                                                            className='field-style'
                                                            name={`advanceFields.${field?.fullFieldName}.notification.value`}
                                                            component="input"
                                                            initialValue={field?.notification?.value}
                                                            type="number"
                                                            min="0"
                                                            label={text?.inventory?.details?.form?.quantity}
                                                        />
                                                    </div>
                                                ) : null}
                                            </>
                                        );
                                    }}
                                </FormSpy>
                            </div>
                        ) : (
                            <MultiFields
                                name={`advanceFields.${field?.fullFieldName}`}
                                label={renderLabel?.label || field.fieldLabel}
                                component={field?.fieldName}
                                disabled={disabled}
                                fieldName={field?.fullFieldName}
                                setIsEditing={setIsEditing}
                                form={formRef}
                                suggestionData={inventory}
                                autoComplete
                                initialValue={field?.value}
                                fields={inventoryColumns}
                                block
                                edit
                                withTimePicker
                            />
                        )}
                        {!disabled && (
                            <button
                                className='delete'
                                style={{
                                    display: 'flex',
                                    marginLeft: '15px',
                                    height: '20%'
                                }}
                                type="button"
                                onClick={() => handleRemoveField(field, field.id)}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        )}
                    </div>
                    <hr />
                </div>
            );
        });
    };

    return renderAdvanceFields()
});

export default RenderAdvanceFields;
