import request from '../request';

export const getActionByFeatureAndStatus = async (params) => {
    try {
        const { data, status } = await request('GET', '/action-tracking/actions', {
            params,
        });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to fetch action: ${error.message}`);
    }
};

export default getActionByFeatureAndStatus;