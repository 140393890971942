import { useEffect, useState } from 'react';

const useInViewport = (messages, containerRef) => {
    const [visibleMessages, setVisibleMessages] = useState([]);

    useEffect(() => {
        if (!containerRef.current || !messages) return;

        const observer = new IntersectionObserver(
            (entries) => {
                const visible = entries
                    .filter((entry) => entry.isIntersecting)
                    .map((entry) => entry.target.getAttribute('data-message-id'));
                setVisibleMessages((prev) => [...new Set([...prev, ...visible])]);
            },
            { root: containerRef.current, threshold: 0.1 }
        );

        // Observe each message in the list
        messages.forEach((message) => {
            const element = document.querySelector(`[data-message-id="${message._id}"]`);
            if (element) observer.observe(element);
        });

        return () => observer.disconnect();
    }, [messages, containerRef]);

    return visibleMessages;
};

export default useInViewport;
