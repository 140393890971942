import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { FormSpy, Field } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import MultiFields from '../Core/MultiFields';
import FieldWithNoValidation from '../Core/FieldWithNoValidation';
import { noSpecialChars, noNumbers, composeValidators, requiredNumber } from '../utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../Core/Loader';
import FieldWithValidation from '../Core/FieldWithValidation';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Info from '../Core/Info';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import CustomField from '../Core/CustomField';
import ConfirmModal from '../Core/ConfirmModal';
import SelectInput from '../Core/SelectInput';
import CheckBox from '../Core/Inputs/CheckBox';
import { isEmpty } from 'lodash';
import Modal from "react-modal";
import CarouselWithCustomArrows from '../Core/CarouselWithCustomArrows';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTheme } from '../context/ThemeContext'
import Wrapper from '../Core/Wrapper';
import CallToAction from '../Core/CallToAction';
import inputTypeOptions from './inputTypeOptions';
import { capitalize } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import { toCamelCase, compareStrings } from '../utils/helperFunctions';
import { searchFieldRecommendations } from '../utils/calls/searchFieldRecommendations'
import { lengthUnits, weightUnits, volumeUnits } from '../utils/helperFunctions';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import SelectableBox from '../Core/SelectableBox';
import classnames from 'classnames';

const StyledExtraFields = styled.div`

    &.desktop {
        .ExtraFields {
            display: flex;
        }
        .ExtraFields-wrapper-fields {
            padding-right: 1em;
        }
    }

    .ExtraFields-wrapper-1 {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ExtraFields-wrapper-inputs {
            flex: 1;
            .ExtraFields-wrapper-input {
                display: flex;
                padding: 15px;
                button.delete {
                    color: ${colors.red};
                    display: flex;
                    height: 20%;
                }
            }
        }

        .ExtraFields-wrapper-fields {
            display: flex;
            flex-direction: column;
            padding-bottom: 1em;
            .error {
                align-self: end;
            }
        }
    }
    .ExtraFields-wrapper-2 {
        flex: 2;
        display: flex;
        justify-content: space-around;
        .ExtraFields-wrapper-imgs {
            flex: 1;
            width: 100%;
            height: 100%;
            padding: 10px;
            background: ${colors.darkGray};
            margin: 0 5px;
            .ExtraFields-wrapper-img {
                position: relative;
                display: inline-block;
                margin: 5px;
                cursor: pointer;
            }
            img {
                maxWidth: 100%;
                 max-height: 250px;
                 object-fit: cover;
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -10px;
                right: -10px;
                background: ${colors.red};
                border-radius: 50%;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: ${colors.white};
                font-size: 0.8rem;
                border: none;
                cursor: pointer;
            }
        }
        .ExtraFields-wrapper-upload {
            background: ${colors.black};
            color: ${colors.white};
            box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
            padding: 5px;
            width: 150px;
            max-height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background: ${colors.lightGray};
                color: ${colors.black};
                transition: all 0.2s ease 0s;
            }
            span {
                display: block;
                font-family: ${colors.openSans};
            }
        }
    }
`;

const StyledDiv = styled.div`
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1%;
    margin-top: 1em;
}
.dropzone {
    display: flex;
    flex-direction: column;
    margin: 0.5em 0;
    padding: 1em;
    background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    &:hover {
        background: ${colors.lightGray};
    }
    span {
        font-family: ${colors.roboto};
        color: ${props => props.theme === 'dark' ? colors.black : colors.black};

    }
}
.image-wrapper {
    position: relative;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: 100%;
        height: 200px;
        cursor: pointer;
    }
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        background: ${colors.red};
        svg {
            color: ${colors.white};
            font-size: 1rem;
        }
    }

    /* Ensure all images maintain the same width */
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        grid-column: span 1;
    }
}
`;

const ExtraFields = ({
    id,
    productIndex,
    fields = [],
    setFields = () => [],
    onDrop,
    uploadedImages,
    withTimePicker,
    setUploadedImages,
    removeImage,
    resetChild,
    resetChildDone,
    form,
    fieldNames,
    setFieldNameAndValues,
    fieldNameAndValues,
    setColumnNameAndValues,
    onFieldRemove,
    setDuplicatedFields,
    imageError,
    companyIndustry
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const { inventory, inventoryColumns } = useContext(GlobalContext)

    const [newFieldName, setNewFieldName] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldError, setFieldError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const currentUploadedImages = uploadedImages[productIndex] || [];

    const [selectedFieldType, setSelectedFieldType] = useState('Field Type'); // New state for selected input type

    const addField = (e) => {
        // prevents field creatiion without selecting a type of input
        if (!isEmpty(newFieldName) && (selectedFieldType && selectedFieldType !== 'Field Type')) {
            if (fields.some((field) => compareStrings(field.fullName.split('-')[1], newFieldName?.value))) {
                setFieldError(text?.inventory?.create?.form?.extraFields?.validations?.exists);
            } else {
                const newId = uuidv4(); // Generate a new UUID
                const newField = {
                    id: newId,
                    fullName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                    label: capitalize(newFieldName?.label),
                    fieldLabel: capitalize(newFieldName?.label),
                    name: toCamelCase(newFieldName?.value),
                    inputType: selectedFieldType
                };

                setFields([
                    ...fields,
                    newField
                ]);
                setDuplicatedFields((prevFields) => {
                    // If the product id exists in the state, append the new field to it, otherwise create a new array
                    const updatedFieldsForProduct = prevFields[id] ? [...prevFields[id], newField] : [newField];

                    return { ...prevFields, [id]: updatedFieldsForProduct };
                });
                setFieldNameAndValues([
                    ...fieldNameAndValues,
                    {
                        id: newId,
                        label: capitalize(newFieldName?.label),
                        fieldLabel: capitalize(newFieldName?.label),
                        name: toCamelCase(newFieldName?.value),
                        fullName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                        inputType: selectedFieldType
                    }
                ])
                setColumnNameAndValues((prevColumnNameAndValues) => {
                    const maxSortOrder = prevColumnNameAndValues.reduce(
                        (max, column) => {
                            const sortOrder = parseInt(column?.sortOrder, 10);
                            return sortOrder > max ? sortOrder : max;
                        },
                        0
                    );
                    const newSortOrder = maxSortOrder + 1;

                    return [
                        ...prevColumnNameAndValues,
                        {
                            id: newId,
                            label: capitalize(newFieldName?.label),
                            fieldLabel: capitalize(newFieldName?.label),
                            fieldName: toCamelCase(newFieldName?.value),
                            fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                            isActive: false,
                            sortOrder: newSortOrder.toString(), // Convert back to string
                        },
                    ];
                });

                setNewFieldName({});
                setFieldError('');
                setErrorMessage('');
                setSelectedFieldType('Field Type');
                form.change(`products[${productIndex}].inputType`, null)
                form.change(`products[${productIndex}].fieldName`, null);
            }
        } else {
            if (isEmpty(newFieldName)) {
                setErrorMessage(text?.inventory?.create?.form?.extraFields?.validations?.fieldName)
            } else {
                setErrorMessage(text?.inventory?.create?.form?.extraFields?.validations?.fieldType)
            }
            return null
        }

    };

    const handleInputChange = (e) => {
        setSelectedFieldType(e?.value);
    }

    const [fieldRecommendationText, setFieldRecommendationText] = useState('');
    const [fieldRecommendationIsLoading, setFieldRecommendationIsLoading] = useState(false);

    const handleFieldRecommendation = (e) => {
        setFieldRecommendationText(e.target.value);
    }

    const resetAdvanceFields = () => {
        setFields([])
        setFieldNameAndValues([])
        setColumnNameAndValues([])
        setDuplicatedFields({})
        form.change(`products[${productIndex}].advanceFields`, {})

    }

    const [fieldsRecommendationIsOpen, setFieldsRecommendationIsOpen] = useState(false);

    const openFieldsRecommendation = () => {
        setFieldsRecommendationIsOpen(!fieldsRecommendationIsOpen);
    }


    const searchFieldRecommendation = async () => {
        resetAdvanceFields()
        let newfields = []
        const { inventoryName, inventoryCategory, inventoryQuantity } = form.getState()?.values?.products[productIndex]
        if (!isEmpty(inventoryName) && !isEmpty(inventoryCategory?.value) && inventoryQuantity?.value !== 0) {
            setFieldRecommendationIsLoading(true)
            try {
                const { data, status } = await searchFieldRecommendations({
                    inventoryName,
                    inventoryCategory,
                    inventoryQuantity: inventoryQuantity?.value || 0,
                    companyIndustry,
                    fieldRecommendationText
                }, 1500)
                if (status === 200) {
                    form.change(`products[${productIndex}].generateFieldsRecommendations`, null)
                    setFieldRecommendationText('')
                    setFieldRecommendationIsLoading(false)
                    setFieldsRecommendationIsOpen(false)

                    data.map((field) => {
                        const newId = uuidv4(); // Generate a new UUID
                        const newField = {
                            id: newId,
                            fullName: `${field?.fieldType}-${toCamelCase(field?.fieldName)}`,
                            label: capitalize(field?.fieldLabel),
                            name: toCamelCase(field?.fieldName),
                            inputType: field?.fieldType
                        }
                        newfields.push(newField)
                        setDuplicatedFields((prevFields) => {
                            // If the product id exists in the state, append the new field to it, otherwise create a new array
                            const updatedFieldsForProduct = prevFields[id] ? [...prevFields[id], newField] : [newField];

                            return { ...prevFields, [id]: updatedFieldsForProduct };
                        });
                        setFieldNameAndValues((prevFieldNameAndValues) => {
                            return [
                                ...prevFieldNameAndValues,
                                {
                                    id: newId,
                                    fullName: `${field?.fieldType}-${toCamelCase(field?.fieldName)}`,
                                    label: capitalize(field?.fieldLabel),
                                    name: toCamelCase(field?.fieldName),
                                    inputType: field?.fieldType
                                }
                            ];
                        })
                        setColumnNameAndValues((prevColumnNameAndValues) => {
                            const maxSortOrder = prevColumnNameAndValues.reduce(
                                (max, column) => {
                                    const sortOrder = parseInt(column?.sortOrder, 10);
                                    return sortOrder > max ? sortOrder : max;
                                },
                                0
                            );
                            const newSortOrder = maxSortOrder + 1;

                            return [
                                ...prevColumnNameAndValues,
                                {
                                    id: newId,
                                    fieldLabel: capitalize(field?.fieldLabel),
                                    fieldName: toCamelCase(field?.fieldName),
                                    fullFieldName: `${field?.fieldType}-${toCamelCase(field?.fieldName)}`,
                                    isActive: false,
                                    sortOrder: newSortOrder.toString(),
                                },
                            ];
                        });
                        if (field.fieldType === 'number') {
                            form.change(`products[${productIndex}].advanceFields.${field?.fieldType}-${toCamelCase(field?.fieldName)}.value`, field?.fieldValue)
                        } else if (field.fieldType === 'length' || field.fieldType === 'weight' || field.fieldType === 'volume') {
                            const findUnit = (fieldType) => ({ 'length': lengthUnits(text), 'volume': volumeUnits(text), 'weight': weightUnits(text) }[fieldType]?.find(u => u.value === field?.fieldValueUnit) || null);

                            form.change(`products[${productIndex}].advanceFields.${field?.fieldType}-${toCamelCase(field?.fieldName)}-unit`, findUnit(field?.fieldType))
                            form.change(`products[${productIndex}].advanceFields.${field?.fieldType}-${toCamelCase(field?.fieldName)}`, field?.fieldValue)
                        } else {
                            form.change(`products[${productIndex}].advanceFields.${field?.fieldType}-${toCamelCase(field?.fieldName)}`, field?.fieldValue)
                        }

                    })
                    setFields(newfields)
                } else {
                    setFieldRecommendationIsLoading(false)
                }
            } catch (error) {
                console.error(error)
                setFieldRecommendationIsLoading(false)
            }
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, productIndex),
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });


    const removeField = (form, field) => {
        const fieldToRemove = fields.find(x => x.id === field?.id);

        // Remove the field from the fields state
        setFields(fields.filter(x => x.id !== field?.id));

        // Remove the field from the fieldNameAndValues state
        setFieldNameAndValues(fieldNameAndValues.filter(x => x.fullName !== field?.fullName));

        // Adjust column names and values
        setColumnNameAndValues((prevColumnNameAndValues) => {
            const removedColumn = prevColumnNameAndValues.find((column) => column.id === field?.id);
            const removedSortOrder = parseInt(removedColumn?.sortOrder, 10);

            const maxSortOrder = prevColumnNameAndValues.reduce((max, column) => {
                const sortOrder = parseInt(column?.sortOrder, 10);
                return sortOrder > max ? sortOrder : max;
            }, 0);

            let adjustedColumnNameAndValues = prevColumnNameAndValues;

            if (removedSortOrder < maxSortOrder) {
                adjustedColumnNameAndValues = prevColumnNameAndValues.map((column) => {
                    if (parseInt(column?.sortOrder, 10) > removedSortOrder) {
                        return {
                            ...column,
                            sortOrder: (parseInt(column?.sortOrder, 10) - 1).toString(),
                        };
                    }
                    return column;
                });
            }

            return adjustedColumnNameAndValues.filter((column) => column.id !== field?.id);
        });

        // Access the current form values
        const currentFormValues = JSON.parse(JSON.stringify(form.getState().values)); // Deep cloning

        if (currentFormValues && currentFormValues.products) {
            // Find the specific product
            if (productIndex !== -1 && currentFormValues?.products[productIndex]?.advanceFields) {
                // Delete the specific field from advanceFields
                delete currentFormValues?.products[productIndex]?.advanceFields[fieldToRemove?.fullName];
                // Now, we update the entire form with currentFormValues
                form.change('products', currentFormValues?.products);
            }
        }

        onFieldRemove(id, field);

    };


    const handleOnChange = (e) => {
        const eValue = e?.value.replace(/\s/g, "");
        const eLabel = e?.label;

        setNewFieldName({
            label: eLabel,
            value: eValue
        });
        if (fields.some((field) => compareStrings(field.fullName.split('-')[1], eValue))) {
            setFieldError(text?.inventory?.create?.form?.extraFields?.validations?.exists);
        } else {
            setFieldError(
                // noSpaces(e?.value) ||
                noNumbers(e?.value) ||
                noSpecialChars(e?.value)
            );
        }
    };

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {

        // resets all fields in the form as well as dependencies
        if (resetChild) {
            setNewFieldName({});
            resetChildDone();
            setFields([]);
            setUploadedImages({})
        }
    }, [resetChild, resetChildDone]);

    return (
        <StyledExtraFields
            className={classnames({
                'mt-md': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
        >
            <>
                <div className='flex items-center mb-sm' style={{
                    fontFamily: colors.openSans,
                    fontSize: isDesktop ? '1.5em' : '1em',
                    textAlign: 'left',
                    color: colors.blue
                }}>
                    <h4>
                        {text?.inventory?.create?.form?.extraFields?.title}
                    </h4>
                    <Info
                        text={text?.inventory?.create?.form?.extraFields?.info}
                        id='advanceField'
                        placement={'top'}
                        className='flex justify-center items-center'
                    />
                </div>
            </>
            <div className='ExtraFields'>
                <div className='ExtraFields-wrapper-1'>
                    {
                        fieldRecommendationIsLoading ? <Loader /> : (
                            <>
                                <div className='ExtraFields-wrapper-fields'>
                                    <CustomField
                                        value={newFieldName?.value}
                                        placeholder={text?.inventory?.details?.form?.selectFieldName}
                                        onChange={handleOnChange}
                                        errorMessage={fieldError}
                                        type={'text'}
                                        wrapperStyles={{ width: 'auto' }}
                                        fieldNames={fieldNames}
                                        name={`products[${productIndex}].fieldName`}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    <SelectInput
                                        name={`products[${productIndex}].inputType`}
                                        options={inputTypeOptions(text)}
                                        onChange={handleInputChange}
                                        placeholder={text?.inventory?.create?.form?.extraFields?.fieldType}
                                        width={100}
                                        isSearchable
                                        isClearable
                                        isValidNewOption={() => false}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    <CallToAction
                                        wrapperClassName={classnames({
                                            'mt-md': true
                                        })}
                                        type="button"
                                        onClick={addField}
                                        disabled={!isEmpty(fieldError)}
                                        text={text?.inventory?.create?.form?.extraFields?.addField}
                                        style={{
                                            alignSelf: 'flex-end',
                                            backgroundColor: theme === 'dark' ? colors.secondary : colors.black
                                        }}
                                    />
                                    <span className='small error mt-md align-self-end'>
                                        {errorMessage}
                                    </span>
                                    <ConditionalRender renderIf={
                                        form.getState().values.products[productIndex]?.inventoryName &&
                                        form.getState().values.products[productIndex]?.inventoryCategory?.value}>
                                        <hr style={{
                                            borderColor: colors.lightGray,
                                        }} />
                                        <p className='b'>
                                            {text?.inventory?.create?.form?.extraFields?.autoGenerate?.title}
                                        </p>
                                        <div className='flex mt-md'>
                                            <FieldWithNoValidation
                                                label={text?.inventory?.create?.form?.extraFields?.autoGenerate?.label}
                                                name={`products[${productIndex}].generateFieldsRecommendations`}
                                                component='input'
                                                block
                                                onChange={handleFieldRecommendation}
                                                onClick={openFieldsRecommendation}
                                                btnText={text?.inventory?.create?.form?.extraFields?.autoGenerate?.button}
                                                info={<Info
                                                    text={text?.inventory?.create?.form?.extraFields?.autoGenerate?.info}
                                                    id='fieldRecommendation'
                                                    placement={'top'}
                                                    className='flex justify-center items-center'
                                                />}
                                            />
                                        </div>
                                        <ConfirmModal
                                            onClick={searchFieldRecommendation}
                                            text={text?.inventory?.create?.form?.extraFields?.autoGenerate?.modal?.button}
                                            toggle={openFieldsRecommendation}
                                            isOpen={fieldsRecommendationIsOpen}
                                            btnStyles={{
                                                color: colors.blue
                                            }}
                                            width={isDesktop ? '50%' : '100%'}
                                            height={isDesktop ? '50%' : '100%'}
                                        >
                                            <div>
                                                <h3 className='mb-md'>
                                                    {text?.inventory?.create?.form?.extraFields?.autoGenerate?.modal?.title}
                                                </h3>
                                                <p>
                                                    {text?.inventory?.create?.form?.extraFields?.autoGenerate?.modal?.subtitle}
                                                </p>
                                                <p>
                                                    {text?.inventory?.create?.form?.extraFields?.autoGenerate?.modal?.text}
                                                </p>
                                            </div>
                                        </ConfirmModal>
                                    </ConditionalRender>
                                </div>
                                <div className='ExtraFields-wrapper-inputs'>
                                    {fields?.map((field) => {
                                        const fieldLabel = field.label;
                                        return (
                                            <div className='ExtraFields-wrapper-input flex-column' key={field.id}>
                                                <div className='flex w-100'>
                                                    {
                                                        field.inputType === 'number' ? (
                                                            <div className='flex flex-column w-100'>
                                                                <MultiFields
                                                                    className='field-style'
                                                                    name={`products[${productIndex}].advanceFields.${field.fullName}.value`}
                                                                    component={field.inputType}
                                                                    min="0"
                                                                    type={field.inputType}
                                                                    label={fieldLabel}
                                                                    form={form}
                                                                    block
                                                                    width={field?.fieldName === 'number' && isDesktop ? 30 : isTablet ? 50 : 100}
                                                                />
                                                                <FormSpy>
                                                                    {({ values }) => {
                                                                        const productAdvanceFields = values?.products?.[productIndex]?.advanceFields?.[field?.fullName];
                                                                        const hasValue = productAdvanceFields?.value;
                                                                        const isChecked = productAdvanceFields?.notification?.isChecked;

                                                                        return (
                                                                            <>
                                                                                {(hasValue || hasValue === 0) && (
                                                                                    <Field name={`products[${productIndex}].advanceFields.${field.fullName}.notification.isChecked`}>
                                                                                        {({ input }) => (
                                                                                            <CheckBox
                                                                                                {...input}
                                                                                                name={`products[${productIndex}].advanceFields.${field.fullName}.notification.isChecked`}
                                                                                                type="checkbox"
                                                                                                checked={input.value}
                                                                                                onChange={input.onChange}
                                                                                                label={text?.inventory?.create?.form?.extraFields?.notifyMe}
                                                                                                checkStyle={{ background: colors.blue }}
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                )}
                                                                                {(isChecked && (hasValue || hasValue === 0)) && (
                                                                                    <div className='flex items-center'>
                                                                                        <p className='mr-md'>{text?.inventory?.create?.form?.extraFields?.numberBellow}</p>
                                                                                        <FieldWithValidation
                                                                                            validate={composeValidators(
                                                                                                requiredNumber('Required')
                                                                                            )}
                                                                                            className='field-style'
                                                                                            name={`products[${productIndex}].advanceFields.${field.fullName}.notification.value`}
                                                                                            component="input"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            label={text?.inventory?.create?.form?.extraFields?.quantity}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }}
                                                                </FormSpy>
                                                            </div>
                                                        ) : (
                                                            <MultiFields
                                                                className='field-style'
                                                                name={`products[${productIndex}].advanceFields.${field.fullName}`}
                                                                component={field.inputType}
                                                                type={field.inputType}
                                                                fieldName={field.name}
                                                                label={fieldLabel}
                                                                form={form}
                                                                withTimePicker={withTimePicker}
                                                                autoComplete
                                                                suggestionData={inventory}
                                                                fields={inventoryColumns}
                                                                block

                                                            />
                                                        )
                                                    }
                                                    <FormSpy>
                                                        {({ form }) => (
                                                            <button className='delete' type="button" onClick={() => removeField(form, field)}>
                                                                <FontAwesomeIcon icon={faClose} />
                                                            </button>
                                                        )}
                                                    </FormSpy>
                                                </div>
                                                <hr className='w-100' />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )
                    }

                </div>

                <div className='ExtraFields-wrapper-2'>
                    <div className='flex flex-column w-100'>
                        <StyledDiv className='image-uploader' theme={theme}>
                            <Wrapper
                                title={'Images'}
                                width={'100%'}
                                button={
                                    <div
                                        className='dropzone'
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <span>{text?.inventory?.create?.form?.extraFields?.uploader?.title}</span>
                                    </div>
                                }
                            >
                                <div className='uploaded-images'>
                                    {currentUploadedImages.map((image, index) => (
                                        <SelectableBox
                                            padding={'0'}
                                            className='w-100'
                                            removeButton
                                            onClickRemove={(e) => {
                                                removeImage(productIndex, index)
                                            }}
                                        >
                                            <div
                                                key={index}
                                                onClick={() => openCarousel(index)}
                                                className='image-wrapper'
                                            >
                                                <img src={image?.dataUrl} alt={`Uploaded ${index + 1}`} className='uploaded-image' />
                                            </div>
                                        </SelectableBox>
                                    ))}
                                </div>
                                {
                                    !isEmpty(imageError) && (
                                        <span
                                            className='error small mt-sm'
                                        >
                                            {imageError}
                                        </span>
                                    )

                                }
                            </Wrapper>
                        </StyledDiv>
                    </div>
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    className='CustomModal'
                    style={{
                        overlay: {
                            backgroundColor: colors.backgroundOverlay,
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                            width: '100vw',
                            height: '100vh',
                            background: ' rgba(0,0,0,0.5)',
                            display: 'flex',
                            flexDirection: 'column'
                        },
                    }}
                >
                    <CarouselWithCustomArrows
                        images={currentUploadedImages}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        hideSave
                    />
                    <CallToAction
                        text={'Close'}
                        onClick={closeModal}
                        style={{
                            alignSelf: 'start',
                            marginTop: '15px',
                            color: colors.white,
                        }}
                    />
                </Modal>
            </div >
        </StyledExtraFields>
    );
};

export default ExtraFields;
