import React, { useState, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { useTheme } from '../../context/ThemeContext';
import { useLanguage } from '../../context/LanguageContext';
import colors from '../../globalStyles.scss';
import ConditionalRender from '../ConditionalRender'; // Adjust the import path as needed

const StyledInput = styled.div`
  position: relative;
  width: 100%;

  .input-container {
    display: flex;
    align-items: center;
    svg {
       color: ${colors.red};
    }
  }
`;

const CreatableMultiInput = ({
    input,
    meta,
    options,
    disabled,
    controlWidth,
    style,
    showLightColors,
    label,
    validate,
    className,
    placeHolder,
    width,
    initialValue,
    setIsEditing,
    ...props
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(null);
    const [formattedInputValue, setFormattedInputValue] = useState(initialValue || []);
    const containerRef = useRef(null);

    const components = {
        DropdownIndicator: null,
    };

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                event.preventDefault();
                addValue(inputValue);
                break;
            default:
                break;
        }
    };

    const addValue = (value) => {
        if (validate) {
            const validationError = validate(value);
            if (validationError) {
                setError(validationError);
                return;
            }
        }
        const newValue = [...formattedInputValue, createOption(value)];
        setFormattedInputValue(newValue);
        input.onChange(newValue);
        setIsEditing && setIsEditing(true);
        setInputValue('');
        setError(null);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target) && inputValue) {
            addValue(inputValue);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputValue]);

    const handleBlur = () => {
        if (inputValue) {
            addValue(inputValue);
        }
    };

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: width || '100%',
        }),
        control: (provided) => ({
            ...provided,
            '&:hover': {
                borderColor: 'none',
            },
            cursor: 'pointer',
            minWidth: controlWidth || '300px',
            minHeight: '35px',
            border: 'none',
            boxShadow: 'none',
            borderRadius: 'none',
            marginTop: style?.marginTop || '0',
            backgroundColor: disabled
                ? (showLightColors ? colors.lightGray : (theme === 'dark' ? colors.disabledDark : colors.lightBlue))
                : (showLightColors ? colors.lightGray : (theme === 'dark' ? colors.backgroundSecondary : colors.lightGray)),
            borderBottom: (meta?.touched && meta?.error) ? `1px solid ${colors.red}` : `1px solid ${colors.blue}`,
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
            borderRadius: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            color: colors.black,
            fontFamily: colors.openSans,
            fontWeight: state.isFocused ? 'bold' : 'regular',
            backgroundColor: state.isFocused ? (theme === 'dark' ? colors.secondary : colors.lightBlue) : colors.lightGray,
            cursor: 'pointer',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0',
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: (theme === 'dark' && !showLightColors) ? colors.white : colors.black,
        }),
        input: (provided) => ({
            ...provided,
            color: (theme === 'dark' && !showLightColors) ? colors.white : colors.black,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: showLightColors
                ? colors.black
                : (theme === 'dark' ? colors.white : colors.black),
        }),
    };

    useEffect(() => {
        setFormattedInputValue(initialValue || []);
        input.onChange(initialValue || []);
    }, [initialValue]);

    return (
        <StyledInput className={className} ref={containerRef}>
            <ConditionalRender renderIf={label}>
                <label className="mb-sm flex b">
                    {label}
                </label>
            </ConditionalRender>
            <div className="input-container">
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isDisabled={disabled}
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                        setFormattedInputValue(newValue);
                        input.onChange(newValue);
                        setIsEditing && setIsEditing(true);
                    }}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    placeholder={placeHolder || text?.employees?.details?.typeAndEnter}
                    styles={customStyles}
                    value={formattedInputValue}
                    {...props}
                />
            </div>
            {error && <span style={{ color: colors.red }}>{error}</span>}
        </StyledInput>
    );
};

export default CreatableMultiInput;
