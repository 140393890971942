import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../Core/HoverPopup';
import Wrapper from '../Core/Wrapper';
import { useNavigate } from "react-router-dom";
import { Field } from 'react-final-form';
import moment from 'moment';
import SearchInput from '../Core/Inputs/SearchInput';
import { composeValidators, isRequired } from '../utils/validators';
import RenderImages from '../Core/RenderImages';
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader';
import { timeLogsColumns } from '../Invetory/columns';
import CustomTable from '../Core/Tables/CustomTable';
import CallToAction from '../Core/CallToAction';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext'
import useScreenSize from '../context/useScreenSize'
import {
    updateDailyLog,
    updateLogsImages,
    getTimesheetById,
} from '../utils/calls';
import { DateRangePicker } from 'react-dates';
import base64ToBlob from '../utils/base64ToBlob';
import { TimerContext } from '../context/TimeContext';

import MultiFields from '../Core/MultiFields';
import ExportLogs from '../Core/ExportLogs';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import SubmitModal from '../Core/SubmitModal';
import { exportLogs } from '../utils/calls/exportLogs';
import { isEmpty } from 'lodash';
import generatePDF from '../Project/utils/generatePDF';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import colors from '../globalStyles.scss'
import { useDropzone } from 'react-dropzone';


const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.white};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;

const StyledUploader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dropzone {
        display: flex;
        flex-direction: column;
        margin: 0.5em 0;
        padding: 1em;
        background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
        cursor: pointer;
        transition: all 0.2s ease 0s;
        &:hover {
            background: ${colors.lightGray};
        }
        span {
            font-family: ${colors.roboto};
            color: ${props => props.theme === 'dark' ? colors.black : colors.black};
        }
    }
`


const TimeLogContainer = ({
    employees,
    user,
    id,
    timeLogs,
    logsIsLoading,
    projects,
    userRole,
    project,
    isAdmin
}) => {
    const { fetchTimeLogs } = useContext(TimerContext)
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const navigate = useNavigate();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (timeLogs?.length === 0) {
            setTableData([]);
        } else {
            setTableData(timeLogs);
        }
    }, [timeLogs]);

    const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
    const [timeLogIsOpen, setTimeLogIsOpen] = useState(false);
    const [dailyLog, setDailyLog] = useState({})

    const [focusedInput, setFocusedInput] = useState(null);
    const handleDatesChange = ({ startDate, endDate }) => {
        setFilters({
            ...filters,
            startDate,
            endDate
        });
    };

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const openExportModal = () => {
        setExportModalIsOpen(!exportModalIsOpen)
    }

    const handleOpenDailyLog = () => {
        setTimeLogIsOpen(!timeLogIsOpen)
    }

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        if (filters.startDate && filters.endDate) {
            filterOptions.startDate = filters.startDate;
            filterOptions.endDate = filters.endDate;
        }

        let filteredLogs = timeLogs;

        if (filterOptions.query) {
            const query = filterOptions.query.toLowerCase();
            filteredLogs = filteredLogs.filter(log => {
                const dailyLog = log?.dailyLog?.toLowerCase().includes(query);
                const firstName = log?.userId?.firstName?.toLowerCase().includes(query);
                const lastName = log?.userId?.lastName?.toLowerCase().includes(query);
                const fullName = `${log?.userId?.firstName?.toLowerCase()} ${log?.userId?.lastName?.toLowerCase()}`.includes(query);
                return dailyLog || firstName || lastName || fullName;
            });
        }

        if (filterOptions.startDate && filterOptions.endDate) {
            filteredLogs = filteredLogs.filter(log =>
                moment(log.clockIn).isBetween(filterOptions.startDate, filterOptions.endDate, 'day', '[]')
            );
        }

        setTableData(filteredLogs);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, timeLogs]);

    const handleExportLogs = async (values) => {
        const dates = values.dates;
        try {
            openExportModal();
            const res = await exportLogs(id, dates.startDate, dates.endDate)
            if (res.status === 200) {
                const logs = res.data;
                generatePDF(logs, dates, formatDate);
            }
        } catch (error) {
            console.error("Error exporting logs:", error);
        }
    };


    const [loading, setLoading] = useState(false);
    const [dailyLogsImgs, setDailyLogsImgs] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const removeImage = (imageIndex) => {
        setDailyLogsImgs((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
    };

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveDailyLog = async (values) => {
        setLoading(true);
        const processProjectImages = async () => {
            const formData = new FormData();

            dailyLogsImgs.forEach((image) => {
                if (image.new) {
                    const imageBlob = base64ToBlob(image?.url);
                    formData.append('newImages', imageBlob, image?.originalName);
                } else {
                    formData.append('oldImages', JSON.stringify(image));
                }
            });

            formData.append('timesheetId', dailyLog?._id);

            // Send to the API
            const res = await updateLogsImages(formData);
            if (res.status === 200) {
                console.log('Images updated');
            } else {
                setLoading(false);
                throw new Error('Images update failed');
            }

            return res;
        };

        try {
            // Step 1: Use fallback for dailyLog if it's undefined or null
            const logToUpdate = values?.dailyLog || dailyLog?.dailyLog

            // Ensure dailyLog is not empty before proceeding
            if (!logToUpdate) {
                throw new Error('No daily log found to update.');
            }

            // Step 2: Update the daily log
            const updateLogRes = await updateDailyLog(logToUpdate, dailyLog?.clockIn, dailyLog?.userId?._id);

            if (updateLogRes.status === 200) {
                // Step 3: Update project images
                await processProjectImages();

                // Step 4: Fetch the latest timesheet after all updates
                const { data: updatedTimesheet, status } = await getTimesheetById(dailyLog?._id);
                if (status === 200) {
                    setDailyLog(updatedTimesheet); // Ensure the daily log is up-to-date
                    setLoading(false);
                } else {
                    setLoading(false);
                    throw new Error('Failed to fetch updated timesheet after updates');
                }

                // Step 5: Refresh time logs after everything is done
                const isAdmin = projects?.some(
                    (project) =>
                        project._id === id &&
                        (project.adminEmployees?.some((employee) => employee?.id === user?.id) || user?.roleName === 'Admin')
                );

                await fetchTimeLogs(null, id, isAdmin);

                // handleOpenDailyLog(); // Close the daily log UI
            } else {
                console.error(updateLogRes.message);
                handleOpenDailyLog();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const required = composeValidators(
        isRequired(text?.projects?.details?.validations?.required)
    )
    const [imageError, setImageError] = useState('')

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setDailyLogsImgs((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setImageError('')
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    useMemo(() => {
        setDailyLogsImgs(dailyLog?.imageUrls)
    }, [dailyLog.imageUrls])

    return (
        <Wrapper
            noDataText={isEmpty(timeLogs) && text?.projects?.details?.noLogsFound}
            title={text?.projects?.details?.logsTitle}
            disableButton={isEmpty(timeLogs)}
            button={
                <CallToAction
                    text={text?.projects?.details?.exportTitle}
                    type='button'
                    onClick={() => openExportModal()}
                />
            }
        >
            <div>
                <div className='flex flex-column mb-md'>
                    <ConditionalRender renderIf={project?.projectStatus !== 'completed' && !isEmpty(timeLogs)}>
                        <HoverPopup
                            className='align-self-start'
                            style={{
                                marginRight: '1rem',
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-tasks'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>
                    </ConditionalRender>
                    <ConditionalRender renderIf={showFilters}>
                        <div
                            className='filters mt-md'
                            style={{
                                flexWrap: 'wrap',
                            }}>
                            <SearchInput
                                className='mt-md'
                                width={isDesktop ? '50%' : isTablet ? '50%' : '100%'}
                                value={filters.query}
                                onChange={handleSearchChange}
                                placeHolder={text?.tasks?.home?.filters?.search}
                            />
                            <StyledDateRangePicker theme={theme} className='flex mt-md'>
                                <DateRangePicker
                                    startDate={filters.startDate}
                                    startDateId="_time_start_date_id"
                                    endDate={filters.endDate}
                                    endDateId="_time_end_date_id"
                                    onDatesChange={handleDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                    isOutsideRange={() => false}
                                    displayFormat={'MM-DD-YYYY'}
                                    showClearDates
                                    numberOfMonths={!isDesktop ? 1 : 2}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                                    endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                                />
                            </StyledDateRangePicker>

                        </div>
                    </ConditionalRender>
                </div>
                <ConditionalRender renderIf={userRole === 'Admin' && !isEmpty(timeLogs)}>
                    <p className='flex tx-left mb-md'>
                        {text?.projects?.details?.logsText}
                    </p>
                </ConditionalRender>
                <ConditionalRender
                    isLoading={logsIsLoading}
                    renderIf={!isEmpty(timeLogs)}
                >

                    <CustomTable
                        keyField="_id"
                        columns={timeLogsColumns({ theme, text, user, formatDate, setTimeLogIsOpen, timeLogIsOpen, setDailyLog })}
                        rows={tableData}
                        theme={theme}
                        noDataText={'No Logs Found'}
                        checkboxSelection={false}
                    />

                    <ConditionalRender renderIf={timeLogIsOpen}>
                        <ConfirmModal
                            text={text?.timeTracker?.modal?.button}
                            toggle={() => handleOpenDailyLog()}
                            isOpen={timeLogIsOpen}
                            btnStyles={{
                                color: colors.blue
                            }}
                            projectId={id}
                            onSubmit={handleSaveDailyLog}
                            isForm
                            width={isDesktop ? '80%' : '100%'}
                            height={isDesktop ? '80%' : '100%'}
                        >
                            <Wrapper
                                width={'100%'}
                                isLoading={loading}
                                title={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(dailyLog?.clockIn)}`}
                                button={

                                    <StyledUploader
                                        theme={theme}
                                    >
                                        {
                                            <div
                                                className='dropzone'
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <span>
                                                    {text?.inventory?.details?.uploadImages}
                                                </span>
                                            </div>

                                        }
                                    </StyledUploader>
                                }
                            >
                                <ConditionalRender renderIf={!isEmpty(dailyLogsImgs)}>
                                    <RenderImages
                                        images={dailyLogsImgs}
                                        removeImage={removeImage}
                                        openModal={openCarousel}
                                        closeModal={closeModal}
                                        setActiveIndex={setActiveIndex}
                                        activeIndex={activeIndex}
                                        isModalOpen={isModalOpen}
                                    />
                                </ConditionalRender>

                                <ConditionalRender renderIf={isEmpty(dailyLogsImgs)}>
                                    <p className='mb-md'>
                                        {text?.projects.create?.noFoundMsgs}
                                    </p>
                                </ConditionalRender>
                                <div className="editor w-100">
                                    <Field
                                        name={'dailyLog'}
                                    >
                                        {({ input }) => {
                                            return (
                                                <RichEditorWithImageUploader
                                                    noGif
                                                    showForm={false}
                                                    name="dailyLog"
                                                    initialValue={dailyLog?.dailyLog}
                                                    onChange={(value) => {
                                                        input.onChange(value)
                                                    }}
                                                    height={false}
                                                    noKeyDown
                                                    noButton
                                                />
                                            )
                                        }}
                                    </Field>

                                </div>
                            </Wrapper>
                        </ConfirmModal>
                    </ConditionalRender>
                    <CallToAction className='mt-md mb-md' style={{ alignSelf: 'flex-start' }} onClick={(e) => navigate(`/logs`)} text={text?.projects?.details?.viewMore} />
                </ConditionalRender>
            </div>

            <SubmitModal
                onClick={handleExportLogs}
                text={text?.projects?.details?.logsModal?.button}
                toggle={openExportModal}
                isOpen={exportModalIsOpen}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '50%' : '100%'}
                btnStyles={{
                    color: colors.blue
                }}
            >
                <div>
                    <h4 className='mb-md'>
                        {text?.projects?.details?.logsModal?.title}
                    </h4>
                    <p>
                        {text?.projects?.details?.logsModal?.text}
                    </p>
                    <p>
                        {text?.projects?.details?.logsModal?.text2}
                    </p>
                    <Field
                        name="dates"
                        validate={required}
                        component={(props) => (
                            <ExportLogs
                                {...props}
                                projectId={id}
                                validate={required}
                            />
                        )}
                    />
                </div>
            </SubmitModal>
        </Wrapper>
    )
}

export default TimeLogContainer