import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../context/useScreenSize';
import { RegisterContext } from '../context/RegisterContext';

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    margin-top: 8px;
  }
  40% {
    margin-top: 0px;
  }
  60% {
    margin-top: 5px;
  }
`;

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => props?.style?.width || '100%'};
  .tooltip-content {
    position: absolute;
    background-color: ${(props) => props.theme === 'dark' ? colors.primary : colors.blue};
    color: ${colors.white};
    padding: 8px 12px;
    border-radius: 6px;
    white-space: normal;
    word-wrap: break-word;
    max-width: 250px;
    width: ${(props => props?.style?.width || 'auto')};
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    animation: ${bounceAnimation} 1s ease-in-out infinite;
    &:hover {
        margin-top: 0x;
        animation: none;
    }
    ${(props) => props.style?.tooltip || ''}

    ${(props) => {
    switch (props.placement) {
      case 'top':
        return `
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 8px;
          `;
      case 'right':
        return `
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 8px;
          `;
      case 'bottom':
        return `
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 8px;
          `;
      case 'left':
        return `
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 8px;
          `;
      default:
        return '';
    }
  }}

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      color: ${colors.white};
      background: ${colors.red};
      padding: 0.2em;
      cursor: pointer;
    }
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;

    ${(props) => {
    switch (props.placement) {
      case 'top':
        return `
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 6px 6px 0 6px;
            border-color: ${props.theme === 'dark' ? colors.primary : colors.blue} transparent transparent transparent;
          `;
      case 'right':
        return `
            left: -6px;
            top: 50%;
            transform: translateY(-50%);
            border-width: 6px 6px 6px 0;
            border-color: transparent ${props.theme === 'dark' ? colors.primary : colors.blue} transparent transparent;
          `;
      case 'bottom':
        return `
            top: -6px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent ${props.theme === 'dark' ? colors.primary : colors.blue} transparent;
          `;
      case 'left':
        return `
            right: -6px;
            top: 50%;
            transform: translateY(-50%);
            border-width: 6px 0 6px 6px;
            border-color: transparent transparent transparent ${props.theme === 'dark' ? colors.primary : colors.blue};
          `;
      default:
        return '';
    }
  }}
  }
`;

const ControlledTooltip = ({
  children,
  text,
  placement = 'top',
  style,
  isDark,
  isVisible = true,
  actionParams,
}) => {
  const { handleRegisterAction, actions } = useContext(RegisterContext);
  const { isPhone } = useScreenSize();
  const { theme } = useTheme();

  const handleActionClick = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      await handleRegisterAction(actionParams);
    } catch (error) {
      console.error('Failed to register action:', error);
    }
  };

  const isActionRegistered = actions?.some(
    (action) => action.feature === actionParams.feature && action.action === actionParams.action
  );

  return (
    <TooltipWrapper
      isPhone={isPhone}
      theme={isDark ? 'dark' : theme}
      placement={placement}
      style={style}
    >
      {children}
      <ConditionalRender renderIf={isVisible && !isActionRegistered}>
        <div className="tooltip-content">
          <FontAwesomeIcon
            className="close-button"
            onClick={handleActionClick}
            icon={faTimes} />
          {text}
          <div className="tooltip-arrow" />
        </div>
      </ConditionalRender>
    </TooltipWrapper>
  );
};

export default ControlledTooltip;
