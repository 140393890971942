import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import ConditionalRender from '../ConditionalRender';
import colors from '../../globalStyles.scss';
import { useTheme } from '../../context/ThemeContext';
import useScreenSize from '../../context/useScreenSize';
import classNames from 'classnames';
import { isEmpty } from 'lodash'

const StyledInput = styled.div`
    position: relative;
    width: 100%;
`;

const CreatableInput = ({
    input,
    meta,
    options,
    isSearchable,
    onChange,
    disabled,
    setIsEditing,
    style,
    initValue,
    className,
    isClearable = true,
    isValidNewOption,
    showLightColors,
    label,
    clearOnOpen,
    onOpen,
    handleClear,
    width,
    callToAction,
    ...props
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: 'pointer',
            minWidth: '100%',
            height: '40px',
            border: 'none',
            boxShadow: 'none',
            borderRadius: '0',
            marginTop: style?.marginTop || '0',
            backgroundColor: disabled
                ? (showLightColors ? colors.lightGray : (theme === 'dark' ? colors.disabledDark : colors.lightBlue))
                : (showLightColors ? colors.lightGray : (theme === 'dark' ? colors.backgroundSecondary : colors.lightGray)),
            borderBottom: meta?.touched && meta?.error ? `1px solid ${colors.red}` : `1px solid ${colors.blue}`,
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
            marginTop: 0,
        }),
        option: (provided, state) => {
            let backgroundColor;
            let color;
            let cursor = 'pointer';

            if (state.data.isDisabled) {
                backgroundColor = theme === 'dark' ? colors.disabledDark : colors.lightGray;
                color = theme === 'dark' ? colors.white : colors.black;
                cursor = 'not-allowed';
            } else if (state.isSelected) {
                backgroundColor = theme === 'dark' ? colors.secondary : colors.lightBlue;
                color = theme === 'dark' ? colors.white : colors.black;
            } else if (state.isFocused) { // Ensure focused state works for keyboard
                backgroundColor = theme === 'dark' ? colors.secondary : colors.lightBlue;
                color = theme === 'dark' ? colors.white : colors.black;
            } else {
                backgroundColor = 'transparent';
                color = theme === 'dark' ? colors.white : colors.black;
            }

            return {
                ...provided,
                backgroundColor,
                color,
                cursor,
                '&:hover': {
                    backgroundColor: state.data.isDisabled
                        ? backgroundColor
                        : state.isSelected
                            ? theme === 'dark' ? colors.secondary : colors.lightBlue
                            : theme === 'dark' ? colors.secondary : colors.lightBlue,
                    color: state.data.isDisabled
                        ? color
                        : theme === 'dark' ? colors.white : colors.black,
                },
                '&:active': {
                    backgroundColor: state.data.isDisabled ? backgroundColor : theme === 'dark' ? colors.secondary : colors.lightBlue,
                    color: state.data.isDisabled ? color : theme === 'dark' ? colors.white : colors.black,
                }
            };
        },
        valueContainer: (provided) => ({
            ...provided,
            padding: '0'
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: (theme === 'dark' && !showLightColors) ? colors.white : colors.black,
        }),
        input: (provided) => ({
            ...provided,
            color: (theme === 'dark' && !showLightColors) ? colors.white : colors.black,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: showLightColors ? colors.black : (theme === 'dark' ? colors.white : colors.black),
        }),
    };

    const value = initValue || input?.value;
    const [formattedInputValue, setFormattedInputValue] = useState(value);

    useEffect(() => {
        if (isEmpty(value)) {
            setFormattedInputValue(null);
        } else if (typeof value === 'object' && value !== null && 'label' in value && 'value' in value) {
            setFormattedInputValue(value);
        } else {
            setFormattedInputValue({ label: value, value: value });
        }
    }, [value]);

    const handleSelectChange = (value, action) => {
        if (action.action === 'clear' && handleClear) {
            handleClear(value, action);
        }
        input?.onChange(value ? value : null);
        if (onChange) {
            onChange(value, props?.name);
        }
        if (setIsEditing) {
            setIsEditing(true);
        }
    };


    return (
        <StyledInput
            disabled={disabled}
            className={classNames(input?.name, {
                'CreatableInput': true,
            })}
            style={{
                ...style
            }}

        >
            <div className={`flex flex-column ${className}`}>
                {label && (
                    <label className='mb-sm flex b'>
                        {label}
                        <ConditionalRender renderIf={callToAction}>
                            <span className='ml-sm'>{callToAction}</span>
                        </ConditionalRender>
                    </label>
                )}
                <Creatable
                    isValidNewOption={isValidNewOption}
                    menuPlacement={props?.menuPlacement || 'auto'}
                    styles={customStyles}
                    options={options}
                    isClearable={isClearable}
                    isDisabled={disabled}
                    isSearchable={isSearchable}
                    isOptionDisabled={(option) => option.isDisabled}
                    placeholder={props?.placeholder || 'Select...'}
                    onMenuOpen={() => {
                        if (clearOnOpen) {
                            handleSelectChange('');
                        }
                        onOpen && onOpen();
                    }}
                    onChange={handleSelectChange}
                    value={formattedInputValue}
                />
                {meta?.error && meta?.touched && (
                    <span className='small error' style={{ alignSelf: 'end' }}>{meta?.error}</span>
                )}
            </div>
        </StyledInput>
    );
};

export default CreatableInput;
