import request from '../request';

/**
 * Sends a event request to the API.
 * @param {Object} values - The event data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const registerAction = async (values) => {
    try {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        };
        const { data, status } = await request('POST', '/action-tracking/register-action', values, config);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to register action: ${error.message}`);
    }
};

export default registerAction;
