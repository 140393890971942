import React, { useContext } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VerifyEmail from '../Registration/VerifyEmail';
import LandingContainer from '../Landing/LandingContainer';
import SettingsContainer from '../Settings/SettingsContainer';
import DetailsProjectContainer from '../Project/DetailsProjectContainer';
import ResourcesContainer from '../Resources/ResourcesContainer';
import HomeContainer from '../Home/HomeContainer';
import SubscriptionOptions from '../Membership/SubscriptionOptions';
import Flowchart from '../Home/Flowchart';
import NotFound from '../Home/NotFound';
import PasswordReset from '../Login/PasswordReset';
import MembershipContainer from '../Membership/MembershipContainer';
import InventoryCreateContainer from '../Invetory/InventoryCreateContainer';
import InventoryDetailsContainer from '../Invetory/InventoryDetailsContainer';
import CategoryContainer from '../Invetory/CategoryContainer';
import DetailsEmployeeContainer from '../Employees/DetailsEmployeeContainer';
import CreateProjectContainer from '../Project/CreateProjectContainer';
import TaskDetailsContainer from '../Tasks/TaskDetailsContainer';
import ProposalDetailsContainer from '../Proposals/ProposalDetailsContainer';
import AnalyticsContainer from '../Analytics/AnalyticsContainer';
import ClientDetailsContainer from '../Clients/ClientDetailsContainer';
import AdminContainer from '../Admin/AdminContainer';
import AdminDetailsContainer from '../Admin/AdminDetailsContainer';
import CookiesPolicy from '../Landing/CookiesPolicy';
import ContactForm from '../SubNavigation/ContactForm';
import CreateResourcesContainer from '../Resources/CreateResourcesContainer';
import DetailsResourcesContainer from '../Resources/DetailsResourcesContainer';
import ChatContainer from '../Chat/ChatContainer';
import FullCalendarContainer from '../Calendar/FullCalendar';
import { GlobalContext } from '../context/GlobalContext';
import { WebSocketProvider } from '../context/WebSocketContext';

import LogDetails from '../TimeLog/LogDetails';
import Loader from '../Core/Loader';
import RegisterAndLoginContainer from '../Home/RegisterAndLoginContainer';
import UpdateStatus from '../Proposals/UpdateStatus';
import HelmetWrapper from '../HelmetWrapper';

const Router = (props) => {
    const {
        user,
        userRole,
        industry,
        isAccountSetUp,
        company,
        companyIsLoading,
        employees,
        employeesIsLoading,
        projects,
        projectsIsLoading,
        fetchCompanyProjects,
        fetchCompanyDetails,
        fetchCompanyEmployees,
        loginUser,
        logoutUser,
        isLoggedIn,
        userIsLoading,
        fetchUserDetails,
        isCompanyPremium,
        trialIsActive,
        inventory,
        fetchInventoryProducts,
        inventorysLoading,
        priorityData,
        priorityIsLoading,
        setInventory,
        categories,
        fetchInventoryCategories,
        fieldNames,
        fetchAttributes,
        fetchFieldNames,
        attributesIsLoading,
        setInventoryColumns,
        inventoryColumns,
        widgets,
        handleToggleChat,
        showHelp,
        setShowHelp,
        handleSendMessage,
        conversationHistory,
        thinking,
        typing,
        chatErrorMessage,
        productsWithDates,
        productsWithDatesIsLoading,
        resources,
        resourcesIsLoading,
        fetchResources,
        featuredResources,
        fetchFeaturedResources,
        featuredResourcesIsLoading,
        history,
        fetchTimeLogs,
        timeLogs,
        logsIsLoading,
        fetchTemplates,
        templates,
        templatesIsLoading,
        tasks,
        tasksIsLoading,
        fetchProjectTasks,
        categoryRecommendationData,
        categoryRecommendationIsLoading,
        fetchAllClients,
        clients,
        clientsIsLoading,
        proposals,
        proposalsIsLoading,
        fetchAllProposals,
        attributes,
        subscription,
        subscriptionIsLoading,
        events,
        eventsIsLoading,
        fetchEvents,
        eventCategories,
        eventCategoriesIsLoading,
        fetchEventCategories,
        isLoggedInAsClient,
        clientIsLoading,
        clientInfo,
        setClientIsLoading,
        setClientInfo,
        setIsLoggedInAsClient,
        clientLogin,
        clientLogout,
        fetchClientDetails,
        fetchFifteenMinutesEventsFromNow

    } = useContext(GlobalContext);


    return (
        <div className='Router'
            style={{
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            {
                (userIsLoading || companyIsLoading || clientIsLoading) ? (
                    <Loader loading={userIsLoading || companyIsLoading || clientIsLoading} count={1} customHeight={'100vh'} />
                ) : (
                    <BrowserRouter>
                        <HelmetWrapper />
                        <LandingContainer
                            logoutUser={logoutUser}
                            clientLogout={clientLogout}
                            isLoggedIn={isLoggedIn}
                            isSuperAdmin={user?.isSuperAdmin}
                            isCompanyPremium={isCompanyPremium}
                            trialIsActive={trialIsActive}
                            handleToggleChat={handleToggleChat}
                            showHelp={showHelp}
                            setShowHelp={setShowHelp}
                            handleSendMessage={handleSendMessage}
                            conversationHistory={conversationHistory}
                            thinking={thinking}
                            chatErrorMessage={chatErrorMessage}
                            typing={typing}
                            accessGranted={company?.accessGranted}
                            projects={projects}
                            employees={employees}
                            userRole={userRole}
                            user={user}
                            company={company}
                            fetchCompanyEmployees={fetchCompanyEmployees}
                            employeesIsLoading={employeesIsLoading}
                            projectsIsLoading={projectsIsLoading}
                            fetchCompanyProjects={fetchCompanyProjects}
                            attributes={attributes}
                            fetchAttributes={fetchAttributes}
                            fetchFieldNames={fetchFieldNames}
                            attributesIsLoading={attributesIsLoading}
                            fetchInventoryProducts={fetchInventoryProducts}
                            inventorysLoading={inventorysLoading}
                            inventory={inventory}
                            isLoggedInAsClient={isLoggedInAsClient}
                            fetchFifteenMinutesEventsFromNow={fetchFifteenMinutesEventsFromNow}
                        >
                            <Routes>
                                <Route exact path="/" element={
                                    <HomeContainer
                                        isCompanyPremium={isCompanyPremium}
                                        inventory={inventory}
                                        inventoryColumns={inventoryColumns}
                                        fetchAttributes={fetchAttributes}
                                        fetchFieldNames={fetchFieldNames}
                                        widgets={widgets}
                                        attributesIsLoading={attributesIsLoading}
                                        isLoggedIn={isLoggedIn}
                                        handleToggleChat={handleToggleChat}
                                        showHelp={showHelp}
                                        handleSendMessage={handleSendMessage}
                                        priorityData={priorityData}
                                        priorityIsLoading={priorityIsLoading}
                                        productsWithDates={productsWithDates}
                                        productsWithDatesIsLoading={productsWithDatesIsLoading}
                                        route={'home'}
                                        history={history}
                                        accessGranted={company?.accessGranted}
                                        isAccountVerified={user?.isAccountVerified}
                                        user={user}
                                        company={company}
                                        fetchCompanyDetails={fetchCompanyDetails}
                                        companyIsLoading={companyIsLoading}
                                        employeesIsLoading={employeesIsLoading}
                                        employees={employees}
                                        userRole={userRole}
                                        industry={industry}
                                        isAccountSetUp={isAccountSetUp}
                                        projects={projects}
                                        projectsIsLoading={projectsIsLoading}
                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                        fetchInventoryProducts={fetchInventoryProducts}
                                        inventorysLoading={inventorysLoading}
                                        setInventoryColumns={setInventoryColumns}
                                        fetchUserDetails={fetchUserDetails}
                                        setInventory={setInventory}
                                        tasks={tasks}
                                        tasksIsLoading={tasksIsLoading}
                                        fetchProjectTasks={fetchProjectTasks}
                                        fetchAllClients={fetchAllClients}
                                        clients={clients}
                                        clientsIsLoading={clientsIsLoading}
                                        proposals={proposals}
                                        proposalsIsLoading={proposalsIsLoading}
                                        fetchAllProposals={fetchAllProposals}
                                        fetchCompanyProjects={fetchCompanyProjects}
                                        isLoggedInAsClient={isLoggedInAsClient}
                                        clientInfo={clientInfo}
                                        fetchClientDetails={fetchClientDetails}
                                        fieldNames={fieldNames}
                                        featuredResources={featuredResources}
                                        fetchFeaturedResources={fetchFeaturedResources}
                                        featuredResourcesIsLoading={featuredResourcesIsLoading}
                                    />
                                } />
                                <Route
                                    {...props}
                                    exact
                                    path="/contact-us"
                                    element={
                                        <ContactForm
                                            route={'contact'}
                                            loginUser={loginUser}
                                            logoutUser={logoutUser}
                                            user={user}
                                            isLoggedIn={isLoggedIn}
                                        />}
                                />
                                <Route
                                    exact
                                    path="/resources"
                                    element={
                                        <ResourcesContainer
                                            {...props}
                                            user={user}
                                            fetchUserDetails={fetchUserDetails}
                                            isCompanyPremium={isCompanyPremium}
                                            isLoggedIn={isLoggedIn}
                                            fieldNames={fieldNames}
                                            inventory={inventory}
                                            fetchAttributes={fetchAttributes}
                                            fetchFieldNames={fetchFieldNames}
                                            resources={resources}
                                            fetchResources={fetchResources}
                                            resourcesIsLoading={resourcesIsLoading}
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path="/resources/*"
                                    element={
                                        <DetailsResourcesContainer
                                            {...props}
                                            user={user}
                                            fetchUserDetails={fetchUserDetails}
                                            isCompanyPremium={isCompanyPremium}
                                            isLoggedIn={isLoggedIn}
                                            fieldNames={fieldNames}
                                            inventory={inventory}
                                            fetchAttributes={fetchAttributes}
                                            fetchFieldNames={fetchFieldNames}
                                            resources={resources}
                                            fetchResources={fetchResources}
                                            resourcesIsLoading={resourcesIsLoading}
                                        />
                                    }
                                />

                                <Route
                                    exact
                                    path="/proposals/status/*"
                                    element={
                                        <UpdateStatus />
                                    }
                                />

                                {
                                    (!isCompanyPremium && company?.accessGranted && isLoggedIn) && (
                                        <Route exact
                                            path="/membership"
                                            element={
                                                <MembershipContainer
                                                    {...props}
                                                    user={user}
                                                    fetchUserDetails={fetchUserDetails}
                                                    isLoggedIn={isLoggedIn}
                                                    company={company}
                                                    fetchCompanyDetails={fetchCompanyDetails}
                                                />
                                            } />
                                    )
                                }
                                {
                                    (isLoggedIn) ? (
                                        <>
                                            <Route
                                                exact
                                                path="/settings"
                                                element={
                                                    <SettingsContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        isLoggedIn={isLoggedIn}
                                                        fieldNames={fieldNames}
                                                        inventory={inventory}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        company={company}
                                                        userRole={userRole}
                                                        employees={employees}
                                                        projects={projects}
                                                        fetchCompanyDetails={fetchCompanyDetails}
                                                        templates={templates}
                                                        templatesIsLoading={templatesIsLoading}
                                                        fetchTemplates={fetchTemplates}
                                                        subscriptionIsLoading={subscriptionIsLoading}
                                                        subscription={subscription}
                                                        fetchResources={fetchResources}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/verify-email/*"
                                                element={
                                                    <VerifyEmail
                                                        isLoggedIn={isLoggedIn}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/reset-password/*"
                                                element={<PasswordReset />
                                                }
                                            />

                                        </>

                                    ) : (
                                        <>
                                            <Route
                                                {...props}
                                                exact
                                                path="/register"
                                                element={
                                                    <RegisterAndLoginContainer
                                                        route={'register'}
                                                    />}
                                            />
                                            <Route
                                                exact
                                                path="/verify-email/*"
                                                element={
                                                    <VerifyEmail
                                                        route={'verifyEmail'}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/reset-password/*"
                                                element={
                                                    <RegisterAndLoginContainer
                                                        route={'passwordReset'}
                                                    />
                                                }
                                            />
                                            <Route
                                                {...props}
                                                exact
                                                path="/login"
                                                element={
                                                    <RegisterAndLoginContainer
                                                        route={'login'}
                                                        loginUser={loginUser}
                                                        logoutUser={logoutUser}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        clientInfo={clientInfo}
                                                        clientIsLoading={clientIsLoading}
                                                        isLoggedInAsClient={isLoggedInAsClient}
                                                        setClientIsLoading={setClientIsLoading}
                                                        setClientInfo={setClientInfo}
                                                        setIsLoggedInAsClient={setIsLoggedInAsClient}
                                                        clientLogin={clientLogin}
                                                    />}
                                            />

                                            {/* not logged in and is somewhere else */}
                                            <Route
                                                {...props}
                                                exact
                                                path="/*"
                                                element={
                                                    <RegisterAndLoginContainer
                                                        route={'login'}
                                                        loginUser={loginUser}
                                                        logoutUser={logoutUser}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        clientInfo={clientInfo}
                                                        clientIsLoading={clientIsLoading}
                                                        isLoggedInAsClient={isLoggedInAsClient}
                                                        setClientIsLoading={setClientIsLoading}
                                                        setClientInfo={setClientInfo}
                                                        setIsLoggedInAsClient={setIsLoggedInAsClient}
                                                        clientLogin={clientLogin}
                                                    />}
                                            />
                                            {/* not logged in and is somewhere else */}
                                            <Route
                                                {...props}
                                                exact
                                                path="/subscriptions"
                                                element={
                                                    <SubscriptionOptions
                                                        isLoggedIn={isLoggedIn}
                                                        fetchUserDetails={fetchUserDetails}
                                                        trialHasBeenUsed={user?.trialHasBeenUsed}
                                                        email={user?.email}
                                                        isPremium={user?.isPremium}
                                                        fetchCompanyDetails={fetchCompanyDetails}
                                                        company={company}
                                                    />}
                                            />
                                            <Route
                                                {...props}
                                                exact
                                                path="/flow"
                                                element={
                                                    <Flowchart
                                                        isLoggedIn={isLoggedIn}
                                                        fetchUserDetails={fetchUserDetails}
                                                        trialHasBeenUsed={user?.trialHasBeenUsed}
                                                        email={user?.email}
                                                        isPremium={user?.isPremium}
                                                        fetchCompanyDetails={fetchCompanyDetails}
                                                        company={company}
                                                    />}
                                            />
                                        </>
                                    )
                                }

                                {/* Routes available for both Admin and Employees */}

                                {
                                    (isCompanyPremium && isLoggedIn) && (
                                        <>
                                            {/* Inventory Routes */}
                                            {/* <Route
                                                exact
                                                path="/inventory"
                                                element={
                                                    <InventoryHomeContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        inventory={inventory}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventorysLoading={inventorysLoading}
                                                        setInventory={setInventory}
                                                        inventoryColumns={inventoryColumns}
                                                        setInventoryColumns={setInventoryColumns}
                                                        fetchAttributes={fetchAttributes}
fetchFieldNames={fetchFieldNames}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                    />
                                                }
                                            /> */}
                                            <Route
                                                exact
                                                path="/inventory/create"
                                                element={
                                                    <InventoryCreateContainer
                                                        {...props}
                                                        company={company}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventory={inventory}
                                                        categories={categories}
                                                        fieldNames={fieldNames}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        inventoryColumns={inventoryColumns}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                        projectsIsLoading={projectsIsLoading}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                        fetchTemplates={fetchTemplates}
                                                        templates={templates}
                                                        templatesIsLoading={templatesIsLoading}

                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/inventory/details/*"
                                                element={
                                                    <InventoryDetailsContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventory={inventory}
                                                        fieldNames={fieldNames}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        categories={categories}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        inventoryColumns={inventoryColumns}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/inventory/category"
                                                element={
                                                    <CategoryContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventory={inventory}
                                                        fieldNames={fieldNames}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        categories={categories}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        inventoryColumns={inventoryColumns}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                    />
                                                }
                                            />

                                            {/* Tasks Routes */}
                                            <Route
                                                exact
                                                path="/tasks/details/*"
                                                element={
                                                    <TaskDetailsContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventory={inventory}
                                                        fieldNames={fieldNames}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        categories={categories}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        inventoryColumns={inventoryColumns}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                        userRole={userRole}
                                                        employees={employees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        company={company}
                                                        inventorysLoading={inventorysLoading}
                                                        setInventoryColumns={setInventoryColumns}
                                                        tasks={tasks}
                                                        tasksIsLoading={tasksIsLoading}
                                                        fetchProjectTasks={fetchProjectTasks}
                                                    />
                                                }
                                            />

                                            {/* Proposal Route */}\
                                            <Route
                                                exact
                                                path="/proposals/details/*"
                                                element={
                                                    <ProposalDetailsContainer
                                                        {...props}
                                                        user={user}
                                                        projects={projects}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        proposals={proposals}
                                                        proposalsIsLoading={proposalsIsLoading}
                                                        fetchAllProposals={fetchAllProposals}
                                                        clients={clients}
                                                    />
                                                }
                                            />

                                            {/* Calendar Routes */}
                                            <Route
                                                exact
                                                path="/calendar"
                                                element={
                                                    <FullCalendarContainer
                                                        {...props}
                                                        events={events}
                                                        eventsIsLoading={eventsIsLoading}
                                                        user={user}
                                                        projects={projects}
                                                        employees={employees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        fetchEvents={fetchEvents}
                                                        eventCategories={eventCategories}
                                                        eventCategoriesIsLoading={eventCategoriesIsLoading}
                                                        fetchEventCategories={fetchEventCategories}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/chat"
                                                element={
                                                    <ChatContainer
                                                        {...props}
                                                        events={events}
                                                        eventsIsLoading={eventsIsLoading}
                                                        user={user}
                                                        projects={projects}
                                                        employees={employees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        fetchEvents={fetchEvents}
                                                        eventCategories={eventCategories}
                                                        eventCategoriesIsLoading={eventCategoriesIsLoading}
                                                        fetchEventCategories={fetchEventCategories}
                                                        clients={clients}
                                                    />
                                                }
                                            />

                                            {/* Client Routes */}
                                            <Route
                                                exact
                                                path="/clients/details/*"
                                                element={
                                                    <ClientDetailsContainer
                                                        {...props}
                                                        user={user}
                                                        fetchUserDetails={fetchUserDetails}
                                                        isCompanyPremium={isCompanyPremium}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        inventory={inventory}
                                                        fieldNames={fieldNames}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        categories={categories}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        inventoryColumns={inventoryColumns}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        projects={projects}
                                                        userRole={userRole}
                                                        employees={employees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        company={company}
                                                        inventorysLoading={inventorysLoading}
                                                        setInventoryColumns={setInventoryColumns}
                                                        clients={clients}
                                                        clientsIsLoading={clientsIsLoading}
                                                        fetchAllClients={fetchAllClients}
                                                    />
                                                }
                                            />

                                            {/* Employee Routes */}
                                            <Route
                                                {...props}
                                                exact
                                                path="/settings/employee/*"
                                                element={
                                                    <DetailsEmployeeContainer
                                                        route={'employeeDetails'}
                                                        loginUser={loginUser}
                                                        logoutUser={logoutUser}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        projects={projects}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                        company={company}
                                                        projectsIsLoading={projectsIsLoading}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        userRole={userRole}
                                                    />}
                                            />

                                            {/* Project Routes */}
                                            {/* <Route
                                                exact
                                                path="/projects"
                                                element={
                                                    <LandingProjectContainer
                                                        user={user}
                                                        company={company}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        employees={employees}
                                                        projects={projects}
                                                        projectsIsLoading={projectsIsLoading}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                    />
                                                }
                                            /> */}
                                            <Route
                                                exact
                                                path="/project/details/*"
                                                element={
                                                    <WebSocketProvider
                                                        userId={user._id}
                                                    >
                                                        <DetailsProjectContainer
                                                            {...props}
                                                            user={user}
                                                            fetchUserDetails={fetchUserDetails}
                                                            isCompanyPremium={isCompanyPremium}
                                                            fetchInventoryProducts={fetchInventoryProducts}
                                                            inventory={inventory}
                                                            fieldNames={fieldNames}
                                                            fetchAttributes={fetchAttributes}
                                                            fetchFieldNames={fetchFieldNames}
                                                            categories={categories}
                                                            fetchInventoryCategories={fetchInventoryCategories}
                                                            inventoryColumns={inventoryColumns}
                                                            handleToggleChat={handleToggleChat}
                                                            showHelp={showHelp}
                                                            handleSendMessage={handleSendMessage}
                                                            projects={projects}
                                                            userRole={userRole}
                                                            employees={employees}
                                                            employeesIsLoading={employeesIsLoading}
                                                            fetchCompanyProjects={fetchCompanyProjects}
                                                            fetchCompanyEmployees={fetchCompanyEmployees}
                                                            company={company}
                                                            inventorysLoading={inventorysLoading}
                                                            setInventoryColumns={setInventoryColumns}
                                                            tasks={tasks}
                                                            tasksIsLoading={tasksIsLoading}
                                                            fetchProjectTasks={fetchProjectTasks}
                                                            fetchTimeLogs={fetchTimeLogs}
                                                            timeLogs={timeLogs}
                                                            logsIsLoading={logsIsLoading}
                                                            categoryRecommendationData={categoryRecommendationData}
                                                            categoryRecommendationIsLoading={categoryRecommendationIsLoading}
                                                            fetchAllClients={fetchAllClients}
                                                            clients={clients}
                                                            clientsIsLoading={clientsIsLoading}
                                                            proposalsIsLoading={proposalsIsLoading}
                                                            fetchAllProposals={fetchAllProposals}
                                                            proposals={proposals}
                                                            events={events}
                                                            eventsIsLoading={eventsIsLoading}
                                                            fetchEvents={fetchEvents}
                                                            fetchEventCategories={fetchEventCategories}
                                                            attributesIsLoading={attributesIsLoading}
                                                        />
                                                    </WebSocketProvider>

                                                }
                                            />

                                            {/* Time logs */}
                                            <Route
                                                exact
                                                path="logs/*"
                                                element={
                                                    <LogDetails
                                                        // {...props}
                                                        fetchTimeLogs={fetchTimeLogs}
                                                        timeLogs={timeLogs}
                                                        logsIsLoading={logsIsLoading}
                                                        projects={projects}
                                                        user={user}
                                                        tasks={tasks}
                                                        fetchProjectTasks={fetchProjectTasks}
                                                        employees={employees}
                                                    />
                                                }
                                            />
                                            <Route
                                                exact
                                                path="cookies-policy"
                                                element={
                                                    <CookiesPolicy
                                                    />
                                                }
                                            />

                                            <Route
                                                {...props}
                                                exact
                                                path="/login"
                                                element={
                                                    <RegisterAndLoginContainer
                                                        route={'login'}
                                                        loginUser={loginUser}
                                                        logoutUser={logoutUser}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        clientInfo={clientInfo}
                                                        clientIsLoading={clientIsLoading}
                                                        isLoggedInAsClient={isLoggedInAsClient}
                                                        setClientIsLoading={setClientIsLoading}
                                                        setClientInfo={setClientInfo}
                                                        setIsLoggedInAsClient={setIsLoggedInAsClient}
                                                        clientLogin={clientLogin}
                                                    />}
                                            />

                                        </>
                                    )
                                }

                                {/* ADMIN ROUTES */}
                                {
                                    (isCompanyPremium && isLoggedIn && userRole === 'Admin') && (
                                        <>
                                            <Route
                                                exact
                                                path="/project/create/*"
                                                element={
                                                    <CreateProjectContainer
                                                        user={user}
                                                        company={company}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        employeesIsLoading={employeesIsLoading}
                                                        employees={employees}
                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                        fieldNames={fieldNames}
                                                        categories={categories}
                                                        inventory={inventory}
                                                        inventoryColumns={inventoryColumns}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        fetchInventoryCategories={fetchInventoryCategories}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        projects={projects}
                                                        projectsIsLoading={projectsIsLoading}
                                                        categoryRecommendationData={categoryRecommendationData}
                                                        categoryRecommendationIsLoading={categoryRecommendationIsLoading}
                                                        fetchAllClients={fetchAllClients}
                                                        clients={clients}
                                                        clientsIsLoading={clientsIsLoading}
                                                    />
                                                }
                                            />
                                            {/* <Route
                                                {...props}
                                                exact
                                                path="/employees"
                                                element={
                                                    <EmployeesContainer
                                                        route={'employees'}
                                                        loginUser={loginUser}
                                                        logoutUser={logoutUser}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        company={company}
                                                        employees={employees}
                                                        employeesIsLoading={employeesIsLoading}
                                                    />}
                                            /> */}
                                            <Route
                                                {...props}
                                                exact
                                                path="/analytics"
                                                element={
                                                    <AnalyticsContainer
                                                        route={'analytics'}
                                                        user={user}
                                                        isLoggedIn={isLoggedIn}
                                                        inventory={inventory}
                                                        inventoryColumns={inventoryColumns}
                                                        fetchAttributes={fetchAttributes}
                                                        fetchFieldNames={fetchFieldNames}
                                                        attributesIsLoading={attributesIsLoading}
                                                        widgets={widgets}
                                                        handleToggleChat={handleToggleChat}
                                                        showHelp={showHelp}
                                                        handleSendMessage={handleSendMessage}
                                                        priorityData={priorityData}
                                                        priorityIsLoading={priorityIsLoading}
                                                        productsWithDates={productsWithDates}
                                                        productsWithDatesIsLoading={productsWithDatesIsLoading}
                                                        projects={projects}
                                                        fetchInventoryProducts={fetchInventoryProducts}
                                                        attributes={attributes}
                                                        employees={employees}
                                                        tasks={tasks}
                                                        tasksIsLoading={tasksIsLoading}
                                                        fieldNames={fieldNames}

                                                    />}
                                            />
                                        </>
                                    )
                                }

                                {/* SUPER ADMIN ROUTES */}
                                {user?.isSuperAdmin && (
                                    <>
                                        <Route
                                            {...props}
                                            exact
                                            path="/resources/create"
                                            element={
                                                <CreateResourcesContainer
                                                    route={'createResource'}
                                                    loginUser={loginUser}
                                                    logoutUser={logoutUser}
                                                    user={user}
                                                    isLoggedIn={isLoggedIn}
                                                    fetchResources={fetchResources}
                                                />}
                                        />
                                        <Route
                                            {...props}
                                            exact
                                            path="/admin"
                                            element={
                                                <AdminContainer
                                                    route={'adminContainer'}
                                                    loginUser={loginUser}
                                                    logoutUser={logoutUser}
                                                    user={user}
                                                    isLoggedIn={isLoggedIn}
                                                />}
                                        />
                                        <Route
                                            {...props}
                                            exact
                                            path="/admin/details/*"
                                            element={
                                                <AdminDetailsContainer
                                                    route={'adminDetails'}
                                                    loginUser={loginUser}
                                                    logoutUser={logoutUser}
                                                    user={user}
                                                    isLoggedIn={isLoggedIn}
                                                />}
                                        />
                                    </>
                                )}
                                <Route
                                    {...props}
                                    exact
                                    path="/*"
                                    element={
                                        <NotFound
                                            isCompanyPremium={isCompanyPremium}
                                        />
                                    }
                                />
                            </Routes>
                        </LandingContainer>
                    </BrowserRouter>
                )
            }

        </div>
    )
}

export default Router