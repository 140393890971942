import React, { useState, useEffect, useCallback } from 'react';
import CreatableInput from './Inputs/CreatableInput';
import { useLanguage } from '../context/LanguageContext';
import { isEmpty } from 'lodash';
import { removeQueryParam } from '../utils/helperFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import StandaloneDatePicker from './Inputs/StandaloneDatePicker';
import moment from 'moment';
import ConditionalRender from './ConditionalRender';

const CalendarLogsFilters = ({
    employees,
    employee,
    user,
    fetchEmployeeData,
    fetchTimeLogs,
    fetchWeeklyTime,
    setEmployee,
    projects,
    fetchProjectTasks,
    project,
    setProject,
    setCurrentWeekStart
}) => {
    const { text } = useLanguage();
    const location = useLocation();
    const navigate = useNavigate();

    const [initialEmployee, setInitialEmployee] = useState(null);
    const [initialProject, setInitialProject] = useState(null);

    // Initialize the employee filter based on the `employee` prop
    useEffect(() => {
        if (!isEmpty(employee)) {
            setInitialEmployee({
                label: `${employee?.firstName} ${employee?.lastName} (${employee?.email})`,
                value: employee?.id,
            });
        } else {
            setInitialEmployee(null);
        }
    }, [employee]);

    // Initialize the project filter based on the `project` prop
    useEffect(() => {
        if (!isEmpty(project)) {
            setInitialProject({
                label: project?.label,
                value: project?.value,
            });
        }
    }, [project]);

    // Ensure the project state is preserved when an employee is selected
    useEffect(() => {
        if (initialEmployee && initialProject === null && !isEmpty(project)) {
            setInitialProject({
                label: project?.label,
                value: project?.value,
            });
        }
    }, [initialEmployee, initialProject, project]);

    // Handle clearing the employee filter
    const handleEmployeeClear = useCallback(
        (newValue, actionMeta) => {
            if (actionMeta.action === 'clear') {
                const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
                const updatedUrl = removeQueryParam(currentUrl, 'id');
                navigate(`${location.pathname}${new URL(updatedUrl).search}`, { replace: true });

                setInitialEmployee(null);
                setEmployee({});
                fetchTimeLogs(null, initialProject?.value || null);
                fetchProjectTasks(initialProject?.value || null);
                fetchWeeklyTime(null, initialProject?.value || null);
            }
        },
        [location, navigate, fetchTimeLogs, fetchWeeklyTime, setEmployee]
    );

    // Handle changing the employee filter
    const handleEmployeeChange = useCallback(
        (value) => {
            if (!value) {
                setInitialEmployee(null);
                setEmployee({});
                if (initialProject) {
                    fetchEmployeeData(null, initialProject?.value);
                    fetchWeeklyTime(null, initialProject?.value);
                }
            } else {
                setInitialEmployee(value);
                fetchEmployeeData(value?.value, initialProject?.value || null);
                fetchWeeklyTime(value?.value, initialProject?.value || null);
            }
        },
        [fetchEmployeeData, fetchTimeLogs, fetchWeeklyTime, setEmployee, initialProject]
    );

    // Handle changing the project filter
    const handleProjectChange = useCallback(
        (value) => {
            if (value) {
                setInitialProject(value);

                // If the user is not an Admin, ensure the initialEmployee is cleared
                if (user?.roleName !== 'Admin') {
                    setInitialEmployee(null);
                    setEmployee({});
                }

                fetchEmployeeData(initialEmployee?.value || null, value?.value || null);
                fetchWeeklyTime(initialEmployee?.value || null, value?.value || null);
            } else {
                setInitialProject(null);
            }
        },
        [fetchEmployeeData, fetchWeeklyTime, initialEmployee, user]
    );


    // Handle clearing the project filter
    const handleProjectClear = useCallback(
        (newValue, actionMeta) => {
            if (actionMeta.action === 'clear') {
                setInitialProject(null);

                // Clear initialEmployee and employee if the user is not an Admin
                if (user?.roleName !== 'Admin') {
                    fetchTimeLogs(user?.id || null, null);
                    fetchProjectTasks(null, user?.id || null);
                    fetchWeeklyTime(user?.id || null);
                    setInitialEmployee(null);
                    setEmployee({});
                } else {
                    // For Admin, retain initialEmployee and fetch data accordingly
                    fetchEmployeeData(initialEmployee?.value || null, null);
                    fetchTimeLogs(initialEmployee?.value || null, null);
                    fetchProjectTasks(null, initialEmployee?.value || null);
                    fetchWeeklyTime(initialEmployee?.value || null);
                }

                // Reset the project state
                setProject({});
            }
        },
        [fetchEmployeeData, fetchTimeLogs, fetchProjectTasks, fetchWeeklyTime, initialEmployee, user]
    );

    const handleDateChange = (value) => {
        if (value) {
            const newWeekStart = moment(value).startOf('week');
            setCurrentWeekStart(newWeekStart);
        } else {
            console.log('No date selected');
        }
    }

    return (
        <div className="flex wrap pb-sm">
            {/* Project Filter */}
            <CreatableInput
                initValue={initialProject}
                className="mr-sm"
                isValidNewOption={() => false}
                name="selectedProject"
                placeholder={text?.projects?.details?.selectProject}
                options={projects
                    ?.map((project) => ({
                        label: project?.projectName,
                        value: project?.id,
                    }))}
                onChange={handleProjectChange}
                handleClear={handleProjectClear}
            />

            {/* Employee Filter */}
            <ConditionalRender renderIf={(user?.roleName === 'Admin' || (user?.roleName !== 'Admin' && project?.value && !isEmpty(employees)))}>
                <CreatableInput
                    initValue={initialEmployee}
                    isValidNewOption={() => false}
                    name="selectedEmployee"
                    placeholder={text?.employees?.landing?.selectEmployee}
                    options={employees
                        ?.filter((emp) => emp?.id !== user?.id)
                        ?.map((emp) => ({
                            label: `${emp?.firstName} ${emp?.lastName} (${emp?.email})`,
                            value: emp?.id,
                        }))}
                    handleClear={handleEmployeeClear}
                    onChange={handleEmployeeChange}
                />
            </ConditionalRender>

            <StandaloneDatePicker
                onChange={handleDateChange}
                isClearable={true}
                minDate='2021-01-01' // Minimum date allowed
                onClear={() => setCurrentWeekStart(moment().startOf('week'))}
            />
        </div>
    );
};

export default CalendarLogsFilters;
