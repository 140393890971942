import React, { useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faReply } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from '../Core/ConditionalRender';
import SenderName from '../Chat/SenderName';
import EditableField from '../Core/Inputs/EditableField';
import { useLanguage } from '../context/LanguageContext';
import colors from '../globalStyles.scss';
import ConfirmModal from '../Core/ConfirmModal';
import HoverPopup from '../Core/HoverPopup';
import { useTheme } from '../context/ThemeContext';
import useScreenSize from '../context/useScreenSize';
import styled from 'styled-components'
import OutsideClickHandler from '../Core/OutsideClickHandler';
import { isEmpty } from 'lodash';
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader';
import ReplyWrapper from './ReplyWrapper';
import { useDropzone } from 'react-dropzone';


const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme === 'light' ? colors.darkGray : colors.lightBlue};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'light') && colors.lightGray)};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'dark') && colors.backgroundSecondary)};
    min-width: ${(props) => (props.isDesktop ? '80%' : '100%')};
    justify-self: ${(props) => (props.isOwnMessage ? 'flex-start' : 'flex-end')};
    align-self: ${(props) => (props.isOwnMessage ? 'flex-start' : 'flex-end')};
    position: relative;
    padding: 1em;
    margin-top: 1em;
    .buttons {
        opacity: ${(props) => (props.isEditing ? 1 : 0)};
        transition: opacity 0.1s ease-out 0s;
    }
    &:hover {
        .buttons {
            opacity: 1;
        }
    }

    p, span {
        font-family: ${colors.openSans};
        text-align: left;
        strong {
            font-weight: bold;
        }
        em {
            font-style: italic;
        }
        s {
            text-decoration: line-through;
        }
    }

    .reaction-container {
        position: relative;
        cursor: pointer;
    }

    .reaction-count {
        position: absolute;
        top: 0;
        right: -5px;
        font-size: 0.5em;
    }

    .custom-tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: 0.75em;
        white-space: nowrap;
        display: none; /* Initially hidden */
        z-index: 1000;
    }

    .reaction-container:hover .custom-tooltip {
        display: block; /* Show the tooltip */
    }


`

const ReactionsPopup = styled.div`
    position: absolute;
    top: 0; 
    left: -4em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${(props) => ((props.theme === 'dark' && props.allowEdits) ? colors.backgroundSecondary : colors.secondary)};
    padding: 0.5em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;

    .reaction {
        cursor: pointer;
        font-size: 1.2em;
        margin: 0 0.5em;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
        }
    }
`;

const Reactions = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    bottom: -5px;
    left: 0;
    cursor: pointer;
    .reaction {
        font-size: 1.2em;
        margin: 0 0.2em;
        transition: transform 0.2s ease;
        &:hover {
            transform: scale(1.2);
        }
    }
`

const MessageWrapper = ({
    index,
    message,
    handleSave,
    handleReply,
    handleCancel,
    handleDeleteComment,
    toggleDeleteModal,
    selectedCommentId,
    handleToggleDelete,
    user,
    projectId,
    processImages,
    name,
    isChat,
    senderFullName,
    createdAt,
    profilePhoto,
    value,
    isProject,
    isClient,
    updateReaction,
    editorProps,
    setReplyImgs,
    replyImgs,
    handleReplyImageChange,
    isSendingMessage
}) => {
    const { text } = useLanguage();
    let { theme } = useTheme();
    theme = isClient ? 'dark' : theme;
    const { isDesktop } = useScreenSize();
    const [isEditing, setIsEditing] = useState(false);
    const [showReactions, setShowReactions] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const allowEdits = isChat ? user?.id === message?.sender?._id : user?.id === message?.commentedBy?.id;

    const reactionTypes = [
        { value: 'like', label: '👍', id: 0 },
        { value: 'love', label: '❤️', id: 1 },
        { value: 'laugh', label: '😂', id: 2 },
        { value: 'sad', label: '😢', id: 3 },
        { value: 'angry', label: '😡', id: 4 },
    ];


    const handleReactionClick = (reaction, messageId) => {
        setShowReactions(false);
        updateReaction(messageId, reaction, user?.id);
    };
    const [imageError, setImageError] = useState('')

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            updateReaction((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];
                processImages({ images: updatedImages, message, projectId });
                return updatedImages;
            });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            key={index}
            theme={isClient ? 'dark' : theme}
            isOwnMessage={allowEdits}
            isEditing={isEditing}
            isDesktop={isDesktop}
        >
            <OutsideClickHandler
                className='flex flex-column'
                onOutsideClick={(event) => {
                    const modal = document.querySelector('.ReactModal__Overlay');
                    if (modal && modal.contains(event.target)) {
                        return;
                    }
                    if (showReply) setShowReply(false);
                    if (showReactions) setShowReactions(false);
                    if (isEditing) setIsEditing(false);
                }}>

                <div className="w-100 flex flex-column">
                    <div className="flex flex-column">
                        <SenderName
                            isDark={isClient}
                            isOwnMessage={allowEdits}
                            message={{
                                senderFullName,
                                sender: {
                                    profilePhoto,
                                },
                                createdAt,
                            }}
                        />
                        <EditableField
                            value={value}
                            onSave={(value) => handleSave(value, message?._id)}
                            onCancel={handleCancel}
                            placeholder=""
                            allowEdit={allowEdits}
                            name={name}
                            initialValue={value}
                            message={message}
                            projectId={projectId}
                            canUpload
                            processImages={processImages}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            isClient={isClient}
                            isDark={isClient || allowEdits}
                            disabled={!allowEdits || isProject}
                            notCancelOutsideClick
                        />

                        {/* all replies go here */}
                        <ConditionalRender renderIf={!isEmpty(message?.replies)}>
                            <h4 className='mt-md' style={{
                                color: theme === 'dark' ? colors.white : colors.textSecondary,
                            }} >
                                {text?.chat?.replies}
                            </h4>
                            {
                                message?.replies?.map((message, index) => (
                                    <React.Fragment key={index}>
                                        <ReplyWrapper
                                            index={index}
                                            message={message}
                                            handleToggleDelete={handleToggleDelete}
                                            handleSave={handleSave}
                                            handleReply={handleReply}
                                            handleDeleteComment={() => {
                                                handleDeleteComment(message._id);
                                            }}
                                            selectedCommentId={selectedCommentId}
                                            user={user}
                                            projectId={message?.projectId}
                                            toggleDeleteModal={toggleDeleteModal}
                                            processImages={processImages}
                                            name={'message'}
                                            isChat
                                            senderFullName={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                                            createdAt={message?.createdAt}
                                            profilePhoto={message?.sender?.profilePhoto}
                                            value={message?.messageContent}
                                            isProject={isProject}
                                            isClient={isClient}
                                            updateReaction={updateReaction}
                                        />

                                    </React.Fragment>
                                ))
                            }
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isProject && isChat}>
                            <HoverPopup
                                noBG
                                className='mb-md mt-md'
                                isDark={isClient}
                                onClick={() => setShowReply((prev) => !prev)}
                                placement={'top'}
                                id={`reply-message-${message?._id}`}
                                text={text?.chat?.reply}
                                wrapperStyle={{
                                    padding: '0.5em',
                                    cursor: 'pointer',
                                    width: '2em',
                                    height: '2em',
                                }}
                            >
                                <FontAwesomeIcon icon={faReply} style={{
                                    color: theme === 'dark' ? colors.white : colors.primary,
                                }} />
                            </HoverPopup>
                        </ConditionalRender>
                        <ConditionalRender renderIf={showReply}>
                            <RichEditorWithImageUploader
                                onImageUpload={handleReplyImageChange}
                                isDark={isClient || allowEdits}
                                maxFiles={5}
                                customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                                title={text?.chat?.reply}
                                showForm={false}
                                onSubmit={(value) => handleReply(value, message?._id)}
                                text={text}
                                isEditing={showReply}
                                submitText={text?.tasks?.details?.button}
                                onCancel={() => setShowReply(false)}
                                name="message"
                                imagePreviews={replyImgs}
                                setImagePreviews={setReplyImgs}
                                isSendingMessage={isSendingMessage}
                                hideSave
                            />

                        </ConditionalRender>
                    </div>
                </div>
                <div
                    className="buttons"
                    style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <ConditionalRender renderIf={!isProject && updateReaction}>
                            <OutsideClickHandler onOutsideClick={() => {
                                setShowReactions(false);
                            }}>
                                <HoverPopup
                                    noBG
                                    isDark={allowEdits || isClient}
                                    onClick={() => setShowReactions((prev) => !prev)}
                                    placement={'top'}
                                    id={`reaction-message-${message?._id}`}
                                    text={text?.chat?.react}
                                    wrapperStyle={{
                                        padding: '0.5em',
                                        cursor: 'pointer',
                                        width: '2em',
                                        height: '2em',
                                    }}
                                >
                                    👍
                                </HoverPopup>

                                {showReactions && (
                                    <ReactionsPopup
                                        theme={theme}
                                        allowEdits={allowEdits}
                                    >
                                        {reactionTypes?.map((reaction) => (
                                            <span
                                                key={reaction?.value}
                                                className="reaction"
                                                onClick={() => handleReactionClick(reaction?.value, message?._id)}
                                            >
                                                {reaction?.label}
                                            </span>
                                        )
                                        )}
                                    </ReactionsPopup>
                                )}
                            </OutsideClickHandler>
                        </ConditionalRender>
                    </div>

                    <ConditionalRender renderIf={allowEdits && !isProject}>
                        <HoverPopup
                            noBG
                            isDark={allowEdits || isClient}
                            onClick={() => setIsEditing((prev) => !prev)}
                            placement={'top'}
                            id={`edit-comment-${message?._id}`}
                            text={text?.logs?.edit}
                            wrapperStyle={{
                                padding: '0.5em',
                                cursor: 'pointer',
                                width: '2em',
                                height: '2em',
                                border: 'none',
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} style={{
                                color: theme === 'dark' ? colors.white : colors.primary,
                            }} />
                        </HoverPopup>
                        <HoverPopup
                            noBG
                            isDark={allowEdits || isClient}
                            onClick={() => handleToggleDelete(message._id)}
                            placement={'top'}
                            id={`delete-comment-${message?._id}`}
                            text={text?.tasks?.details?.delete}
                            wrapperStyle={{
                                padding: '0.5em',
                                cursor: 'pointer',
                                width: '2em',
                                height: '2em',
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faClose}
                                style={{
                                    color: colors.red,
                                }}
                            />
                        </HoverPopup>

                        {toggleDeleteModal && selectedCommentId === message._id && (
                            <ConfirmModal
                                onClick={() => {
                                    handleDeleteComment(message._id);
                                    handleToggleDelete(null);
                                }}
                                text={text?.tasks?.details?.deleteModal?.button}
                                toggle={() => handleToggleDelete(null)}
                                isOpen={toggleDeleteModal}
                                btnStyles={{
                                    color: colors.red,
                                }}
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '50%' : '100%'}
                            >
                                {text?.tasks?.details?.deleteModal?.title}
                            </ConfirmModal>
                        )}
                    </ConditionalRender>
                </div>
                <ConditionalRender renderIf={message?.isEdited}>
                    <p
                        className="mt-sm"
                        style={{
                            color: allowEdits ? colors.white : colors.backgroundSecondary,
                            alignSelf: 'flex-end',
                            fontSize: '0.7em',
                        }}
                    >
                        {text?.chat?.edited}
                    </p>
                </ConditionalRender>
                <ConditionalRender renderIf={isChat}>
                    <ConditionalRender renderIf={!isEmpty(message?.reactions)}>
                        <Reactions className="flex flex-row">
                            {message?.reactions && Object?.entries(
                                message?.reactions?.reduce((acc, reaction) => {
                                    if (!acc[reaction.type]) {
                                        acc[reaction.type] = {
                                            count: 0,
                                            names: [],
                                        };
                                    }
                                    acc[reaction.type].count += 1;

                                    const name = reaction.user
                                        ? `${reaction.user.firstName} ${reaction.user.lastName}` // User name
                                        : reaction.client?.name; // Client name
                                    acc[reaction.type].names.push(name);

                                    return acc;
                                }, {})
                            )?.map(([type, { count, names }]) => (
                                <HoverPopup
                                    noBG
                                    key={type}
                                    isDark={isClient}
                                    onClick={() => handleReactionClick(type, message?._id)} // Handle reaction clicks
                                    placement={'top'}
                                    id={`reaction-message-${message?._id}-${type}`}
                                    text={names.join(', ')}
                                    style={{
                                        padding: '0 0.2em',
                                    }}
                                    wrapperStyle={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'none',
                                    }}
                                >
                                    {/* Emoji */}
                                    <span
                                        className='reaction'
                                    >
                                        {reactionTypes.find((reactionType) => reactionType.value === type)?.label}
                                    </span>

                                    {/* Count */}
                                    {count > 1 && (
                                        <span
                                            style={{
                                                fontSize: '0.6em',
                                                color: colors.textSecondary,
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                            }}
                                        >
                                            x{count}
                                        </span>
                                    )}
                                </HoverPopup>
                            ))}
                        </Reactions>
                    </ConditionalRender>
                </ConditionalRender>
            </OutsideClickHandler>
        </StyledDiv >
    );
};

export default MessageWrapper;
