import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames'
import { isEmpty } from 'lodash';
import ConditionalRender from './ConditionalRender';
import { useTheme } from '../context/ThemeContext'

import colors from '../globalStyles.scss'
import './styles.scss'

const StyledFormGroup = styled.div`
.form-group {
    div.custom-arrows {
        display: flex;
        align-self: end;
        button {
            background: ${(props) => props.disabled ? colors.lightBlue : colors.lightGray};
            margin: 0 0.2rem;
            &:hover {
                background: ${(props) => props.disabled ? colors.lightBlue : colors.blue};
                color: ${(props) => props.disabled ? colors.lightGrey : colors.white};
            }
            color: ${colors.blue};
        }
    }
    .toggle-password-visibility {
            position: absolute;
            right: -30px;
            top: 0;
            cursor: pointer;
        }

        input {
            border: none;
            border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid black'};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
            border-bottom-color: ${props => props.showLightColors && colors.black};
            background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightBlue) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
            background: ${props => props.showLightColors && colors.white};
            padding: 0.2rem;
            border-radius: 0;
            flex: 1; // Allow the input to grow
            color: ${(props) => props.theme === 'light' ? colors.black : colors.white};
        }
    label {
        display: flex;
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white};
    }
        input.error_message {
            border-bottom: ${(props) => props.value || (props.type === 'number' && props.value === 0) ? '1px solid' + colors.red : '1px solid' + colors.red};
        }
    }
    input:focus,
    textarea:focus {
      outline: 0;
      border-bottom: 1px solid ${colors.blue};
      border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
      border-bottom-color: ${props => props.theme === 'light' ? colors.black : colors.white};};

    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    transition: all 0.225s ease;
    }

        /* For Webkit browsers like Chrome, Safari */
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* For Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }

        /* For IE 10+ */
        input[type='number']::-ms-clear,
        input[type='number']::-ms-reveal {
            display: none;
        }
`;

// Wrap the component with forwardRef and receive 'ref' as the second argument
const FieldWithValidation = forwardRef(({
    label,
    name,
    component,
    type,
    validate,
    width,
    showPassword,
    togglePasswordVisibility,
    style,
    className,
    block,
    wrapperStyles,
    setIsEditing,
    autoComplete,
    disabled,
    allowNegatives = true,
    subText = '',
    min,
    info,
    showLightColors,
    fieldName,
    suggestionData = [],  // Pass the data to search through
    fields = [], // Pass the fields/columns data
    inputRef = null,
    isMail = false,
    ...rest
}, ref) => {

    const { theme } = useTheme();
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [value, setValue] = useState('');
    const [hint, setHint] = useState("");

    const hiddenTextRef = useRef(null);

    const getLastFieldName = (fieldName) => {
        if (!fieldName) return '';
        const parts = fieldName.split('.');
        return parts[parts.length - 1];
    };

    const deepSearch = (obj, searchKey) => {
        let results = [];
        for (const key in obj) {
            if (key.includes(searchKey)) {
                results.push(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                results = results.concat(deepSearch(obj[key], searchKey));
            }
        }
        return results;
    };

    const getSuggestedValues = (fieldName) => {
        const lastFieldName = getLastFieldName(fieldName);

        const mappedField = fields.find(column =>
            column.fullFieldName?.includes(lastFieldName) || column.fieldName?.includes(lastFieldName)
        );

        if (!mappedField) return [];
        let suggestions = [];
        for (const item of suggestionData) {
            const foundValues = mappedField?.fullFieldName
                ? deepSearch(item, mappedField?.fieldName)
                : [item[mappedField.fieldName]];
            suggestions = suggestions.concat(foundValues);
        }

        return suggestions.filter(value => value !== undefined);
    };

    useEffect(() => {
        if (!autoComplete) return;
        const matchedSuggestions = getSuggestedValues(fieldName);
        const uniqueSuggestions = Array.from(new Set(matchedSuggestions));
        setSuggestions(uniqueSuggestions);
    }, [showSuggestions]);

    const onInputChange = (value, originalOnChange) => {
        if (type === 'number' && !allowNegatives && value < 0) {
            value = 0;
        }

        handleOnChange(value, originalOnChange);

        if (!autoComplete) return;

        if (value) {
            const allSuggestions = getSuggestedValues(fieldName);
            const matchedSuggestions = allSuggestions?.filter(suggestion =>
                suggestion?.toLowerCase()?.startsWith(value?.toLowerCase())
            );

            if (matchedSuggestions && matchedSuggestions?.length > 0) {
                setHint(matchedSuggestions[0].slice(value?.length));
            } else {
                setHint('');
            }

            setSuggestions(matchedSuggestions);
            setShowSuggestions(true);
        } else {
            setHint('');
            setShowSuggestions(false);
        }
    };

    const onKeyDown = (e, onChange) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && hint) {
            e.preventDefault();
            const fullValue = value + hint;
            setHint('');
            onChange(fullValue);
        }
    };

    const handleOnChange = (inputValue, originalOnChange) => {
        if (inputValue === '') {
            originalOnChange('');
        } else {
            originalOnChange(inputValue);
        }
        if (setIsEditing) {
            setIsEditing(true);
        }
    };

    return (
        <StyledFormGroup
            showLightColors={showLightColors}
            theme={theme}
            type={type}
            disabled={disabled}
            value={value || rest?.initialValue || 0}
            className={`FieldWithValidation ${className}`}
            style={{
                width: width ? `${width}%` : '100%',
                display: !block ? `flex` : 'block',
                ...style
            }}
        >
            <Field name={name} validate={validate ? validate : null} {...rest}>
                {({ input, meta }) => {
                    const { onChange, ...restInput } = input;
                    setValue(input.value)
                    return (
                        <div className="form-group wide" style={wrapperStyles}>
                            <span className='input-wrapper'>
                                <div className='flex items-center '>
                                    <div className='w-100'>
                                        <label className='mb-sm b' htmlFor={type}>
                                            {label}
                                            <ConditionalRender renderIf={info}>
                                                <div style={{
                                                    color: theme === 'light' ? colors.blue : colors.white,
                                                }} className="info ml-md italic">{info}</div>
                                            </ConditionalRender>
                                        </label>
                                        <div className='w-100'>
                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    {...restInput}
                                                    className={classnames({
                                                        'error_message': meta.error && meta.touched
                                                    })}
                                                    ref={ref || inputRef}
                                                    disabled={disabled}
                                                    type={type}
                                                    id={type}
                                                    onChange={(e) => onInputChange(e.target.value, input.onChange)}
                                                    onKeyDown={(e) => onKeyDown(e, input.onChange)}
                                                    autoComplete='off'
                                                    min={min}

                                                />

                                                <span
                                                    ref={hiddenTextRef}
                                                    className="hidden-text"
                                                    style={{
                                                        visibility: 'hidden',
                                                        position: 'absolute',
                                                        fontSize: '1.1em',
                                                        whiteSpace: 'pre',
                                                        fontFamily: 'inherit',
                                                        padding: 0,
                                                        margin: 0,
                                                        top: 0,
                                                        left: 0,
                                                        pointerEvents: 'none',
                                                        paddingRight: '0.1em',
                                                    }}
                                                >
                                                    {value}
                                                </span>

                                                {/* Attach the ref to this input */}
                                                <ConditionalRender renderIf={!isEmpty(hint)}>
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: 0,
                                                            transform: 'translateY(-50%)',
                                                            color: '#DDD',
                                                            pointerEvents: 'none',
                                                            userSelect: 'none',
                                                            whiteSpace: 'nowrap',
                                                            // left: `${hiddenTextRef.current?.offsetWidth}px`,
                                                            paddingRight: '0.5em',
                                                        }}
                                                    >
                                                        {hint}
                                                    </span>

                                                </ConditionalRender>
                                                <ConditionalRender renderIf={input.value && isMail}>
                                                    <a
                                                        href={`mailto:${input.value}`}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </a>
                                                </ConditionalRender>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        type === 'number' && (
                                            <div className="custom-arrows">
                                                <button disabled={disabled} type="button"
                                                    onClick={() => {
                                                        const newValue = parseInt(input.value || 0) + 1;
                                                        handleOnChange(newValue, input.onChange);
                                                    }}
                                                >↑</button>
                                                <button disabled={disabled} type="button"
                                                    onClick={() => {
                                                        const newValue = parseInt(input.value || 0) - 1;
                                                        if (newValue > 0) {
                                                            handleOnChange(newValue, input.onChange);
                                                        }
                                                        if (newValue <= 0) {
                                                            handleOnChange(0, input.onChange)
                                                        }

                                                    }}
                                                >↓</button>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    (name === 'password' || name === 'confirmPassword') && (
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="toggle-password-visibility"
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    )
                                }
                            </span>

                            {meta.error && meta.touched && (
                                <span className='small error'>{meta.error}</span>
                            )}
                            <ConditionalRender renderIf={!isEmpty(subText)}>
                                {subText}
                            </ConditionalRender>
                        </div>
                    )
                }}
            </Field >
        </StyledFormGroup >
    )
});

export default FieldWithValidation;