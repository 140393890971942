import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FormSpy } from 'react-final-form';
import CallToAction from './CallToAction';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { btnUnderline } from '../mixins';
import colors from '../globalStyles.scss';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import ConditionalRender from './ConditionalRender';
import { useTheme } from '../context/ThemeContext';
import classnames from 'classnames';

const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    background: ${(props) => props.theme === 'light' ? colors.gostWhite : colors.background};
    h3, h4, p, label, div {
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white}};
    }
    .InputElement {
        color: ${({ theme }) => theme === 'light' ? colors.black : colors.white} !important;
    }
    &.desktop {
            width: ${(props) => props.width || '80%'};
            height: ${(props) => props.height || '80%'};
    }

    &.tablet, &.phone {
        width: ${(props) => props.width || '100%'} !important;
        height: ${(props) => props.height || '100%'} !important;
    }

    &.desktop {
        .SearchInput {
            width: 30%;
        }
        .CreatableInput {
            width: 30%;
        }
    }
    &.tablet {
        .SearchInput {
            width: 50%;
        }
        .CreatableInput {
            width: 50%;
        }
    }
    &.phone {
        .SearchInput {
            width: 100%;
        }
        .CreatableInput {
            width: 100%;
        }
    }


    transform: translate(-50%, -50%);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0 !important;
    background: $dark-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .modal-body {
        font-family: $openSans;
        flex: 1 1;
        height: 100%;
        width: 100%;
        font-size: 1.5rem;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        padding: 0.5em;
    }
`

const StyledButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;

.close {
    color: ${colors.black};
    font-family: ${colors.openSans};
  }
  .confirm {
    height: 100%;
    padding-bottom: 5px;
    position: relative;
    color: ${(props) => props?.theme === 'light' ? colors.black : colors.white};
    font-family: ${colors.openSans};
    ${(props) => btnUnderline(props?.theme === 'light' ? colors.black : colors.white)}
  }
  .save {
    height: 100%;
    padding-bottom: 5px;
    position: relative;
    color: ${(props) => props?.theme === 'light' ? colors.black : colors.white};
    font-family: ${colors.openSans};
    ${(props) => btnUnderline(props?.theme === 'light' ? colors.black : colors.white)}
  }
    .delete {
        height: 100%;
        padding-bottom: 5px;
        position: relative;
        color: ${colors.red};
        font-family: ${colors.openSans};
        ${() => btnUnderline(colors.red)}
    }
`;

const StyledBody = styled.div`
form {
    width: 100%;
  }
`;

Modal.setAppElement('#root');

const SubmitModal = ({
    isOpen,
    toggle,
    onSubmit,
    className = '',
    btnStyles,
    onClick,
    saveAsDraft,
    saveAsDraftText,
    deleteText,
    onDelete,
    text,
    disabled,
    children,
    mutators,
    width = '80%',
    height = '80%',
    formEvents = null
}) => {
    const modalText = useLanguage();
    const { theme } = useTheme();
    const [errorFields, setErrorFields] = useState([]); // State to store error fields

    const { isDesktop, isTablet, isPhone, loading } = useScreenSize();

    const formRef = useRef();  // Create a ref for the form

    const scrollToField = (fieldName) => {
        let fieldElement = null;

        // Escape square brackets for querySelector
        const safeFieldName = fieldName.replace(/\[/g, '\\[').replace(/\]/g, '\\]');

        // Try to find the field by name attribute
        fieldElement = document.querySelector(`[name="${safeFieldName}"], .${safeFieldName}`);

        // Scroll to the element if found
        if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: 'smooth' });
            fieldElement.focus();  // Optionally focus the element
        } else {
            console.error(`Could not find field with name "${safeFieldName}"`);
        }
    };

    useEffect(() => {
        if (Object.keys(errorFields).length > 0) {
            // Iterate over the error fields and scroll to the first invalid field
            for (const field in errorFields) {
                if (Array.isArray(errorFields[field])) {
                    // Handle array fields (e.g., fields[0].description)
                    errorFields[field].forEach((item, index) => {
                        for (const subField in item) {
                            // Build the name for the nested field (e.g., fields[0].description)
                            const fieldName = `${field}[${index}].${subField}`;
                            scrollToField(fieldName);
                            return; // Scroll to the first error and stop
                        }
                    });
                } else {
                    // Scroll to simple field (e.g., title, clientId)
                    scrollToField(field);
                    return; // Scroll to the first error and stop
                }
            }
        }
    }, [errorFields]);  // Run effect when errorFields change


    const handleConfirmClick = async () => {
        if (formRef.current) {
            const formState = formRef.current.getState();
            const { errors } = formState;

            if (Object.keys(errors).length > 0) {
                // Store the error field names in state
                setErrorFields(errors);
            } else {
                setErrorFields([]); // Clear error fields if no errors
            }

            // Submit the form if no errors
            formRef.current.submit();
        }
    };


    return (
        <ConditionalRender renderIf={true} isLoading={loading}>
            <StyledModal
                theme={theme}
                isOpen={isOpen}
                toggle={toggle}
                onRequestClose={toggle}
                className={classnames({
                    'confirm-modal': true,
                    [className]: className,
                    desktop: isDesktop,
                    tablet: isTablet,
                    phone: isPhone
                })}
                width={width}
                height={height}
                style={{
                    overlay: {
                        backgroundColor: colors.backgroundOverlay,
                    }
                }}
            >
                <StyledBody className='modal-body scroll-container mb-md'>
                    <Form
                        onSubmit={onClick}
                        mutators={mutators}
                        render={({ handleSubmit, form }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormSpy subscription={{ values: true }}>
                                        {({ form }) => {
                                            formRef.current = form;
                                            if (formEvents) {
                                                formEvents.current = form;
                                            }
                                            return null;
                                        }}
                                    </FormSpy>
                                    {children}
                                </form>
                            );
                        }}
                    />
                </StyledBody>
                <StyledButtons theme={theme} btnStyles={btnStyles} className='modal-buttons'>
                    <CallToAction
                        text={modalText?.text?.modal?.close}
                        onClick={toggle}
                        style={{
                            alignSelf: 'start'
                        }}
                    />
                    <div className='flex items-center'>
                        <ConditionalRender renderIf={saveAsDraft}>
                            <button className='save' onClick={saveAsDraft}>
                                {saveAsDraftText}
                            </button>
                            <span>
                                {' | '}
                            </span>
                        </ConditionalRender>
                        <ConditionalRender renderIf={onDelete}>
                            <button className='delete' onClick={onDelete}>
                                {deleteText}
                            </button>
                            <span>
                                {' | '}
                            </span>
                        </ConditionalRender>
                        <ConditionalRender renderIf={onClick && !disabled}>
                            <button className='confirm' onClick={handleConfirmClick}>
                                {text}
                            </button>
                        </ConditionalRender>
                    </div>
                </StyledButtons>
            </StyledModal>
        </ConditionalRender>
    );
};

export default SubmitModal;
