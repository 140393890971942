import React, { useState } from 'react'
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import EmployeesContainer from '../Employees/EmployeesContainer';
import Wrapper from '../Core/Wrapper'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import TasksContainer from '../Tasks/TasksContainer';
import ClientsContainer from '../Clients/ClientsContainer';
import ProposalsContainer from '../Proposals/ProposalsContainer';
import useScreenSize from '../context/useScreenSize';
import ExpandedContainer from '../Expanded/ExpandedContainer';

const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    form {
        align-self: flex-end;
        .TimeTracker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            .time {
                font-family: ${colors.roboto};
            }
        }
    }
`;


const WelcomeAdminContainer = ({
    projects,
    company,
    user,
    projectId,
    employees,
    employeesIsLoading,
    fetchCompanyEmployees,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    initialProject,
    handleChange,
    isRunning,
    fetchUserDetails,
    isCompanyPremium,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    setInventory,
    userRole,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    fetchCompanyProjects
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [expanded, setExpanded] = useState('');

    return (
        <StyledDiv
            className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}
        >
            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 15px 0 0'}
                borderRight={isDesktop && `
                ${theme === 'dark' ? colors.backgroundSecondary : colors.black} 1px solid
            `
                }>
                <EmployeesContainer
                    employees={employees}
                    userRole={userRole}
                    user={user}
                    projects={projects}
                    company={company}
                    fetchCompanyEmployees={fetchCompanyEmployees}
                    title={text?.dashboard?.employees}
                    isDashboard
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <hr />
                <TasksContainer
                    projects={projects}
                    adminProjects={projects}
                    user={user}
                    employees={employees}
                    tasks={tasks}
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    title={text?.dashboard?.tasks}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <hr />

                <ProposalsContainer
                    title={text?.homeLandingContainer?.services?.proposals?.title}
                    adminProjects={projects}
                    projects={projects}
                    user={user}
                    clients={clients}
                    proposals={proposals}
                    proposalsIsLoading={proposalsIsLoading}
                    fetchAllProposals={fetchAllProposals}
                    isAdmin
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
                <hr />
            </Wrapper>

            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 0 0 15px'}
            >
                <ClientsContainer
                    user={user}
                    fetchAllClients={fetchAllClients}
                    clients={clients}
                    clientsIsLoading={clientsIsLoading}
                    title={text?.client?.create?.clients}
                    fetchCompanyProjects={fetchCompanyProjects}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
                <hr />
                {/* Inventory */}
                <InventoryHomeContainer
                    user={user}
                    fetchUserDetails={fetchUserDetails}
                    isCompanyPremium={isCompanyPremium}
                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    setInventory={setInventory}
                    inventoryColumns={inventoryColumns}
                    setInventoryColumns={setInventoryColumns}
                    fetchAttributes={fetchAttributes}
                    projects={projects}
                    employees={employees}
                    title={text?.dashboard?.inventory}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

            </Wrapper>

            {/* Expanded Components */}
            <ExpandedContainer
                expanded={expanded}
                setExpanded={setExpanded}
                projects={projects}
                user={user}
                clients={clients}
                proposals={proposals}
                proposalsIsLoading={proposalsIsLoading}
                fetchAllProposals={fetchAllProposals}

                fetchAllClients={fetchAllClients}
                clientsIsLoading={clientsIsLoading}
                fetchCompanyProjects={fetchCompanyProjects}

                employees={employees}
                tasks={tasks}
                tasksIsLoading={tasksIsLoading}
                fetchProjectTasks={fetchProjectTasks}

                userRole={userRole}
                company={company}
                fetchCompanyEmployees={fetchCompanyEmployees}
                title={text?.dashboard?.employees}
                isDashboard

                fetchUserDetails={fetchUserDetails}
                isCompanyPremium={isCompanyPremium}
                inventory={inventory}
                fetchInventoryProducts={fetchInventoryProducts}
                inventorysLoading={inventorysLoading}
                setInventory={setInventory}
                inventoryColumns={inventoryColumns}
                setInventoryColumns={setInventoryColumns}
                fetchAttributes={fetchAttributes}


            />
        </StyledDiv>
    )
}

export default WelcomeAdminContainer